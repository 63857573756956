export const dialogTitle = "Quick Question before you Check-In";

export const dialogParagraphs = ["Have you updated <b>The Plan</b> you put in place?", "If not, you'll want to..."];

export const dialogToDoList = [
    "Close out completed Key Results or Objective",
    "Close out completed Priority Actions",
    "Add Priority Actions you want to complete this month",
];

export const planLinkPrompt = "To access your Plan, click...";
export const planLinkText = "The Plan";
export const planLinkUrl = "/employee/master-plan";
export const closingParagraphs = ["The Plan update resets your focus and gets you to your goal faster!"];

export const doneButtonText = "Done";
