import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useStyles } from "./divisions.styles";

interface DivisionsContainerProps {
    children: React.ReactNode;
}

const DivisionsContainer = ({ children }: DivisionsContainerProps) => {
    const classes = useStyles();

    return <Box className={classes.divisionsContainer}>{children}</Box>;
};

DivisionsContainer.propTypes = {
    children: PropTypes.node,
};

export { DivisionsContainer };
