import { Box, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

interface CategoryTitleProps {
    title: string;
    subheading?: string;
    append?: string;
    style?: React.CSSProperties;
    prepend?: string;
    fontWeight?: number;
}

function CategoryTitle({ title, subheading, append, style, prepend, fontWeight = 500 }: CategoryTitleProps) {
    return (
        <Box style={style}>
            <Typography variant="subtitle1" style={{ fontWeight }}>
                {prepend && (
                    <span>
                        <span
                            style={{
                                color: "#FFB84C",
                                fontWeight,
                                fontSize: 18,
                                borderLeft: "8px solid #FFB84C",
                                borderRadius: 4,
                                paddingLeft: 12,
                            }}
                        >
                            {prepend}
                        </span>{" "}
                        -{" "}
                    </span>
                )}
                {title}
                {append && " - " + append}
            </Typography>
            {subheading && (
                <Typography variant="caption" style={{ fontSize: "0.8rem" }}>
                    {subheading}
                </Typography>
            )}
        </Box>
    );
}

CategoryTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    append: PropTypes.string,
    style: PropTypes.object,
};

export default CategoryTitle;
