import { Checkbox, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API } from "../../util/api";
import Avatar from "../avatar";
import TabPanel from "./tab-panel";

function CoachesTab(props) {
    const { value, index, user } = props;
    const [coaches, setCoaches] = useState([]);
    const [userData, setUserData] = useState({});
    const queryClient = useQueryClient();
    const allCoachesQuery = useQuery(["getAllCoaches"], () => {
        return API.findUsers({ "permissions.isCoach": true, isDeleted: false });
    });
    const userMutation = useMutation(
        (user) => {
            return API.updateUser(user);
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(["getUser", user.id], data);
                queryClient.invalidateQueries("fetchTeam");
            },
        }
    );

    useEffect(() => {
        if (user) {
            setUserData(user);
        }
    }, [user]);

    useEffect(() => {
        if (allCoachesQuery.isSuccess) {
            setCoaches(allCoachesQuery.data.users);
        }
    }, [allCoachesQuery]);

    const isCoach = (id) => {
        return userData?.coaches?.includes(id);
    };

    const handleChange = (event) => {
        const { checked, name } = event.target;
        if (checked) {
            userData.coaches.push(name);
        } else {
            userData.coaches = userData.coaches.filter((coachID) => coachID !== name);
        }
        userMutation.mutate(userData);
    };

    const getNormalizedName = (name) => {
        if (!name) {
            return "";
        }
        return name.toLowerCase();
    };

    const sortByLastName = (coachA, coachB) => {
        const lastNameA = getNormalizedName(coachA.lastName);
        const lastNameB = getNormalizedName(coachB.lastName);

        if (lastNameA < lastNameB) {
            return -1;
        }
        if (lastNameA > lastNameB) {
            return 1;
        }

        return 0;
    };

    const getCoachesSortedByLastName = () => {
        if (!coaches) {
            return [];
        }
        return coaches.sort(sortByLastName);
    };

    return (
        <TabPanel index={index} value={value}>
            <Grid container style={{ padding: 48 }} spacing={4}>
                <Typography variant="subtitle1" style={{ marginBottom: 12 }}>
                    Assign Coaches
                </Typography>
                {getCoachesSortedByLastName().map((coach) => (
                    <Grid container item justify="center" spacing={4} alignItems="center" key={coach.id}>
                        <Grid item>
                            <Avatar id={coach.id} borderWidth={1} diameter={32} />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                {coach.firstName} {coach.lastName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                color="primary"
                                checked={isCoach(coach.id)}
                                onChange={handleChange}
                                name={coach.id}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </TabPanel>
    );
}

CoachesTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default CoachesTab;
