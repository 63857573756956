import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API } from "../util/api";
import TrackedItemCalendarDay from "./tracked-item-calendar-day";

const useStyles = makeStyles((theme) => ({
    box: {
        position: "relative",
        width: 64,
        height: 64,
        border: "1px solid #e0e0e1",
        fontSize: 14,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    date: {
        position: "absolute",
        top: 4,
        right: 4,
        fontSize: 12,
        fontWeight: 300,
        color: "rgba(70,71,73,1)",
    },
}));

function TrackedItemCalendarWeek(props) {
    const { days, trackedItemId, goal = 0, weekIndex } = props;
    const classes = useStyles();
    const trackedItemQuery = useQuery(["getTrackedItem", trackedItemId]);
    const { dailyProgress, id, weeklyGoals } = trackedItemQuery.data;
    const queryClient = useQueryClient();
    const trackedItemMutation = useMutation(
        (trackedItem) => {
            return API.updateTrackedItem(trackedItem);
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(["getTrackedItem", id], data);
                queryClient.invalidateQueries("getTrackedItem");
                queryClient.invalidateQueries("fetchTrackedItems");
            },
        }
    );

    const getProgress = (day) => {
        return dailyProgress[day] || "";
    };

    const getDayValue = (day) => {
        //blank date boxes are filled with 0
        const isRealDay = day > 0;
        //daily progress is not initialized
        const value = dailyProgress[day] ?? 0;

        return isRealDay ? value : 0;
    };

    const getWeekProgress = () => {
        let progress = 0;
        days.forEach((day) => (progress += getDayValue(day)));
        return progress;
    };

    const handleChange = (day, value) => {
        dailyProgress[day] = value;
        trackedItemMutation.mutate({ id, dailyProgress });
    };

    const handleGoalChange = (index, value) => {
        weeklyGoals[index] = value;
        trackedItemMutation.mutate({ id, weeklyGoals });
    };

    return (
        <Grid container item style={{ flexWrap: "nowrap" }}>
            {days.map((day) => (
                <TrackedItemCalendarDay day={day} value={getProgress(day)} onChange={handleChange} />
            ))}
            <Box className={classes.box}>{getWeekProgress()}</Box>
            <TrackedItemCalendarDay
                day={weekIndex}
                value={goal}
                onChange={handleGoalChange}
                forceEditable={true}
                showDate={false}
            />
        </Grid>
    );
}

export default TrackedItemCalendarWeek;
