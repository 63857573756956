import React from "react";
import PropTypes from "prop-types";
import { Cell } from "./index";

interface MonthCellProps {
    children: React.ReactNode;
}

const HeaderCell = ({ children }: MonthCellProps) => {
    return <Cell>{children}</Cell>;
};

HeaderCell.propTypes = {
    children: PropTypes.node,
};

export { HeaderCell };
