import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./divisions.styles";

interface DivisionListProps {
    children?: React.ReactNode;
}

const DivisionList = ({ children }: DivisionListProps) => {
    const classes = useStyles();

    if (React.Children.count(children) === 0) {
        return (
            <Typography style={{ width: "100%" }} align="center">
                No Divisions found.
            </Typography>
        );
    }
    return <Box className={classes.divisionList}>{children}</Box>;
};

DivisionList.propTypes = {
    children: PropTypes.node,
};

export { DivisionList };
