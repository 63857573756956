import { CRUD } from "../interfaces/crud";
import { Endpoint } from "../interfaces/endpoint";
import { HttpClient } from "../interfaces/http-client";

export class CRUDEndpoint implements CRUD, Endpoint {
    httpClient: HttpClient;
    url: string;

    constructor(httpClient: HttpClient, url: string) {
        this.httpClient = httpClient;
        this.url = url;
    }

    create(attrs: any): Promise<any> {
        return this.httpClient.post(this.url, attrs);
    }

    delete(id: string | number): Promise<any> {
        const url = this.concatUrlAndId(id);
        return this.httpClient.delete(url);
    }

    find(params: any): Promise<any> {
        return this.httpClient.get(this.url, params);
    }

    get(id: string | number): Promise<any> {
        const url = this.concatUrlAndId(id);
        return this.httpClient.get(url);
    }

    update(params: any, id: string | number): Promise<any> {
        const url = this.concatUrlAndId(id);
        return this.httpClient.patch(url, params);
    }

    private concatUrlAndId(id: string | number): string {
        return this.url + "/" + id;
    }
}
