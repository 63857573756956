import { CSSProperties } from "react";

export const discussionPageName = "Discussion";
export const objectiveSectionTitle = "Objective";
export const keyResultSectionTitle = "Key Result";

export const avatarProps = {
    diameter: 32,
    borderWidth: 1,
};

export const postButtonStyles: CSSProperties = { marginTop: "24px", alignSelf: "flex-end" };
