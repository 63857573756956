import { Card, Grid, makeStyles } from "@material-ui/core";
import * as PropTypes from "prop-types";
import React, { ChangeEvent } from "react";
import SearchTextField from "./search-text-field";
import CoachTeamMeetingFilters from "./coach-team-meeting-filters";
import { SearchFilter } from "../config";

const useStyles = makeStyles((theme) => ({
    searchCard: {
        padding: theme.spacing(5, 7),
        minHeight: theme.spacing(9),
        display: "flex",
        alignItems: "center",
    },
}));

interface SearchCardProps {
    searchTerm: string;
    onSearchTermChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
    filter: SearchFilter;
    onFilterChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
}

function SearchCard({ searchTerm, onSearchTermChange, filter, onFilterChange }: SearchCardProps) {
    const classes = useStyles();
    return (
        <Card className={classes.searchCard}>
            <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                    <SearchTextField value={searchTerm} onChange={onSearchTermChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CoachTeamMeetingFilters value={filter} onChange={onFilterChange} />
                </Grid>
            </Grid>
        </Card>
    );
}

SearchCard.propTypes = {
    searchTerm: PropTypes.string,
    onSearchTermChange: PropTypes.func,
    filter: PropTypes.string,
    onFilterChange: PropTypes.func,
};

export default SearchCard;
