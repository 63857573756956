import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: "24px 32px",
        marginBottom: theme.spacing(6),
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "16px",
    },
}));
