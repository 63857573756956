import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";
import { Pricing } from "./pricing";
import { QuestionsLink } from "./questions-link";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        maxWidth: theme.spacing(15),
        marginTop: theme.spacing(4),
    },
}));

function RegisterStepOne(props) {
    const classes = useStyles();
    const { onUserChange, nextStep, onCompanyChange, user, company } = props;
    const [stepCompleted, setStepCompleted] = useState(false);

    const checkCompletion = () => {
        const { name } = company;
        const { firstName, lastName, email, password1, password2 } = user;
        setStepCompleted(name && firstName && lastName && email && password1 && password2);
    };

    const handleCompanyChange = (event) => {
        checkCompletion();
        onCompanyChange(event);
    };

    const handleUserChange = (event) => {
        checkCompletion();
        onUserChange(event);
    };

    const createTextField = (attrs) => {
        return <MyCheckinTextField {...attrs} fullWidth onChange={handleUserChange} value={user[attrs.name]} />;
    };

    return (
        <Grid container className={classes.formWrapper} spacing={5}>
            <Grid container item xs={12}>
                <MyCheckinTextField
                    name="name"
                    label="Company Name"
                    fullWidth
                    onChange={handleCompanyChange}
                    value={company.name}
                />
            </Grid>
            <Grid container item spacing={6}>
                <Grid item md={6} xs={12}>
                    {createTextField({ name: "firstName", label: "First Name" })}
                </Grid>
                <Grid item md={6} xs={12}>
                    {createTextField({ name: "lastName", label: "Last Name" })}
                </Grid>
            </Grid>
            <Grid container item spacing={6}>
                <Grid item md={6} xs={12}>
                    {createTextField({ name: "email", label: "Email Address" })}
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography>Your email address will also be your username</Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={6}>
                <Grid item md={6} xs={12}>
                    {createTextField({ name: "password1", label: "Password", type: "password" })}
                </Grid>
                <Grid item md={6} xs={12}>
                    {createTextField({ name: "password2", label: "Repeat Password", type: "password" })}
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Pricing />
            </Grid>
            <Grid container item justify="space-between">
                <QuestionsLink />
                <MyCheckinButton variant="contained" color="primary" onClick={nextStep} disabled={!stepCompleted}>
                    Next
                </MyCheckinButton>
            </Grid>
        </Grid>
    );
}

RegisterStepOne.propTypes = {
    company: PropTypes.object,
    user: PropTypes.object,
    onCompanyChange: PropTypes.func,
    onUserChange: PropTypes.func,
    nextStep: PropTypes.func,
};

export default RegisterStepOne;
