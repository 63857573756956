import { Box, Container, makeStyles } from "@material-ui/core";
import React from "react";
import usePermissions from "../hooks/use-permissions";
import NavLink from "./nav-link";
import AdminLinks from "./nav/admin-links";
import { urls } from "../config/urls";
import { menuLabels } from "../config/menu-labels";
import { useCompanySettings } from "../hooks/use-company-settings";
import { useGetApi } from "../hooks/api/use-get-api";
import { companyAPI } from "../util/api";
import { queryKeys } from "../hooks/api/config/query-keys";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    navLinkContainer: {
        height: theme.spacing(7),
        display: "flex",
        flexDirection: "row",
    },
}));

function CoachNav(props) {
    const classes = useStyles();
    const { isAdmin } = usePermissions();
    const { isTeamshareEnabled } = useCompanySettings();
    const { data: company } = useGetApi(companyAPI, queryKeys.company.get, "");

    return (
        <Container className={classes.wrapper}>
            <Box className={classes.navLinkContainer}>
                <NavLink to={urls.coach.employeePanel.baseUrl} text={menuLabels.employeePanel} />
                {isTeamshareEnabled && (
                    <NavLink
                        to={urls.coach.divisions.baseUrl}
                        text={company?.organizationalUnitName?.plural || menuLabels.divisions}
                    />
                )}
                {isAdmin && <AdminLinks />}
                <NavLink to={urls.learnVault.homePage.baseUrl} text={menuLabels.learnVault} external />
            </Box>
            <Box className={classes.navLinkContainer}>
                <NavLink to={urls.employee.dashboard.baseUrl} text={menuLabels.employeeToggle} />
            </Box>
        </Container>
    );
}

export default CoachNav;
