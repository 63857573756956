import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "12px 32px 24px 32px",
        margin: "32px 0px",
    },
    breadcrumbs: {
        width: "100%",
        height: "18px",
        padding: "2px 0px",
    },
    breadcrumbLink: {
        color: "#202122",
    },
    breadcrumbText: {
        color: "#929497",
    },
    profileSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 0,
        margin: "18px 0px 48px 0px",
    },
    profileWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 0,
        margin: "12px 0px",
    },
    name: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 36,
        lineHeight: "44px",
        textAlign: "center",
        letterSpacing: "-1px",
        margin: "4px 0px",
    },
    jobTitle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 24,
        lineHeight: "29px",
        textAlign: "center",
        margin: "4px 0px",
    },
    sectionTitle: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "22px",
        margin: "8px 0px",
    },
    okrSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        maxWidth: 768,
        marginBottom: "36px",
        width: "100%",
    },
    okrContent: {
        maxWidth: 640,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        lineHeight: "20px",
        margin: "12px 0",
        flexGrow: 1,
    },
    okrContentWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    spacer: {
        width: 64,
        minWidth: 64,
    },
    buttonWrapper: {
        width: 48,
        height: 48,
        margin: 8,
        color: "#000",
    },
}));
