import React, { useState } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge, Menu, Typography } from "@material-ui/core";
import { useStyles } from "./top-nav.styles";
import { useFindApi } from "../../hooks/api/use-find-api";
import { notificationAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { NotificationList } from "./notification-list";
import { ViewAllLink } from "./view-all-link";
import { NotificationListItem } from "./notification-list-item";
import { Notification } from "../../interfaces/notification";
import Avatar from "../avatar";
import { useNotificationFns } from "../../hooks/use-notification-fns";
import { notificationRefetchInterval } from "../../config/query-config";

const notificationQueryOptions = { refetchInterval: notificationRefetchInterval };

const NotificationMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const findNotificationsResult = useFindApi<Notification>(
        notificationAPI,
        queryKeys.notification.find,
        //@ts-ignore
        { limit: 5 },
        "notifications",
        notificationQueryOptions
    );
    const findUnreadNotificationsResult = useFindApi<Notification>(
        notificationAPI,
        queryKeys.notification.find,
        { isUnread: true },
        "notifications",
        notificationQueryOptions
    );

    const { getNotificationText, getLink, handleNotificationClick } = useNotificationFns();

    const notifications = findNotificationsResult.data.result;

    const handleClick = (event: React.MouseEvent<Element>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Badge badgeContent={findUnreadNotificationsResult.data.count} color="secondary">
                <NotificationsIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.notificationIcon}
                />
            </Badge>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.notificationMenu}
            >
                <NotificationList>
                    {notifications.map((notification) => (
                        <NotificationListItem
                            key={notification.id}
                            isUnread={!!notification.isUnread}
                            link={getLink(notification)}
                            onClick={() =>
                                handleNotificationClick({ id: notification.id, isUnread: notification.isUnread })
                            }
                        >
                            <Avatar id={notification.initiatingUser || ""} borderWidth={1} diameter={24} />
                            <Typography className={classes.notificationText}>
                                {getNotificationText(notification)}
                            </Typography>
                        </NotificationListItem>
                    ))}
                </NotificationList>
                <ViewAllLink />
            </Menu>
        </>
    );
};

export { NotificationMenu };
