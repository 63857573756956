import { useState } from "react";
import { User } from "../interfaces/user";

const getYearsArray = (userCreatedDate: Date) => {
    const years = [];
    const createdYear = userCreatedDate.getFullYear();
    for (let i = createdYear; i <= new Date().getFullYear(); i++) {
        years.push(i);
    }
    return years;
};

export const useYear = (user: User) => {
    if (!user.createdAt) {
        user.createdAt = new Date();
    }
    const userCreatedDate = new Date(user.createdAt);
    const [year, setYear] = useState(new Date().getFullYear());
    const [yearsArray] = useState(getYearsArray(userCreatedDate));

    return {
        year,
        setYear,
        yearsArray,
    };
};
