import React from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import MyCheckinButton from "../../my-checkin-button";
import { sub } from "date-fns";

const MonthlyCheckInButton = () => {
    const today = new Date();
    const lastMonth = sub(today, { months: 1 });
    const { url } = useRouteMatch();

    const getLink = (year: number, month: number) => {
        const separator = "/";
        const urlParts = url.split(separator);
        //Filter out master-plan from url, because urls were inconsistent on coach side forcing stuff like this.
        const filteredUrlParts = urlParts.filter((part: string) => part !== "master-plan");
        const filteredUrl = filteredUrlParts.join(separator);

        if (filteredUrl.startsWith("/employee")) {
            return `monthly-check-in/${year}/${month}`;
        } else if (filteredUrl.startsWith("/coach/")) {
            return `${filteredUrl}/monthly-check-in/${year}/${month}`;
        }
    };

    const monthlyCheckInUrl = getLink(lastMonth.getFullYear(), lastMonth.getMonth());

    return (
        <MyCheckinButton variant="contained" color="primary" component={RouterLink} to={monthlyCheckInUrl}>
            Monthly Check-In
        </MyCheckinButton>
    );
};

export default MonthlyCheckInButton;
