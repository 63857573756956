import React from "react";
import { CardActions } from "@material-ui/core";

interface AccountDeactivatedActionsProps {
    children: any;
}

function AccountDeactivatedActions({ children }: AccountDeactivatedActionsProps) {
    return <CardActions>{children}</CardActions>;
}

export { AccountDeactivatedActions };
