import React from "react";
import { useQueryClient } from "react-query";
import { useActivityRecordState } from "../../../hooks/states/use-activity-record-state";
import MasterPlanAlertDialog from "../master-plan-alert-dialog";
import { useActivityRecordMutation } from "../../../hooks/mutations/use-activity-record-mutation";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";

function MonthlyCheckInAlerts() {
    const { user, month, year, viewingAs } = useMonthlyCheckInState();
    const viewingAsEmployee = viewingAs === "employee";
    const activityRecordParams = {
        user: user?.id || "",
        month,
        year,
    };
    const { activityRecord, isSuccess } = useActivityRecordState(activityRecordParams);
    const { monthlyResultsMasterPlanAlertViewed } = activityRecord.alerts;
    const queryClient = useQueryClient();
    const activityRecordMutation = useActivityRecordMutation(activityRecordParams, {
        onSuccess: () => queryClient.invalidateQueries("fetchActivityRecord"),
    });

    const handleMasterPlanAlertDialogClose = () => {
        activityRecordMutation.mutate({ alerts: { monthlyResultsMasterPlanAlertViewed: true } });
    };

    return viewingAsEmployee ? (
        <MasterPlanAlertDialog
            open={!monthlyResultsMasterPlanAlertViewed && isSuccess}
            onClose={handleMasterPlanAlertDialogClose}
        />
    ) : (
        <div />
    );
}

export { MonthlyCheckInAlerts };
