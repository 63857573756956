import React from "react";
import PropTypes from "prop-types";
import { Text, StyleSheet, View } from "@react-pdf/renderer";

interface CompanyNameProps {
    children: string;
}

const styles = StyleSheet.create({
    companyName: {
        position: "absolute",
        bottom: 12,
        right: 20,
        fontSize: 16,
        fontWeight: 600,
    },
});

const CompanyName = ({ children }: CompanyNameProps) => {
    return (
        <View style={styles.companyName} fixed>
            <Text>{children}</Text>
        </View>
    );
};

CompanyName.propTypes = {
    children: PropTypes.string,
};

export { CompanyName };
