import { MeetingStatus } from "../../../types/meeting-status";

interface UseIsResultSectionDisabledParams {
    isSubmitted: boolean;
    viewingAsCoach: boolean;
}

export const useIsResultSectionDisabled = ({ isSubmitted, viewingAsCoach }: UseIsResultSectionDisabledParams) => {
    return () => isSubmitted || viewingAsCoach;
};

interface UseIsTakeawaySectionDisabledParams {
    viewingAsCoach: boolean;
    meetingStatus: MeetingStatus;
    isMeetingStarted: boolean;
}

export const useIsTakeawaySectionDisabled = ({
    viewingAsCoach,
    meetingStatus,
    isMeetingStarted,
}: UseIsTakeawaySectionDisabledParams) => {
    return () => !(viewingAsCoach && meetingStatus === "scheduled" && isMeetingStarted);
};

interface UseIsTopDiscussionTopicDisabledParams {
    isSubmitted: boolean;
    viewingAsCoach: boolean;
}

export const useIsTopDiscussionTopicDisabled = ({
    isSubmitted,
    viewingAsCoach,
}: UseIsTopDiscussionTopicDisabledParams) => {
    return () => isSubmitted || viewingAsCoach;
};
