import React, { ChangeEvent } from "react";
import PropTypes from "prop-types";
import MyCheckinTextField from "../../my-checkin-text-field";

interface SearchTextFieldProps {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
}

function SearchTextField({ value, onChange }: SearchTextFieldProps) {
    return <MyCheckinTextField label="Search" fullWidth value={value} onChange={onChange} />;
}

SearchTextField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default SearchTextField;
