import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useUserMutation } from "../../hooks/mutations/use-user-mutation";
import { useCurrentUser } from "../../hooks/queries/use-current-user";
import TabPanel from "../coach-station/tab-panel";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";

const useStyles = makeStyles((theme) => ({
    formContainer: { padding: theme.spacing(8) },
}));

const fields = [
    {
        name: "email",
        label: "Email Address",
        helperText: (
            <Typography align="right" style={{ fontSize: 12 }}>
                *When changing, this will be the email address you log in with
            </Typography>
        ),
    },
    {
        name: "firstName",
        label: "First Name",
    },
    {
        name: "lastName",
        label: "Last Name",
    },
];

function AccountInfoTab(props) {
    const classes = useStyles();
    const { index, value } = props;
    const { status, data } = useCurrentUser();
    const [user, setUser] = useState({});
    const [error, setError] = useState("");
    const userMutation = useUserMutation({
        onError: (data) => {
            const message = data.response.data.errors.map((err) => err.message).join(", ");
            console.log(data.response.data.errors.map((err) => err.message).join(", "));
            setError(message);
        },
    });

    useEffect(() => {
        if (status === "success") {
            setUser(data);
        } else {
            setUser({});
        }
    }, [status, data]);

    const handleChange = (event) => {
        userMutation.reset();
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
    };

    const getTextField = ({ name, label, ...others }) => {
        return (
            <MyCheckinTextField
                onChange={handleChange}
                fullWidth
                value={user[name]}
                name={name}
                label={label}
                {...others}
            />
        );
    };

    const handleSave = (event) => {
        event.preventDefault();
        setError("");
        userMutation.mutate(user);
    };

    return (
        <TabPanel index={index} value={value}>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <form>
                    {userMutation.isSuccess && <Alert severity="success">Saved</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                    <Grid container className={classes.formContainer} spacing={4}>
                        {fields.map((field) => (
                            <Grid container item xs={12}>
                                {getTextField(field)}
                            </Grid>
                        ))}
                        <Grid container item justify="flex-end">
                            <Grid item>
                                <MyCheckinButton variant="outlined" type="submit" onClick={handleSave}>
                                    Save
                                </MyCheckinButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </TabPanel>
    );
}

AccountInfoTab.propTypes = {
    index: PropTypes.number,
    value: PropTypes.number,
};

export default AccountInfoTab;
