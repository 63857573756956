import React, { useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import ContainedIconButton from "./contained-icon-button";
import GoalAccomplishedDialog from "./goal-accomplished-dialog";

function GoalAccomplishedButton(props) {
    const { goal, ...others } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <ContainedIconButton onClick={handleDialogOpen} {...others}>
                <CheckIcon />
            </ContainedIconButton>
            <GoalAccomplishedDialog open={dialogOpen} onClose={handleDialogClose} goal={goal} />
        </>
    );
}

export default GoalAccomplishedButton;
