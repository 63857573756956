import React from "react";
import TermlyEmbed from "./termly-embed";

const privacyNoticeID = "52f1918d-f868-4b04-a21c-10ed5139f49e";

function PrivacyNotice() {
    return <TermlyEmbed id={privacyNoticeID} />;
}

export default PrivacyNotice;
