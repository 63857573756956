import { useContext } from "react";
import { MonthlyCheckInContext } from "../context/monthly-check-in-context";

export const useMonthlyCheckInState = () => {
    const state = useContext(MonthlyCheckInContext);

    return {
        ...state,
    };
};
