import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px 32px",
    },
    divisionName: {
        fontWeight: 500,
        fontSize: 36,
        lineHeight: "44px",
        textAlign: "center",
        letterSpacing: "-2px",
    },
    userRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px 32px",
        alignSelf: "stretch",
        flexGrow: 0,
        margin: "24px 0px",
    },
    user: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 0px 0px 24px",
        flexGrow: 0,
        maxWidth: 384,
        width: "100%",
    },
    userInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        flexGrow: 0,
        margin: "0px 32px",
    },
    userName: {
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "22px",
        textAlign: "center",
        order: 0,
        flexGrow: 0,
        margin: "4px 0px",
    },
    jobTitle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 16,
        lineHeight: "20px",
        textAlign: "center",
        flexGrow: 0,
        margin: "4px 0px",
    },
    objectiveText: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: 0,
        flexGrow: 1,
        margin: "0px 32px",
        maxWidth: 640,
    },
    actions: {
        display: "flex",
        flexGrow: 0,
        margin: "0px 32px",
        gap: 12,
    },
    objectiveTitle: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "15px",
        flexGrow: 0,
        margin: "4px 0px",
    },
    objectiveContent: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "17px",
        flexGrow: 0,
        margin: "4px 0px",
    },
    paginationWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 12,
        flexGrow: 0,
        margin: "24px 0px",
        width: "100%",
    },
    emptyStateText: {
        fontStyle: "italic",
        fontWeight: "normal",
        fontSize: 16,
        lineHeight: "17px",
        flexGrow: 0,
        margin: "32px 0px",
        textAlign: "center",
    },
    coachActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 0,
        width: "100%",
        height: 33,
        marginTop: 24,
    },
}));
