import React from "react";
import PropTypes from "prop-types";
import { Menu } from "@material-ui/core";

interface DivisionOverflowMenuProps {
    anchorEl: null | HTMLElement;
    onClose: () => unknown;
    children: React.ReactNode;
}

const DivisionOverflowMenu = ({ anchorEl, onClose, children }: DivisionOverflowMenuProps) => {
    return (
        <Menu open={Boolean(anchorEl)} keepMounted anchorEl={anchorEl} onClose={onClose}>
            {children}
        </Menu>
    );
};

DivisionOverflowMenu.propTypes = {
    anchorEl: PropTypes.node,
    onClose: PropTypes.func,
    children: PropTypes.node,
};

export { DivisionOverflowMenu };
