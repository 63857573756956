import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { getDate, getDay, getDaysInMonth, getWeekOfMonth, getWeeksInMonth, format } from "date-fns";
import React from "react";
import TrackedItemCalendarWeek from "./tracked-item-calendar-week";

const useStyles = makeStyles((theme) => ({
    header: {
        width: 64,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 14,
        fontWeight: 600,
        border: "1px solid #e0e0e1",
    },
    box: {
        position: "relative",
        width: 64,
        height: 64,
        border: "1px solid #e0e0e1",
        fontSize: 14,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    date: {
        position: "absolute",
        top: 4,
        right: 4,
        fontSize: 12,
        fontWeight: 300,
        color: "rgba(70,71,73,1)",
    },
}));

const headers = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Total", "Goal"];

function TrackedItemCalendar(props) {
    const classes = useStyles();
    const { weeklyGoals, month = 0, year = 1900, trackedItemId, progress, totalGoal } = props;

    //Initial date object, doubles as start of month
    const date = new Date(year, month);
    const daysInMonth = getDaysInMonth(date);
    const weeksInMonth = getWeeksInMonth(date);

    const isInitialized = () => {
        return !(month === 0 && year === 1900);
    };

    const getInitWeeks = (weeksInMonth) => {
        const arr = [];
        for (let i = 0; i < weeksInMonth; i++) {
            arr.push([0, 0, 0, 0, 0, 0, 0]);
        }
        return arr;
    };

    const getCalendarAsArray = () => {
        if (!isInitialized()) {
            return;
        }
        const weeksArr = getInitWeeks(weeksInMonth);
        for (let i = 1; i <= daysInMonth; i++) {
            date.setDate(i);

            const week = getWeekOfMonth(date) - 1;
            const dayOfWeek = getDay(date);

            weeksArr[week][dayOfWeek] = getDate(date);
        }
        return weeksArr;
    };

    const getMonthString = () => {
        return format(date, "MMMM");
    };

    return (
        <Grid>
            <Typography align="center" variant="h5" style={{ marginBottom: 12 }}>
                {getMonthString()}
            </Typography>
            <Grid container item>
                {headers.map((header) => (
                    <Box className={classes.header}>{header}</Box>
                ))}
            </Grid>
            {getCalendarAsArray()?.map((week, index) => (
                <TrackedItemCalendarWeek
                    days={week}
                    trackedItemId={trackedItemId}
                    goal={weeklyGoals[index] ?? 0}
                    weekIndex={index}
                />
            ))}
            <Grid container item>
                <Box className={classes.box} style={{ width: 64 * 6 }} />
                <Box className={classes.box}>Totals:</Box>
                <Box className={classes.box}>{progress}</Box>
                <Box className={classes.box}>{totalGoal}</Box>
            </Grid>
        </Grid>
    );
}

export default TrackedItemCalendar;
