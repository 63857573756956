import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(5),
    },
    breadcrumbLink: {
        color: theme.palette.neutral.dark,
        "&:visited": {
            color: theme.palette.neutral.dark,
        },
    },
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        border: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(3),
        borderRadius: 5,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: "unset",
    },
    tabPanel: {
        flexGrow: 1,
    },
}));
