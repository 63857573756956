import React from "react";
import { AccountDeactivatedContent } from "./account-deactivated-content";
import { AccountDeactivatedActions } from "./account-deactivated-actions";
import { AccountDeactivated } from "./account-deactivated";
import { SignOutButton } from "./sign-out-button";

function UserAccountDeactivated() {
    return (
        <AccountDeactivated>
            <AccountDeactivatedContent>
                This account has been deactivated. If you feel this is an error, please contact your admin.
            </AccountDeactivatedContent>
            <AccountDeactivatedActions>
                <SignOutButton />
            </AccountDeactivatedActions>
        </AccountDeactivated>
    );
}

export { UserAccountDeactivated };
