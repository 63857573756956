import React from "react";
import PropTypes from "prop-types";
import { Result } from "../../../interfaces/result";
import { PdfDoc } from "../pdf-doc";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { useMontserrat } from "../../../hooks/pdf/use-montserrat";
import { PdfPageSubtitle } from "../pdf-page-subtitle";
import { MonthlyCheckInCategory } from "./monthly-check-in-category";
import { useMasterPlanTemplate } from "../../../hooks/use-master-plan-template";
import logo from "../../../assets/images/logos/my-checkin-logo-transparent.png";
import { Takeaway } from "../../../interfaces/takeaway";

interface MonthlyCheckInPdfDocProps {
    results?: Result[];
    priorityDiscussionTopic?: string;
    takeaways?: Takeaway[];
    monthName: string;
    year: number;
    employeeName: string;
}

const styles = StyleSheet.create({
    date: {
        textAlign: "right",
        fontSize: 14,
        marginBottom: 16,
    },
    sectionWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    category: {
        marginLeft: 16,
    },
    blockLetter: {
        marginBottom: 16,
    },
});

const MonthlyCheckInPdfDoc = ({
    results = [],
    priorityDiscussionTopic = "",
    takeaways = [],
    monthName,
    year,
    employeeName,
}: MonthlyCheckInPdfDocProps) => {
    useMontserrat();
    const masterPlanTemplate = useMasterPlanTemplate();

    return (
        <PdfDoc>
            <Text style={styles.date}>
                {monthName} {year}
            </Text>
            <View style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Image src={logo} style={{ width: "60%" }} />
            </View>
            <PdfPageSubtitle style={{ fontSize: 18 }}>{employeeName}</PdfPageSubtitle>

            <MonthlyCheckInCategory
                heading={masterPlanTemplate[0].checkIn.results.description}
                results={results}
                style={styles.category}
            />

            <MonthlyCheckInCategory
                heading={"Priority Discussion Topic"}
                results={[{ id: "", description: priorityDiscussionTopic }]}
                style={styles.category}
                showNumbering={false}
            />

            <MonthlyCheckInCategory heading="Takeaways" results={takeaways} style={styles.category} />
        </PdfDoc>
    );
};

MonthlyCheckInPdfDoc.propTypes = {
    results: PropTypes.array.isRequired,
    monthName: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    employeeName: PropTypes.string,
};

export { MonthlyCheckInPdfDoc };
