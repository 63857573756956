import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
    },
    contentContainer: {
        maxWidth: theme.spacing(14),
    },
}));
