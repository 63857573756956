import React, { FormEvent, useEffect, useState } from "react";
import { Card, Grid, MuiThemeProvider, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useQueryClient } from "react-query";
import { useDialog } from "../../../hooks/use-dialog";
import MyCheckinButton from "../../my-checkin-button";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TakeawayDialog from "../../takeaway-dialog";
import { LoadingIndicator } from "../../loading-indicator";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";
import { queryKeys } from "../../../hooks/api/config/query-keys";
import { useAuthState } from "../../../hooks/use-auth-state";
import SubmissionUpdateDialog from "../../monthly-check-in/submission-update-dialog";
import { Meeting } from "../../../interfaces/meeting";
import { useUpdateApi } from "../../../hooks/api/use-update-api";
import { meetingAPI, submissionNoteAPI } from "../../../util/api";
import { dateTimePickerTheme, useStyles } from "./meeting-panel.styles";
import { statusSectionTitle } from "./config";
import { useCreateApi } from "../../../hooks/api/use-create-api";
import { SubmissionNote } from "../../../interfaces/submission-note";

const MeetingPanel = () => {
    const { user, month, year, meeting, isLoading } = useMonthlyCheckInState();
    const { user: currentUser } = useAuthState();
    const [isOwner, setIsOwner] = useState(user?.id === meeting?.user);
    const classes = useStyles();
    const takeawayDialog = useDialog({});
    const queryClient = useQueryClient();
    const meetingUpdate = useUpdateApi<Meeting>(meetingAPI, queryKeys.meeting.update, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.meeting.find);
            queryClient.invalidateQueries("fetchTeamMeetings");
        },
    });
    const updateSubmissionDialog = useDialog({ initialOpenState: false });
    const [meetingDate, setMeetingDate] = useState<Date | null>(null);
    // const saveSubmissionNote = useSaveSubmissionNote();
    const createSubmissionNote = useCreateApi<SubmissionNote>(submissionNoteAPI, queryKeys.submissionNote.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.submissionNote.find, { exact: false });
            queryClient.invalidateQueries(queryKeys.submissionNote.get);
        },
    });

    const saveSubmissionNote = async ({
        authorId,
        meetingId,
        note,
    }: Pick<SubmissionNote, "authorId" | "meetingId" | "note">): Promise<void> => {
        const newSubmissionNote: SubmissionNote = {
            authorId,
            meetingId,
            note,
        };
        await createSubmissionNote.create(newSubmissionNote);
    };

    useEffect(() => {
        if (currentUser && meeting) {
            setIsOwner(currentUser.id === meeting.user);
        } else {
            setIsOwner(false);
        }
    }, [currentUser, meeting]);

    const onAccept = (date: Date | null) => {
        if (!date) {
            return;
        }
        setMeetingDate(date);
        updateSubmissionDialog.handleOpen();
    };

    const onUpdateSubmission = async (note: string) => {
        if (!meetingDate || !currentUser?.id) {
            return;
        }
        const updatedMeeting = { ...meeting };
        updatedMeeting.date = meetingDate;
        updatedMeeting.isConfirmedBy.employee = false;
        updatedMeeting.isConfirmedBy.coach = false;
        updatedMeeting.status = "scheduling";
        meetingUpdate.update(updatedMeeting.id, updatedMeeting);

        if (note) {
            await saveSubmissionNote({ authorId: currentUser.id, meetingId: updatedMeeting.id, note });
        }
    };

    const onConfirm = (event: FormEvent) => {
        event.preventDefault();
        const updatedMeeting = { ...meeting };
        if (isOwner) {
            updatedMeeting.isConfirmedBy.employee = true;
        } else {
            updatedMeeting.isConfirmedBy.coach = true;
        }
        if (updatedMeeting.isConfirmedBy.employee && updatedMeeting.isConfirmedBy.coach) {
            updatedMeeting.status = "scheduled";
        }
        const { id, isConfirmedBy, status } = updatedMeeting;
        meetingUpdate.update(id, { isConfirmedBy, status });
    };

    const getMeetingDate = () => {
        if (!meeting || meeting.status === "no meeting") {
            return null;
        }

        return meeting.date || null;
    };

    const isConfirmButtonDisabled = () => {
        const meetingDate = getMeetingDate();
        if (isOwner) {
            return meeting?.isConfirmedBy?.employee || meetingDate === null;
        }
        if (!isOwner) {
            return meeting?.isConfirmedBy?.coach || meetingDate === null;
        }
    };

    return (
        <Card className={classes.root + " " + classes.centered}>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Grid container spacing={3}>
                    <Grid item md={2} xs={12} sm={6} className={classes.centeredX + " " + classes.rightBorder}>
                        <Grid container>
                            <Grid container item xs={12} className={classes.centeredX}>
                                <Typography
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        textTransform: "uppercase",
                                        marginBottom: 4,
                                    }}
                                >
                                    {statusSectionTitle}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} className={classes.centeredX}>
                                <Typography style={{ fontSize: 18, fontWeight: 600, textTransform: "capitalize" }}>
                                    {meeting?.status || "Not Submitted"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12} sm={6} className={classes.centeredX + " " + classes.rightBorder}>
                        <Grid container>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    className={`${classes.centered} ${classes.title}`}
                                    style={{ fontSize: 12, marginBottom: 8 }}
                                >
                                    Select a new date, or confirm the proposed date:
                                </Grid>
                            </Grid>
                            <Grid container justify="center" spacing={3}>
                                <Grid item className={classes.centered}>
                                    <MuiThemeProvider theme={dateTimePickerTheme}>
                                        <DateTimePicker
                                            value={getMeetingDate()}
                                            onChange={(date) => null} //required attribute, not needed for us. using dummy function to satisfy req
                                            label="Meeting Date"
                                            disablePast={
                                                meeting?.status !== "scheduled" && meeting?.status !== "complete"
                                            }
                                            readOnly={meeting?.status === "complete"}
                                            disabled={!meeting?.status}
                                            onAccept={onAccept}
                                            InputLabelProps={{ shrink: true, classes: { root: classes.label } }}
                                            minutesStep={5}
                                        />
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item>
                                    <MyCheckinButton
                                        variant="outlined"
                                        color="primary"
                                        onClick={onConfirm}
                                        disabled={isConfirmButtonDisabled()}
                                    >
                                        Confirm
                                    </MyCheckinButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6} className={classes.centeredX + " " + classes.rightBorder}>
                        <Grid container>
                            <Grid
                                container
                                item
                                xs={12}
                                className={`${classes.centeredX} ${classes.smallText} ${classes.title}`}
                                style={{ marginBottom: 12 }}
                            >
                                Meeting date confirmed by:
                            </Grid>
                            <Grid container direction="column" alignItems="center">
                                <Grid item className={classes.smallText} style={{ width: 128 }}>
                                    {meeting?.isConfirmedBy?.employee ? (
                                        <CheckCircleIcon color="primary" className={classes.statusIcon} />
                                    ) : (
                                        <CancelIcon color={"disabled"} className={classes.statusIcon} />
                                    )}{" "}
                                    Employee
                                </Grid>
                                <Grid item className={classes.smallText} style={{ width: 128 }}>
                                    {meeting?.isConfirmedBy?.coach ? (
                                        <CheckCircleIcon color="primary" className={classes.statusIcon} />
                                    ) : (
                                        <CancelIcon color={"disabled"} className={classes.statusIcon} />
                                    )}{" "}
                                    Coach
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6} className={classes.rightBorder}>
                        <Grid container>
                            <Grid
                                container
                                item
                                xs={12}
                                className={`${classes.centeredX} ${classes.smallText} ${classes.title}`}
                            >
                                Takeaways and notes:
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                className={`${classes.smallText} + ${classes.centered}`}
                                style={{ margin: "4px 32px", fontStyle: "italic" }}
                            >
                                {meeting?.status === "complete" ? (
                                    <MyCheckinButton
                                        onClick={takeawayDialog.handleOpen}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Takeaways
                                    </MyCheckinButton>
                                ) : (
                                    <Typography align="center" className={classes.smallText} style={{ marginTop: 8 }}>
                                        Check back here after the meeting to review Takeaways and Notes
                                    </Typography>
                                )}
                            </Grid>
                            <TakeawayDialog
                                open={takeawayDialog.isOpen}
                                handleClose={takeawayDialog.handleClose}
                                month={month}
                                year={year}
                                notes={meeting?.notes}
                                user={user}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <SubmissionUpdateDialog
                isOpen={updateSubmissionDialog.isOpen}
                onSubmit={onUpdateSubmission}
                onClose={updateSubmissionDialog.handleClose}
            />
        </Card>
    );
};

export default MeetingPanel;
