import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";
import { usePriorityActionMutation } from "../../hooks/mutations/use-priority-action-mutation";
import { usePriorityActions } from "../../hooks/queries/use-priority-actions";
import AutoSaveTextField from "../autosave-text-field";
import PriorityActionAccomplishedButton from "../priority-action-accomplished-button";
import CategoryTitle from "../monthly-check-in/category-title";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";

const useStyles = makeStyles((theme) => ({
    centered: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function PriorityActionSection(props) {
    const classes = useStyles();
    const { userID, category, year, disabled } = props;
    const { status, data } = usePriorityActions({ user: userID, year, category, isAccomplished: false });
    const [priorityActions, setPriorityActions] = useState([]);
    const queryClient = useQueryClient();
    const priorityActionMutation = usePriorityActionMutation({
        onSuccess: () => queryClient.invalidateQueries("fetchPriorityActions"),
    });
    const masterPlanTemplate = useMasterPlanTemplate();

    useEffect(() => {
        if (status === "success") {
            const fillPriorityActions = (priorityActionsArr) => {
                const filledPriorityActions = [...priorityActionsArr];
                for (let i = filledPriorityActions.length; i < 3; i++) {
                    filledPriorityActions.push({
                        id: null,
                        category,
                        year,
                        description: "",
                    });
                }
                return filledPriorityActions;
            };

            const filledPriorityActions = fillPriorityActions(data.priorityActions);
            setPriorityActions(filledPriorityActions);
        } else {
            setPriorityActions([]);
        }
    }, [year, status, data, category]);

    return (
        <Box>
            <CategoryTitle
                title={masterPlanTemplate[category].masterPlan.priorityActionDescription}
                style={{ marginTop: 24, marginBottom: 24 }}
            />
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container style={{ flexGrow: 1 }} spacing={5}>
                    {priorityActions.map((priorityAction, index) => (
                        <Grid
                            container
                            item
                            key={priorityAction.id || `${priorityAction}-${category}-${year}-${index}`}
                        >
                            <Grid item xs={11}>
                                <AutoSaveTextField
                                    object={priorityAction}
                                    textFieldName="description"
                                    index={index}
                                    mutation={priorityActionMutation}
                                    disabled={disabled}
                                    maxLength={165}
                                />
                            </Grid>
                            <Grid item className={classes.centered} xs={1}>
                                <PriorityActionAccomplishedButton
                                    priorityAction={priorityAction}
                                    disabled={!priorityAction.id || disabled}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

PriorityActionSection.propTypes = {
    category: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    userID: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default PriorityActionSection;
