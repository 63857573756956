import { useCompany } from "./queries/use-company";
import { useQueryState } from "./states/use-query-state";
import { useQueryStatus } from "./use-query-status";
import { Company } from "../interfaces/company/company";

export function useCompanyState() {
    const companyQuery = useCompany();
    const company: Company = useQueryState(companyQuery);
    const queryStatus = useQueryStatus(companyQuery);

    return {
        company,
        ...queryStatus,
    };
}
