import React from "react";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";
import { useViewingAs } from "../../../hooks/use-viewing-as";
import MonthlyResultsEmployeeActions from "../monthly-check-in-employee-actions";
import MonthlyResultsCoachActions from "../monthly-check-in-coach-actions";
import { MeetingStatus } from "../../../types/meeting-status";

const Actions = () => {
    const { viewingAs, meeting, user } = useMonthlyCheckInState();
    const { isViewingAsCoach, isViewingAsEmployee } = useViewingAs({ viewingAs });

    const getMeetingStatus = (): MeetingStatus => {
        if (!meeting) {
            return "no meeting";
        }
        return meeting.status;
    };

    const getMeetingId = (): string | undefined => {
        if (!meeting) {
            return "";
        }
        return meeting.id;
    };

    if (isViewingAsEmployee && user) {
        return (
            <MonthlyResultsEmployeeActions
                meetingStatus={getMeetingStatus()}
                meetingId={getMeetingId()}
                userId={user.id}
                firstName={user.firstName}
                lastName={user.lastName}
            />
        );
    } else if (isViewingAsCoach && user) {
        return (
            <MonthlyResultsCoachActions
                employeeId={user.id}
                meetingId={getMeetingId()}
                meetingStatus={getMeetingStatus()}
                userId={user.id}
                firstName={user.firstName}
                lastName={user.lastName}
            />
        );
    } else {
        return <div />;
    }
};

export default Actions;
