import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import { useDialog } from "../hooks/use-dialog";
import ContainedIconButton from "./contained-icon-button";
import PriorityActionAccomplishedDialog from "./priority-action-accomplished-dialog";

function PriorityActionAccomplishedButton(props) {
    const { priorityAction, ...others } = props;
    const priorityActionDialog = useDialog({});

    return (
        <>
            <ContainedIconButton onClick={priorityActionDialog.handleOpen} {...others}>
                <CheckIcon />
            </ContainedIconButton>
            <PriorityActionAccomplishedDialog
                open={priorityActionDialog.isOpen}
                onClose={priorityActionDialog.handleClose}
                priorityAction={priorityAction}
            />
        </>
    );
}

export default PriorityActionAccomplishedButton;
