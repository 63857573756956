import React, { ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { dialogTitle } from "./config";
import SubmissionNoteField from "../submission-note-field";
import MyCheckinButton from "../../my-checkin-button";

interface SubmissionUpdateDialogProps {
    isOpen: boolean;
    onSubmit: (note: string) => void;
    onClose: () => void;
}

const SubmissionUpdateDialog = ({ isOpen, onSubmit, onClose }: SubmissionUpdateDialogProps) => {
    const [submissionNote, updateSubmissionNote] = useState<string>("");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        updateSubmissionNote(value);
    };

    const handleClose = () => {
        updateSubmissionNote("");
        onClose();
    };

    const handleSubmit = () => {
        onSubmit(submissionNote);
        handleClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <SubmissionNoteField onChange={handleChange} value={submissionNote} />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>Cancel</MyCheckinButton>
                <MyCheckinButton variant="outlined" onClick={handleSubmit}>
                    Submit
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

SubmissionUpdateDialog.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

export default SubmissionUpdateDialog;
