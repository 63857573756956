import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { useObjectiveState } from "../../hooks/states/use-objective-state";
import { objectiveDescriptions } from "../../config/objective-descriptions";
import EditIcon from "@material-ui/icons/Edit";
import { Objective } from "../../interfaces/objective";
import { useDialog } from "../../hooks/use-dialog";
import { EditObjectiveDialog } from "./edit-objective-dialog";

interface CompletedObjectivesSectionProps {
    userId: string;
    category: number;
    year: number;
}

const useStyles = makeStyles({
    centeredY: {
        display: "flex",
        alignItems: "center",
    },
    centeredX: {
        display: "flex",
        justifyContent: "center",
    },
});

const CompletedObjectivesSection = ({ userId, category, year }: CompletedObjectivesSectionProps) => {
    const classes = useStyles();
    const { objectives, isLoading } = useObjectiveState({ user: userId, category, year, isAccomplished: true });
    const [selectedObjective, setSelectedObjective] = useState<
        Pick<Objective, "id" | "description" | "accomplishment">
    >({
        id: "",
        description: "",
        accomplishment: "",
    });
    const editObjectiveDialog = useDialog({});

    const handleEditObjectiveClick = (objective: Objective) => {
        console.log(objective);
        setSelectedObjective({
            id: objective.id,
            description: objective.description,
            accomplishment: objective.accomplishment,
        });
        editObjectiveDialog.handleOpen();
    };
    return (
        <>
            <Box style={{ marginBottom: 24 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{ marginBottom: 16, marginLeft: 20 }}>
                            {objectiveDescriptions.completedItems.name}
                        </Typography>
                    </Grid>
                </Grid>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    objectives.map((objective, index) => (
                        <Grid container spacing={5}>
                            <Grid item xs={5}>
                                <Typography key={`objective-${year}-${category}-${index}`}>
                                    {`${index + 1}. ${objective.description}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography key={`objective-accomplishment-${year}-${category}-${index}`}>
                                    {`${index + 1}. ${objective.accomplishment || ""}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={1} className={[classes.centeredX, classes.centeredY].join(" ")}>
                                <IconButton onClick={() => handleEditObjectiveClick(objective)}>
                                    <EditIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))
                )}
            </Box>
            <EditObjectiveDialog
                isOpen={editObjectiveDialog.isOpen}
                onClose={editObjectiveDialog.handleClose}
                objectiveId={selectedObjective.id}
                description={selectedObjective.description}
                accomplishment={selectedObjective.accomplishment}
            />
        </>
    );
};

CompletedObjectivesSection.propTypes = {
    userId: PropTypes.string.isRequired,
    category: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
};

export { CompletedObjectivesSection };
