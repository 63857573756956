import { Grid, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React from "react";

function ReminderListItem({ description, onDismiss }) {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>{description}</Grid>
            <Grid item>
                <Tooltip title="Dismiss">
                    <IconButton size="small" onClick={onDismiss}>
                        <CloseIcon color="action" />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

ReminderListItem.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
};

export default ReminderListItem;
