import { useCurrentUser } from "../queries/use-current-user";
import { useQueryStatus } from "../use-query-status";
import { User } from "../../interfaces/user";
import { useEffect, useState } from "react";

export const useCurrentUserState = (): {
    currentUser: User | null;
    error: string;
    isError: boolean;
    isIdle: boolean;
    isLoading: boolean;
    isSuccess: boolean;
} => {
    const [state, setState] = useState(null);
    const currentUserQuery = useCurrentUser();
    const queryStatus = useQueryStatus(currentUserQuery);

    useEffect(() => {
        if (currentUserQuery.status === "success") {
            setState(currentUserQuery.data);
        } else {
            setState(null);
        }
    }, [currentUserQuery]);

    return {
        currentUser: state,
        ...queryStatus,
    };
};
