import React from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { links } from "../config";
import { useStyles } from "./styles";

interface IndexProps {
    isCoach: boolean;
    isAdmin: boolean;
}

const ChecklistLinks = ({ isCoach, isAdmin }: IndexProps) => {
    const classes = useStyles();
    const accessLevel = isCoach || isAdmin ? "coach" : "employee";
    const filteredLinks = links.filter((link) => link.accessLevel.includes(accessLevel));

    return (
        <ul className={classes.linkList}>
            {filteredLinks.map((link, index) => (
                <li className={classes.linkListItem} key={`new-user-welcome-dialog-checklist-link-${index}`}>
                    <Link href={link.url} target="_blank" className={classes.link}>
                        {link.text}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

ChecklistLinks.propTypes = {
    isCoach: PropTypes.bool,
    isAdmin: PropTypes.bool,
};

export default ChecklistLinks;
