import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { usePriorityActionDelete } from "../hooks/mutations/use-priority-action-delete";
import { usePriorityActionMutation } from "../hooks/mutations/use-priority-action-mutation";
import { useMaxLength } from "../hooks/use-max-length";
import MyCheckinTextField from "./my-checkin-text-field";
import DeleteIcon from "@material-ui/icons/Delete";
import MyCheckinButton from "./my-checkin-button";
import { Alert } from "@material-ui/lab";

function EditPriorityActionDialog(props) {
    const { priorityAction, open, onClose } = props;
    const [state, setState] = useState({
        description: priorityAction?.description,
    });
    const [showWarning, setShowWarning] = useState(false);
    const queryClient = useQueryClient();
    const mutationOptions = { onSuccess: () => queryClient.invalidateQueries("fetchPriorityActions") };
    const priorityActionMutation = usePriorityActionMutation(mutationOptions);
    const deletePriorityActionMutation = usePriorityActionDelete(mutationOptions);
    const { error, handleFocus, handleBlur, helperText } = useMaxLength({ value: state.description });

    const handleClose = () => {
        setShowWarning(false);
        setState({
            description: priorityAction.description,
        });
        onClose();
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setState({ description: value });
    };

    const handleDelete = () => {
        if (!showWarning) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
            deletePriorityActionMutation.mutate(priorityAction);
            onClose();
        }
    };

    const handleSave = () => {
        priorityActionMutation.mutate({ ...priorityAction, description: state.description });
        setShowWarning(false);
        onClose();
    };

    useEffect(() => {
        setState({
            description: priorityAction?.description,
        });
    }, [priorityAction]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Priority Action</DialogTitle>
            <DialogContent>
                <MyCheckinTextField
                    value={state.description}
                    name="description"
                    label="Priority Action"
                    onChange={handleChange}
                    multiline
                    rows={2}
                    fullWidth
                    error={error}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    helperText={helperText}
                />
                {showWarning && (
                    <Alert color="warning">
                        Delete this priority action? <strong>This cannot be undone.</strong> Click again to proceed.
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handleDelete} color="primary">
                    <DeleteIcon />
                </IconButton>
                <MyCheckinButton color="primary" onClick={handleClose}>
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton variant="outlined" color="primary" onClick={handleSave} disabled={error}>
                    Save
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default EditPriorityActionDialog;
