interface UseViewingAsProps {
    viewingAs: "employee" | "coach";
}

export const useViewingAs = ({ viewingAs }: UseViewingAsProps) => {
    const isViewingAsEmployee = viewingAs === "employee";
    const isViewingAsCoach = viewingAs === "coach";

    return {
        isViewingAsCoach,
        isViewingAsEmployee,
    };
};
