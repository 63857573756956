import { CRUD } from "../interfaces/crud";
import { Endpoint } from "../interfaces/endpoint";
import { HttpClient } from "../interfaces/http-client";
import { Discussion } from "../../../interfaces/discussion";

interface DiscussionRequiredAttrs {
    goal: string;
    comments?: CommentRequiredAttrs[];
}

interface CommentRequiredAttrs {
    author: string;
    comment: string;
}

export class DiscussionEndpoint implements CRUD, Endpoint {
    httpClient: HttpClient;
    url = "/api/discussion";

    constructor({ httpClient, url }: { httpClient: HttpClient; url?: string }) {
        this.httpClient = httpClient;
        if (url) {
            this.url = url;
        }
    }

    create(attrs: DiscussionRequiredAttrs): Promise<Discussion> {
        return this.httpClient.post(this.url, attrs);
    }

    delete(id: string | number): Promise<never> {
        return Promise.reject("Operation not supported");
    }

    find(params: DiscussionRequiredAttrs): Promise<{ count: number; discussions: Discussion[] }> {
        return this.httpClient.get(this.url, params);
    }

    get(id: string | number): Promise<Discussion> {
        return this.httpClient.get(this.url + "/" + id);
    }

    update(params: DiscussionRequiredAttrs, id: string | number): Promise<Discussion> {
        return this.httpClient.patch(this.url + "/" + id, params);
    }

    addComment(comment: CommentRequiredAttrs, id: string | number): Promise<Discussion> {
        return this.httpClient.post(this.url + "/" + id + "/add-comment", comment);
    }

    deleteComment(discussionId: string | number, commentId: string | number): Promise<Discussion> {
        return this.httpClient.delete(this.url + "/" + discussionId + "/delete-comment/" + commentId);
    }
}
