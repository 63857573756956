import { useEffect } from "react";

export const useTermly = () => {
    const termly = (d, s, id) => {
        let js,
            tjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.id = id;
        js.src = "https://app.termly.io/embed-policy.min.js";
        tjs.parentNode.insertBefore(js, tjs);
    };

    useEffect(() => {
        termly(document, "script", "termly-jssdk");
        return () => {
            document.getElementById("termly-jssdk").remove();
        };
    });
};
