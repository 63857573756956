import { Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
    heading: {
        fontWeight: "bold",
        fontSize: 10,
        marginBottom: 8,
    },
});

const Heading = ({ children }: { children: string }) => {
    return <Text style={styles.heading}>{children}</Text>;
};

Heading.propTypes = {
    children: PropTypes.node.isRequired,
};

export { Heading };
