import axios from "axios";
import Qs from "qs";

interface PasswordParams {
    password1: string;
    password2: string;
    token: string;
}

interface SignInParams {
    email: string;
    password: string;
}

axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
        return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false,
        });
    };

    return config;
});

/**
 * @deprecated
 */
export class API {
    static async validateRegistrationToken(token: string) {
        const response = await axios.post("/api/auth/validateregistrationtoken/" + token);
        return response.data;
    }

    static async uploadProfileImage(image: any) {
        const response = await axios.post("/api/user/profileimage", { image });
        return response.data;
    }

    static async getProfileImage(id: string) {
        const response = await axios.get("/api/user/profileimage/" + id);
        return response.data;
    }

    static async validatePasswordResetToken(token: string) {
        const response = await axios.post("/api/auth/validateresettoken/" + token);
        return response.data;
    }

    static async updatePassword(updatePasswordParams: PasswordParams) {
        const { password1, password2, token } = updatePasswordParams;
        const response = await axios.post("/api/auth/updatepassword", { password1, password2, token });
        return response.data;
    }

    static async registerPassword(updatePasswordParams: PasswordParams) {
        const { password1, password2, token } = updatePasswordParams;
        const response = await axios.post("/api/auth/registeruser", { password1, password2, token });
        return response.data;
    }

    static async getCurrentUser() {
        const response = await axios.get("/api/auth/currentuser");
        if (response.status === 200) {
            return response.data.currentUser;
        }
        return response.data;
    }

    static async signIn(signInParams: SignInParams) {
        const { email, password } = signInParams;
        const response = await axios.post("/api/auth/signin", { email, password });
        return response.data;
    }

    static async getMeetings(params: any) {
        return await API.get("/api/meeting", params);
    }

    static async getGoals(params: any) {
        return await API.get("/api/goal", params);
    }

    static async getCoaches(coachIdArray: string[]) {
        return await API.get("/api/user", { id: coachIdArray });
    }

    static async getPriorityActions(params: any) {
        return await API.get("/api/priorityaction", params);
    }

    static async getTakeaways(params: any) {
        return await API.get("/api/takeaway", params);
    }

    static async createTakeaway(takeaway: any) {
        return await API.post("/api/takeaway", takeaway);
    }

    static async updateTakeaway(takeaway: any) {
        return await API.patch("/api/takeaway", takeaway.id, takeaway);
    }

    static async deleteTakeaway(takeaway: any) {
        return await API.delete("/api/takeaway", takeaway.id);
    }

    static async getCompany() {
        return await API.get("/api/company", {});
    }

    static async updateGoal(goal: any) {
        return await API.patch("/api/goal", goal.id, goal);
    }

    static async createGoal(goal: any) {
        return await API.post("/api/goal", goal);
    }

    static async deleteGoal(goal: any) {
        return await API.delete("/api/goal", goal.id);
    }

    static async updatePriorityAction(priorityAction: any) {
        return await API.patch("/api/priorityaction", priorityAction.id, priorityAction);
    }

    static async createPriorityAction(priorityAction: any) {
        return await API.post("/api/priorityaction", priorityAction);
    }

    static async deletePriorityAction(priorityAction: any) {
        return await API.delete("/api/priorityaction", priorityAction.id);
    }

    static async createResult(result: any) {
        return await API.post("/api/result", result);
    }

    static async updateResult(result: any) {
        return await API.patch("/api/result", result.id, result);
    }

    static async getResults(params: any) {
        return await API.get("/api/result", params);
    }

    static async createMeeting(meeting: any) {
        return await API.post("/api/meeting", meeting);
    }

    static async updateMeeting(meeting: any) {
        return await API.patch("/api/meeting", meeting.id, meeting);
    }

    static async createJournalEntry(journalEntry: any) {
        return await API.post("/api/jot", journalEntry);
    }

    static async updateJournalEntry(journalEntry: any) {
        return await API.patch("/api/jot", journalEntry.id, journalEntry);
    }

    static async findJournalEntries(params: any) {
        return await API.get("/api/jot", params);
    }

    static async getJournalEntry(id: any) {
        return await API.getById("/api/jot", id);
    }

    static async createTrackedItem(trackedItem: any) {
        return await API.post("/api/trackeditem", trackedItem);
    }

    static async findTrackedItems(params: any) {
        return await API.get("/api/trackeditem", params);
    }

    static async getTrackedItem(id: any, config = {}) {
        return await API.getById("/api/trackeditem", id, config);
    }

    static async updateTrackedItem(journalEntry: any) {
        return await API.patch("/api/trackeditem", journalEntry.id, journalEntry);
    }

    static async findUsers(params: any) {
        return await API.get("/api/user", params);
    }

    static async getUser(id: any) {
        return await API.getById("/api/user", id);
    }

    static async updateUser(user: any) {
        return await API.patch("/api/user", user.id, user);
    }

    static async createUser(user: any) {
        return await API.post("/api/user", user);
    }

    static async deleteUser(id: any) {
        return await API.delete("/api/user", id);
    }

    static async createCompany(company: any) {
        return await API.post("/api/company", company);
    }

    static async updateCompany(company: any) {
        return await API.patch("/api/company", company.id, company);
    }

    static async get(url: any, params: any) {
        const response = await axios.get(url, { params });
        if (response.status >= 400) {
            throw new Error(response.data.errors[0].message);
        }
        return response.data;
    }

    static async post(url: any, params: any) {
        const response = await axios.post(url, params);
        return response.data;
    }

    static async patch(url: any, id: any, params: any) {
        const response = await axios.patch(API.appendId(url, id), params);
        return response.data;
    }

    static async getById(url: any, id: any, config = {}) {
        const response = await axios.get(API.appendId(url, id), config);
        return response.data;
    }

    static async delete(url: any, id: any) {
        const response = await axios.delete(API.appendId(url, id));
        return response.data;
    }

    static appendId(url: any, id: any) {
        let formattedUrl = url;
        if (formattedUrl[formattedUrl.length - 1] !== "/") {
            formattedUrl += "/";
        }
        return formattedUrl + id;
    }
}
