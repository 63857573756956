import React from "react";
import ListCard from "./list-card";
import ListCardItem from "./list-card-item";

function PriorityActionsCard({ priorityActionsArr = [], className }) {
    return (
        <ListCard title="Priority Actions" className={className}>
            <ListCardItem>{priorityActionsArr[0] ? priorityActionsArr[0].description : ""}</ListCardItem>
            <ListCardItem>{priorityActionsArr[1] ? priorityActionsArr[1].description : ""}</ListCardItem>
            <ListCardItem>{priorityActionsArr[2] ? priorityActionsArr[2].description : ""}</ListCardItem>
        </ListCard>
    );
}

export default PriorityActionsCard;
