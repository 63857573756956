import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useStyles } from "./division.styles";

interface CoachActionsProps {
    children: React.ReactNode;
}

const CoachActions = ({ children }: CoachActionsProps) => {
    const classes = useStyles();

    return <Box className={classes.coachActions}>{children}</Box>;
};

CoachActions.propTypes = {
    children: PropTypes.node,
};

export { CoachActions };
