import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./points-header.styles";

interface PointsHeaderProps {
    show: boolean;
}

function PointsHeader({ show }: PointsHeaderProps) {
    const classes = useStyles();
    return (
        <Typography className={classes.root} align="center">
            {show && "Points"}
        </Typography>
    );
}

export { PointsHeader };
