import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

interface CommentListProps {
    children?: React.ReactNode;
}

const CommentList = ({ children }: CommentListProps) => {
    if (React.Children.count(children) === 0) {
        return (
            <Typography align="center" style={{ width: "100%" }}>
                No comments.
            </Typography>
        );
    }

    return <Box>{children}</Box>;
};

CommentList.propTypes = {
    children: PropTypes.node,
};

export { CommentList };
