/**
 * Functional application configuration will go here.
 *
 * For example, number of goals, number of priority actions, etc.
 */

/// Maximum number of objectives, goals, etc. per master plan
//TODO - implement these numbers into the application
export const maxObjectives = 1;
export const maxGoals = 3;
export const maxObstacles = 3;
export const maxPriorityActions = 3;
export const maxAnyIdeas = 3;
export const maxLevelUps = 3;

/// Max number of takeaways per month
export const maxTakeaways = 5;
