import { useMutation, UseMutationOptions } from "react-query";
import { objectiveAPI } from "../../util/api";

interface UseObjectiveDeleteParams {
    id: string;
}

export const useObjectiveDelete = (options: UseMutationOptions<any, unknown, UseObjectiveDeleteParams>) => {
    return useMutation((params: UseObjectiveDeleteParams) => objectiveAPI.delete(params.id), options);
};
