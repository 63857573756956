import React from "react";
import PropTypes from "prop-types";
import MyCheckinButton from "../../../my-checkin-button";
import { closeButtonText } from "../config";

interface CloseButtonProps {
    onClick: () => unknown;
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
    return (
        <MyCheckinButton variant="contained" color="primary" onClick={onClick}>
            {closeButtonText}
        </MyCheckinButton>
    );
};

CloseButton.propTypes = {
    onClick: PropTypes.func,
};

export { CloseButton };
