import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

export interface GoalListItemProps {
    children?: React.Component;
    points?: number;
    showPoints?: boolean;
}

const GoalListItem = ({ children, points, showPoints = false }: GoalListItemProps) => {
    return (
        <>
            <Grid item xs={showPoints ? 10 : 11}>
                <Typography>{children}</Typography>
            </Grid>
            {showPoints && (
                <Grid item xs={1}>
                    <Typography align="center">{points}</Typography>
                </Grid>
            )}
        </>
    );
};

GoalListItem.propTypes = {
    children: PropTypes.node,
    points: PropTypes.number,
    showPoints: PropTypes.bool,
};

export { GoalListItem };
