import { useQueryKey } from "./use-query-key";
import { objectiveAPI } from "../../util/api";
import { useQuery } from "react-query";
import { queryKeys } from "../api/config/query-keys";

type UseObjectivesParams =
    | {
          id?: never;
          user: string;
          category: number;
          year: number;
          isAccomplished?: boolean;
      }
    | {
          id: string;
          user?: never;
          category?: never;
          year?: never;
          isAccomplished?: never;
      };

export const useObjectives = (params: UseObjectivesParams) => {
    const queryKey = useQueryKey(queryKeys.objective.find, params);
    return useQuery(queryKey, () => objectiveAPI.find(params));
};
