import React from "react";
import SubmitButton from "../submit-button";
import SubmitDialog from "../submit-dialog";
import { Box, Grid } from "@material-ui/core";
import { useDialog } from "../../../hooks/use-dialog";
import { MeetingStatus } from "../../../types/meeting-status";
import { useStyles } from "./monthly-check-in-employee-actions.styles";
import { DownloadMonthlyCheckInPdfButton } from "../download-monthly-check-in-pdf-button";
import { useParams } from "react-router-dom";

interface MonthlyCheckInEmployeeActionsProps {
    meetingStatus: MeetingStatus;
    meetingId: string | undefined;
    userId: string;
    firstName: string;
    lastName: string;
}

function MonthlyCheckInEmployeeActions({
    userId,
    firstName,
    lastName,
    meetingStatus,
    meetingId,
}: MonthlyCheckInEmployeeActionsProps) {
    const classes = useStyles();
    const { year, month } = useParams<{ year: string; month: string }>();
    const monthInt = parseInt(month);
    const yearInt = parseInt(year);
    const submitDialog = useDialog({});

    return (
        <Box className={classes.actions}>
            <Grid container justify="flex-end" spacing={5}>
                <Grid item>
                    <DownloadMonthlyCheckInPdfButton
                        userId={userId}
                        month={monthInt}
                        year={yearInt}
                        firstName={firstName}
                        lastName={lastName}
                    />
                </Grid>
                <Grid item>
                    <SubmitButton
                        month={monthInt}
                        year={yearInt}
                        onClick={submitDialog.handleOpen}
                        meetingStatus={meetingStatus}
                    />
                </Grid>
                <SubmitDialog
                    isOpen={submitDialog.isOpen}
                    handleClose={submitDialog.handleClose}
                    meetingId={meetingId}
                    month={monthInt}
                    year={yearInt}
                />
            </Grid>
        </Box>
    );
}

export { MonthlyCheckInEmployeeActions };
