import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useStyles } from "./notifications.styles";

interface NotificationTextProps {
    children: React.ReactNode;
}

const NotificationText = ({ children }: NotificationTextProps) => {
    const classes = useStyles();

    return <Typography className={classes.notificationText}>{children}</Typography>;
};

NotificationText.propTypes = {
    children: PropTypes.node,
};

export { NotificationText };
