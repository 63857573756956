import { useTakeaways } from "../queries/use-takeaways";
import { useQueryStatus } from "../use-query-status";
import { useQueryState } from "./use-query-state";

export const useTakeawayState = (params) => {
    const takeawayQuery = useTakeaways(params);
    const takeaways = useQueryState(takeawayQuery, "takeaways");
    const queryStatus = useQueryStatus(takeawayQuery);

    return {
        takeaways,
        ...queryStatus,
    };
};
