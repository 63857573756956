import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

interface PageSubtitleProps {
    children: React.ReactNode;
}

const PageSubtitle = ({ children }: PageSubtitleProps) => {
    return (
        <Typography variant="h6" align="center">
            {children}
        </Typography>
    );
};

PageSubtitle.propTypes = {
    children: PropTypes.node,
};

export { PageSubtitle };
