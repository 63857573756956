import React from "react";
import PropTypes from "prop-types";
import { Box, Link, Typography, Breadcrumbs as MuiBreadcrumbs } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useStyles } from "./discussion.styles";
import { urls } from "../../config/urls";

interface BreadcrumbsProps {
    id: string;
    name: string;
    divisionId: string;
    divisionName: string;
}

const Breadcrumbs = ({ id, name, divisionId, divisionName }: BreadcrumbsProps) => {
    const classes = useStyles();
    const { pathname } = useLocation();

    const getBaseLink = () => {
        return pathname.includes("coach") ? "/coach/divisions/" + divisionId : urls.employee.teamshare.baseUrl;
    };

    const getBaseLabel = () => {
        return pathname.includes("coach") ? divisionName : "TeamShare";
    };

    const getUserLink = () => {
        return pathname.includes("coach")
            ? urls.coach.teamshare.baseUrl + "/" + id
            : urls.employee.teamshare.baseUrl + "/" + id;
    };

    return (
        <Box className={classes.breadcrumbsWrapper}>
            <MuiBreadcrumbs separator=">">
                <Link component={RouterLink} to={getBaseLink()} className={classes.breadcrumbLink}>
                    {getBaseLabel()}
                </Link>
                <Link component={RouterLink} to={getUserLink()} className={classes.breadcrumbLink}>
                    {name}
                </Link>
                <Typography>Discussion</Typography>
            </MuiBreadcrumbs>
        </Box>
    );
};

Breadcrumbs.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
};

export { Breadcrumbs };
