import React from "react";
import NavLink from "../nav-link";
import { urls } from "../../config/urls";
import { menuLabels } from "../../config/menu-labels";

function AdminLinks() {
    return (
        <>
            <NavLink to={urls.coach.employees.baseUrl} text={menuLabels.employees} />
            <NavLink to={urls.coach.companySettings.baseUrl} text={menuLabels.settings} />
        </>
    );
}

export default AdminLinks;
