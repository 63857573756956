import React from "react";
import { Card, Container, Grid, Typography } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import { useStyles } from "./notifications.styles";
import { NotificationList } from "../../components/notifications/notification-list";
import { NotificationListItem } from "../../components/notifications/notification-list-item";
import { NotificationContent } from "../../components/notifications/notification-content";
import { useNotificationFns } from "../../hooks/use-notification-fns";
import { useFindApi } from "../../hooks/api/use-find-api";
import { Notification } from "../../interfaces/notification";
import { notificationAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { usePagination } from "../../hooks/use-pagination";
import { NotificationText } from "../../components/notifications/notification-text";
import { Pagination } from "../../components/division/pagination";
import { notificationRefetchInterval } from "../../config/query-config";
import { useAuthState } from "../../hooks/use-auth-state";
import MyCheckinButton from "../../components/my-checkin-button";

const Notifications = () => {
    const classes = useStyles();
    const { user: currentUser } = useAuthState();
    const { getNotificationText, getLink, handleNotificationClick, handleDeleteClick, handleDeleteAllClick } =
        useNotificationFns();
    const findNotificationsResult = useFindApi<Notification>(
        notificationAPI,
        queryKeys.notification.find,
        {},
        "notifications",
        { refetchInterval: notificationRefetchInterval }
    );

    const notifications = findNotificationsResult.data.result;

    const {
        paginatedArray: paginatedNotifications,
        offset,
        limit,
        onClickNext,
        onClickPrevious,
    } = usePagination({
        objectArr: notifications,
        limit: 10,
    });

    return (
        <Container>
            <PageTitle>Notifications</PageTitle>
            <Card className={classes.contentContainer}>
                <Typography className={classes.title}>
                    Viewing All Notifications for{" "}
                    {currentUser ? currentUser.firstName + " " + currentUser.lastName : ""}
                </Typography>
                <NotificationList>
                    {paginatedNotifications.map((notification: Notification) => (
                        <NotificationListItem isUnread={notification.isUnread!}>
                            <NotificationContent
                                userId={notification.initiatingUser!}
                                link={getLink(notification)}
                                onLinkClick={() => handleNotificationClick(notification)}
                                onDeleteClick={() => handleDeleteClick(notification.id)}
                            >
                                <NotificationText>{getNotificationText(notification)}</NotificationText>
                            </NotificationContent>
                        </NotificationListItem>
                    ))}
                </NotificationList>
                <Grid container justify="flex-end" style={{ width: 640 }}>
                    <MyCheckinButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleDeleteAllClick(currentUser?.id)}
                    >
                        Clear All Notifications
                    </MyCheckinButton>
                </Grid>
                <Pagination
                    offset={offset}
                    limit={limit}
                    max={notifications.length}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrevious}
                />
            </Card>
        </Container>
    );
};

export { Notifications };
