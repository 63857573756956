import { Card, Container, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import CoachesAndAdminsTab from "../components/company-settings/coaches-and-admins-tab";
import CompanyInfoTab from "../components/company-settings/company-info-tab";
import CompanySettingsTab from "../components/company-settings/company-settings-tab";
import PageTitle from "../components/page-title";
import { BillingTab } from "../components/company-settings/billing-tab";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: theme.spacing(5),
    },
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        border: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(3),
        borderRadius: 5,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: "unset",
    },
    tabPanel: {
        flexGrow: 1,
    },
}));

function CompanySettings() {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container>
            <PageTitle>Settings</PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container justify="center">
                    <Grid item className={classes.tabRoot} md={9}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            className={classes.tabs}
                            indicatorColor="primary"
                        >
                            <Tab label="Company Info" id="vertical-tab-0" />
                            <Tab label="Company Settings" id="vertical-tab-1" />
                            <Tab label="Coaches & Admins" id="vertical-tab-2" />
                            <Tab label="Billing" id="vertical-tab-3" />
                        </Tabs>
                        <CompanyInfoTab value={tabValue} index={0} />
                        <CompanySettingsTab value={tabValue} index={1} />
                        <CoachesAndAdminsTab value={tabValue} index={2} />
                        <BillingTab index={tabValue} value={3} />
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export default CompanySettings;
