import { Meeting } from "../../../interfaces/meeting";
import { monthIndexArr } from "../config";

const compareMeetingsByMonth = (a: Meeting, b: Meeting) => a.month - b.month;

const copyMeetingsArr = (meetings: Meeting[]): Meeting[] => {
    const copiedArr: Meeting[] = [];

    meetings.forEach((meeting) => copiedArr.push(meeting));

    return copiedArr;
};

export const useSortedMeetings = (meetings: Meeting[]): Meeting[] => {
    const sortedMeetings: Meeting[] = copyMeetingsArr(meetings);

    sortedMeetings.sort(compareMeetingsByMonth);

    return sortedMeetings;
};

export const useFilledMeetings = (meetings: Meeting[]) => {
    const filledMeetings: (Meeting | null)[] = [];

    monthIndexArr.forEach((monthIndex) => {
        const meeting = meetings.find((meeting) => meeting.month === monthIndex);
        if (meeting) {
            filledMeetings.push(meeting);
        } else {
            filledMeetings.push(null);
        }
    });

    return filledMeetings;
};
