import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import BlockLetter from "./block-letter";
import { useMasterPlanTemplate } from "../hooks/use-master-plan-template";

const useStyles = makeStyles((theme) => ({
    tabSpacing: {
        marginBottom: 8,
    },
    tabContainer: {
        display: "flex",
        flexDirection: "column",
    },
    tabs: {
        display: "flex",
        marginTop: theme.spacing(3),
        flexWrap: "nowrap",
    },
    tabPane: {
        display: "flex",
        flexGrow: 1,
        borderLeft: "6px solid #ffb84c",
        borderTop: "1px solid #929497",
        borderRight: "1px solid #929497",
        borderBottom: "1px solid #929497",
        borderRadius: "0 5px 5px 5px",
        padding: "8px 32px",
    },
}));

function GoalTabs(props) {
    const { children, value, setValue, ...others } = props;
    const classes = useStyles();
    const masterPlanDescriptions = useMasterPlanTemplate();

    const getBlockLetter = (title, index) => {
        return (
            <BlockLetter
                title={title}
                className={classes.tabSpacing}
                onClick={() => setValue(index)}
                selected={index === value}
            />
        );
    };

    return (
        <Grid container className={classes.tabs} {...others}>
            <Grid item className={classes.tabContainer}>
                {[0, 1, 2, 3].map((index) => getBlockLetter(masterPlanDescriptions[index].name, index))}
            </Grid>
            <Grid item className={classes.tabPane}>
                {children}
            </Grid>
        </Grid>
    );
}

export default GoalTabs;
