import React from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";

interface PageTitleProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.neutral.main,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: theme.spacing(6),
    },
    title: {
        fontSize: 48,
        fontWeight: "bold",
        letterSpacing: "-2px",
        color: theme.palette.primary.main,
    },
}));

function PageTitle({ children }: PageTitleProps) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Typography className={classes.title}>{children}</Typography>
        </Card>
    );
}

export default PageTitle;
