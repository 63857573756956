import React, { useState } from "react";
import { MonthlyCheckInContext } from "../context/monthly-check-in-context";
import { useLocation, useParams } from "react-router-dom";
import { useCurrentUser } from "../hooks/queries/use-current-user";
import { useGetApi } from "../hooks/api/use-get-api";
import { User } from "../interfaces/user";
import { meetingAPI, userAPI } from "../util/api";
import { queryKeys } from "../hooks/api/config/query-keys";
import { useFindApi } from "../hooks/api/use-find-api";
import { Meeting } from "../interfaces/meeting";

interface MonthlyCheckInProviderProps {
    children: React.ReactNode;
}

export const MonthlyCheckInProvider = ({ children }: MonthlyCheckInProviderProps) => {
    const location = useLocation();
    const { year, month } = useParams<{ year: string; month: string }>();
    const yearInt = parseInt(year);
    const monthInt = parseInt(month);
    const { userId } = useParams<{ userId: string }>();
    const { data: currentUser } = useCurrentUser();
    const user = useGetApi<User>(userAPI, queryKeys.user.get, userId, { enabled: !!userId });
    const meetings = useFindApi<Meeting>(
        meetingAPI,
        queryKeys.meeting.find,
        { user: user.data?.id || currentUser?.id, month: monthInt, year: yearInt },
        "meetings"
    );
    const [isMeetingStarted, setIsMeetingStarted] = useState(false);

    const viewingAs = location.pathname.includes("employee") ? "employee" : "coach";

    const toggleIsMeetingStarted = () => setIsMeetingStarted((current) => !current);

    return (
        <MonthlyCheckInContext.Provider
            value={{
                year: yearInt,
                month: monthInt,
                user: user.data || currentUser,
                meeting: meetings.data.result[0],
                viewingAs,
                isLoading: user.status === "loading" && meetings.status === "loading",
                isMeetingStarted,
                toggleIsMeetingStarted,
            }}
        >
            {children}
        </MonthlyCheckInContext.Provider>
    );
};
