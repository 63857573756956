import React from "react";
import PropTypes, { string } from "prop-types";
import { Box, IconButton, Typography } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import Avatar from "../../avatar";
import ContainedIconButton from "../../contained-icon-button";
import { ChevronRight, MoreVert } from "@material-ui/icons";
import { useStyles } from "./division-list-item.styles";
import { avatarProps, maxAvatars, viewButtonStyle } from "./config";
import { Link as RouterLink } from "react-router-dom";
import { urls } from "../../../config/urls";

interface DivisionListItemProps {
    onOverflowMenuClick: (event: React.MouseEvent<HTMLButtonElement>, divisionId: string, name: string) => unknown;
    userIdArr: string[];
    name: string;
    divisionId: string;
    showOverflow: boolean;
}

const DivisionListItem = ({
    onOverflowMenuClick,
    userIdArr,
    name,
    divisionId,
    showOverflow,
}: DivisionListItemProps) => {
    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onOverflowMenuClick(event, divisionId, name);
    };

    return (
        <Box className={classes.divisionListItem}>
            <Box className={classes.avatarGroup}>
                <AvatarGroup max={maxAvatars} spacing="small" classes={{ avatar: classes.avatar }}>
                    {userIdArr.map((id) => (
                        <Avatar key={id} id={id} {...avatarProps} />
                    ))}
                </AvatarGroup>
            </Box>
            <Box className={classes.divisionName}>
                <Typography className={classes.divisionText}>{name}</Typography>
            </Box>
            <Box className={classes.userCount}>
                <Typography className={classes.divisionText}>
                    {userIdArr.length} {`user${userIdArr.length === 1 ? "" : "s"}`}
                </Typography>
            </Box>
            <Box className={classes.divisionActions}>
                <ContainedIconButton
                    style={viewButtonStyle}
                    component={RouterLink}
                    to={urls.coach.divisions.baseUrl + "/" + divisionId}
                >
                    <ChevronRight />
                </ContainedIconButton>
                {showOverflow && (
                    <IconButton className={classes.divisionActionButton} onClick={handleClick}>
                        <MoreVert />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

DivisionListItem.propTypes = {
    onOverflowMenuClick: PropTypes.func,
    userIdArr: PropTypes.arrayOf(string),
    name: PropTypes.string,
    divisionId: PropTypes.string,
    showOverflow: PropTypes.bool,
};

export { DivisionListItem };
