import { Box, Card, Container, Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import CertificateDecorations from "../../components/certificate-decorations";
import Laurel from "../../components/laurel";
import PageTitle from "../../components/page-title";
import YearSelector from "../../components/year-selector";
import { useGoalState } from "../../hooks/states/use-goal-state";
import { useCompanyState } from "../../hooks/use-company-state";
import { useYear } from "../../hooks/use-year";
import { useStyles } from "./major-achievements.styles";
import { User } from "../../interfaces/user";
import { Goal } from "../../interfaces/goal";
import { useObjectiveState } from "../../hooks/states/use-objective-state";
import { ThumbUp } from "@material-ui/icons";
import { DownloadMajorAchievementsPdfButton } from "../../components/major-achievements/download-major-achievements-pdf-button";

interface MajorAchievementsProps {
    user: User;
}

interface Item {
    description: string;
    isObjective: boolean;
}

function MajorAchievements({ user }: MajorAchievementsProps) {
    const classes = useStyles();
    const { company } = useCompanyState();
    const { year, setYear, yearsArray } = useYear(user);
    const { goals }: { goals: Goal[] } = useGoalState({ year, user: user.id, isAccomplished: true, category: 0 });
    const { objectives } = useObjectiveState({ year, user: user.id, isAccomplished: true, category: 0 });

    const renderAchievements = () => {
        const numberOfItems = goals.length + objectives.length;
        const itemsArray = getItemsArray();

        if (numberOfItems > 6) {
            return twoColumnAchievements(itemsArray);
        } else {
            return oneColumnAchievements(itemsArray);
        }
    };

    const getItemsArray = (): Item[] => {
        const items: Item[] = [];
        objectives.forEach((objective) => items.push({ description: objective.accomplishment, isObjective: true }));
        goals.forEach((goal) => items.push({ description: goal.accomplishment || "", isObjective: false }));
        return items;
    };

    const oneColumnAchievements = (items: Item[]) => {
        return (
            <Grid container spacing={3}>
                {items.map((item) => (
                    <Grid item xs={12}>
                        <Typography>
                            {item.description}
                            {item.isObjective && (
                                <ThumbUp color="primary" fontSize="small" style={{ marginLeft: 8, marginBottom: -3 }} />
                            )}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const twoColumnAchievements = (items: Item[]) => {
        const middle = Math.floor(items.length / 2);
        const firstHalf = items.slice(0, middle);
        const secondHalf = items.slice(middle);

        return (
            <Grid container spacing={7}>
                <Grid item xs={6}>
                    {oneColumnAchievements(firstHalf)}
                </Grid>
                <Grid item xs={6}>
                    {oneColumnAchievements(secondHalf)}
                </Grid>
            </Grid>
        );
    };

    const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setYear(parseInt(value));
    };

    return (
        <Container>
            <PageTitle>Major Achievements</PageTitle>
            <Card className={classes.actionsCard}>
                <Box>
                    <Typography variant="subtitle1" style={{ display: "inline" }}>
                        Viewing achievements for:&nbsp;&nbsp;
                    </Typography>
                    <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleSelect} />
                </Box>
                <DownloadMajorAchievementsPdfButton userId={user.id} year={year} />
            </Card>
            <Card className={classes.certificate}>
                <CertificateDecorations />
                <Laurel year={year} />
                <Box className={classes.companyName}>{company?.name}</Box>
                <Box className={classes.title}>Certificate</Box>
                <Box className={classes.subtitle1}>Of Achievement</Box>
                <Box className={classes.subtitle2}>Presented to</Box>
                <Box className={classes.name}>{`${user?.firstName} ${user?.lastName}`}</Box>
                <Box className={classes.description}>
                    For demonstrated excellence in attaining the following achievements
                </Box>
                <Box className={classes.divider} />
                <Box className={classes.achievementContainer}>{renderAchievements()}</Box>
            </Card>
        </Container>
    );
}

export { MajorAchievements };
