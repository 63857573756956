import React from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";

interface WelcomeCardProps {
    firstName: string;
}

const useStyles = makeStyles((theme) => ({
    verticalSpacing: {
        marginBottom: theme.spacing(5),
    },
    cardPadding: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
}));

const WelcomeCard = ({ firstName }: WelcomeCardProps) => {
    const classes = useStyles();

    return (
        <Card className={`${classes.verticalSpacing} ${classes.cardPadding}`} style={{ padding: 12 }}>
            <Typography variant="subtitle1">Welcome, {firstName}!</Typography>
        </Card>
    );
};

export default WelcomeCard;
