import { Font } from "@react-pdf/renderer";
import montserrat from "../../assets/fonts/montserrat/Montserrat-Regular.ttf";
import montserratBold from "../../assets/fonts/montserrat/Montserrat-Bold.ttf";
import montserratExtraBold from "../../assets/fonts/montserrat/Montserrat-ExtraBold.ttf";
import montserratBlack from "../../assets/fonts/montserrat/Montserrat-Black.ttf";

export const useMontserrat = () => {
    Font.register({
        family: "Montserrat",
        fonts: [
            { src: montserrat },
            { src: montserratBold, fontWeight: 700 },
            { src: montserratExtraBold, fontWeight: 800 },
            { src: montserratBlack, fontWeight: 900 },
        ],
    });
};
