import { QueryKeyset } from "../interfaces/query-keyset";

interface QueryKeys {
    activityRecord: QueryKeyset;
    additionalResources: QueryKeyset;
    company: QueryKeyset;
    division: QueryKeyset;
    goal: QueryKeyset;
    jot: QueryKeyset;
    meeting: QueryKeyset;
    objective: QueryKeyset;
    priorityAction: QueryKeyset;
    result: QueryKeyset;
    submissionNote: QueryKeyset;
    takeaway: QueryKeyset;
    topDiscussionTopic: QueryKeyset;
    trackedItem: QueryKeyset;
    user: QueryKeyset;
    discussion: QueryKeyset;
    notification: QueryKeyset;
    todo: QueryKeyset;
}

const generateQueryKeys = (moduleName: string): QueryKeyset => {
    return {
        create: "create-" + moduleName,
        delete: "delete-" + moduleName,
        find: "find-" + moduleName,
        get: "get-" + moduleName,
        update: "update-" + moduleName,
    };
};

export const queryKeys: QueryKeys = {
    activityRecord: generateQueryKeys("activity-record"),
    additionalResources: generateQueryKeys("additional-resources"),
    company: generateQueryKeys("company"),
    division: generateQueryKeys("division"),
    goal: generateQueryKeys("goal"),
    jot: generateQueryKeys("jot"),
    meeting: generateQueryKeys("meeting"),
    objective: generateQueryKeys("objective"),
    priorityAction: generateQueryKeys("priority-action"),
    result: generateQueryKeys("result"),
    submissionNote: generateQueryKeys("submission-note"),
    takeaway: generateQueryKeys("takeaway"),
    topDiscussionTopic: generateQueryKeys("top-discussion-topic"),
    trackedItem: generateQueryKeys("tracked-item"),
    user: generateQueryKeys("user"),
    discussion: generateQueryKeys("discussion"),
    notification: generateQueryKeys("notification"),
    todo: generateQueryKeys("todo"),
};
