import React from "react";
import PropTypes from "prop-types";
import { Comment } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";
import { Link as RouterLink, useParams } from "react-router-dom";

interface DiscussionLinkProps {
    id: string;
}

const DiscussionLink = ({ id }: DiscussionLinkProps) => {
    const classes = useStyles();
    const { id: userId } = useParams<{ id: string }>();

    return (
        <IconButton className={classes.buttonWrapper} component={RouterLink} to={`${userId}/discussion/${id}`}>
            <Comment />
        </IconButton>
    );
};

DiscussionLink.propTypes = {
    id: PropTypes.string,
};

export { DiscussionLink };
