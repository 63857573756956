import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useGoalMutation } from "../../hooks/mutations/use-goal-mutation";
import { useGoals } from "../../hooks/queries/use-goals";
import { useCompanyState } from "../../hooks/use-company-state";
import AutoSaveTextField from "../autosave-text-field";
import GoalAccomplishedButton from "../goal-accomplished-button";
import CategoryTitle from "../monthly-check-in/category-title";
import PointsHeader from "./points-header";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import { queryKeys } from "../../hooks/api/config/query-keys";

const useStyles = makeStyles((theme) => ({
    centered: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function GoalSection(props) {
    const classes = useStyles();
    const { category, year, userID, disabled } = props;
    const { company } = useCompanyState();
    const { status, data } = useGoals({ user: userID, year, category, isAccomplished: false });
    const [goals, setGoals] = useState([]);
    const queryClient = useQueryClient();
    const goalMutation = useGoalMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.goal.get);
            queryClient.invalidateQueries(queryKeys.goal.find);
        },
    });
    const masterPlanTemplate = useMasterPlanTemplate();

    useEffect(() => {
        const fillGoals = (itemArr) => {
            const filledArray = [...itemArr];
            for (let i = filledArray.length; i < 3; i++) {
                filledArray.push({
                    id: null,
                    category,
                    year,
                    description: "",
                });
            }
            return filledArray;
        };

        if (status === "success") {
            const filledGoals = fillGoals(data.goals || []);
            setGoals(filledGoals);
        } else {
            setGoals([]);
        }
    }, [status, data, category, year]);

    const showGoalPoints = () => {
        return category === 0 && company?.settings?.isGoalPointsEnabled;
    };

    return (
        <Box>
            <CategoryTitle
                title={masterPlanTemplate[category].masterPlan.description}
                subheading={masterPlanTemplate[category].masterPlan.subheading}
                style={category !== 0 ? { marginBottom: 24 } : undefined}
            />
            <Grid container>
                <Grid item xs={10} />
                <Grid item xs={1}>
                    <PointsHeader show={showGoalPoints()} />
                </Grid>
                <Grid item xs={1} />
            </Grid>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container style={{ flexGrow: 1 }} spacing={5}>
                    {goals.map((goal, index) => (
                        <Grid container item key={goal.id || `goal-${category}-${year}-${index}`}>
                            <Grid item xs={showGoalPoints() ? 10 : 11}>
                                <AutoSaveTextField
                                    object={goal}
                                    textFieldName="description"
                                    index={index}
                                    mutation={goalMutation}
                                    disabled={disabled}
                                    maxLength={165}
                                />
                            </Grid>
                            {showGoalPoints() && disabled ? (
                                <Grid item xs={1} className={classes.centered} style={{ padding: 8 }}>
                                    <AutoSaveTextField
                                        textFieldName="points"
                                        index={index}
                                        object={goal}
                                        mutation={goalMutation}
                                        multiline={false}
                                        fullWidth={false}
                                        variant="standard"
                                        inputProps={{ style: { textAlign: "center" } }}
                                        InputLabelProps={{ style: { display: "none" } }}
                                        disabled={!goal.id}
                                    />
                                </Grid>
                            ) : (
                                showGoalPoints() && (
                                    <Grid item xs={1} className={classes.centered}>
                                        {goal.points ?? 0}
                                    </Grid>
                                )
                            )}
                            <Grid item xs={1} className={classes.centered}>
                                <GoalAccomplishedButton goal={goal} disabled={!goal.id || disabled} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

GoalSection.propTypes = {
    category: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    userID: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default GoalSection;
