import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { cancelButtonText, dialogMaxWidth, dialogPrompt, dialogTitle, saveButtonText, textFieldLabel } from "./config";
import { User } from "../../../interfaces/user";
import { Autocomplete } from "@material-ui/lab";
import MyCheckinTextField from "../../my-checkin-text-field";
import MyCheckinButton from "../../my-checkin-button";
import Avatar from "../../avatar";

interface AddEmployeeDialogProps {
    open: boolean;
    onClose: () => unknown;
    onSave: (userId: string) => unknown;
    users: User[];
}

const AddEmployeeDialog = ({ open, onClose, users, onSave }: AddEmployeeDialogProps) => {
    const [value, setValue] = useState<User | null>(null);

    const handleClose = () => {
        setValue(null);
        onClose();
    };

    const handleSave = () => {
        if (!value) {
            return;
        }
        onSave(value.id);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={dialogMaxWidth}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent style={{ display: "flex", flexDirection: "column", gap: 24, alignItems: "center" }}>
                <Typography>{dialogPrompt}</Typography>
                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => (
                        <MyCheckinTextField {...params} label={textFieldLabel} style={{ width: 384 }} />
                    )}
                    options={users}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    renderOption={(option) => (
                        <>
                            <Avatar id={option.id} diameter={32} borderWidth={1} />
                            <span style={{ marginLeft: 16 }}>{`${option.firstName} ${option.lastName}`}</span>
                        </>
                    )}
                />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>{cancelButtonText}</MyCheckinButton>
                <MyCheckinButton variant="contained" color="primary" disabled={!value} onClick={handleSave}>
                    {saveButtonText}
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

AddEmployeeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    users: PropTypes.array,
};

export default AddEmployeeDialog;
