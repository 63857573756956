import React from "react";
import image from "../assets/images/backgrounds/coach-card-bg.jpg";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import CoachCardRow from "./coach-card-row";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        position: "relative",
        paddingBottom: 24,
    },
    image: {
        width: "100%",
        height: 96,
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    imageOverlay: {
        // width: 330,
        height: 96,
        backgroundColor: "rgba(70,71,73,0.3)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imageOverlayText: {
        color: theme.palette.neutral.light,
        fontSize: 24,
        fontWeight: 600,
    },
}));

function CoachCard({ coaches }) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Box className={classes.image} />
            <Box className={classes.imageOverlay}>
                <Typography className={classes.imageOverlayText}>Coach</Typography>
            </Box>
            {coaches?.map((coach, index) => (
                <CoachCardRow key={index} coach={coach} />
            ))}
        </Card>
    );
}

export default CoachCard;
