import React from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import image from "../assets/images/backgrounds/employee-card-bg.jpg";
import Avatar from "./avatar";

const useStyles = makeStyles((theme) => ({
    root: {
        // width: 330,
        position: "relative",
        height: 313,
    },
    image: {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 175,
    },
    imageOverlay: {
        backgroundColor: "rgba(70,71,73,0.4)",
        height: 175,
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
    },
    avatar: {
        position: "absolute",
        top: 88,
        left: 0,
        right: 0,
        margin: "auto",
    },
    employeeInfo: {
        height: 150,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
    },
    name: {
        fontSize: 24,
        marginBottom: 4,
    },
    email: {
        textDecoration: "none",
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 21,
    },
}));

function EmployeeCard({ imageUrl, name, email, className, userID }) {
    const classes = useStyles();

    return (
        <Card className={classes.root + " " + className}>
            <Box className={classes.image} />
            <Box className={classes.imageOverlay} />
            <Avatar className={classes.avatar} id={userID} diameter={137} />
            <Box className={classes.employeeInfo}>
                <Typography className={classes.name}>{name}</Typography>
                <Typography className={classes.email}>{email}</Typography>
            </Box>
        </Card>
    );
}

export default EmployeeCard;
