import React from "react";
import PropTypes from "prop-types";
import { MasterPlanPdfDoc } from "./master-plan-pdf-doc";
import { useObjectiveState } from "../../../hooks/states/use-objective-state";
import { useGoalState } from "../../../hooks/states/use-goal-state";
import { usePriorityActionState } from "../../../hooks/states/use-priority-action-state";
import { useUsersState } from "../../../hooks/states/use-users-state";

interface MasterPlanPdfProps {
    userId: string;
    year: number;
}

const MasterPlanPdf = ({ userId, year }: MasterPlanPdfProps) => {
    const { users } = useUsersState({ id: userId });
    const { objectives } = useObjectiveState({
        user: userId,
        year,
        isAccomplished: false,
        category: 0,
    });
    const { goals } = useGoalState({ user: userId, year, isAccomplished: false });
    const { priorityActions } = usePriorityActionState({
        user: userId,
        year,
        isAccomplished: false,
    });

    const getEmployeeName = () => {
        return users ? `${users[0]?.firstName} ${users[0]?.lastName}` : "";
    };

    return (
        <MasterPlanPdfDoc
            year={year}
            goals={goals}
            priorityActions={priorityActions}
            objective={objectives[0]}
            employeeName={getEmployeeName()}
        />
    );
};

MasterPlanPdf.propTypes = {
    userId: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
};

export { MasterPlanPdf };
