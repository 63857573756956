import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import MyCheckinButton from "../../my-checkin-button";
import { Link } from "react-router-dom";
import {
    closingParagraphs,
    dialogParagraphs,
    dialogTitle,
    dialogToDoList,
    doneButtonText,
    planLinkPrompt,
    planLinkText,
    planLinkUrl,
} from "./config";
import { useStyles } from "./master-plan-alert-dialog.styles";

interface MasterPlanAlertDialogProps {
    open: boolean;
    onClose: () => any;
}

/**
 * Dialog paragraph component.
 *
 * TODO - This is the same as the paragraph component in the new user welcome dialog.
 *       Move this out to a separate component and import it into both dialogs.
 *
 * @param children
 * @constructor
 */
const Paragraph = ({ children }: { children: string }) => (
    <Typography gutterBottom align="center" dangerouslySetInnerHTML={{ __html: children }} />
);

const MasterPlanAlertDialog = ({ open, onClose }: MasterPlanAlertDialogProps) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const paragraphMapFunction = (paragraph: string, index: number) => (
        <Paragraph key={`master-plan-alert-dialog-paragraph-${index}`}>{paragraph}</Paragraph>
    );

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogParagraphs.map(paragraphMapFunction)}
                <ul>
                    {dialogToDoList.map((item, index) => (
                        <li key={`master-plan-alert-dialog-list-item-${index}`}>{item}</li>
                    ))}
                </ul>
                <Paragraph>{planLinkPrompt}</Paragraph>
                <Link to={planLinkUrl} className={classes.link}>
                    {planLinkText}
                </Link>
                {closingParagraphs.map(paragraphMapFunction)}
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>{doneButtonText}</MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

export default MasterPlanAlertDialog;
