import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTakeawayState } from "../hooks/states/use-takeaway-state";
import MyCheckinButton from "./my-checkin-button";
import MyCheckinTextField from "./my-checkin-text-field";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
    },
}));

function TakeawayDialog({ open, handleClose, month, year, notes, user, ...others }) {
    const { takeaways } = useTakeawayState({ user: user.id, month, year });
    const classes = useStyles();

    const getTakeawaysByCategory = (category) => {
        return takeaways
            .filter((takeaway) => takeaway.category === category)
            .map((takeaway, i) => (
                <MyCheckinTextField
                    disabled
                    value={takeaway.description}
                    multiline
                    rows={2}
                    fullWidth
                    label={`${i + 1}.`}
                />
            ));
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose} {...others}>
            <DialogTitle>Meeting Takeaways</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {getTakeawaysByCategory(0)}
                <Typography variant="subtitle1" style={{ alignSelf: "flex-start" }}>
                    Notes
                </Typography>
                <MyCheckinTextField disabled value={notes} multiline fullWidth rows={2} />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton variant="outlined" onClick={handleClose}>
                    Close
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default TakeawayDialog;
