import { Card, Container, Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useRouteMatch } from "react-router-dom";
import PageTitle from "../../components/page-title";
import YearSelector from "../../components/year-selector";
import { useYear } from "../../hooks/use-year";
import { useStyles } from "./completed-items.styles";
import { User } from "../../interfaces/user";
import { useViewingAs } from "../../hooks/use-viewing-as";
import CompletedGoalSection from "../../components/completed-items/completed-goal-section";
import CompletedPriorityActionSection from "../../components/completed-items/completed-priority-action-section";

interface CompletedItemsProps {
    viewingAs: "employee" | "coach";
    user: User;
}

interface CompletedItemsParams {
    userId?: string;
}

function CompletedItems({ viewingAs, user }: CompletedItemsProps) {
    const { params } = useRouteMatch<CompletedItemsParams>();
    const classes = useStyles();
    const value = 0;
    const { year, setYear, yearsArray } = useYear(user);
    const { isViewingAsCoach } = useViewingAs({ viewingAs });
    const userID = params.userId || user.id;
    const isDisabled = !!params.userId;

    const handleYearSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setYear(parseInt(value));
    };

    const renderEmployeeName = () => {
        return (
            <Typography variant="h6" align="center">
                {getUserFullName()}
            </Typography>
        );
    };

    const getUserFullName = () => {
        return `${user.firstName} ${user.lastName}`;
    };

    return (
        <Container>
            <PageTitle>
                {year} Completions List
                {isViewingAsCoach && renderEmployeeName()}
            </PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container item className={classes.selectRow}>
                    <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleYearSelect} />
                </Grid>
                {[0, 1, 2, 3].map((index) => (
                    <CompletedGoalSection userID={userID} year={year} category={index} disabled={isDisabled} />
                ))}
                <CompletedPriorityActionSection userID={userID} year={year} category={value} disabled={isDisabled} />
            </Card>
        </Container>
    );
}

export { CompletedItems };
