import React from "react";
import PropTypes from "prop-types";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { Laurel } from "./laurel";

interface YearDisplayProps {
    year: number;
}

const styles = StyleSheet.create({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 32,
        left: 48,
    },
    center: {
        position: "absolute",
        display: "flex",
    },
    year: {
        fontSize: 22,
        color: "#F1960E",
        fontWeight: 600,
    },
});

const YearDisplay = ({ year }: YearDisplayProps) => {
    return (
        <View style={styles.wrapper} fixed>
            <Text style={[styles.center, styles.year]}>{year.toString()}</Text>
            <Laurel width={128} height={128} />
        </View>
    );
};

YearDisplay.propTypes = {
    year: PropTypes.number,
};

export { YearDisplay };
