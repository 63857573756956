import { CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useCompanyMutation } from "../../hooks/mutations/use-company-mutation";
import { useCompany } from "../../hooks/queries/use-company";
import { stateAbbreviations } from "../../util/state-abbreviations";
import TabPanel from "../coach-station/tab-panel";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6),
    },
    stateSelector: {
        width: "100%",
        // marginLeft: 16,
    },
    stateSelectorLabel: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.neutral.dark,
    },
}));

function CompanyInfoTab(props) {
    const classes = useStyles();
    const { index, value } = props;
    const queryClient = useQueryClient();
    const [company, setCompany] = useState({});
    const [state, setState] = useState("AL");
    const { status, data } = useCompany();
    const companyMutation = useCompanyMutation({ onError: () => queryClient.invalidateQueries("fetchCompany") });

    useEffect(() => {
        if (status === "success") {
            setCompany(data || {});
        } else {
            setCompany({});
        }
    }, [status, data]);

    const handleChange = (event) => {
        const { value } = event.target;
        setCompany({ ...company, name: value });
    };

    const handleAddressChange = (event) => {
        const { name, value } = event.target;
        setCompany({ ...company, address: { ...company.address, [name]: value } });
    };

    const handleStateChange = (event) => {
        const { value } = event.target;
        setState(value);
        setCompany({ ...company, address: { ...company.address, state: value } });
    };

    const handleSave = () => {
        const updatedCompany = { ...company };
        companyMutation.mutate(updatedCompany);
    };

    return (
        <TabPanel index={index} value={value}>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container className={classes.container} spacing={6}>
                    <Grid container item xs={12}>
                        <MyCheckinTextField
                            label="Company Name"
                            name="name"
                            value={company.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <MyCheckinTextField
                            label="Address 1"
                            name="address1"
                            value={company.address?.address1}
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <MyCheckinTextField
                            label="Address 2"
                            name="address2"
                            value={company.address?.address2}
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item spacing={5}>
                        <Grid item md={4} xs={12}>
                            <MyCheckinTextField
                                label="City"
                                name="city"
                                value={company.address?.city}
                                onChange={handleAddressChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl className={classes.stateSelector}>
                                <InputLabel id="state" className={classes.stateSelectorLabel}>
                                    State
                                </InputLabel>
                                <Select
                                    labelId="state"
                                    id="state-select"
                                    value={company.address?.state || state}
                                    onChange={handleStateChange}
                                >
                                    {stateAbbreviations.map((state) => (
                                        <MenuItem value={state}>{state}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <MyCheckinTextField
                                label="Zip Code"
                                name="zip"
                                value={company.address?.zip}
                                onChange={handleAddressChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify="flex-end">
                        <MyCheckinButton variant="outlined" onClick={handleSave}>
                            Save
                        </MyCheckinButton>
                    </Grid>
                </Grid>
            )}
        </TabPanel>
    );
}

CompanyInfoTab.propTypes = {
    index: PropTypes.number,
    value: PropTypes.number,
};

export default CompanyInfoTab;
