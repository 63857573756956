import React from "react";
import PropTypes from "prop-types";
import { Takeaway } from "../../interfaces/takeaway";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import { OpenReminders } from "../employee-dashboard/open-reminders";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface RemindersAccordionProps {
    reminders: Takeaway[];
}

const accordionXPadding = 5;

const useStyles = makeStyles((theme) => ({
    accordion: {
        paddingLeft: theme.spacing(accordionXPadding),
        paddingRight: theme.spacing(accordionXPadding),
    },
}));

const RemindersAccordion = ({ reminders }: RemindersAccordionProps) => {
    const classes = useStyles();

    const getRemindersCount = () => {
        return reminders.length;
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="subtitle2">Reminders</Typography>
                    </Grid>
                    <Grid item>
                        <Chip
                            variant="default"
                            color="default"
                            label={getRemindersCount()}
                            size="small"
                            style={{ marginLeft: 16 }}
                        />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <OpenReminders reminders={reminders} />
            </AccordionDetails>
        </Accordion>
    );
};

RemindersAccordion.propTypes = {
    reminders: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            user: PropTypes.string,
            month: PropTypes.number,
            year: PropTypes.number,
            category: PropTypes.number,
            description: PropTypes.string,
            isReminder: PropTypes.bool,
            isDismissed: PropTypes.bool,
        })
    ),
};

export { RemindersAccordion };
