import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    box: {
        height: 38,
        borderWidth: 1,
        borderColor: "#e0e0e1",
        borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        fontSize: 14,
        fontWeight: 500,
    },
    backgroundNeutral: {
        backgroundColor: "#f2f2f3",
        color: theme.palette.neutral.dark,
    },
    backgroundPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.neutral.light,
    },
    backgroundSuccess: {
        backgroundColor: "#8bc34a",
        color: theme.palette.neutral.light,
    },
}));
