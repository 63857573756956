import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: "flex",
        justifyContent: "center",
    },
    inputField: {
        width: 384,
    },
}));
