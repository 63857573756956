import React from "react";
import { Image } from "@react-pdf/renderer";
import gradientHr from "../../../assets/images/certificate/gradient-hr.png";
import PropTypes from "prop-types";

interface GradientHrProps {
    style?: any;
}

const GradientHr = ({ style }: GradientHrProps) => {
    return <Image src={gradientHr} style={style} />;
};

GradientHr.propTypes = {
    style: PropTypes.object,
};

export { GradientHr };
