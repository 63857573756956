import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

function RouteChangeTracker() {
    const location = useLocation();

    useEffect(() => {
        let path = location.pathname;

        const splitPathname = () => {
            return location.pathname.split("/");
        };

        const pathIncludes = (searchString: string) => {
            return location.pathname.includes(searchString);
        };

        const trimIdFromViewEntry = () => {
            const splitPath = splitPathname();
            const withoutId = splitPath.slice(0, splitPath.length - 1);
            return withoutId.join("/");
        };

        const trimIdFromCoachStation = () => {
            const acceptedPaths = ["", "coach", "coach-station", "master-plan", "completed-items", "monthly-check-in"];
            const splitPath = splitPathname();
            const filteredPath = splitPath.filter((value) => acceptedPaths.includes(value));
            return filteredPath.join("/"); //debug
        };

        if (pathIncludes("/view-entry")) {
            path = trimIdFromViewEntry();
        } else if (location.pathname.includes("/coach-station")) {
            path = trimIdFromCoachStation();
        }

        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }, [location]);

    return <div></div>;
}

export default withRouter(RouteChangeTracker);
