import React from "react";
import { ObjectiveSection } from "../objective-section";
import GoalSection from "../goal-section";
import Section from "../section";

const OkrSection = () => {
    return (
        <Section>
            <ObjectiveSection />
            <div style={{ marginTop: 36 }}>
                <GoalSection category={0} />
            </div>
        </Section>
    );
};

export default OkrSection;
