import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuthState } from "../hooks/use-auth-state";
import { API } from "../util/api";
import { CoachAppContext } from "../context/coach-app-context";

export function CoachAppProvider({ children }) {
    const { user } = useAuthState();

    const teamQuery = useQuery("fetchTeam", () => {
        return API.findUsers({ coaches: user.id });
    });

    const meetingQuery = useQuery(
        "fetchTeamMeetings",
        () => {
            const team = teamQuery.data.users;
            const activeUsers = team.filter((member) => !member.isDeleted);
            const queries = [];
            activeUsers.forEach((member) => queries.push(API.getMeetings({ user: member.id })));
            return Promise.all(queries);
        },
        {
            enabled: teamQuery.isSuccess,
        }
    );

    const [state, setState] = useState({
        user: {
            email: "",
            firstName: "",
            lastName: "",
            company: "",
            permissions: {
                isCoach: true,
                isAdmin: false,
            },
            lastLogin: "",
            coaches: [],
            profileImage: "",
        },
        queries: {},
    });

    useEffect(() => {
        setState({
            user,
            queries: {
                teamQuery,
                meetingQuery,
            },
        });
    }, [user, teamQuery, meetingQuery]);

    return <CoachAppContext.Provider value={state}>{children}</CoachAppContext.Provider>;
}
