import React, { ChangeEvent } from "react";
import MyCheckinButton from "../../my-checkin-button";
import { Tooltip } from "@material-ui/core";
import { endOfMonth, isAfter } from "date-fns";
import { MeetingStatus } from "../../../types/meeting-status";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";

interface SubmitButtonProps {
    month: number;
    year: number;
    onClick: (event?: ChangeEvent<HTMLButtonElement>) => any;
    meetingStatus: MeetingStatus;
}

const returnedTooltipTitle = "Re-submit your Monthly Check-In to your Coach";
const notSubmittedTooltipTitle = "Submit your Monthly Check-In to your Coach";
const cannotSubmitFutureMonthTooltipTitle = "Cannot submit until this month has elapsed";

function SubmitButton({ onClick }: SubmitButtonProps) {
    const { month, year, meeting } = useMonthlyCheckInState();
    const getEndOfMonthBeingViewed = () => {
        const firstDayOfResultsMonth = new Date(year, month);
        return endOfMonth(firstDayOfResultsMonth);
    };

    const isSubmittable = () => {
        if (isReturned()) {
            return true;
        }
        if (isSubmitted()) {
            return false;
        }
        return isMonthElapsed();
    };

    const isReturned = () => {
        return meeting?.status === "returned";
    };

    const isSubmitted = () => {
        return !!meeting?.status;
    };

    const isMonthElapsed = () => {
        const today = new Date();
        const endOfMonthBeingViewed = getEndOfMonthBeingViewed();
        return isAfter(today, endOfMonthBeingViewed);
    };

    const createTooltipTitle = (): string => {
        if (!isMonthElapsed()) {
            return cannotSubmitFutureMonthTooltipTitle;
        }
        if (isReturned()) {
            return returnedTooltipTitle;
        }
        if (!isSubmitted()) {
            return notSubmittedTooltipTitle;
        }
        return "";
    };

    return (
        <Tooltip title={createTooltipTitle()}>
            <div>
                <MyCheckinButton variant="contained" color="primary" disabled={!isSubmittable()} onClick={onClick}>
                    Submit
                </MyCheckinButton>
            </div>
        </Tooltip>
    );
}

export { SubmitButton };
