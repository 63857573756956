import React from "react";
import PropTypes from "prop-types";
import { Spacer } from "./spacer";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";
import { DiscussionLink } from "./discussion-link";

interface KeyResultListItemProps {
    description: string;
    id: string;
}

const KeyResultListItem = ({ description, id }: KeyResultListItemProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.okrContentWrapper}>
            <Spacer />
            <Typography className={classes.okrContent}>{description}</Typography>
            <DiscussionLink id={id} />
        </Box>
    );
};

KeyResultListItem.propTypes = {
    description: PropTypes.string,
    id: PropTypes.string,
};

export { KeyResultListItem };
