import React from "react";
import TermlyEmbed from "./termly-embed";

const termsAndConditionsID = "9293fb4b-3529-48d4-835f-898399a3c3fc";

function TermsAndConditions() {
    return <TermlyEmbed id={termsAndConditionsID} />;
}

export default TermsAndConditions;
