import React from "react";
import { Card, Container } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import { useStyles } from "./discussion.styles";
import { Breadcrumbs } from "../../components/discussion/breadcrumbs";
import { DiscussionCardTitle } from "../../components/discussion/discussion-card-title";
import { Section } from "../../components/discussion/section";
import { OkrSection } from "../../components/discussion/okr-section";
import { keyResultSectionTitle, objectiveSectionTitle } from "../../components/discussion/config";
import { Divider } from "../../components/discussion/divider";
import { DiscussionSection } from "../../components/discussion/discussion-section";
import { useParams } from "react-router-dom";
import { useFindApi } from "../../hooks/api/use-find-api";
import { Discussion as IDiscussion } from "../../interfaces/discussion";
import { discussionAPI, divisionAPI, goalAPI, objectiveAPI, userAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { Objective } from "../../interfaces/objective";
import { useGetApi } from "../../hooks/api/use-get-api";
import { Goal } from "../../interfaces/goal";
import { User } from "../../interfaces/user";
import { Division } from "../../interfaces/division";

const Discussion = () => {
    const { id, goalId } = useParams<{ id: string; goalId: string }>();
    const classes = useStyles();

    const getUserResult = useGetApi<User>(userAPI, queryKeys.user.get, id);

    const user = getUserResult.data;

    const getDivisionResult = useGetApi<Division>(divisionAPI, queryKeys.division.get, user?.division || "", {
        enabled: !!user && !!user.division,
    });

    const findObjectiveResult = useFindApi<Objective>(
        objectiveAPI,
        queryKeys.objective.find,
        { user: id, isAccomplished: false, year: new Date().getFullYear() },
        "objectives"
    );

    const getGoalResult = useGetApi<Goal>(goalAPI, queryKeys.goal.get, goalId);

    const findDiscussionResponse = useFindApi<IDiscussion>(
        discussionAPI,
        queryKeys.discussion.find,
        { goal: goalId },
        "discussions",
        { enabled: !!goalId }
    );

    const discussion = findDiscussionResponse.data.result[0];
    const reversedComments = discussion ? [...discussion.comments].reverse() : [];
    const objective = findObjectiveResult.data.result[0];
    const goal = getGoalResult.data;
    const division = getDivisionResult.data;

    return (
        <Container>
            <PageTitle>TeamShare</PageTitle>
            <Card className={classes.contentWrapper}>
                <Breadcrumbs
                    id={id}
                    name={user ? user.firstName + " " + user.lastName : "Loading..."}
                    divisionId={division?.id || ""}
                    divisionName={division?.name || ""}
                />
                <DiscussionCardTitle />
                <OkrSection
                    title={objectiveSectionTitle}
                    content={findObjectiveResult.status === "loading" ? "Loading..." : objective?.description || ""}
                />
                <OkrSection
                    title={keyResultSectionTitle}
                    content={getGoalResult.status === "loading" ? "Loading..." : goal?.description || ""}
                />
                <Divider />
                <Section>
                    <DiscussionSection
                        comments={reversedComments || []}
                        discussionId={discussion?.id}
                        isLoading={findDiscussionResponse.status === "loading"}
                    />
                </Section>
            </Card>
        </Container>
    );
};

Discussion.propTypes = {};

export { Discussion };
