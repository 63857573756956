import { ActivityRecordRequiredAttrs } from "../../util/api/interfaces/activity-record-required-attrs";
import { useActivityRecord } from "../queries/use-activity-record";
import { useQueryState } from "./use-query-state";
import { ActivityRecord } from "../../interfaces/activity-record/activity-record";
import { useQueryStatus } from "../use-query-status";

const isCurrentMonth = ({ month, year }: { month: number; year: number }) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    return month === currentMonth && year === currentYear;
};

const getDefaultActivityRecord = ({ user, month, year }: ActivityRecordRequiredAttrs): ActivityRecord => {
    return {
        alerts: { monthlyResultsMasterPlanAlertViewed: false },
        createdAt: new Date(),
        goals: { active: [], completed: [], created: [] },
        id: "",
        meetings: { numberCompleted: 0 },
        month,
        priorityActions: { created: [], completed: [], active: [] },
        updatedAt: new Date(),
        user,
        userStats: { hasLoggedIn: isCurrentMonth({ month, year }), logins: isCurrentMonth({ month, year }) ? 1 : 0 },
        year,
    };
};

export const useActivityRecordState = (params: ActivityRecordRequiredAttrs) => {
    const activityRecordQuery = useActivityRecord(params);
    const activityRecords: ActivityRecord[] = useQueryState(activityRecordQuery, "activityRecords");
    const queryStatus = useQueryStatus(activityRecordQuery);

    let activityRecord: ActivityRecord | undefined = activityRecords[0];

    if (!activityRecord) {
        activityRecord = getDefaultActivityRecord(params);
    }

    return {
        activityRecord,
        ...queryStatus,
    };
};
