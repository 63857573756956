import { Card, Container, Grid } from "@material-ui/core";
import React from "react";
import MeetingPanel from "../../components/monthly-check-in/meeting-panel";
import PageTitle from "../../components/page-title";
import { useStyles } from "./monthly-check-in.styles";
import MonthlyResultsNavigation from "../../components/monthly-check-in/monthly-check-in-navigation";
import ViewSubmissionNotesButton from "../../components/monthly-check-in/view-submission-notes-button";
import MonthlyResultsAlerts from "../../components/monthly-check-in/monthly-check-in-alerts";
import { useViewingAs } from "../../hooks/use-viewing-as";
import { useMonthlyCheckInState } from "../../hooks/use-monthly-check-in-state";
import { LoadingIndicator } from "../../components/loading-indicator";
import { PageSubtitle } from "../../components/page-title/page-subtitle";
import OkrSection from "../../components/monthly-check-in/okr-section";
import { pageTitle } from "./config";
import ResultsSection from "../../components/monthly-check-in/results-section";
import PriorityActionsSection from "../../components/monthly-check-in/priority-actions-section";
import TakeawaysSection from "../../components/monthly-check-in/takeaways-section";
import Actions from "../../components/monthly-check-in/actions";
import StartMeetingButton from "../../components/monthly-check-in/start-meeting-button";
import NoMeetingAlert from "../../components/monthly-check-in/no-meeting-alert";
import { ReviewSectionTitle } from "../../components/monthly-check-in/review-section-title";

function MonthlyCheckIn() {
    const classes = useStyles();
    const { meeting, month, user, viewingAs, year, isLoading } = useMonthlyCheckInState();
    const { isViewingAsCoach } = useViewingAs({ viewingAs });

    const getMeetingId = (): string | undefined => {
        if (!meeting) {
            return "";
        }
        return meeting.id;
    };

    const getUserFullName = () => {
        return `${user?.firstName} ${user?.lastName}`;
    };

    return isLoading || !user ? (
        <LoadingIndicator />
    ) : (
        <Container>
            <MonthlyResultsAlerts />
            <PageTitle>
                {pageTitle}
                {isViewingAsCoach && <PageSubtitle>{getUserFullName()}</PageSubtitle>}
            </PageTitle>
            <MeetingPanel />
            <Card className={classes.contentContainer}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <MonthlyResultsNavigation user={user} viewingAs={viewingAs} month={month} year={year} />
                    </Grid>
                    <Grid item>
                        <Grid container>
                            {isViewingAsCoach && (
                                <Grid item style={{ marginRight: 24 }}>
                                    <StartMeetingButton />
                                </Grid>
                            )}
                            <Grid item>
                                <ViewSubmissionNotesButton meetingId={getMeetingId()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {meeting?.status === "no meeting" && (
                    <div style={{ marginTop: 32 }}>
                        <NoMeetingAlert />
                    </div>
                )}
                <ResultsSection />
                <ReviewSectionTitle />
                <OkrSection />
                <PriorityActionsSection />
                <TakeawaysSection />
                <Actions />
            </Card>
        </Container>
    );
}

export { MonthlyCheckIn };
