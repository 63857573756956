import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: "24px 32px",
    },
    selectRow: {
        display: "flex",
        justifyContent: "flex-end",
    },
    downloadPdfContainer: {
        marginTop: theme.spacing(4),
    },
}));
