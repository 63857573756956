import { ContainerProps } from "./container-props";
import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    todoItemContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        padding: 0,
        margin: 0,
        width: "100%",
    },
}));

export const TodoItemContainer = ({ children }: ContainerProps) => {
    const classes = useStyles();

    return <Box className={classes.todoItemContainer}>{children}</Box>;
};
