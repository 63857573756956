import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import MyCheckinTextField from "../../my-checkin-text-field";
import MyCheckinButton from "../../my-checkin-button";
import { useGetApi } from "../../../hooks/api/use-get-api";
import { Company } from "../../../interfaces/company/company";
import { companyAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface DeleteDivisionDialogProps {
    open: boolean;
    onClose: () => unknown;
    onDelete: ({ divisionId }: { divisionId: string }) => unknown;
    name?: string;
    divisionId?: string;
}

const DeleteDivisionDialog = ({ open, onClose, onDelete, name, divisionId }: DeleteDivisionDialogProps) => {
    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");
    const [confirmValue, setConfirmValue] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setConfirmValue(value);
    };

    const handleConfirm = () => {
        if (!divisionId || confirmValue !== name) {
            return;
        }
        onDelete({ divisionId });
    };

    const handleCancel = () => {
        setConfirmValue("");
        onClose();
    };

    const singularOrgUnitName = company?.organizationalUnitName.singular || "Division";

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
            <DialogTitle>Delete {singularOrgUnitName}</DialogTitle>
            <DialogContent style={{ display: "flex", flexDirection: "column", gap: 24, marginBottom: 24 }}>
                <Typography
                    align="center"
                    style={{
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: 24,
                        lineHeight: "29px",
                        textAlign: "center",
                        color: "#E12D39",
                    }}
                >
                    WARNING
                </Typography>
                <Typography style={{ fontStyle: "normal", fontWeight: 600, fontSize: "16px", lineHeight: "20px" }}>
                    {name} will permanently be deleted. This cannot be undone.
                </Typography>
                <Typography style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "20px" }}>
                    If there are any users assigned to this {singularOrgUnitName.toLocaleLowerCase()}, they will need to
                    be assigned to a new {singularOrgUnitName.toLocaleLowerCase()}.
                </Typography>
                <Typography style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "20px" }}>
                    Type the exact {singularOrgUnitName.toLocaleLowerCase()} name in the box below and click the delete
                    button to proceed.
                </Typography>
                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <MyCheckinTextField
                        label={`${singularOrgUnitName} Name`}
                        value={confirmValue}
                        style={{ width: 384 }}
                        onChange={handleChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleCancel}>Cancel</MyCheckinButton>
                <MyCheckinButton
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    disabled={name !== confirmValue}
                >
                    Delete
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

DeleteDivisionDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    name: PropTypes.string,
    divisionId: PropTypes.string,
};

export { DeleteDivisionDialog };
