import { CSSProperties } from "react";

export const maxAvatars = 5;
export const avatarProps = {
    diameter: 48,
    borderWidth: 1,
};

const viewButtonSize = "36px";
export const viewButtonStyle: CSSProperties = {
    width: viewButtonSize,
    height: viewButtonSize,
};
