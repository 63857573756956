import React from "react";
import MyCheckinButton from "../../my-checkin-button";

interface DialogCancelButtonProps {
    onClick: () => any;
}

function DialogCancelButton({ onClick }: DialogCancelButtonProps) {
    return <MyCheckinButton onClick={onClick}>Cancel</MyCheckinButton>;
}

export { DialogCancelButton };
