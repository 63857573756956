import React, { useState } from "react";
import { useUpdateApi } from "../../../hooks/api/use-update-api";
import { User } from "../../../interfaces/user";
import { userAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";
import { useGetApi } from "../../../hooks/api/use-get-api";

export {};

export const useDismissCheckbox = () => {
    const [checked, setChecked] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return {
        checked,
        setChecked,
        handleChange,
    };
};

export const useCloseButton = (dismiss: boolean, userId: string, onClose: () => unknown) => {
    const updateUser = useUpdateApi<User>(userAPI, queryKeys.user.update, {});

    return {
        handleCloseButtonClick: () => {
            if (dismiss) {
                updateUser.update(userId, { isNewUserWelcomeDismissed: dismiss });
            }
            onClose();
        },
    };
};

export const useIsNewUserDialogDismissed = (userId: string) => {
    const getUser = useGetApi<User>(userAPI, queryKeys.user.get, userId);

    return getUser.data?.isNewUserWelcomeDismissed;
};
