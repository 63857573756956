import React from "react";
import PropTypes from "prop-types";
import MyCheckinTextField from "../my-checkin-text-field";

function GoalField(props) {
    const { value, label, disabled, ...others } = props;

    return (
        <MyCheckinTextField value={value} label={label} multiline rows={2} fullWidth disabled={disabled} {...others} />
    );
}

GoalField.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default GoalField;
