import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MyCheckinTextField from "./my-checkin-text-field";
import MyCheckinButton from "./my-checkin-button";
import { API } from "../util/api";
import { useMutation, useQueryClient } from "react-query";

function GoalAccomplishedDialog(props) {
    const { open, onClose, goal, ...others } = props;
    const [value, setValue] = useState("");
    const queryClient = useQueryClient();
    const goalMutation = useMutation(
        (updateData) => {
            return API.updateGoal(updateData);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["fetchGoals", goal.user, goal.year, goal.category]);
                setValue("");
            },
        }
    );
    const maxLength = 165;
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");

    const getHelperText = useCallback(() => {
        return maxLength && isFocused ? `${value.length} / ${maxLength} characters used` : "";
    }, [isFocused, maxLength, value]);

    useEffect(() => {
        setError(value.length > maxLength && maxLength !== 0);
        setHelperText(getHelperText);
    }, [value, maxLength, getHelperText]);

    const handleFocus = () => {
        setIsFocused(true);
        setHelperText(getHelperText);
    };

    const handleBlur = () => {
        setHelperText("");
        setIsFocused(false);
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setValue(value);
    };

    const handleCancel = () => {
        setValue("");
        onClose();
    };

    const handleConfirm = () => {
        goalMutation.mutate({
            id: goal.id,
            isAccomplished: true,
            accomplishment: value,
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth={true} {...others}>
            <DialogTitle id="goal-accomplished-dialog-title">Goal Accomplished</DialogTitle>
            <DialogContent>
                <MyCheckinTextField
                    multiline={true}
                    rows={4}
                    value={value}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoFocus
                    label="Please describe what was accomplished"
                    fullWidth
                    helperText={helperText}
                    error={error}
                />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleCancel} color="primary">
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton
                    onClick={handleConfirm}
                    type="submit"
                    color="primary"
                    variant="outlined"
                    disabled={error}
                >
                    Confirm
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default GoalAccomplishedDialog;
