import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { dismissText } from "../config";

interface DismissCheckboxProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
}

const DismissCheckbox = ({ checked, onChange }: DismissCheckboxProps) => {
    return (
        <FormControlLabel
            control={<Checkbox color="primary" checked={checked} onChange={onChange} />}
            label={dismissText}
            labelPlacement="end"
        />
    );
};

DismissCheckbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export { DismissCheckbox };
