import React from "react";
import MyCheckinButton from "../../my-checkin-button";

interface ReturnButtonProps {
    meetingId: string | undefined;
    onClick?: () => any;
    style?: React.CSSProperties;
}

function ReturnButton({ meetingId, style, onClick }: ReturnButtonProps) {
    const isDisabled = (): boolean => {
        const hasMeetingId = !!meetingId;
        return !hasMeetingId;
    };

    return (
        <MyCheckinButton variant="outlined" onClick={onClick} disabled={isDisabled()} style={style}>
            Return / Incomplete
        </MyCheckinButton>
    );
}

export { ReturnButton };
