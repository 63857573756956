import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

interface LoadingIndicatorProps {
    size?: number;
    style?: React.CSSProperties;
}

const LoadingIndicator = ({ size, style }: LoadingIndicatorProps) => {
    return <CircularProgress color="primary" size={size} style={style} />;
};

LoadingIndicator.propTypes = {
    size: PropTypes.number,
    style: PropTypes.object,
};

export { LoadingIndicator };
