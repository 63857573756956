import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px 32px",
        margin: "32px 0px",
    },
}));
