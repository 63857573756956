import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    notificationList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        width: 640,
        gap: 8,
    },
    notificationListItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 16px 0px 0px",
        border: "1px solid #e0e0e1",
        boxSizing: "border-box",
        borderRadius: 4,
        height: 64,
        width: "100%",
    },
    newNotificationIndicator: {
        width: 4,
        minWidth: 4,
        background: theme.palette.primary.main,
        position: "static",
        top: 0,
        left: 0,
        height: 64,
    },
    notificationContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 0,
        width: "100%",
        margin: "0px 16px",
        gap: 4,
    },
    notificationText: {
        width: 480,
        height: 48,
        maxHeight: 48,
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    viewButton: {
        width: 32,
    },
}));
