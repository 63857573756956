import { Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import { UseMutationResult, useQueryClient } from "react-query";
import { useCoachAppState } from "../../hooks/use-coach-app-state";
import DateFormat from "../date-format";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";
import TabPanel from "./tab-panel";
import { useUserMutation } from "../../hooks/mutations/use-user-mutation";
import { User, UserCoachNote } from "../../interfaces/user";

interface CoachNotesTabProps {
    value: number;
    index: number;
    user: User;
}

const CoachNotesTab = ({ value, index, user }: CoachNotesTabProps) => {
    const [note, setNote] = useState("");
    const coachAppState = useCoachAppState();
    const queryClient = useQueryClient();
    const userMutation: UseMutationResult<any, unknown, Partial<User>> = useUserMutation({
        onSuccess: () => {
            resetForm();
            refetchUser();
        },
    });

    const resetForm = () => setNote("");
    const refetchUser = () => queryClient.invalidateQueries("fetchUser");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNote(value);
    };

    const handleSave = () => {
        if (!note) {
            return;
        }

        const noteObject = createNoteObject();

        userMutation.mutate({
            id: user.id,
            $push: { notes: noteObject },
        });
    };

    const createNoteObject = () => {
        return {
            text: note,
            author: `${coachAppState.user.firstName} ${coachAppState.user.lastName}`,
        };
    };

    const getNotes = () => {
        if (!user || !user.notes) {
            return [];
        }
        const notes = user?.notes;

        if (!notes) {
            return [];
        }

        return notes;
    };

    const getSortedNotes = () => {
        const notes = getNotes();
        return sortNotesReverseChronological(notes);
    };

    const sortNotesReverseChronological = (notes: UserCoachNote[]) => {
        const sortedNotes = [...notes];
        return sortedNotes.sort(reverseChronologicalCompareFunction);
    };

    const reverseChronologicalCompareFunction = (firstCoachNote: UserCoachNote, secondCoachNote: UserCoachNote) => {
        if (!firstCoachNote.createdAt || !secondCoachNote.createdAt) {
            return 0;
        }

        const dateA = new Date(firstCoachNote.createdAt);
        const dateB = new Date(secondCoachNote.createdAt);
        return dateB.valueOf() - dateA.valueOf();
    };

    return (
        <TabPanel index={index} value={value}>
            <Grid container style={{ padding: 48 }} spacing={4}>
                <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                    Notes
                </Typography>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} justify="center" alignItems="center">
                        <MyCheckinTextField
                            multiline
                            fullWidth
                            rows={3}
                            label="Note"
                            name="text"
                            value={note}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item justify="flex-end" style={{ marginBottom: 24 }}>
                    <MyCheckinButton variant="outlined" onClick={handleSave}>
                        Save
                    </MyCheckinButton>
                </Grid>
                {getSortedNotes().map((note) => (
                    <Grid container style={{ marginBottom: 12 }} key={note.id}>
                        <Grid container item xs={12}>
                            <Typography style={{ fontWeight: 600, marginRight: 8 }}>{note.author}</Typography>
                            <Typography style={{ fontWeight: 600 }}>
                                <DateFormat date={note.createdAt && new Date(note.createdAt)} />
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} style={{ padding: 16 }}>
                            {note.text}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </TabPanel>
    );
};

CoachNotesTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default CoachNotesTab;
