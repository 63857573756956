import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { API } from "../util/api";
import MyCheckinButton from "./my-checkin-button";
import MyCheckinTextField from "./my-checkin-text-field";
import DateFormat from "./date-format";

function TrackedItemNotes(props) {
    const { notes, trackedItemId } = props;
    const [text, setText] = useState("");
    const queryClient = useQueryClient();
    const trackedItemMutation = useMutation(
        (trackedItem) => {
            return API.updateTrackedItem(trackedItem);
        },
        {
            onSuccess: (data) => queryClient.setQueryData(["getTrackedItem", trackedItemId], data),
        }
    );

    const handleChange = (event) => {
        const { value } = event.target;
        setText(value);
    };

    const handleSave = () => {
        notes.push({ text });
        trackedItemMutation.mutate({ id: trackedItemId, $push: { notes: { text } } });
        setText("");
    };

    return (
        <Box>
            <Grid
                container
                style={{ marginTop: 24, paddingLeft: 64, paddingRight: 64 }}
                justify="center"
                alignItems="center"
                spacing={4}
            >
                <Grid item md={9}>
                    <MyCheckinTextField
                        multiline
                        rows={3}
                        label="Notes"
                        name="notes"
                        value={text}
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item>
                    <MyCheckinButton color="primary" variant="contained" onClick={handleSave}>
                        Save
                    </MyCheckinButton>
                </Grid>
            </Grid>
            <Grid
                container
                style={{ marginTop: 24, paddingLeft: 64, paddingRight: 64 }}
                justify="center"
                alignItems="center"
                spacing={4}
            >
                {notes?.map((note) => (
                    <Grid
                        container
                        item
                        justify="space-between"
                        style={{ borderBottom: "1px solid #e0e0e1", marginBottom: 12 }}
                    >
                        <Grid item>{note.text}</Grid>
                        <Grid item>
                            <DateFormat date={note.createdAt} />{" "}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default TrackedItemNotes;
