import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./discussion.styles";
import { Box } from "@material-ui/core";

interface SectionProps {
    children: React.ReactNode;
}

const Section = ({ children }: SectionProps) => {
    const classes = useStyles();
    return <Box className={classes.sectionWrapper}>{children}</Box>;
};

Section.propTypes = {
    children: PropTypes.node,
};

export { Section };
