import React from "react";
import { Card, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/page-title";
import { useStyles } from "./okr-profile.styles";
import { Breadcrumbs } from "../../components/okr-profile/breadcrumbs";
import { ProfileSection } from "../../components/okr-profile/profile-section";
import { ObjectiveSection } from "../../components/okr-profile/objective-section";
import { KeyResultsSection } from "../../components/okr-profile/key-results-section";
import { useGetApi } from "../../hooks/api/use-get-api";
import { User } from "../../interfaces/user";
import { divisionAPI, goalAPI, objectiveAPI, userAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useFindApi } from "../../hooks/api/use-find-api";
import { Objective } from "../../interfaces/objective";
import { Goal } from "../../interfaces/goal";
import { Division } from "../../interfaces/division";

const OkrProfile = () => {
    const { id } = useParams<{ id: string }>();
    const classes = useStyles();
    const year = new Date().getFullYear();
    const { data: user } = useGetApi<User>(userAPI, queryKeys.user.get, id, { enabled: !!id });
    const findObjectiveResult = useFindApi<Objective>(
        objectiveAPI,
        queryKeys.objective.find,
        { user: id, isAccomplished: false, year },
        "objectives",
        { enabled: !!id }
    );
    const findKeyResultsResult = useFindApi<Goal>(
        goalAPI,
        queryKeys.goal.find,
        {
            user: id,
            isAccomplished: false,
            year,
            category: 0,
        },
        "goals",
        { enabled: !!id }
    );
    const getDivisionResult = useGetApi<Division>(divisionAPI, queryKeys.division.get, user?.division || "", {
        enabled: !!user && !!user.division,
    });

    const objective = findObjectiveResult.data.result[0];
    const keyResults = findKeyResultsResult.data.result;
    const division = getDivisionResult.data;

    const getFullName = ({ user }: { user?: User }) => {
        if (!user) {
            return "";
        }

        return user.firstName + " " + user.lastName;
    };

    return (
        <Container>
            <PageTitle>TeamShare</PageTitle>
            <Card className={classes.contentWrapper}>
                <Breadcrumbs
                    name={getFullName({ user })}
                    divisionName={division?.name || ""}
                    divisionId={division?.id || ""}
                />
                <ProfileSection userId={id} name={getFullName({ user })} jobTitle={user?.jobTitle || ""} />
                <ObjectiveSection
                    objective={
                        findObjectiveResult.status === "loading"
                            ? "Loading..."
                            : objective?.description || "No objective entered."
                    }
                />
                <KeyResultsSection keyResults={keyResults} isLoading={findKeyResultsResult.status === "loading"} />
            </Card>
        </Container>
    );
};

OkrProfile.propTypes = {};

export { OkrProfile };
