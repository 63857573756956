import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { GoalListItemProps } from "./goal-list-item";
import { Styleable } from "../../interfaces/styleable";
import { Classable } from "../../interfaces/classable";

interface GoalListProps extends Classable, Styleable {
    children: React.Component<GoalListItemProps>[];
}

const GoalList = ({ children, style, className }: GoalListProps) => {
    const getUserFriendlyIndex = (mapIndex: number) => {
        return mapIndex + 1;
    };

    return (
        <Grid container spacing={3} style={style} className={className}>
            {React.Children.map(children, (child, index) => (
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={1}>
                            <Typography align="center">{getUserFriendlyIndex(index)}.</Typography>
                        </Grid>
                        {child}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

GoalList.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
};

export { GoalList };
