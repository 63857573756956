import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMeetingLookupFunctions } from "../../hooks/use-meeting-lookup-functions";
import DateFormat from "../date-format";
import MyCheckinButton from "../my-checkin-button";
import SnapshotGrid from "../snapshot/snapshot-grid";
import YearSelector from "../year-selector";
import TabPanel from "./tab-panel";
import { menuLabels } from "../../config/menu-labels";
import { urls } from "../../config/urls";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const getYearsArray = (userCreatedDate) => {
    const years = [];
    const createdYear = userCreatedDate.getFullYear();
    for (let i = createdYear; i <= new Date().getFullYear(); i++) {
        years.push(i);
    }
    return years;
};

function MyCheckinTab(props) {
    const { meetings, value, index, user, reminders, takeaways } = props;
    const [year, setYear] = useState(new Date().getFullYear());
    const [yearsArray, setYearsArray] = useState([new Date().getFullYear()]);
    const [view, setView] = useState("master-plan");
    const history = useHistory();
    const { getLastMeetingDate, getNextMeetingDate, getLastMeeting } = useMeetingLookupFunctions();

    const handleYearSelect = (event) => {
        const { value } = event.target;
        setYear(value);
    };

    useEffect(() => {
        if (user) {
            setYearsArray(getYearsArray(new Date(user.createdAt)));
        }
    }, [user]);

    const isEmptyArray = (array) => {
        return array && array.length === 0;
    };

    const handleViewClick = () => {
        view === "master-plan"
            ? history.push(`${urls.coach.masterPlan.baseUrl}/${user.id}`)
            : view === "completed-items"
            ? history.push(`${urls.coach.completedItems.baseUrl}/${user.id}`)
            : view === "major-achievements"
            ? history.push(`${urls.coach.majorAchievements.baseUrl}/${user.id}`)
            : console.log("uh-oh");
    };

    const getTakeawaysFromMeeting = (meeting) => {
        if (!meeting) {
            throw new Error("Meeting does not exist");
        }
        return takeaways.filter((takeaway) => takeaway.month === meeting.month && takeaway.year === meeting.year);
    };

    const getLastMeetingTakeaways = () => {
        if (isEmptyArray(meetings)) {
            return [];
        }
        const lastMeeting = getLastMeeting(meetings);
        let lastMeetingTakeaways;
        try {
            lastMeetingTakeaways = getTakeawaysFromMeeting(lastMeeting);
        } catch (e) {
            lastMeetingTakeaways = [];
        }

        return lastMeetingTakeaways;
    };

    const getTakeawaysByYear = (year) => {
        return takeaways.filter((takeaway) => takeaway.year === year);
    };

    return (
        <TabPanel value={value} index={index}>
            <Grid container justify="flex-end">
                <Grid item>
                    <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleYearSelect} />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <SnapshotGrid meetings={meetings.filter((meeting) => meeting.year === year)} />
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
                <Grid item md={6} style={{ padding: 16, borderRight: "1px solid #929497" }}>
                    <Grid container style={{ marginBottom: 16 }}>
                        <Grid item xs={6}>
                            <Typography style={{ fontWeight: 600, marginLeft: 32 }}>Last Meeting: </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                <DateFormat date={getLastMeetingDate(meetings)} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography style={{ fontWeight: 600, marginLeft: 32 }}>Next Meeting: </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                <DateFormat date={getNextMeetingDate(meetings)} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} style={{ padding: 16 }}>
                    <Grid container alignItems="center" justify="center" spacing={3}>
                        <Grid item>
                            <Select value={view} onChange={(event) => setView(event.target.value)}>
                                <MenuItem value="master-plan">{menuLabels.masterPlan}</MenuItem>
                                <MenuItem value="completed-items">{menuLabels.completedItems}</MenuItem>
                                <MenuItem value="major-achievements">{menuLabels.majorAchievements}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <MyCheckinButton variant="outlined" onClick={handleViewClick}>
                                View
                            </MyCheckinButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ padding: 16 }}>
                <Grid container item>
                    <Typography variant="h6">Reminders</Typography>
                </Grid>
                {reminders.map((reminder, index) => (
                    <Grid container item style={{ padding: 16 }}>
                        <Typography>
                            {index + 1}. {reminder.description}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            <Grid container style={{ padding: 16 }}>
                <Grid container item>
                    <Typography variant="h6">Last Meeting Takeaways</Typography>
                </Grid>
                {getLastMeetingTakeaways().map((takeaway, index) => (
                    <Grid container item style={{ padding: 16 }}>
                        <Typography>
                            {index + 1}. {takeaway.description}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            <Grid container style={{ padding: 16 }}>
                <Grid container item>
                    <Typography variant="h6">YTD Takeaways</Typography>
                </Grid>
                {getTakeawaysByYear(year).map((takeaway, index) => (
                    <Grid container item style={{ padding: 16 }}>
                        <Typography>
                            {index + 1}. {takeaway.description} ({months[takeaway.month]})
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </TabPanel>
    );
}

MyCheckinTab.propTypes = {
    meetings: PropTypes.array.isRequired,
    reminders: PropTypes.array.isRequired,
    takeaways: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default MyCheckinTab;
