import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    titleRow: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(5),
        paddingBottom: 0,
        paddingLeft: theme.spacing(5),
        display: "flex",
        justifyContent: "space-between",
    },
    cardTitle: {
        fontSize: 24,
    },
}));
