import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ContainedIconButton from "../contained-icon-button";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { useMutation, useQueryClient } from "react-query";
import { TrackedItem } from "../../interfaces/tracked-item";
import { API } from "../../util/api";

interface TrackedItemCounterProps {
    id: string;
    dailyProgress: number[];
    progress: number;
    month: number;
    goal: number;
}

const useStyles = makeStyles((theme) => ({
    todayCount: {
        display: "inline",
        marginLeft: theme.spacing(7),
        marginRight: theme.spacing(7),
        fontSize: 24,
        fontWeight: 500,
    },
}));

function TrackedItemCounter({ id, dailyProgress, progress, month, goal }: TrackedItemCounterProps) {
    const classes = useStyles();
    const today = new Date();
    const queryClient = useQueryClient();
    const trackedItemMutation = useMutation<any, unknown, Partial<TrackedItem>>(
        (trackedItem) => {
            return API.updateTrackedItem(trackedItem);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("fetchTrackedItems");
                queryClient.invalidateQueries("getTrackedItem");
            },
            onError: () => queryClient.invalidateQueries(["getTrackedItem", id]),
        }
    );

    const isInBounds = (amount: number) => {
        const todayCount = dailyProgress[today.getDate()];
        const wouldBeNegative = amount < 0 && todayCount === 0;
        const goalAlreadyComplete = progress === goal && amount > 0;
        return !(wouldBeNegative || goalAlreadyComplete);
    };

    const initToday = (index: number) => {
        if (!dailyProgress[index]) {
            dailyProgress[index] = 0;
        }
    };

    const updateCount = (amount: number) => {
        const index = today.getDate();
        initToday(index);

        if (!isInBounds(amount)) {
            return;
        }

        dailyProgress[index] += amount;
        progress += amount;

        trackedItemMutation.mutate({ id, progress, dailyProgress });
    };

    return (
        <Grid container alignItems="center" justify="center" style={{ marginTop: 24 }}>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                <ContainedIconButton
                    color="primary"
                    onClick={() => updateCount(-1)}
                    disabled={today.getMonth() !== month}
                >
                    <RemoveIcon />
                </ContainedIconButton>
                <Typography className={classes.todayCount}>{dailyProgress[today.getDate()] || 0}</Typography>
                <ContainedIconButton
                    color="primary"
                    onClick={() => updateCount(1)}
                    disabled={today.getMonth() !== month}
                >
                    <AddIcon />
                </ContainedIconButton>
            </Grid>
        </Grid>
    );
}

export { TrackedItemCounter };
