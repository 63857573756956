import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import MyCheckinButton from "../../my-checkin-button";
import { useStyles } from "./unassigned-users-dialog.styles";
import { cancelButtonText, confirmButtonProps, confirmButtonText, dialogMaxWidth, dialogTitle } from "./config";
import { UnassignedUsersList } from "./unassigned-users-list";
import { UnassignedUsersListItem } from "./unassigned-users-list-item";
import { User } from "../../../interfaces/user";
import PropTypes from "prop-types";
import { useGetApi } from "../../../hooks/api/use-get-api";
import { Company } from "../../../interfaces/company/company";
import { companyAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface UnassignedUsersDialogProps {
    open: boolean;
    onClose: () => unknown;
    onSave: (users: Pick<User, "id" | "division">[]) => unknown;
    users: { id: string; name: string }[];
    divisions: { id: string; name: string }[];
}

const UnassignedUsersDialog = ({ open, onClose, onSave, users, divisions }: UnassignedUsersDialogProps) => {
    const classes = useStyles();
    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");
    const [userUpdates, setUserUpdates] = useState<{ id: string; division: string }[]>([]);

    const handleDivisionChange = ({ id, division }: { id: string; division: string }) => {
        const userUpdate = userUpdates.find((userUpdate) => userUpdate.id === id);

        if (userUpdate) {
            setUserUpdates((userUpdates) => [
                ...userUpdates.filter((userUpdate) => userUpdate.id !== id),
                { id, division },
            ]);
        } else {
            setUserUpdates((userUpdates) => [...userUpdates, { id, division }]);
        }
    };

    const handleCancel = () => {
        setUserUpdates([]);
        onClose();
    };

    const handleSave = () => {
        onSave(userUpdates);
    };

    return (
        <Dialog open={open} fullWidth maxWidth={dialogMaxWidth} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.subtitle}>
                    Select a {company?.organizationalUnitName?.singular || "division"} from the dropdown to assign.
                </Typography>
                <UnassignedUsersList>
                    {users.map((user) => (
                        <UnassignedUsersListItem
                            key={user.id}
                            id={user.id}
                            name={user.name}
                            divisions={divisions}
                            onDivisionChange={handleDivisionChange}
                        />
                    ))}
                </UnassignedUsersList>
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleCancel}>{cancelButtonText}</MyCheckinButton>
                <MyCheckinButton {...confirmButtonProps} onClick={handleSave}>
                    {confirmButtonText}
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

UnassignedUsersDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    users: PropTypes.array,
    divisions: PropTypes.array,
};

export { UnassignedUsersDialog };
