import { Breadcrumbs, Card, CircularProgress, Container, Grid, Link, Tab, Tabs, Typography } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import Avatar from "../../components/avatar";
import AccountTab from "../../components/coach-station/account-tab";
import CoachNotesTab from "../../components/coach-station/coach-notes-tab";
import CoachesTab from "../../components/coach-station/coaches-tab";
import EmployeeInfoTab from "../../components/coach-station/employee-info-tab";
import PermissionsTab from "../../components/coach-station/permissions-tab";
import MyCheckinTab from "../../components/coach-station/my-checkin-tab";
import { userAPI } from "../../util/api";
import { useStyles } from "./coach-station.styles";
import { User } from "../../interfaces/user";
import { useMeetingState } from "../../hooks/states/use-meeting-state";
import { useReminderState } from "../../hooks/states/use-reminder-state";
import { useTakeawayState } from "../../hooks/states/use-takeaway-state";

interface CoachStationParams {
    userId: string;
}

function CoachStation() {
    const classes = useStyles();
    const { userId }: CoachStationParams = useParams();
    const [user, setUser] = useState<User>();
    const { meetings } = useMeetingState({ user: userId });
    const { reminders } = useReminderState({ user: userId, isDismissed: false });
    const { takeaways } = useTakeawayState({ user: userId, isReminder: false });
    const [tabValue, setTabValue] = useState(0);

    const userQuery = useQuery(["fetchUser", userId], () => {
        return userAPI.find({ id: userId });
    });

    useEffect(() => {
        if (userQuery.isSuccess) {
            setUser(userQuery.data.users[0]);
        }
    }, [userQuery]);

    const getFullName = () => {
        if (!user) {
            return "Loading...";
        }
        let fullName = user?.firstName + " " + user?.lastName;
        if (user.isDeleted) {
            fullName += " (Deleted)";
        }
        return fullName;
    };

    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const renderTabPanels = () => {
        if (!user) {
            return <CircularProgress style={{ margin: "auto", marginTop: 32 }} />;
        }
        return (
            <>
                <MyCheckinTab
                    meetings={meetings}
                    takeaways={takeaways}
                    reminders={reminders}
                    value={tabValue}
                    index={0}
                    user={user}
                />
                <EmployeeInfoTab value={tabValue} index={1} user={user} />
                <AccountTab value={tabValue} index={2} user={user} />
                <CoachesTab value={tabValue} index={3} user={user} />
                <PermissionsTab value={tabValue} index={4} user={user} />
                <CoachNotesTab value={tabValue} index={5} user={user} />
            </>
        );
    };

    return (
        <Container>
            <Card className={classes.container}>
                <Breadcrumbs separator={">"}>
                    <Link component={RouterLink} to="/coach/employee-panel" className={classes.breadcrumbLink}>
                        Employee Panel
                    </Link>
                    <Typography>Coach Station</Typography>
                </Breadcrumbs>
                <Grid container justify="center" alignItems="center" spacing={3}>
                    <Grid item>
                        <Avatar diameter={64} id={userId} borderWidth={2} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">{getFullName()}</Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item className={classes.tabRoot} md={9}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            className={classes.tabs}
                            indicatorColor="primary"
                        >
                            <Tab label="MyCheck-In" id="vertical-tab-0" />
                            <Tab label="Employee Info" id="vertical-tab-1" />
                            <Tab label="Account" id="vertical-tab-2" />
                            <Tab label="Coaches" id="vertical-tab-3" />
                            <Tab label="Permissions" id="vertical-tab-4" />
                            <Tab label="Coach Notes" id="vertical-tab-5" />
                        </Tabs>
                        {renderTabPanels()}
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export { CoachStation };
