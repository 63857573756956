import React from "react";
import PropTypes from "prop-types";
import { Text, StyleSheet } from "@react-pdf/renderer";

interface PdfPageSubtitleProps {
    children: React.ReactNode;
    style?: any;
}

const styles = StyleSheet.create({
    subtitle: {
        textAlign: "center",
        fontSize: 14,
        marginBottom: 16,
    },
});

const PdfPageSubtitle = ({ children, style }: PdfPageSubtitleProps) => {
    return <Text style={{ ...styles.subtitle, ...style }}>{children}</Text>;
};

PdfPageSubtitle.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PdfPageSubtitle };
