import React from "react";
import PropTypes from "prop-types";
import { Text, View } from "@react-pdf/renderer";
import { ThumbsUp } from "./thumbs-up";

interface AchievementsProps {
    achievements: { description: string; isObjective: boolean }[];
}

const Achievements = ({ achievements }: AchievementsProps) => {
    const achievementPairs: { description: string; isObjective: boolean }[][] = [];

    for (let i = 0; i * 2 < achievements.length; i++) {
        const pair = [];
        pair.push(achievements[i * 2]);
        if (achievements[i * 2 + 1]) {
            pair.push(achievements[i * 2 + 1]);
        }
        achievementPairs.push(pair);
    }

    return (
        <>
            {achievementPairs.map((achievements) => (
                <View
                    style={{
                        width: 725,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginLeft: 64,
                    }}
                >
                    {achievements.map((achievement) => (
                        <Text
                            style={{
                                textAlign: "center",
                                width: "48%",
                                fontSize: 10,
                                marginBottom: 8,
                                marginLeft: 2,
                                marginRight: 2,
                            }}
                        >
                            {achievement.description} {achievement.isObjective && <ThumbsUp />}
                        </Text>
                    ))}
                </View>
            ))}
        </>
    );
};

Achievements.propTypes = {
    achievements: PropTypes.array,
};

export { Achievements };
