import { Box, CircularProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useGoals } from "../../hooks/queries/use-goals";
import { useCompanyState } from "../../hooks/use-company-state";
import CategoryTitle from "./category-title";
import PointsHeader from "../master-plan/points-header";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import { GoalList } from "../goal-list/goal-list";
import { GoalListItem } from "../goal-list/goal-list-item";
import { useFillGoalArray } from "../../hooks/use-fill-goal-array";
import { useMonthlyCheckInState } from "../../hooks/use-monthly-check-in-state";

function GoalSection({ category, style }) {
    const { year, month, user, meeting } = useMonthlyCheckInState();
    const userID = user?.id || "";
    const { company } = useCompanyState();

    const goalsParams = meeting?.submission.goals.length
        ? { id: meeting.submission.goals, category, user: userID }
        : { user: userID, category, year, isAccomplished: false };

    const { status, data } = useGoals(goalsParams);
    const masterPlanTemplate = useMasterPlanTemplate();
    const goals = useFillGoalArray({ array: data?.goals, object: { id: null, category, year, description: "" } });

    const showGoalPoints = () => {
        return category === 0 && company?.settings?.isGoalPointsEnabled;
    };

    return (
        <Box style={style}>
            <CategoryTitle
                prepend={category > 1  && masterPlanTemplate[category].name.toUpperCase()}
                title={masterPlanTemplate[category].checkIn.goal.description}
                subheading={masterPlanTemplate[category].checkIn.goal.subheading}
            />
            <Grid container spacing={5} style={{ paddingLeft: 12, paddingRight: 12 }}>
                <Grid item xs={1} />
                <Grid item xs={10} />
                <Grid item xs={1}>
                    <PointsHeader show={showGoalPoints()} />
                </Grid>
            </Grid>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <GoalList style={{ marginTop: 0, marginBottom: 0 }}>
                    {goals.map((goal, index) => (
                        <GoalListItem
                            points={goal.points}
                            showPoints={showGoalPoints()}
                            key={`goal-${category}-${month}-${year}-${index}`}
                        >
                            {goal.description}
                        </GoalListItem>
                    ))}
                </GoalList>
            )}
        </Box>
    );
}

GoalSection.propTypes = {
    category: PropTypes.number.isRequired,
    style: PropTypes.object,
};

export default GoalSection;
