import { HttpClient } from "../interfaces/http-client";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import Qs from "qs";

export class AxiosClient implements HttpClient {
    instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            paramsSerializer: (params) => {
                return Qs.stringify(params, {
                    arrayFormat: "brackets",
                    encode: false,
                });
            },
        });
    }

    async delete(url: string): Promise<any> {
        return this.getResponseData(this.instance.delete(url));
    }

    async get(url: string, params: any): Promise<any> {
        return this.getResponseData(this.instance.get(url, { params }));
    }

    async patch(url: string, data: any): Promise<any> {
        return this.getResponseData(this.instance.patch(url, data));
    }

    async post(url: string, data: any): Promise<any> {
        return this.getResponseData(this.instance.post(url, data));
    }

    private async getResponseData(request: Promise<AxiosResponse<any>>) {
        const response = await request;
        return response.data;
    }
}
