import { useEffect } from "react";

export const useQueryEffect = (query, setStateFn, entry) => {
    return useEffect(() => {
        if (query.status === "success") {
            setStateFn(entry ? query.data[entry] || [] : query.data || {});
        } else {
            setStateFn([]);
        }
    }, [entry, query, setStateFn]);
};
