import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import MyCheckinButton from "../components/my-checkin-button";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: theme.spacing(6),
        width: "100%",
        maxWidth: theme.spacing(14),
        margin: "auto",
    },
    heading: {
        marginBottom: theme.spacing(4),
    },
}));

function FourOhFour() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    return (
        <Card className={classes.contentContainer}>
            <Grid container justify="center" className={classes.heading}>
                <Grid item>
                    <Typography variant="h2" align="center">
                        404
                    </Typography>
                    <Typography variant="h5" align="center" className={classes.heading}>
                        Page Not Found
                    </Typography>
                    <Typography variant="body2" align="center">
                        {location.pathname}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <MyCheckinButton variant="outlined" onClick={history.goBack}>
                    Click Here To Go Back
                </MyCheckinButton>
            </Grid>
        </Card>
    );
}

export default FourOhFour;
