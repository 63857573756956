const defaultName = "Additional Resources";
const defaultSubheading = "Additional resources needed to achieve my Goal";

export const additionalResourcesDescriptions = {
    masterPlan: {
        name: defaultSubheading, // not a typo - requested by client
        subheading: defaultSubheading,
    },
    checkIn: {
        name: defaultName,
        subheading: defaultSubheading,
    },
};
