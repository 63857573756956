import React from "react";
import MyCheckinButton from "../../my-checkin-button";
import { UseMutationResult, useQueryClient } from "react-query";
import { useMeetingMutation } from "../../../hooks/mutations/use-meeting-mutation";
import { queryKeys } from "../../../hooks/api/config/query-keys";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";

interface NoMeetingButtonProps {
    meetingId: string | undefined;
    style?: React.CSSProperties;
}

function NoMeetingButton({ meetingId, ...others }: NoMeetingButtonProps) {
    const { meeting } = useMonthlyCheckInState();
    const queryClient = useQueryClient();
    const meetingMutation: UseMutationResult<any, unknown, any, unknown> = useMeetingMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchMeetings");
            queryClient.invalidateQueries(queryKeys.meeting.get);
            queryClient.invalidateQueries(queryKeys.meeting.find);
        },
    });

    const handleClick = () => {
        if (meetingId === undefined) {
            return;
        }
        const meetingData = {
            id: meetingId,
            status: "no meeting",
            isConfirmedBy: {
                employee: false,
                coach: false,
            },
        };

        meetingMutation.mutate(meetingData);
    };

    const isDisabled = () => {
        return meetingId === undefined || meeting?.status === "no meeting";
    };

    return (
        <MyCheckinButton variant="outlined" onClick={handleClick} disabled={isDisabled()} {...others}>
            No Meeting Necessary
        </MyCheckinButton>
    );
}

export { NoMeetingButton };
