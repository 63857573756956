import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.dark,
        display: "block",
        textAlign: "center",
        fontWeight: 700,
        textDecoration: "none",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));
