import { Typography } from "@material-ui/core";
import React, { CSSProperties } from "react";

const variant = "subtitle1";

const style: CSSProperties = {
    marginTop: 32,
    marginBottom: -32,
    textTransform: "uppercase",
    fontSize: 18,
    fontWeight: 700,
};

const text = '"The Plan" Recap';

export const ReviewSectionTitle = () => (
    <Typography variant={variant} style={style}>
        {text}
    </Typography>
);
