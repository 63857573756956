interface UseSortArrayParams<T> {
    array: T[];
    field: keyof T;
    order: "asc" | "desc";
}

export const useSortArray = <T>({ array, field, order }: UseSortArrayParams<T>): T[] => {
    const arrCopy = [...array];

    arrCopy.sort((a, b) => {
        const fieldA = a[field];
        const fieldB = b[field];
        const orderScalar = order === "asc" ? 1 : -1;

        if (!fieldA || !fieldB) {
            return 0;
        }

        if (fieldA === fieldB) {
            return 0;
        } else if (fieldA < fieldB) {
            return orderScalar * -1;
        } else {
            return orderScalar;
        }
    });

    return arrCopy;
};
