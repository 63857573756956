import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    actionsCard: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    certificate: {
        position: "relative",
        width: 1256,
        height: 768,
        marginBottom: 16,
    },
    companyName: {
        position: "absolute",
        bottom: 32,
        right: 32,
        fontSize: 24,
        fontWeight: 700,
        maxWidth: 640,
    },
    title: {
        position: "absolute",
        top: 64,
        fontSize: 48,
        fontWeight: 600,
        textTransform: "uppercase",
        width: "100%",
        textAlign: "center",
    },
    subtitle1: {
        position: "absolute",
        top: 128,
        fontSize: 24,
        width: "100%",
        textAlign: "center",
        textTransform: "uppercase",
    },
    subtitle2: {
        position: "absolute",
        top: 168,
        width: "100%",
        textAlign: "center",
        fontSize: 14,
        fontWeight: 500,
        textTransform: "uppercase",
    },
    name: {
        position: "absolute",
        top: 192,
        fontFamily: "Monsieur La Doulaise",
        fontSize: 64,
        letterSpacing: 2,
        width: "100%",
        textAlign: "center",
    },
    description: {
        position: "absolute",
        top: 290,
        fontWeight: 500,
        fontSize: 18,
        textTransform: "uppercase",
        width: "100%",
        textAlign: "center",
    },
    divider: {
        position: "absolute",
        width: 960,
        height: 2,
        top: 320,
        left: 148,
        background: "linear-gradient(90deg, #ffffff 0%, #000000 50%, #ffffff 100%)",
    },
    achievementContainer: {
        position: "absolute",
        top: 370,
        left: 96,
        right: 96,
        height: 281,
        textAlign: "center",
    },
}));
