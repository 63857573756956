import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import CategoryTitle from "../monthly-check-in/category-title";
import AutoSaveTextField from "../autosave-text-field";
import ContainedIconButton from "../contained-icon-button";
import CheckIcon from "@material-ui/icons/Check";
import { useQuery, useQueryClient } from "react-query";
import { AdditionalResources } from "../../interfaces/additional-resources";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { additionalResourcesAPI } from "../../util/api";
import { useCreateApi } from "../../hooks/api/use-create-api";
import { useUpdateApi } from "../../hooks/api/use-update-api";
import { additionalResourcesDescriptions } from "../../config/additional-resources-descriptions";

interface AdditionalResourcesSectionProps {
    userId: string;
    disabled: boolean;
}

/**
 * Master Plan Additional Resource section.
 *
 * Contains an autosaving text field where the Additional Resources are to be filled in, and a button to mark
 * the Additional Resources as "complete" (or "obtained", etc.).
 *
 * @param {Object} params - Component parameters
 * @param {string} params.userId - the user this `AdditionaResources` belongs to.
 * @param {boolean} params.disabled - if true, disables the text field and complete button.
 * @constructor
 */
const AdditionalResourcesSection = ({ userId, disabled }: AdditionalResourcesSectionProps) => {
    const queryClient = useQueryClient();
    const additionalResourcesFindResult = useQuery<{ additionalResources: AdditionalResources[], count: number}>(queryKeys.additionalResources.find, async () => await additionalResourcesAPI.find({ isCompleted: false, user: userId}));

    const mutationQueryOptions = {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.additionalResources.find);
            queryClient.invalidateQueries(queryKeys.additionalResources.get);
        }
    }

    const createAdditionalResources = useCreateApi<AdditionalResources>(additionalResourcesAPI, queryKeys.additionalResources.create, mutationQueryOptions);
    const updateAdditionalResources = useUpdateApi<AdditionalResources>(additionalResourcesAPI, queryKeys.additionalResources.update, mutationQueryOptions);
    const { data, status } = additionalResourcesFindResult;
    const [additionalResources, setAdditionalResources] = useState<AdditionalResources | { id: "", description: "" }>(additionalResourcesFindResult?.data?.additionalResources[0] || { id: "", description: "" });

    useEffect(() => {
        const additionalResources = data?.additionalResources[0];
        if (status === "success") {
            setAdditionalResources(additionalResources || { id: "", description: "" });
        }
    }, [data, status])

    /**
     * Returns true if the complete button should be disabled.
     */
    const isCompleteButtonDisabled = (): boolean => {
        return !additionalResources?.id;
    };

    /**
     * Updates the `AdditionalResources` with the parameters given.
     *
     * If `additionalResources` contains an ID, will attempt to update the existing `additionalResources`.
     * If `additionalResources` does not contain an ID, a new `AdditionalResources` will be created.
     * @param additionalResources
     */
    const handleAdditionalResourcesUpdate = (additionalResources: Partial<AdditionalResources>): void => {
        if (!additionalResources.id) {
            createAdditionalResources.create(additionalResources);
        } else {
            updateAdditionalResources.update(additionalResources.id, additionalResources);
        }
    }

    /**
     * Calls the API to set `additionalResources.isCompleted` to true.
     */
    const setAdditionalResourcesComplete = () => {
        if (additionalResources?.id) {
            updateAdditionalResources.update(additionalResources.id, { ...additionalResources, isCompleted: true });
        }
    }

    /**
     * Hacking around to make `AutoSaveTextField` work with the new APIs. Should
     * be removed later when `AutoSaveTextField` is updated.
     */
    const mutation = {
        mutate: handleAdditionalResourcesUpdate
    }

    return (
        <Box style={{ marginBottom: 24, marginTop: 24 }}>
            <CategoryTitle
                title={additionalResourcesDescriptions.masterPlan.name}
                style={{ marginBottom: 24 }}
            />
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container style={{ flexGrow: 1 }} spacing={5} alignItems="center">
                    <Grid item xs={11}>
                        <AutoSaveTextField
                            textFieldName="description"
                            object={additionalResources}
                            maxLength={165}
                            mutation={mutation}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ContainedIconButton
                            onClick={setAdditionalResourcesComplete}
                            disabled={disabled || isCompleteButtonDisabled()}
                        >
                            <CheckIcon />
                        </ContainedIconButton>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

AdditionalResourcesSection.propTypes = {
    userId: PropTypes.string,
    category: PropTypes.number,
    year: PropTypes.number,
    disabled: PropTypes.bool,
};

export { AdditionalResourcesSection };
