import { Card, Container, FormControlLabel, Grid, makeStyles, Switch } from "@material-ui/core";
import React, { useState } from "react";
import AddEmployeeButton from "../components/employees/add-employee-button";
import EmployeeTable from "../components/employees/employee-table";
import SearchBar from "../components/employees/search-bar";
import PageTitle from "../components/page-title";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: "32px 24px",
    },
}));

function Employees(props) {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState("");
    const [showDeleted, setShowDeleted] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
    };

    const handleShowDeletedToggle = (event) => {
        setShowDeleted(event.target.checked);
    };

    return (
        <Container>
            <PageTitle>Employees</PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item md={6}>
                        <SearchBar value={searchValue} onChange={handleChange} />
                    </Grid>
                    <Grid item md={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showDeleted}
                                    onChange={handleShowDeletedToggle}
                                    name="showDeleted"
                                    color="primary"
                                />
                            }
                            label="Show Deleted Users"
                        />
                    </Grid>
                    <Grid item>
                        {" "}
                        <AddEmployeeButton />
                    </Grid>
                </Grid>
                <EmployeeTable searchFilter={searchValue} showDeleted={showDeleted} />
            </Card>
        </Container>
    );
}

export default Employees;
