import { useState } from "react";
import { QueryObserverResult } from "react-query";
import { useQueryEffect } from "../use-query-effect";

export const useQueryState = (query: QueryObserverResult<any, unknown>, entry = "") => {
    const [state, setState] = useState<any>([]);
    useQueryEffect(query, setState, entry);

    return state;
};
