import React from "react";
import TakeawaySection from "../takeaway-section";
import Section from "../section";
import { useIsTakeawaySectionDisabled } from "../monthly-check-in-tab-panel/hooks";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";
import { useViewingAs } from "../../../hooks/use-viewing-as";

const TakeawaysSection = () => {
    const { meeting, viewingAs, isMeetingStarted = false } = useMonthlyCheckInState();
    const { isViewingAsCoach } = useViewingAs({ viewingAs });

    const isTakeawaySectionDisabled = useIsTakeawaySectionDisabled({
        meetingStatus: meeting?.status || "",
        viewingAsCoach: isViewingAsCoach,
        isMeetingStarted,
    });

    return (
        <Section withBorder borderColor={isTakeawaySectionDisabled() ? "neutral" : "primary"}>
            <TakeawaySection disabled={isTakeawaySectionDisabled()} category={0} />
        </Section>
    );
};

export default TakeawaysSection;
