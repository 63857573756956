import React from "react";
import { MenuItem, Select } from "@material-ui/core";

function MonthSelector(props) {
    const { month, monthsArr, handleSelect, ...others } = props;

    const renderMonthOptions = () => {
        return (
            monthsArr &&
            monthsArr.map((monthOption) => (
                <MenuItem value={monthOption.index} key={monthOption.index}>
                    {monthOption.name}
                </MenuItem>
            ))
        );
    };

    return (
        <Select
            style={{ marginRight: 8, fontSize: 18, fontWeight: 600 }}
            value={month ?? new Date().getMonth()}
            onChange={handleSelect}
            placeholder="Select a Month"
            {...others}
        >
            {renderMonthOptions()}
        </Select>
    );
}

export default MonthSelector;
