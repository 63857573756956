import React, { CSSProperties } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./notifications.styles";
import { Box, IconButton } from "@material-ui/core";
import Avatar from "../avatar";
import ContainedIconButton from "../contained-icon-button";
import { ChevronRight, Delete } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";

interface NotificationContentProps {
    userId: string;
    children: string | React.ReactNode;
    link: string;
    onLinkClick: () => unknown;
    onDeleteClick: () => unknown;
}

const NotificationContent = ({ userId, children, link, onLinkClick, onDeleteClick }: NotificationContentProps) => {
    const classes = useStyles();
    const buttonStyle: CSSProperties = {
        width: 32,
        height: 32,
    };

    const handleLinkClick = () => {
        onLinkClick();
    };

    const handleDeleteClick = () => {
        onDeleteClick();
    };

    return (
        <Box className={classes.notificationContent}>
            <Avatar id={userId} borderWidth={1} diameter={48} />
            {children}
            <IconButton style={buttonStyle} onClick={handleDeleteClick}>
                <Delete />
            </IconButton>
            <ContainedIconButton style={buttonStyle} component={RouterLink} to={link} onClick={handleLinkClick}>
                <ChevronRight />
            </ContainedIconButton>
        </Box>
    );
};

NotificationContent.propTypes = {
    userId: PropTypes.string,
    children: PropTypes.node,
    link: PropTypes.string,
    onLinkClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

export { NotificationContent };
