import React from "react";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { urls } from "../../config/urls";
import { menuLabels } from "../../config/menu-labels";
import { useStyles } from "./okr-profile.styles";

interface BreadcrumbsProps {
    name: string;
    divisionName: string;
    divisionId: string;
}

const Breadcrumbs = ({ name, divisionName, divisionId }: BreadcrumbsProps) => {
    const classes = useStyles();
    const { pathname } = useLocation();

    const getLink = () => {
        return pathname.includes("coach") ? "/coach/divisions/" + divisionId : urls.employee.teamshare.baseUrl;
    };

    const getLabel = () => {
        return pathname.includes("coach") ? divisionName : menuLabels.teamshare;
    };

    return (
        <MuiBreadcrumbs separator=">" className={classes.breadcrumbs}>
            <Link component={RouterLink} to={getLink()} className={classes.breadcrumbLink}>
                {getLabel()}
            </Link>
            <Typography className={classes.breadcrumbText}>{name}</Typography>
        </MuiBreadcrumbs>
    );
};

Breadcrumbs.propTypes = {
    name: PropTypes.string,
};

export { Breadcrumbs };
