import { useUpdateApi } from "./api/use-update-api";
import { Notification } from "../interfaces/notification";
import { notificationAPI } from "../util/api";
import { queryKeys } from "./api/config/query-keys";
import { useQueryClient } from "react-query";
import React from "react";
import { useMeetingUpdateType } from "./use-meeting-update-type";
import { useAuthState } from "./use-auth-state";
import { useDeleteApi } from "./api/use-delete-api";
import axios from "axios";

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const useNotificationFns = () => {
    const queryClient = useQueryClient();
    const meetingUpdateType = useMeetingUpdateType();
    const { user: currentUser } = useAuthState();
    const updateNotification = useUpdateApi<Notification>(notificationAPI, queryKeys.notification.update, {
        onSuccess: () => queryClient.invalidateQueries(queryKeys.notification.find),
    });
    const deleteNotification = useDeleteApi<Notification>(notificationAPI, queryKeys.notification.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.notification.find);
            queryClient.invalidateQueries(queryKeys.notification.get);
        },
    });

    const getNotificationText = (notification: Notification) => {
        switch (notification.category) {
            case "new-comment":
                return (
                    <>
                        New comment from{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {/*//@ts-ignore*/}
                            {notification.data.author.firstName} {notification.data.author.lastName}
                        </span>
                        : {/*//@ts-ignore*/}
                        <span style={{ fontStyle: "italic" }}>"{notification.data.comment}"</span>
                    </>
                );
            case "meeting-update":
                return (
                    <>
                        <span style={{ fontWeight: "bold" }}>
                            {/*//@ts-ignore*/}
                            {notification.data.employeeName}
                            &nbsp;{/*//@ts-ignore*/}
                            {monthNames[notification.data.meeting.month]} {notification.data.meeting.year}
                            &nbsp;Check In&nbsp;
                        </span>{" "}
                        - {/*//@ts-ignore*/}
                        {meetingUpdateType.getUserFriendlyString(notification.data.updateType)}
                    </>
                );
            default:
        }
    };

    const getLink = (notification: Notification) => {
        switch (notification.category) {
            case "new-comment":
                // @ts-ignore
                return `/employee/teamshare/${notification.user}/discussion/${notification.data.goalId}`;
            case "meeting-update":
                // @ts-ignore
                if (notification.data.meeting.user === currentUser?.id) {
                    // @ts-ignore
                    return `/employee/monthly-check-in/${notification.data.meeting.year}/${notification.data.meeting.month}`;
                } else {
                    // @ts-ignore
                    return `/coach/coach-station/${notification.data.meeting.user}/monthly-check-in/${notification.data.meeting.year}/${notification.data.meeting.month}`;
                }
            default:
                return "/employee/dashboard";
        }
    };

    const handleNotificationClick = (notification: Pick<Notification, "id" | "isUnread">) => {
        const { id, isUnread } = notification;

        if (isUnread) {
            updateNotification.update(id, { isUnread: false });
        }
    };

    const handleDeleteClick = (id: string) => {
        deleteNotification.deleteFn(id);
    };

    const handleDeleteAllClick = async (userId: string | undefined) => {
        if (!userId) {
            return;
        }
        await axios.delete(`/api/notification/user/${userId}/all`);
        await queryClient.invalidateQueries(queryKeys.notification.find);
    };

    return {
        getNotificationText,
        getLink,
        handleNotificationClick,
        handleDeleteClick,
        handleDeleteAllClick,
    };
};
