import { Box, Container, makeStyles, MenuItem } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import usePermissions from "../hooks/use-permissions";
import NavLink from "./nav-link";
import NavMenu from "./nav-menu";
import { urls } from "../config/urls";
import { menuLabels } from "../config/menu-labels";
import { useCompanySettings } from "../hooks/use-company-settings";
import { sub } from "date-fns";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    navLinkContainer: {
        height: theme.spacing(7),
        display: "flex",
        flexDirection: "row",
    },
}));

function EmployeeNav() {
    const classes = useStyles();
    const { isCoach } = usePermissions();
    const { isTeamshareEnabled } = useCompanySettings();

    const today = new Date();
    const lastMonth = sub(today, { months: 1 });

    const {
        dashboard,
        masterPlan,
        quickPlan,
        completedItems,
        majorAchievements,
        editEntry,
        viewAllEntries,
        monthlyTracker,
        teamshare,
        todo,
        monthlyCheckIn,
    } = urls.employee;

    return (
        <Container className={classes.wrapper}>
            <Box className={classes.navLinkContainer}>
                <NavLink to={dashboard.baseUrl} text={menuLabels.dashboard} />
                <NavMenu text={menuLabels.thePlanMenu} to={masterPlan.baseUrl}>
                    <MenuItem component={RouterLink} to={quickPlan.baseUrl}>
                        {menuLabels.quickPlan}
                    </MenuItem>
                    <MenuItem
                        component={RouterLink}
                        to={`${monthlyCheckIn.baseUrl}/${lastMonth.getFullYear()}/${lastMonth.getMonth()}`}
                    >
                        {menuLabels.monthlyCheckIn}
                    </MenuItem>
                    <MenuItem component={RouterLink} to={completedItems.baseUrl}>
                        {menuLabels.completedItems}
                    </MenuItem>
                    <MenuItem component={RouterLink} to={majorAchievements.baseUrl}>
                        {menuLabels.majorAchievements}
                    </MenuItem>
                    <MenuItem component={RouterLink} to={masterPlan.baseUrl}>
                        {menuLabels.masterPlan}
                    </MenuItem>
                </NavMenu>
                {/*<NavLink*/}
                {/*    to={`${monthlyCheckIn.baseUrl}/${lastMonth.getFullYear()}/${lastMonth.getMonth()}`}*/}
                {/*    text={menuLabels.monthlyCheckIn}*/}
                {/*/>*/}
                <NavMenu text={menuLabels.jots}>
                    <MenuItem component={RouterLink} to={editEntry.baseUrl}>
                        {menuLabels.newJot}
                    </MenuItem>
                    <MenuItem component={RouterLink} to={viewAllEntries.baseUrl}>
                        {menuLabels.jotsLog}
                    </MenuItem>
                </NavMenu>
                <NavLink to={todo.baseUrl} text={menuLabels.todo} />
                <NavLink to={monthlyTracker.baseUrl} text={menuLabels.monthlyTracker} />
                {isTeamshareEnabled && <NavLink to={teamshare.baseUrl} text={menuLabels.teamshare} />}
                <NavLink to={urls.learnVault.homePage.baseUrl} text={menuLabels.learnVault} external />
            </Box>
            <Box className={classes.navLinkContainer}>
                {isCoach && <NavLink to={urls.coach.employeePanel.baseUrl} text={menuLabels.coachToggle} />}
            </Box>
        </Container>
    );
}

export default EmployeeNav;
