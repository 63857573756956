import { useSubmissionNoteMutation } from "../../../hooks/mutations/use-submission-note-mutation";
import { SubmissionNote } from "../../../interfaces/submission-note";

interface UseSaveSubmissionNoteProps {
    authorId: string | undefined;
    meetingId: string | undefined;
    note: string;
}

export const useSaveSubmissionNote = () => {
    const submissionNoteMutation = useSubmissionNoteMutation();

    return async ({ authorId, meetingId, note }: UseSaveSubmissionNoteProps) => {
        if (!(authorId && meetingId)) {
            throw new Error(
                "Could not save note. Required information was missing, please check your network connection and try again."
            );
        }
        const newSubmissionNote: SubmissionNote = {
            authorId,
            meetingId,
            note,
        };
        await submissionNoteMutation.mutate(newSubmissionNote);
    };
};
