import React from "react";
import PropTypes from "prop-types";
import MyCheckinButton from "../my-checkin-button";
import { Badge } from "@material-ui/core";

interface ShowOpenRemindersButtonProps {
    onClick: () => any;
    style?: React.CSSProperties;
    badgeContent: React.ReactNode;
}

const ShowOpenRemindersButton = ({ onClick, style, badgeContent }: ShowOpenRemindersButtonProps) => {
    return (
        <Badge badgeContent={badgeContent} style={style} overlap="circle" color="secondary">
            <MyCheckinButton onClick={onClick}>View All</MyCheckinButton>
        </Badge>
    );
};

ShowOpenRemindersButton.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object,
    badgeContent: PropTypes.node,
};

export { ShowOpenRemindersButton };
