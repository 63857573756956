import React from "react";
import PropTypes from "prop-types";
import { PdfDownloadButton } from "../pdf/pdf-download-button";
import { QueryClientProvider, useQueryClient } from "react-query";
import { MajorAchievementsPdf } from "../pdf/major-achievements/major-achievements-pdf";

interface DownloadMajorAchievementsPdfButtonProps {
    userId: string;
    year: number;
    className?: string;
}

const DownloadMajorAchievementsPdfButton = ({ userId, year, className }: DownloadMajorAchievementsPdfButtonProps) => {
    const queryClient = useQueryClient();
    return (
        <PdfDownloadButton fileName="major_achievements.pdf" className={className}>
            <QueryClientProvider client={queryClient}>
                <MajorAchievementsPdf userId={userId} year={year} />
            </QueryClientProvider>
        </PdfDownloadButton>
    );
};

DownloadMajorAchievementsPdfButton.propTypes = {
    userId: PropTypes.string,
    year: PropTypes.number,
    className: PropTypes.string,
};

export { DownloadMajorAchievementsPdfButton };
