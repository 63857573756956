import { Container, Grid, makeStyles } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import EmployeePanelInfoCards from "../../components/employee-panel/employee-panel-info-cards";
import SearchCard from "../../components/employee-panel/search-card";
import TeamMemberInfoCard from "../../components/employee-panel/team-member-info-card";
import { useCoachAppState } from "../../hooks/use-coach-app-state";
import { useLastMonth } from "../../hooks/use-last-month";
import { useUsersState } from "../../hooks/states/use-users-state";
import { User } from "../../interfaces/user";
import { Meeting } from "../../interfaces/meeting";
import { useReminderState } from "../../hooks/states/use-reminder-state";
import { Takeaway } from "../../interfaces/takeaway";
import { useDialog } from "../../hooks/use-dialog";
import { OpenRemindersDialog } from "../../components/employee-dashboard/open-reminders-dialog";
import { RemindersAccordion } from "../../components/reminders-accordian/reminders-accordion";
import { CheckInStatus, DivisionFilter } from "../../components/employee-panel/config";
import { useSortArray } from "../../hooks/use-sort-array";

interface EmployeePanelProps {
    userId: string;
}

interface MeetingSet {
    count: number;
    user: string;
    meetings: Meeting[];
}

const useStyles = makeStyles((theme) => ({
    spacing: {
        marginBottom: theme.spacing(3),
    },
}));

function EmployeePanel({ userId }: EmployeePanelProps) {
    const classes = useStyles();
    const { queries }: { queries: any } = useCoachAppState();
    const { meetingQuery } = queries;
    const [teamMeetingSetList, setTeamMeetingSetList] = useState<MeetingSet[]>([]);
    const { users: team } = useUsersState({ coaches: userId, isDeleted: false });
    const [searchFilter, setSearchFilter] = useState<{ division: DivisionFilter; checkInStatus: CheckInStatus }>({
        division: "all",
        checkInStatus: "all",
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredTeam, setFilteredTeam] = useState<User[]>([]);
    const lastMonth = useLastMonth();
    const getUserId = (user: User) => user.id;
    const teamIdArray = team.map(getUserId);
    const { reminders }: { reminders: Takeaway[] } = useReminderState(
        { user: teamIdArray },
        { enabled: !!teamIdArray.length }
    );
    const [selectedReminders, setSelectedReminders] = useState<Takeaway[]>([]);
    const remindersDialog = useDialog({});

    useEffect(() => {
        if (meetingQuery?.isSuccess) {
            setTeamMeetingSetList(meetingQuery.data);
        }
    }, [meetingQuery]);

    useEffect(() => {
        const filteredTeam: User[] = [];
        const month = lastMonth.getMonth();
        const year = lastMonth.getFullYear();
        const { division, checkInStatus } = searchFilter;

        const divisionTeam = division === "all" ? team : team.filter((member) => member.division === division);

        divisionTeam.forEach((teamMember) => {
            if (checkInStatus === "all") {
                filteredTeam.push(teamMember);
            }
            if (checkInStatus === "completed") {
                const teamMemberMeetings = teamMeetingSetList.find(
                    (meetings: MeetingSet) => meetings.user === teamMember.id
                );
                if (!teamMemberMeetings) {
                    return;
                }
                const meeting = teamMemberMeetings.meetings.find(
                    (meeting) => meeting.month === month && meeting.year === year && meeting.status === "complete"
                );
                if (meeting) {
                    filteredTeam.push(teamMember);
                }
            }
            if (checkInStatus === "pending") {
                const teamMemberMeetings = teamMeetingSetList.find((meetings) => meetings.user === teamMember.id);
                if (!teamMemberMeetings) {
                    return;
                }
                const meeting = teamMemberMeetings.meetings.find(
                    (meeting) => meeting.month === month && meeting.year === year
                );
                if (!meeting) {
                    filteredTeam.push(teamMember);
                }
            }
        });

        setFilteredTeam(
            filteredTeam.filter((teamMember) => {
                const { firstName, lastName } = teamMember;
                return `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(searchTerm.toLowerCase());
            })
        );
    }, [team, searchFilter, lastMonth, teamMeetingSetList, searchTerm]);

    const getMeetingsForUser = (user: User): Meeting[] => {
        const meetingSet = getMeetingSetForUser(user);
        if (!meetingSet || isMeetingSetEmpty(meetingSet)) {
            return [];
        }
        return meetingSet.meetings;
    };

    const getRemindersForUser = (user: User) => {
        const userId = getUserId(user);
        return reminders.filter((reminder) => reminder.user === userId);
    };

    const getMeetingSetForUser = (user: User) => {
        return teamMeetingSetList.find((meetingSet) => meetingSet.user === user.id);
    };

    const isMeetingSetEmpty = (meetingSet: MeetingSet) => {
        return meetingSet.count === 0;
    };

    const handleSearchFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        console.log(event.target);
        const { name, value } = event.target;
        setSearchFilter((currentValue) => ({ ...currentValue, [name]: value }));
    };

    const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchTerm(value);
    };

    const renderTeamMemberInfoCard = (user: User) => {
        const { id, firstName, lastName, lastLogin, email } = user;
        const meetings = getMeetingsForUser(user);
        const reminders = getRemindersForUser(user);
        const handleRemindersButtonClick = () => {
            setSelectedReminders(reminders);
            remindersDialog.handleOpen();
        };

        return (
            <Grid item md={4}>
                <TeamMemberInfoCard
                    id={id}
                    firstName={firstName}
                    lastName={lastName}
                    lastLogin={lastLogin}
                    email={email}
                    meetings={meetings}
                    reminders={reminders}
                    onRemindersButtonClick={handleRemindersButtonClick}
                />
            </Grid>
        );
    };

    const getUserFullName = (id: string) => {
        const user = team.find((user) => user.id === id);
        return `${user?.firstName} ${user?.lastName}`;
    };

    const getRemindersWithUserNames = () => {
        return reminders.map((reminder) => ({
            ...reminder,
            description: reminder.description + " -- " + getUserFullName(reminder.user),
        }));
    };

    const sortedFilteredTeam = useSortArray<User>({ array: filteredTeam, field: "lastName", order: "asc" });

    return (
        <Container>
            <EmployeePanelInfoCards teamMeetingSetList={teamMeetingSetList} team={team} className={classes.spacing} />
            <Grid container style={{ marginBottom: 32 }}>
                <Grid item xs={12}>
                    <SearchCard
                        searchTerm={searchTerm}
                        onSearchTermChange={handleSearchTermChange}
                        filter={searchFilter}
                        onFilterChange={handleSearchFilterChange}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: 32 }}>
                <Grid item xs={12}>
                    <RemindersAccordion reminders={getRemindersWithUserNames()} />
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                {sortedFilteredTeam.map(renderTeamMemberInfoCard)}
            </Grid>
            <OpenRemindersDialog
                isOpen={remindersDialog.isOpen}
                onClose={remindersDialog.handleClose}
                reminders={selectedReminders}
            />
        </Container>
    );
}

export { EmployeePanel };
