import React, { ChangeEvent, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import DialogCancelButton from "../dialog-cancel-button";
import MyCheckinButton from "../../my-checkin-button";
import { useMeetingMutation } from "../../../hooks/mutations/use-meeting-mutation";
import { UseMutationResult, useQueryClient } from "react-query";
import { useCurrentUserState } from "../../../hooks/states/use-current-user-state";
import SubmissionNoteField from "../submission-note-field";
import { useSaveSubmissionNote } from "../hooks/use-save-submission-note";
import DialogAlert from "../dialog-alert";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface ReturnDialogProps {
    isOpen: boolean;
    meetingId: string | undefined;
    handleClose: () => any;
}

interface Status {
    isError: boolean;
    message: string;
}

function ReturnDialog({ isOpen, handleClose, meetingId }: ReturnDialogProps) {
    const [note, setNote] = useState("");
    const [status, setStatus] = useState<Status | null>(null);
    const { currentUser } = useCurrentUserState();
    const queryClient = useQueryClient();
    const meetingMutation: UseMutationResult<any, unknown, any> = useMeetingMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchMeetings");
            queryClient.invalidateQueries("fetchTeamMeetings");
            queryClient.invalidateQueries(queryKeys.meeting.get);
            queryClient.invalidateQueries(queryKeys.meeting.find);
        },
    });
    const saveSubmissionNote = useSaveSubmissionNote();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNote(value);
    };

    const handleSend = async () => {
        try {
            if (note) {
                await saveSubmissionNote({ authorId: currentUser?.id, meetingId, note });
            }
            await updateMeetingStatusToReturned();
            handleClose();
        } catch (error) {
            setStatus({ isError: true, message: error.message });
        }
    };

    const updateMeetingStatusToReturned = async () => {
        if (!meetingId) {
            throw new Error(
                "Could not update submission status. Required information was missing, please check your network connection and try again."
            );
        }
        const updatedMeeting = {
            id: meetingId,
            status: "returned",
        };
        await meetingMutation.mutate(updatedMeeting);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Return / Incomplete</DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: 24 }}>
                    Send this submission back to the employee for further editing. Enter any notes to the employee
                    below.
                </Typography>
                <SubmissionNoteField onChange={handleChange} value={note} />
                <DialogAlert error={status?.isError} message={status?.message} />
                {/*{status && (*/}
                {/*    <Alert severity={status.isError ? "error" : "success"} style={{ marginTop: 12 }}>*/}
                {/*        {status.message}*/}
                {/*    </Alert>*/}
                {/*)}*/}
            </DialogContent>
            <DialogActions>
                <DialogCancelButton onClick={handleClose} />
                <MyCheckinButton variant="outlined" onClick={handleSend}>
                    Send
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export { ReturnDialog };
