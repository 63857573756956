import { useQueryKey } from "./use-query-key";
import { useQuery } from "react-query";
import { submissionNoteAPI } from "../../util/api";
import { queryKeys } from "../api/config/query-keys";

export interface UseSubmissionNotesParams {
    meetingId: string | undefined;
    id?: string;
    authorId?: string;
}

export const useSubmissionNotes = (params: UseSubmissionNotesParams) => {
    const queryKey = useQueryKey(queryKeys.submissionNote.find, params);
    return useQuery(queryKey, () => submissionNoteAPI.find(params), { enabled: !!params.meetingId });
};
