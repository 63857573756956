import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./providers/auth-provider";
import Home from "./pages/home";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactGA from "react-ga";
import RouteChangeTracker from "./components/route-change-tracker";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 15 * 60 * 1000,
        },
    },
});

function App() {
    ReactGA.initialize("UA-194952222-1");
    const performanceObserverCallback = (list) => {
        list.getEntries().forEach((entry) => {
            ReactGA.timing({
                category: "Load Performance",
                variable: "Server Latency",
                value: entry.responseStart - entry.requestStart,
            });
            ReactGA.timing({
                category: "Load Performance",
                variable: "Download Time",
                value: entry.responseEnd - entry.responseStart,
            });
            ReactGA.timing({
                category: "Load Performance",
                variable: "Total app load time",
                value: entry.responseEnd - entry.requestStart,
            });
        });
    };

    const observer = new PerformanceObserver(performanceObserverCallback);
    observer.observe({ entryTypes: ["navigation"] });

    return (
        <QueryClientProvider client={queryClient}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <AuthProvider>
                    <Router>
                        <RouteChangeTracker />
                        <Home />
                    </Router>
                </AuthProvider>
                <ReactQueryDevtools />
            </MuiPickersUtilsProvider>
        </QueryClientProvider>
    );
}

export default App;
