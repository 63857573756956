import React from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px 24px",
    },
    title: {
        fontSize: 24,
    },
    list: {
        fontSize: 18,
        padding: 0,
        margin: "24px",
    },
}));

function ListCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root + " " + props.className}>
            <Typography className={classes.title}>{props.title}</Typography>
            <ol className={classes.list}>{props.children}</ol>
        </Card>
    );
}

export default ListCard;
