import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import MyCheckinButton from "../../my-checkin-button";
import SubmissionNoteField from "../submission-note-field";
import { UseMutationResult, useQueryClient } from "react-query";
import { useMeetingMutation } from "../../../hooks/mutations/use-meeting-mutation";
import { MeetingStatus } from "../../../types/meeting-status";
import { useSaveSubmissionNote } from "../hooks/use-save-submission-note";
import { useCurrentUserState } from "../../../hooks/states/use-current-user-state";
import DialogAlert from "../dialog-alert";
import { Meeting } from "../../../interfaces/meeting";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface SubmitDialogProps {
    handleClose: () => any;
    isOpen: boolean;
    meetingId: string | undefined;
    month: number;
    year: number;
}

interface MeetingSubmission {
    id?: string;
    month: number;
    year: number;
    status: MeetingStatus;
}

function SubmitDialog({ handleClose, isOpen }: SubmitDialogProps) {
    const { month, year, meeting } = useMonthlyCheckInState();
    const [note, setNote] = useState("");
    const { currentUser } = useCurrentUserState();
    const queryClient = useQueryClient();
    const meetingMutation: UseMutationResult<any, unknown, any> = useMeetingMutation({
        onSuccess: async (data: Meeting) => {
            await queryClient.invalidateQueries(queryKeys.meeting.find);
            await queryClient.invalidateQueries(queryKeys.meeting.get);
            await queryClient.invalidateQueries("fetchTeamMeetings");
            const { id } = data;
            if (note) {
                await saveSubmissionNote({ authorId: currentUser?.id, meetingId: id, note });
                await queryClient.invalidateQueries(queryKeys.submissionNote.find);
                await queryClient.invalidateQueries(queryKeys.submissionNote.get);
            }
        },
    });
    const saveSubmissionNote = useSaveSubmissionNote();
    const [status, setStatus] = useState({ isError: false, message: "" });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNote(value);
    };

    const onSubmit = async () => {
        try {
            const meeting = createMeeting();
            await meetingMutation.mutateAsync(meeting);
        } catch (error) {
            setStatus({ isError: true, message: error.message });
        }
        handleClose();
    };

    const createMeeting = () => {
        const newMeeting: MeetingSubmission = {
            month,
            year,
            status: "submitted",
        };
        if (meeting?.id) {
            newMeeting.id = meeting.id;
        }
        return newMeeting;
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Submit to Coach</DialogTitle>
            <DialogContent>
                <Typography>Submit this month to your coach?</Typography>
                <Typography style={{ marginBottom: 24 }}>
                    Once submitted, you will not be able to modify your submission. Use the field below to send a note
                    to your coach along with the submission.
                </Typography>
                <SubmissionNoteField onChange={handleChange} value={note} />
                <DialogAlert error={status.isError} message={status.message} />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>Cancel</MyCheckinButton>
                <MyCheckinButton variant="outlined" onClick={onSubmit}>
                    Submit
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

SubmitDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    meetingId: PropTypes.string,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
};

export { SubmitDialog };
