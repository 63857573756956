import { Box, Card, Link, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import TrackedItemProgressCircle from "./tracked-item-progress-circle";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    progressContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 130,
    },
    placeholderCard: {
        background: "rgba(182,183,185,0.3)",
        border: "3px dashed #c6c7c8",
        borderRadius: 15,
    },
    progressCircle: {
        position: "absolute",
    },
    titleContainer: {
        marginTop: 0,
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 16,
        textAlign: "center",
    },
}));

function TrackedItemCard(props) {
    const classes = useStyles();

    const {
        current = 0,
        goal = 1,
        title = "Visit the Monthly Tracker to add a new Tracked Goal",
        exists = false,
        className,
        id = "",
    } = props;
    return (
        <Card
            className={(exists ? classes.root : classes.root + " " + classes.placeholderCard) + " " + className}
            elevation={exists ? 1 : 0}
        >
            <Box className={classes.progressContainer}>
                <TrackedItemProgressCircle progress={current} goal={goal} size={90} thickness={2} />
            </Box>
            <Box className={classes.titleContainer}>
                {exists ? (
                    <Link component={RouterLink} to={`/employee/monthly-tracker/${id}`} color="inherit">
                        {title}
                    </Link>
                ) : (
                    <Typography>{title}</Typography>
                )}
            </Box>
        </Card>
    );
}

TrackedItemCard.propTypes = {
    current: PropTypes.number,
    goal: PropTypes.number,
    title: PropTypes.string,
    exists: PropTypes.bool,
    className: PropTypes.string,
};

export default TrackedItemCard;
