import { Box, CircularProgress, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { usePriorityActions } from "../../hooks/queries/use-priority-actions";
import { useCompanyState } from "../../hooks/use-company-state";
import { useDialog } from "../../hooks/use-dialog";
import EditPriorityActionDialog from "../edit-priority-action-dialog";

const useStyles = makeStyles((theme) => ({
    titleRow: {
        marginBottom: theme.spacing(4),
    },
    centeredY: {
        display: "flex",
        alignItems: "center",
    },
    centeredX: {
        display: "flex",
        justifyContent: "center",
    },
    priorityActions: {
        marginTop: theme.spacing(8),
    },
}));

function CompletedPriorityActionSection({ userID, year, category, disabled }) {
    const classes = useStyles();
    const { company } = useCompanyState();
    const { status, data } = usePriorityActions({ user: userID, year, category, isAccomplished: true });
    const [priorityActions, setPriorityActions] = useState([]);
    const [selectedPriorityAction, setSelectedPriorityAction] = useState();
    const priorityActionDialog = useDialog({});

    useEffect(() => {
        if (status === "success") {
            setPriorityActions(data.priorityActions || []);
        } else {
            setPriorityActions([]);
        }
    }, [status, data]);

    const showGoalPoints = () => {
        return company?.settings?.isGoalPointsEnabled;
    };

    const handleEditPriorityActionClick = (priorityAction) => {
        setSelectedPriorityAction(priorityAction);
        priorityActionDialog.handleOpen();
    };

    return (
        <Box>
            <Grid container className={`${classes.titleRow} ${classes.priorityActions}`}>
                <Grid item>
                    <Typography variant="subtitle1">Priority Actions</Typography>
                </Grid>
            </Grid>
            {status === "loading" ? (
                <CircularProgress />
            ) : priorityActions.length ? (
                priorityActions.map((priorityAction, index) => (
                    <Grid container spacing={4}>
                        <Grid item md={10}>
                            {`${index + 1}. ${priorityAction.description}`}
                        </Grid>
                        {showGoalPoints() && (
                            <Grid item md={1} className={classes.centeredY}>
                                &nbsp;
                            </Grid>
                        )}
                        <Grid
                            item
                            md={showGoalPoints() ? 1 : 2}
                            className={[classes.centeredY, classes.centeredX].join(" ")}
                        >
                            {!disabled && (
                                <IconButton onClick={() => handleEditPriorityActionClick(priorityAction)}>
                                    <EditIcon color="primary" />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))
            ) : (
                <Typography variant="caption" style={{ fontStyle: "italic" }}>
                    No completed Priority Actions recorded.
                </Typography>
            )}
            {!disabled && (
                <EditPriorityActionDialog
                    priorityAction={selectedPriorityAction}
                    open={priorityActionDialog.isOpen}
                    onClose={priorityActionDialog.handleClose}
                />
            )}
        </Box>
    );
}

CompletedPriorityActionSection.propTypes = {
    userID: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    category: PropTypes.number.isRequired,
};

export default CompletedPriorityActionSection;
