import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import laurel from "../assets/images/certificate/laurel-color.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        width: 192,
        height: 192,
        "& *": {
            position: "absolute",
        },
    },
    text: {
        fontWeight: 600,
        fontSize: 36,
        lineHeight: 44,
        color: "#f1960e",
    },
}));

function Laurel(props) {
    const classes = useStyles();
    const { year } = props;

    return (
        <Box className={classes.root} style={{ top: 48, left: 96 }}>
            <img src={laurel} style={{ width: 192, height: 192 }} alt="Gold leaf laurel" />
            <Typography className={classes.text}>{year}</Typography>
        </Box>
    );
}

export default Laurel;
