import React from "react";
import ContainedIconButton from "../../contained-icon-button";
import { PriorityHigh } from "@material-ui/icons";
import { Badge } from "@material-ui/core";

interface ShowRemindersButtonProps {
    onClick: () => unknown;
    size?: "small" | "medium";
    badgeContent?: number;
}

const ShowRemindersButton = ({ onClick, size = "medium", badgeContent = 0 }: ShowRemindersButtonProps) => {
    return (
        <Badge badgeContent={badgeContent} color="secondary" overlap="circle">
            <ContainedIconButton size={size} onClick={onClick}>
                <PriorityHigh fontSize="small" />
            </ContainedIconButton>
        </Badge>
    );
};

ShowRemindersButton.propTypes = {};

export { ShowRemindersButton };
