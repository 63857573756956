import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import backgroundImage from "./assets/images/backgrounds/app-background.jpg";
import CoachApp from "./coach-app";
import EmployeeApp from "./employee-app";
import usePermissions from "./hooks/use-permissions";
import { useAutodetectDateTimeFormat } from "./hooks/use-autodetect-date-time-format";
import { useCompanyState } from "./hooks/use-company-state";
import { useAuthState } from "./hooks/use-auth-state";
import { isBefore } from "date-fns";
import { SubscriptionEndWarning } from "./components/subscription-end-warning";
import { UserAccountDeactivated } from "./pages/account-deactivated/user-account-deactivated";
import { SubscriptionDeactivated } from "./pages/account-deactivated/subscription-deactivated";

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        overflowY: "scroll",
    },
}));

function AuthenticatedApp() {
    const classes = useStyles();
    const { isCoach, isAdmin } = usePermissions();
    const { company, isLoading } = useCompanyState();
    const { user } = useAuthState();
    useAutodetectDateTimeFormat();

    const isUserActive = () => {
        return !user.isDeleted;
    };

    const isUserAdmin = () => {
        return user.permissions.isAdmin;
    };

    const isSubscriptionActive = () => {
        const isActive = !company.subscription?.isCanceled;
        return isActive || isTodayBeforeSubscriptionEnd();
    };

    const isTodayBeforeSubscriptionEnd = () => {
        // No end date
        if (!companyHasSubscriptionEndDate()) {
            return true;
        }
        return isBefore(new Date(), getSubscriptionEndDate());
    };

    const companyHasSubscriptionEndDate = () => {
        return !!company.subscription?.endsOn;
    };

    const getSubscriptionEndDate = () => {
        return new Date(company.subscription.endsOn);
    };

    return isLoading ? (
        <Backdrop open={isLoading}>
            <CircularProgress />
        </Backdrop>
    ) : !isUserActive() ? (
        <UserAccountDeactivated />
    ) : !isSubscriptionActive() ? (
        isUserAdmin() ? (
            <SubscriptionDeactivated />
        ) : (
            <UserAccountDeactivated />
        )
    ) : (
        <div className={classes.background}>
            {isUserAdmin() && companyHasSubscriptionEndDate() && (
                <SubscriptionEndWarning endsOn={getSubscriptionEndDate()} />
            )}
            <Switch>
                <Route exact path="/">
                    <Redirect to="/employee/dashboard" />
                </Route>
                <Route path="/employee">
                    <EmployeeApp />
                </Route>
                <Route path="/coach">{isCoach || isAdmin ? <CoachApp /> : <Redirect to="/employee/dashboard" />}</Route>
                <Route path="*">
                    <Redirect to="/employee/dashboard" />
                </Route>
            </Switch>
        </div>
    );
}

export default AuthenticatedApp;
