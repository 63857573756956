import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useObjectiveMutation } from "../../hooks/mutations/use-objective-mutation";
import { objectiveDescriptions } from "../../config/objective-descriptions";
import CategoryTitle from "../monthly-check-in/category-title";
import AutoSaveTextField from "../autosave-text-field";
import ContainedIconButton from "../contained-icon-button";
import CheckIcon from "@material-ui/icons/Check";
import { useQueryClient } from "react-query";
import { Objective } from "../../interfaces/objective";
import { useObjectives } from "../../hooks/queries/use-objectives";
import { CompleteObjectiveDialog } from "./complete-objective-dialog";
import { useDialog } from "../../hooks/use-dialog";
import { queryKeys } from "../../hooks/api/config/query-keys";

interface ObjectiveSectionProps {
    userId: string;
    category: number;
    year: number;
    disabled: boolean;
}

const ObjectiveSection = ({ userId, category, year, disabled }: ObjectiveSectionProps) => {
    const { status, data } = useObjectives({
        user: userId,
        category,
        year,
        isAccomplished: false,
    });
    const [objective, setObjective] = useState<
        Objective | { id: undefined; user: string; category: number; year: number; description: string }
    >();
    const queryClient = useQueryClient();
    const objectiveMutation = useObjectiveMutation({
        onSuccess: () => queryClient.invalidateQueries(queryKeys.objective.find),
    });
    const completeObjectiveDialog = useDialog({});

    useEffect(() => {
        if (status === "success") {
            const objective = data.objectives[0];
            setObjective(
                objective || {
                    id: undefined,
                    user: userId,
                    category,
                    year,
                    description: "",
                }
            );
        } else {
            setObjective({
                id: undefined,
                user: userId,
                category,
                year,
                description: "",
            });
        }
    }, [category, data, status, userId, year]);

    const isCompleteButtonDisabled = () => {
        return !objective?.id;
    };

    const handleCompleteObjectiveClick = () => {
        completeObjectiveDialog.handleOpen();
    };

    const getObjectiveId = () => {
        return objective?.id || "";
    };

    return (
        <Box style={{ marginBottom: 24 }}>
            <CategoryTitle
                title={objectiveDescriptions.masterPlan.description}
                subheading={objectiveDescriptions.masterPlan.subheading}
                style={{ marginBottom: 24 }}
            />
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container style={{ flexGrow: 1 }} spacing={5} alignItems="center">
                    <Grid item xs={11}>
                        <AutoSaveTextField
                            textFieldName="description"
                            object={objective}
                            maxLength={165}
                            mutation={objectiveMutation}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ContainedIconButton
                            onClick={handleCompleteObjectiveClick}
                            disabled={disabled || isCompleteButtonDisabled()}
                        >
                            <CheckIcon />
                        </ContainedIconButton>
                    </Grid>
                    <CompleteObjectiveDialog
                        isOpen={completeObjectiveDialog.isOpen}
                        onClose={completeObjectiveDialog.handleClose}
                        id={getObjectiveId()}
                    />
                </Grid>
            )}
        </Box>
    );
};

ObjectiveSection.propTypes = {
    userId: PropTypes.string,
    category: PropTypes.number,
    year: PropTypes.number,
    disabled: PropTypes.bool,
};

export { ObjectiveSection };
