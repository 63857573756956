import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useStyles } from "./division.styles";

interface DivisionNameProps {
    children: string;
}

const DivisionName = ({ children }: DivisionNameProps) => {
    const classes = useStyles();

    return <Typography className={classes.divisionName}>{children}</Typography>;
};

DivisionName.propTypes = {
    children: PropTypes.string,
};

export { DivisionName };
