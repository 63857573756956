import PropTypes from "prop-types";
import React from "react";
import { useTermly } from "../../hooks/use-termly";

function TermlyEmbed({ id }) {
    useTermly();

    return <div name="termly-embed" data-id={id} data-type="iframe" />;
}

TermlyEmbed.propTypes = {
    id: PropTypes.string,
}

export default TermlyEmbed;