import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import CategoryTitle from "../category-title";
import { useUpdateApi } from "../../../hooks/api/use-update-api";
import { topDiscussionTopicAPI } from "../../../util/api";
import { useFindApi } from "../../../hooks/api/use-find-api";
import { queryKeys } from "../../../hooks/api/config/query-keys";
import { useCreateApi } from "../../../hooks/api/use-create-api";
import AutoSaveTextField from "../../autosave-text-field";
import { TopDiscussionTopic } from "../../../interfaces/top-discussion-topic";
import { useQueryClient } from "react-query";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";

interface TopDiscussionTopicSectionProps {
    disabled: boolean;
}

const defaultDiscussionTopicText = "No topic entered for this month.";

const TopDiscussionTopicSection = ({ disabled }: TopDiscussionTopicSectionProps) => {
    const { month, year, user } = useMonthlyCheckInState();
    const userId = user?.id || "";
    const queryClient = useQueryClient();
    const mutationQueryOptions = {
        onSuccess: () => queryClient.invalidateQueries(queryKeys.topDiscussionTopic.find),
    };
    const findTopDiscussionTopicResult = useFindApi<TopDiscussionTopic>(
        topDiscussionTopicAPI,
        queryKeys.topDiscussionTopic.find,
        { month, year, user: userId },
        "topDiscussionTopics"
    );
    const createTopDiscussionTopic = useCreateApi<TopDiscussionTopic>(
        topDiscussionTopicAPI,
        queryKeys.topDiscussionTopic.create,
        mutationQueryOptions
    );
    const updateTopDiscussionTopic = useUpdateApi<TopDiscussionTopic>(
        topDiscussionTopicAPI,
        queryKeys.topDiscussionTopic.update,
        mutationQueryOptions
    );

    const topDiscussionTopic: TopDiscussionTopic = findTopDiscussionTopicResult.data.result[0] || {
        text: "",
        month,
        year,
        user: userId,
    };

    const createMutation = (topDiscussionTopic: TopDiscussionTopic) =>
        createTopDiscussionTopic.create(topDiscussionTopic);
    const updateMutation = (topDiscussionTopic: TopDiscussionTopic) =>
        updateTopDiscussionTopic.update(topDiscussionTopic.id, topDiscussionTopic);

    const mutation = {
        mutate: topDiscussionTopic.id ? updateMutation : createMutation,
    };

    const getText = () => {
        return topDiscussionTopic.text ? (
            <Typography>{topDiscussionTopic.text}</Typography>
        ) : (
            <Typography style={{ fontStyle: "italic" }}>{defaultDiscussionTopicText}</Typography>
        );
    };

    return (
        <Box>
            <CategoryTitle title="Priority Discussion Topic" style={{ marginBottom: 24 }} fontWeight={700} />
            {findTopDiscussionTopicResult.status === "loading" ? (
                <CircularProgress />
            ) : disabled ? (
                <Grid container spacing={5}>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        {getText()}
                    </Grid>
                </Grid>
            ) : (
                <AutoSaveTextField
                    textFieldName="text"
                    object={topDiscussionTopic}
                    maxLength={165}
                    mutation={mutation}
                />
            )}
        </Box>
    );
};

TopDiscussionTopicSection.propTypes = {
    disabled: PropTypes.bool,
};

export default TopDiscussionTopicSection;
