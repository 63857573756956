import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: (props) => (props.hidden ? 0 : 1),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles({ hidden: value !== index });

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`my-checkin-tab-${index}`}
            {...other}
            className={classes.root}
        >
            {value === index && (
                <Box p={3} style={{ flexGrow: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel;
