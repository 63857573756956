import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(5, 7),
        height: theme.spacing(9),
        display: "flex",
        alignItems: "center",
    },
    number: {
        fontSize: 24,
    },
    description: {
        fontSize: 18,
        fontWeight: 500,
    },
}));

interface InfoCardProps {
    number: number;
    description: string;
}

function InfoCard({ number, description }: InfoCardProps) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={3}>
                    <Typography className={classes.number}>{number}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography className={classes.description}>{description}</Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

InfoCard.propTypes = {
    number: PropTypes.number,
    description: PropTypes.string,
};

export default InfoCard;
