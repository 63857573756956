import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    linkList: {
        listStyle: "none",
        marginLeft: 0,
        padding: 0,
    },
    linkListItem: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
        fontSize: 18,
        fontWeight: 700,
    },
    link: {
        color: theme.palette.primary.dark,
    },
}));
