import { Card, Grid, Link, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import MyCheckinButton from "../components/my-checkin-button";
import MyCheckinTextField from "../components/my-checkin-text-field";
import useRequest from "../hooks/use-request";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(13),
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(6),
    },
    loginForm: {
        padding: theme.spacing(4),
        // marginTop: theme.spacing(7),
        // [theme.breakpoints.only("xl")]: {
        //     marginTop: theme.spacing(14),
        // },
        // [theme.breakpoints.only("lg")]: {
        //     marginTop: theme.spacing(12),
        // },
        // [theme.breakpoints.only("md")]: {
        //     marginTop: theme.spacing(12),
        // },
        // [theme.breakpoints.only("sm")]: {
        //     marginTop: theme.spacing(12),
        // },
        // [theme.breakpoints.only("xs")]: {
        //     marginTop: theme.spacing(6),
        // },
    },
    loginFormContainer: {
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
    },
    forgotPassword: {
        marginTop: theme.spacing(3),
    },
}));

function LoginForm() {
    const [state, setState] = useState({ email: "", password: "" });
    const { doRequest, errors } = useRequest({
        url: "/api/auth/signin",
        method: "post",
        body: state,
    });
    // const theme = useTheme();
    // const xsMedia = useMediaQuery(theme.breakpoints.only("xs"));

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await doRequest();
        if (response) {
            //
            window.location.reload();
        }
    };

    return (
        <>
            <form>
                <Grid container spacing={3} alignItems={"center"} justify="center">
                    <Grid item xs={12} sm={6}>
                        <MyCheckinTextField label="Email" name="email" onChange={handleChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyCheckinTextField
                            label="Password"
                            name="password"
                            type="password"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <MyCheckinButton type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                            Log In
                        </MyCheckinButton>
                    </Grid>
                </Grid>
            </form>
            {errors}
        </>
    );
}

function Login() {
    const classes = useStyles();

    return (
        <Grid container justify={"center"} alignItems={"center"} className={classes.loginFormContainer}>
            <Grid item>
                <Card className={classes.loginForm}>
                    <LoginForm />
                    <Grid container justify="center">
                        <Grid item className={classes.forgotPassword}>
                            <Link component={RouterLink} to="/forgot-password" href="#">
                                Forgot your password?
                            </Link>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Login;
