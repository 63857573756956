import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./notifications.styles";
import { Box } from "@material-ui/core";

interface NotificationListItemProps {
    children: React.ReactNode;
    isUnread: boolean;
}

const NotificationListItem = ({ children, isUnread }: NotificationListItemProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.notificationListItem} style={isUnread ? {} : { background: "#f2f2f3", paddingLeft: 4 }}>
            {isUnread && <Box className={classes.newNotificationIndicator} />}
            {children}
        </Box>
    );
};

NotificationListItem.propTypes = {
    children: PropTypes.node,
    isUnread: PropTypes.bool,
};

export { NotificationListItem };
