import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { API } from "../../util/api";
import TabPanel from "./tab-panel";

function PermissionsTab(props) {
    const { value, index, user } = props;
    const userMutation = useMutation((user) => {
        return API.updateUser(user);
    });

    const handleChange = (event) => {
        const { checked, name } = event.target;
        user.permissions[name] = checked;
        userMutation.mutate(user);
    };

    return (
        <TabPanel index={index} value={value}>
            <Grid container style={{ padding: 48 }} spacing={3}>
                <Typography variant="subtitle1">Granted Permissions</Typography>
                <Grid container item spacing={4} justify="center" alignItems="center">
                    <Grid item md={9}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={user?.permissions?.isCoach}
                                    color="primary"
                                    style={{ padding: 4 }}
                                    name="isCoach"
                                    onChange={handleChange}
                                />
                            }
                            label="Coach"
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={4} justify="center" alignItems="center">
                    <Grid item md={9}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={user?.permissions?.isAdmin}
                                    color="primary"
                                    style={{ padding: 4 }}
                                    name="isAdmin"
                                    onChange={handleChange}
                                />
                            }
                            label="System Admin"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    );
}

PermissionsTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default PermissionsTab;
