import React from "react";
import TrackedItemProgressCircle from "../tracked-item-progress-circle";
import { Grid, makeStyles, Typography } from "@material-ui/core";

interface TrackedItemProgressProps {
    goal: number;
    progress: number;
}

const useStyles = makeStyles((theme) => ({
    progressCircleContainer: {
        marginBottom: theme.spacing(6),
    },
    listLabel: {
        textAlign: "right",
        fontWeight: 600,
    },
}));

function TrackedItemProgress({ goal, progress }: TrackedItemProgressProps) {
    const classes = useStyles();

    return (
        <>
            <TrackedItemProgressCircle
                progress={progress || 0}
                goal={goal || 0}
                className={classes.progressCircleContainer}
            />
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <Typography className={classes.listLabel}>Current Progress</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{progress}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <Typography className={classes.listLabel}>Goal</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{goal}</Typography>
                </Grid>
            </Grid>
        </>
    );
}

export { TrackedItemProgress };
