import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { useStyles } from "./divisions.styles";

interface DivisionOverflowMenuItemProps {
    onClick: () => unknown;
    children: React.ReactNode;
    color?: string;
}

const DivisionOverflowMenuItem = ({ onClick, children, color = "#000" }: DivisionOverflowMenuItemProps) => {
    const classes = useStyles();

    return (
        <MenuItem className={classes.overflowMenuItem} onClick={onClick} style={{ color }}>
            {children}
        </MenuItem>
    );
};

DivisionOverflowMenuItem.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    color: PropTypes.string,
};

export { DivisionOverflowMenuItem };
