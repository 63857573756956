import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import PropTypes from "prop-types";
import MyCheckinTextField from "../my-checkin-text-field";

function SearchBar(props) {
    const { value, onChange } = props;

    return (
        <MyCheckinTextField
            value={value}
            onChange={onChange}
            label="Search"
            InputProps={{ endAdornment: <SearchIcon /> }}
            fullWidth
        />
    );
}

SearchBar.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default SearchBar;
