import { useMutation } from "react-query";
import { CRUD } from "../../util/api/interfaces/crud";

interface DeleteOptions<T> {
    preUpdate?: (id: string | number) => unknown;
    onSuccess?: (data: T) => any;
    onError?: (err: unknown) => any;
}

export const useDeleteApi = <T>(api: CRUD, key: string, options: DeleteOptions<T>) => {
    const deleteMutationFn = (id: string | number) => api.delete(id);
    const deleteMutation = useMutation<T, unknown, string | number>(key, deleteMutationFn, {
        onMutate: options.preUpdate,
        onSuccess: options.onSuccess,
        onError: options.onError,
    });

    const deleteFn = (id: string | number) => deleteMutation.mutate(id);
    const { data, status } = deleteMutation;

    return {
        deleteFn,
        data,
        status,
    };
};
