import React, { useEffect, useState } from "react";
import { useMaxLength } from "../hooks/use-max-length";
import MyCheckinTextField from "./my-checkin-text-field";
import PropTypes from "prop-types";

function AutoSaveTextField({ object, textFieldName, index = null, mutation, maxLength = 0, ...others }) {
    const [state, setState] = useState({ ...object });
    const [hasChange, setHasChange] = useState(false);
    const { error, handleFocus, handleBlur: maxLengthBlur, helperText } = useMaxLength({
        maxLength,
        value: state[textFieldName],
    });

    useEffect(() => {
        setState({ ...object });
    }, [object]);

    const handleChange = (event) => {
        const { value } = event.target;
        setState({ ...state, [textFieldName]: value });
        setHasChange(true);
    };

    const handleBlur = () => {
        if (hasChange && !error) {
            mutation.mutate(state);
        }
        setHasChange(false);
        maxLengthBlur();
    };

    const getLabel = () => {
        return `${index !== null ? index + 1 + "." : ""}`;
    };

    return (
        <MyCheckinTextField
            multiline={true}
            error={error}
            value={state[textFieldName]}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth={true}
            label={getLabel()}
            rows={2}
            helperText={helperText}
            {...others}
        />
    );
}

AutoSaveTextField.propTypes = {
    object: PropTypes.object,
    textFieldName: PropTypes.string,
    index: PropTypes.number,
    mutation: PropTypes.object,
    maxLength: PropTypes.number,
    others: PropTypes.any,
};

export default AutoSaveTextField;
