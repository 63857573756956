import React from "react";
import PropTypes from "prop-types";
import { Section } from "../section";
import { Heading } from "../heading";
import { List } from "../list";
import { ListItem } from "../list-item";

interface MonthlyCheckInCategoryProps {
    heading: string;
    results: { id: string; description: string }[];
    style?: any;
    showNumbering?: boolean;
}

const MonthlyCheckInCategory = ({ heading, results, style, showNumbering = true }: MonthlyCheckInCategoryProps) => {
    const toListItemDescription = (result: { id: string; description: string }) => (
        <ListItem key={`result-${result.id}`}>{result.description}</ListItem>
    );

    return (
        <Section style={style}>
            <Heading>{heading}</Heading>
            <List showNumbering={showNumbering}>{results.map(toListItemDescription)}</List>
        </Section>
    );
};

MonthlyCheckInCategory.propTypes = {
    heading: PropTypes.string.isRequired,
    results: PropTypes.array,
};

export { MonthlyCheckInCategory };
