import { Card, Grid, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import SnapshotGrid from "./snapshot-grid";
import YearSelector from "../year-selector";
import { Meeting } from "../../interfaces/meeting";
import { cardTitle } from "./config";
import { useStyles } from "./styles";

interface SnapshotProps {
    year: number;
    setYear: (year: number) => unknown;
    yearsArray: number[];
    meetings: Meeting[];
    className: string;
}

const Snapshot = ({ year, setYear, yearsArray, meetings, className }: SnapshotProps) => {
    const classes = useStyles();

    const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setYear(parseInt(value));
    };

    return (
        <Card className={className}>
            <Grid container item className={classes.titleRow}>
                <Typography className={classes.cardTitle}>{cardTitle}</Typography>
                <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleSelect} />
            </Grid>
            <SnapshotGrid meetings={meetings} />
        </Card>
    );
};

export default Snapshot;
