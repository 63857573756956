import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./discussion.styles";
import { discussionPageName } from "./config";

const DiscussionCardTitle = () => {
    const classes = useStyles();

    return <Typography className={classes.discussionCardTitle}>{discussionPageName}</Typography>;
};

export { DiscussionCardTitle };
