import React from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";

const Spacer = () => {
    const classes = useStyles();
    return <Box className={classes.spacer} />;
};

export { Spacer };
