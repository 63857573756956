import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    box: {
        position: "relative",
        width: 64,
        height: 64,
        border: "1px solid #e0e0e1",
        fontSize: 14,
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    date: {
        position: "absolute",
        top: 4,
        right: 4,
        fontSize: 12,
        fontWeight: 300,
        color: "rgba(70,71,73,1)",
    },
}));

function TrackedItemCalendarDay(props) {
    const classes = useStyles();
    const { day, value, onChange, forceEditable = false, showDate = true } = props;
    const [editing, setEditing] = useState(false);
    const [state, setState] = useState(0);

    useEffect(() => {
        setState(value);
    }, [value]);

    const toggleEditing = () => {
        setEditing(!editing);
    };

    const handleChange = (event) => {
        const { value } = event.target;
        const intValue = parseInt(value);
        setState(isNaN(intValue) ? 0 : intValue);
    };

    const handleBlur = () => {
        toggleEditing();
        if (state !== value) {
            onChange(day, isNaN(state) ? 0 : state);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleBlur();
        }
    };

    const handleFocus = (event) => {
        event.target.select();
    };

    const getProgress = () => {
        return state || "";
    };

    return day || forceEditable ? (
        <Box className={classes.box} onClick={toggleEditing}>
            {showDate && <Typography className={classes.date}>{day}</Typography>}
            {editing ? (
                <TextField
                    value={getProgress()}
                    inputProps={{ style: { textAlign: "center" } }}
                    autoFocus
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onFocus={handleFocus}
                />
            ) : (
                getProgress()
            )}
        </Box>
    ) : (
        <Box className={classes.box} />
    );
}

export default TrackedItemCalendarDay;
