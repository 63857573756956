import { API } from "../util/api";
import { useQuery } from "react-query";

export type TokenType = "registration" | "password-reset";

interface UseValidateTokenProps {
    token: string;
    tokenType: TokenType;
}

export const useValidateToken = ({ token, tokenType }: UseValidateTokenProps) => {
    const getTokenValidationQueryFunction = () => {
        let validationQueryFunction: Promise<any>;
        if (tokenType === "registration") {
            validationQueryFunction = API.validateRegistrationToken(token);
        } else if (tokenType === "password-reset") {
            validationQueryFunction = API.validatePasswordResetToken(token);
        } else {
            throw new Error("Unrecognized token type");
        }
        return validationQueryFunction;
    };

    return useQuery(["validateToken", token], getTokenValidationQueryFunction, {
        enabled: !!token,
    });
};
