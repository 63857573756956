import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import { ContainerProps } from "./container-props";

const useStyles = makeStyles(() => ({
    todoLink: {
        fontSize: 16,
        fontWeight: 600,
        cursor: "pointer",
        width: "100%",
    },
}));

interface TodoLinkProps extends ContainerProps {
    centered?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

export const TodoLink = ({ children, centered = false, onClick, disabled = false }: TodoLinkProps) => {
    const classes = useStyles();

    const handleClick = onClick;

    return !disabled ? (
        <Link
            color={"primary"}
            className={classes.todoLink}
            style={{ textAlign: centered ? "center" : "left", paddingLeft: centered ? 0 : 48 }}
            onClick={handleClick}
        >
            {children}
        </Link>
    ) : null;
};
