import React from "react";
import { Alert } from "@material-ui/lab";

interface DialogAlertProps {
    error?: boolean;
    warning?: boolean;
    message?: string;
}

function DialogAlert({ error = false, warning = false, message = "" }: DialogAlertProps) {
    const getSeverity = () => {
        return error ? "error" : warning ? "warning" : "success";
    };

    return (
        <>
            {message && (
                <Alert severity={getSeverity()} style={{ marginTop: 12 }}>
                    {message}
                </Alert>
            )}
        </>
    );
}

export { DialogAlert };
