import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    notificationIcon: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    notificationMenu: {
        "& ul": {
            padding: 0,
        },
    },
    notificationList: {
        width: 256,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
    },
    notificationListItem: {
        width: "100%",
        height: 48,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 8px 0px 0px",
    },
    newNotificationRectangle: {
        position: "static",
        width: 4,
        minWidth: 4,
        height: 48,
        left: 0,
        top: 0,
        background: theme.palette.primary.main,
    },
    readSpacer: {
        position: "static",
        width: 4,
        minWidth: 4,
        height: 48,
        left: 0,
        top: 0,
    },
    notificationContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 0,
        margin: "0px 16px",
        whiteSpace: "pre-wrap",
        gap: 12,
        width: "100%",
    },
    notificationText: {
        flex: "none",
        position: "static",
        width: 192,
        minWidth: 0,
        height: 30,
        maxHeight: 30,
        left: 36,
        top: 0,
        fontSize: 12,
        lineHeight: "15px",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    viewAllNotificationsLink: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        width: "100%",
        height: 48,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 12,
    },
}));
