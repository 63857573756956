import React from "react";
import { Meeting } from "../interfaces/meeting";
import { User } from "../interfaces/user";

export interface MonthlyCheckInContextProps {
    user?: User;
    month: number;
    year: number;
    meeting?: Meeting;
    viewingAs: "employee" | "coach";
    isLoading: boolean;
    isMeetingStarted?: boolean;
    toggleIsMeetingStarted?: () => void;
}

export const MonthlyCheckInContext = React.createContext<MonthlyCheckInContextProps>({
    user: undefined,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    meeting: undefined,
    viewingAs: "employee",
    isLoading: true,
    isMeetingStarted: false,
});
