import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./unassigned-users-dialog.styles";

interface UnassignedUsersListProps {
    children: React.ReactNode;
}

const UnassignedUsersList = ({ children }: UnassignedUsersListProps) => {
    const classes = useStyles();

    if (React.Children.count(children) === 0) {
        return (
            <Typography align="center" style={{ width: "100%" }}>
                No users found.
            </Typography>
        );
    }

    return <Box className={classes.unassignedUsersList}>{children}</Box>;
};

UnassignedUsersList.propTypes = {
    children: PropTypes.node,
};

export { UnassignedUsersList };
