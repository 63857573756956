import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

interface SectionProps {
    children?: React.ReactNode;
    withBorder?: boolean;
    borderColor?: "primary" | "neutral";
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(6),
    },
    border: {
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 4,
    },
    borderPrimary: {
        borderColor: theme.palette.primary.main,
    },
    borderNeutral: {
        borderColor: theme.palette.neutral.main,
    },
}));

const Section = ({ children, withBorder = false, borderColor = "primary" }: SectionProps) => {
    const classes = useStyles();

    return (
        <div
            className={[
                classes.root,
                withBorder ? classes.border : "",
                borderColor === "primary" ? classes.borderPrimary : "",
                borderColor === "neutral" ? classes.borderNeutral : "",
            ].join(" ")}
        >
            {children}
        </div>
    );
};

Section.propTypes = {
    children: PropTypes.node,
};

export default Section;
