export const cardTitle = "MyCheck-In Snapshot";

export const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const monthIndexArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

interface StatusTooltips {
    [index: string]: string;
}

export const statusTooltips: StatusTooltips = {
    submitted: "Submitted",
    scheduling: "Scheduling",
    scheduled: "Scheduled",
    complete: "Complete",
    "no meeting": "No Meeting",
    returned: "Returned",
    none: "",
};
