import React from "react";
import NoMeetingButton from "../no-meeting-button";
import MeetingSummaryWrapUpButton from "../meeting-summary-wrap-up-button";
import MeetingWrapUpDialog from "../meeting-wrap-up-dialog";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "./monthly-check-in-coach-actions.styles";
import { useDialog } from "../../../hooks/use-dialog";
import { UseMutationResult, useQueryClient } from "react-query";
import { useMeetingMutation } from "../../../hooks/mutations/use-meeting-mutation";
import { MeetingStatus } from "../../../types/meeting-status";
import ReturnButton from "../return-button";
import ReturnDialog from "../return-dialog";
import { DownloadMonthlyCheckInPdfButton } from "../download-monthly-check-in-pdf-button";
import { useParams } from "react-router-dom";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface MonthlyCheckInCoachActionsProps {
    meetingId: string | undefined;
    employeeId: string;
    meetingStatus: MeetingStatus;
    userId: string;
    firstName: string;
    lastName: string;
}

const withActionButtonGrid = (component: JSX.Element) => {
    return (
        <Grid item xs={12} sm={4} md="auto">
            {component}
        </Grid>
    );
};

function MonthlyCheckInCoachActions({
    meetingId,
    meetingStatus,
    employeeId,
    userId,
    firstName,
    lastName,
}: MonthlyCheckInCoachActionsProps) {
    const classes = useStyles();
    const { year, month } = useParams<{ year: string; month: string }>();
    const monthInt = parseInt(month);
    const yearInt = parseInt(year);
    const wrapUpDialog = useDialog({});
    const returnDialog = useDialog({});
    const queryClient = useQueryClient();
    const meetingMutation: UseMutationResult<any, unknown, any, unknown> = useMeetingMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchMeetings");
            queryClient.invalidateQueries("fetchTeamMeetings");
            queryClient.invalidateQueries(queryKeys.meeting.get);
            queryClient.invalidateQueries(queryKeys.meeting.find);
        },
    });

    const handleFinish = (notes: string) => {
        if (!meetingId) {
            return;
        }
        const updatedMeeting = {
            id: meetingId,
            notes,
            status: "complete",
        };
        updatedMeeting.notes = notes;
        updatedMeeting.status = "complete";
        meetingMutation.mutate(updatedMeeting);
    };

    const isMeetingComplete = () => {
        return meetingStatus === "complete";
    };

    return (
        <Box className={classes.actions}>
            <Grid container justify="flex-end" spacing={5}>
                {withActionButtonGrid(
                    <DownloadMonthlyCheckInPdfButton
                        userId={userId}
                        month={monthInt}
                        year={yearInt}
                        firstName={firstName}
                        lastName={lastName}
                    />
                )}
                {!isMeetingComplete() &&
                    withActionButtonGrid(<ReturnButton meetingId={meetingId} onClick={returnDialog.handleOpen} />)}
                {!isMeetingComplete() && withActionButtonGrid(<NoMeetingButton meetingId={meetingId} />)}
                {withActionButtonGrid(
                    <MeetingSummaryWrapUpButton meetingStatus={meetingStatus} onClick={wrapUpDialog.handleOpen} />
                )}
            </Grid>
            <MeetingWrapUpDialog
                handleClose={wrapUpDialog.handleClose}
                onFinish={handleFinish}
                isOpen={wrapUpDialog.isOpen}
                month={monthInt}
                year={yearInt}
                userID={employeeId}
            />
            {!isMeetingComplete() && (
                <ReturnDialog
                    isOpen={returnDialog.isOpen}
                    handleClose={returnDialog.handleClose}
                    meetingId={meetingId}
                />
            )}
        </Box>
    );
}

export { MonthlyCheckInCoachActions };
