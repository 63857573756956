import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MyCheckinTextField from "../../my-checkin-text-field";
import MyCheckinButton from "../../my-checkin-button";
import { cancelButtonText, confirmButtonProps, dialogMaxWidth } from "./config";
import { useStyles } from "./add-division-dialog.styles";
import { useGetApi } from "../../../hooks/api/use-get-api";
import { Company } from "../../../interfaces/company/company";
import { companyAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface AddDivisionDialogProps {
    open: boolean;
    onClose: () => unknown;
    onSave: ({ name }: { name: string }) => unknown;
}

const AddDivisionDialog = ({ open, onClose, onSave }: AddDivisionDialogProps) => {
    const classes = useStyles();
    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");
    const [name, setName] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);
    };

    const handleCancel = () => {
        setName("");
        onClose();
    };

    const handleSave = () => {
        onSave({ name });
    };

    return (
        <Dialog open={open} maxWidth={dialogMaxWidth} fullWidth onClose={onClose}>
            <DialogTitle>Add {company?.organizationalUnitName?.singular || "Division"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <MyCheckinTextField
                    label={`${company?.organizationalUnitName?.singular || "Division"} Name`}
                    className={classes.inputField}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleCancel}>{cancelButtonText}</MyCheckinButton>
                <MyCheckinButton {...confirmButtonProps} onClick={handleSave}>
                    Save
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

AddDivisionDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

export { AddDivisionDialog };
