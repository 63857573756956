import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState } from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import useRequest from "../hooks/use-request";
import { profilePath } from "../config/urls";

const useStyles = makeStyles((theme) => ({
    accountMenu: {
        "&:hover": {
            cursor: "pointer",
        },
    },
}));

function UserMenu() {
    const match = useRouteMatch();
    const [anchorEl, setAnchorEl] = useState();
    const { doRequest } = useRequest({
        url: "/api/auth/signout",
        method: "post",
        onSuccess: () => {
            window.location.reload();
        },
    });
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.target);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = async () => {
        await doRequest();
    };

    return (
        <>
            <PersonIcon
                className={classes.accountMenu}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            />
            <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem component={RouterLink} to={match.path + profilePath} onClick={handleClose}>
                    Profile
                </MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </Menu>
        </>
    );
}

export default UserMenu;
