import { Checkbox, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useUserMutation } from "../../hooks/mutations/use-user-mutation";
import { useUsers } from "../../hooks/queries/use-users";
import Avatar from "../avatar";
import TabPanel from "../coach-station/tab-panel";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6),
    },
}));

function CoachesAndAdminsTab(props) {
    const classes = useStyles();
    const { value, index } = props;
    const { status, data } = useUsers({ isDeleted: false });
    const [users, setUsers] = useState([]);
    const queryClient = useQueryClient();
    const userMutation = useUserMutation({
        onSuccess: () => queryClient.invalidateQueries("getAllCoaches"),
    });

    useEffect(() => {
        if (status === "success") {
            setUsers(
                data.users.sort((userA, userB) => {
                    const lastNameA = userA.lastName.toLowerCase();
                    const lastNameB = userB.lastName.toLowerCase();
                    return lastNameA < lastNameB ? -1 : 1;
                }) || []
            );
        } else {
            setUsers([]);
        }
    }, [status, data]);

    const handleChange = (event, user) => {
        const { name, checked } = event.target;
        userMutation.mutate({ id: user.id, $set: { [`permissions.${name}`]: checked } });
        user.permissions[name] = checked;
    };

    const getCheckbox = (user, name) => {
        return (
            <Checkbox
                checked={user.permissions[name]}
                name={name}
                color="primary"
                onChange={(event) => handleChange(event, user)}
            />
        );
    };

    const getUserRow = (user) => {
        return (
            <Grid container item spacing={4} alignItems="center">
                <Grid item md={1}>
                    <Avatar id={user.id} borderWidth={1} diameter={32} />
                </Grid>
                <Grid item md={7} xs={4}>
                    {user.firstName} {user.lastName}
                </Grid>
                <Grid item md={2}>
                    {getCheckbox(user, "isAdmin")}
                </Grid>
                <Grid item md={2}>
                    {getCheckbox(user, "isCoach")}
                </Grid>
            </Grid>
        );
    };

    return (
        <TabPanel index={index} value={value}>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container className={classes.container}>
                    <Grid container item>
                        <Grid md={1}></Grid>
                        <Grid md={7} xs={4}></Grid>
                        <Grid md={2}>
                            <Typography>Admin</Typography>
                        </Grid>
                        <Grid md={2}>
                            <Typography>Coach</Typography>
                        </Grid>
                    </Grid>
                    {users.map(getUserRow)}
                </Grid>
            )}
        </TabPanel>
    );
}

CoachesAndAdminsTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

export default CoachesAndAdminsTab;
