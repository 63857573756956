import React from "react";
import { Alert } from "@material-ui/lab";

const NoMeetingAlert = () => {
    return (
        <Alert severity="info" color="warning">
            Your Coach has indicated that no meeting is necessary for this month.
        </Alert>
    );
};

export default NoMeetingAlert;
