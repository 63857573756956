import React from "react";
import PropTypes from "prop-types";
import { Section } from "./section";
import { NewCommentForm } from "./new-comment-form";
import { Box } from "@material-ui/core";
import { Pagination } from "../division/pagination";
import { useStyles } from "./discussion.styles";
import { CommentList } from "./comment-list";
import { CommentListItem } from "./comment-list-item";
import { Comment } from "../../interfaces/comment";
import { usePagination } from "../../hooks/use-pagination";
import { LoadingIndicator } from "../loading-indicator";

interface DiscussionSectionProps {
    comments: Comment[];
    discussionId: string;
    isLoading: boolean;
}

const DiscussionSection = ({ comments, discussionId, isLoading }: DiscussionSectionProps) => {
    const classes = useStyles();
    const { offset, limit, onClickNext, onClickPrevious, paginatedArray: paginatedComments } = usePagination({
        objectArr: comments,
    });

    return (
        <Box className={classes.discussionSectionWrapper}>
            <Section>
                <NewCommentForm discussionId={discussionId} />
                {isLoading ? (
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <LoadingIndicator />
                    </Box>
                ) : (
                    <CommentList>
                        {paginatedComments.map((comment) => (
                            <CommentListItem
                                userId={comment.author.id}
                                name={comment.author.firstName + " " + comment.author.lastName}
                                datePosted={new Date(comment.createdAt)}
                                comment={comment.comment}
                            />
                        ))}
                    </CommentList>
                )}
                <Pagination
                    offset={offset}
                    limit={limit}
                    max={comments.length}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrevious}
                />
            </Section>
        </Box>
    );
};

DiscussionSection.propTypes = {
    comments: PropTypes.array,
    discussionId: PropTypes.string,
};

export { DiscussionSection };
