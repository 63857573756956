import React from "react";
import MyCheckinButton from "../../components/my-checkin-button";
import useRequest from "../../hooks/use-request";

function SignOutButton() {
    const { doRequest } = useRequest({
        url: "/api/auth/signout",
        method: "post",
        onSuccess: () => {
            window.location.reload();
        },
        body: {},
    });

    const handleSignOut = async () => {
        await doRequest();
    };

    return (
        <MyCheckinButton variant="outlined" onClick={handleSignOut}>
            Click here to log out
        </MyCheckinButton>
    );
}

export { SignOutButton };
