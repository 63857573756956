import React from "react";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useStyles } from "./divisions.styles";
import { useGetApi } from "../../hooks/api/use-get-api";
import { companyAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { Company } from "../../interfaces/company/company";

interface UnassignedUsersAlertProps {
    onClick: () => unknown;
    count: number;
}

const UnassignedUsersAlert = ({ count, onClick }: UnassignedUsersAlertProps) => {
    const classes = useStyles();

    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");

    return (
        <Alert severity="warning" variant="filled" className={classes.unassignedUsersAlert}>
            {count} users are not assigned to a {company?.organizationalUnitName?.singular || "Division"}.{" "}
            <Link style={{ color: "#000", textDecoration: "underline", cursor: "pointer" }} onClick={onClick}>
                Click here
            </Link>{" "}
            to resolve.
        </Alert>
    );
};

UnassignedUsersAlert.propTypes = {
    onClick: PropTypes.func,
    count: PropTypes.number,
};

export { UnassignedUsersAlert };
