import React from "react";
import { MenuItem, Select } from "@material-ui/core";

function YearSelector(props) {
    const { year, yearsArray, handleSelect, className } = props;

    const renderYearOptions = () => {
        return yearsArray ? (
            yearsArray.map((yearOption) => (
                <MenuItem value={yearOption} key={yearOption}>
                    {yearOption}
                </MenuItem>
            ))
        ) : (
            <MenuItem value={year}>{year}</MenuItem>
        );
    };

    return (
        <Select
            style={{ marginRight: 8, fontSize: 18, fontWeight: 600 }}
            value={year}
            onChange={handleSelect}
            className={className}
        >
            {renderYearOptions()}
        </Select>
    );
}

export default YearSelector;
