import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: theme.spacing(5),
        fontSize: 14,
        fontWeight: 600,
        textTransform: "uppercase",
        textAlign: "center",
    },
}));
