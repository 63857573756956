import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

interface TextEditorProps {
    value?: string;
    name: string;
    onChange: (content: string) => void;
}

const apiKey = "ys6tabw6tnibqi5s5w1qangn5wldajrltg6lhxfjghvos91w";
const editorHeight = 500;
const isMenuBarEnabled = false;

const plugins = [
    "advlist autolink lists link image charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount",
];
const toolbar =
    "undo redo | formatselect | bold italic underline strikethrough subscript superscript | fontselect fontsizeselect | alignleft aligncenter alignright | bullist numlist outdent indent | help";
const contextMenu = false;

const TextEditor = ({ value = "", name, onChange }: TextEditorProps) => {
    return (
        <Editor
            apiKey={apiKey}
            initialValue=""
            init={{
                height: editorHeight,
                menubar: isMenuBarEnabled,
                plugins,
                toolbar,
                contextmenu: contextMenu,
            }}
            textareaName={name}
            value={value}
            onEditorChange={onChange}
        />
    );
};

TextEditor.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export { TextEditor };
