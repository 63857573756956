import { useMutation } from "react-query";
import { CRUD } from "../../util/api/interfaces/crud";

interface CreateOptions<T> {
    preCreate?: (params: Partial<T>) => unknown;
    onSuccess?: (data: T) => any;
    onError?: (err: unknown) => any;
}

export const useCreateApi = <T>(api: CRUD, key: string, options?: CreateOptions<T>) => {
    const createMutationFn = (params: Partial<T>) => api.create(params);
    const createMutation = useMutation<T, unknown, Partial<T>>(key, createMutationFn, {
        onMutate: options?.preCreate,
        onSuccess: options?.onSuccess,
        onError: options?.onError,
    });

    const create = (params: Partial<T>) => createMutation.mutate(params);
    const { data, status } = createMutation;

    return {
        create,
        data,
        status,
    };
};
