import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { GradientHr } from "./gradient-hr";

interface CertificateHeadingProps {
    name: string;
}

const styles = StyleSheet.create({
    wrapper: {
        marginTop: 42,
    },
    uppercase: {
        textTransform: "uppercase",
    },
    centerText: {
        textAlign: "center",
    },
    h1: {
        fontSize: 48,
        fontWeight: 600,
    },
    h2: {
        fontSize: 24,
    },
    h3: {
        fontSize: 14,
    },
    h4: {
        fontSize: 12,
    },
    name: {
        fontFamily: "MonsieurLaDoulaise",
        fontSize: 64,
        letterSpacing: "2px",
    },
});

const CertificateHeading = ({ name }: CertificateHeadingProps) => {
    return (
        <View style={styles.wrapper} fixed>
            <Text style={[styles.uppercase, styles.centerText, styles.h1]}>Certificate</Text>
            <Text style={[styles.uppercase, styles.centerText, styles.h2]}>Of Achievement</Text>
            <Text style={[styles.uppercase, styles.centerText, styles.h3, { marginTop: 6 }]}>Presented To</Text>
            <Text style={[styles.centerText, styles.name]}>{name}</Text>
            <Text style={[styles.uppercase, styles.centerText, styles.h4]}>
                For demonstrated excellence in attaining the following achievements
            </Text>
            <GradientHr style={{ margin: "auto", marginTop: 8, width: 530, marginBottom: 12 }} />
        </View>
    );
};

CertificateHeading.propTypes = {
    name: PropTypes.string,
};

export { CertificateHeading };
