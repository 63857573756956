import React from "react";
import PriorityActionSection from "../priority-action-section";
import Section from "../section";

const PriorityActionsSection = () => {
    return (
        <Section>
            <PriorityActionSection />
        </Section>
    );
};

export default PriorityActionsSection;
