import { useGoals } from "../queries/use-goals";
import { useQueryStatus } from "../use-query-status";
import { useQueryState } from "./use-query-state";

export const useGoalState = (params) => {
    const goalQuery = useGoals(params);
    const goals = useQueryState(goalQuery, "goals");
    const queryStatus = useQueryStatus(goalQuery);

    return {
        goals,
        ...queryStatus,
    };
};
