import { useState } from "react";

export const usePagination = ({
    objectArr,
    limit = 5,
    offset = 0,
}: {
    objectArr: any[];
    limit?: number;
    offset?: number;
}) => {
    const [pagination, setPagination] = useState<{
        limit: number;
        offset: number;
    }>({
        limit,
        offset,
    });

    const onClickNext = () => {
        setPagination((pagination) => ({
            offset: pagination.offset + pagination.limit,
            limit: pagination.limit,
        }));
    };

    const onClickPrevious = () => {
        setPagination((pagination) => ({
            offset: pagination.offset - pagination.limit,
            limit: pagination.limit,
        }));
    };

    const paginatedArray = objectArr.filter(
        (obj, index) => index >= pagination.offset && index < pagination.limit + pagination.offset
    );

    return {
        limit: pagination.limit,
        offset: pagination.offset,
        paginatedArray,
        onClickNext,
        onClickPrevious,
    };
};
