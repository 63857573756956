import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(4),
    },
    box: {
        height: 38,
        borderWidth: 1,
        borderColor: "#e0e0e1",
        borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        fontSize: 14,
        fontWeight: 500,
    },
}));
