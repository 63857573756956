import React, { useEffect, useState } from "react";
import {
    Breadcrumbs,
    Card,
    CardActions,
    CircularProgress,
    Container,
    Grid,
    Link,
    makeStyles,
    Typography,
} from "@material-ui/core";
import PageTitle from "../../components/page-title";
import { Link as RouterLink, useParams } from "react-router-dom";
import { jotAPI } from "../../util/api";
import MyCheckinButton from "../../components/my-checkin-button";
import { urls } from "../../config/urls";
import { Jot } from "../../interfaces/jot";
import { DeleteJotButton } from "../../components/jots/delete-jot-button";
import { useGetApi } from "../../hooks/api/use-get-api";
import { queryKeys } from "../../hooks/api/config/query-keys";

interface ViewJotParams {
    id: string;
}

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: theme.spacing(5),
    },
    breadcrumbLink: {
        color: theme.palette.neutral.dark,
        "&:visited": {
            color: theme.palette.neutral.dark,
        },
    },
    flexEnd: {
        display: "flex",
        justifyContent: "flex-end",
    },
    entryBody: {
        width: 768,
    },
}));

const ViewJot = () => {
    const classes = useStyles();
    const { id } = useParams<ViewJotParams>();
    const [jot, setJot] = useState<Jot>({
        id: "",
        title: "",
        body: "",
        user: "",
        createdAt: new Date(),
        updatedAt: new Date(),
    });
    const getJotQuery = useGetApi<Jot>(jotAPI, queryKeys.jot.get, id);

    useEffect(() => {
        if (getJotQuery.data) {
            setJot(getJotQuery.data);
        }
    }, [getJotQuery.data]);

    return (
        <Container>
            <PageTitle>Jots Log</PageTitle>
            <Card className={classes.contentContainer}>
                <Breadcrumbs separator={">"}>
                    <Link
                        component={RouterLink}
                        to={urls.employee.viewAllEntries.baseUrl}
                        color="primary"
                        className={classes.breadcrumbLink}
                    >
                        Jots
                    </Link>
                    <Typography>View Entry</Typography>
                </Breadcrumbs>
                {getJotQuery.status === "loading" ? (
                    <Grid container justify="center" style={{ margin: "64px auto" }}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid container item xs={12} justify="center" style={{ marginTop: 16 }}>
                            <Typography variant="h5">{jot.title}</Typography>
                        </Grid>
                        <hr
                            style={{ border: "none", borderTop: "1px solid #929497", marginTop: 16, marginBottom: 32 }}
                        />
                        <Grid container justify="center">
                            <Grid item className={classes.entryBody}>
                                <div dangerouslySetInnerHTML={{ __html: jot.body }} />
                            </Grid>
                        </Grid>
                        <CardActions className={classes.flexEnd}>
                            <Typography variant="caption">{`Last edited ${new Date(
                                jot.updatedAt
                            ).toLocaleDateString()} at ${new Date(jot.updatedAt).toLocaleTimeString()}`}</Typography>
                            <DeleteJotButton id={jot.id} />
                            <MyCheckinButton
                                variant="outlined"
                                color="primary"
                                component={RouterLink}
                                to={`${urls.employee.editEntry.baseUrl}/${jot.id}`}
                            >
                                Edit
                            </MyCheckinButton>
                        </CardActions>
                    </>
                )}
            </Card>
        </Container>
    );
};

export default ViewJot;
