import { Avatar as MuiAvatar, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { API } from "../util/api";

type AvatarProps =
    | {
          id: string;
          url?: never;
          diameter?: number;
          borderWidth?: number;
          className?: string;
      }
    | {
          id?: never;
          url: string;
          diameter?: number;
          borderWidth?: number;
          className?: string;
      };

interface Props {
    borderWidth: number;
    diameter: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.neutral.main,
        border: `solid ${theme.palette.neutral.light}`,
        borderWidth: (props: Props) => props.borderWidth,
        boxSizing: "border-box",
        filter: "drop-shadow(0px 3px 4px rgba(0,0,0,0.14))",
        borderRadius: (props: Props) => props.diameter / 2,
        height: (props: Props) => props.diameter,
        width: (props: Props) => props.diameter,
    },
}));

function Avatar({ id, diameter = 150, borderWidth = 5, className = "", url }: AvatarProps) {
    const styleProps = {
        diameter,
        borderWidth,
    };
    const classes = useStyles(styleProps);
    const imageQuery = useQuery(
        ["fetchProfileImage", id],
        () => {
            return id ? API.getProfileImage(id) : null;
        },
        { enabled: !!id }
    );

    useEffect(() => {
        if (imageQuery.isSuccess) {
        }
    });

    const getAvatarSrc = () => {
        if (url) {
            return url;
        }
        return imageQuery.isSuccess ? imageQuery.data.toString() : "";
    };

    return <MuiAvatar src={getAvatarSrc()} className={classes.root + " " + className} />;
}

export default Avatar;
