import React, { useState } from "react";
import { useDialog } from "../../hooks/use-dialog";
import ListCardItem from "../list-card-item";
import ConfirmDismissDialog from "../reminders-card/confirm-dismiss-dialog";
import ReminderListItem from "../reminders-card/reminder-list-item";
import { Takeaway } from "../../interfaces/takeaway";
import { Card, CardHeader, makeStyles } from "@material-ui/core";
import { ShowOpenRemindersButton } from "./show-open-reminders-button";
import { OpenRemindersDialog } from "./open-reminders-dialog";

interface RemindersCardProps {
    remindersArr: Takeaway[];
}

const useStyles = makeStyles({
    root: {
        padding: "16px 24px",
    },
    list: {
        fontSize: 18,
        padding: 0,
        margin: "24px",
    },
});

const RemindersCard = ({ remindersArr = [] }: RemindersCardProps) => {
    const classes = useStyles();
    const confirmDismissDialog = useDialog({});
    const showOpenRemindersDialog = useDialog({});
    const [selectedID, setSelectedID] = useState("");

    const handleDismissDialogOpen = (id: string) => {
        setSelectedID(id);
        confirmDismissDialog.handleOpen();
    };

    const getReminderListItem = (reminder: Takeaway) => {
        const { id, description } = reminder;
        return <ReminderListItem id={id} description={description} onDismiss={() => handleDismissDialogOpen(id)} />;
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                title="Reminders"
                titleTypographyProps={{ style: { fontSize: 24 } }}
                style={{ padding: 0 }}
                action={
                    <ShowOpenRemindersButton
                        onClick={showOpenRemindersDialog.handleOpen}
                        style={{ marginTop: 8, marginRight: 8 }}
                        badgeContent={remindersArr.length}
                    />
                }
            />
            <ol className={classes.list}>
                {[0, 1, 2].map((index) => (
                    <ListCardItem key={`reminders-card-${index}`}>
                        {remindersArr[index] ? getReminderListItem(remindersArr[index]) : ""}
                    </ListCardItem>
                ))}
            </ol>
            <ConfirmDismissDialog
                id={selectedID}
                isOpen={confirmDismissDialog.isOpen}
                handleClose={confirmDismissDialog.handleClose}
            />
            <OpenRemindersDialog
                isOpen={showOpenRemindersDialog.isOpen}
                onClose={showOpenRemindersDialog.handleClose}
                reminders={remindersArr}
            />
        </Card>
    );
};

export default RemindersCard;
