import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";

export type CellBackgroundColor = "primary" | "success" | "neutral" | undefined;

interface CellProps {
    children?: React.ReactNode;
    color?: CellBackgroundColor;
}

const Cell = ({ children, color }: CellProps) => {
    const classes = useStyles();

    const getColorClass = () => {
        switch (color) {
            case "primary":
                return classes.backgroundPrimary;
            case "success":
                return classes.backgroundSuccess;
            case "neutral":
                return classes.backgroundNeutral;
        }
    };

    return (
        <Grid container item className={`${classes.box} ${getColorClass()}`}>
            {children}
        </Grid>
    );
};

Cell.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(["primary", "success", "neutral", undefined]),
};

export { Cell };
