import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./division.styles";

interface UserListProps {
    children: React.ReactNode;
}

const UserList = ({ children }: UserListProps) => {
    const classes = useStyles();

    if (React.Children.count(children) === 0) {
        return (
            <Box style={{ width: "100%" }}>
                <Typography className={classes.emptyStateText}>No users found.</Typography>
            </Box>
        );
    }

    return <Box style={{ width: "100%" }}>{children}</Box>;
};

UserList.propTypes = {
    children: PropTypes.node,
};

export { UserList };
