import { Card, Container, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import PageTitle from "../components/page-title";
import AccountInfoTab from "../components/profile/account-info-tab";
import ProfileImageTab from "../components/profile/profile-image-tab";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: `${theme.spacing(7)}px 0`,
    },
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        border: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(3),
        borderRadius: 5,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: "unset",
    },
    tabPanel: {
        flexGrow: 1,
    },
}));

function Profile() {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container>
            <PageTitle>Profile</PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container justify="center">
                    <Grid item className={classes.tabRoot} md={9}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            className={classes.tabs}
                            indicatorColor="primary"
                        >
                            <Tab label="Account Info" id="vertical-tab-0" />
                            <Tab label="Profile Image" id="vertical-tab-1" />
                        </Tabs>
                        <AccountInfoTab index={0} value={tabValue} />
                        <ProfileImageTab index={1} value={tabValue} />
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export default Profile;
