import React from "react";
import { EmployeeAppContext } from "../context/employee-app-context";

// const getYearsArray = (userCreatedDate) => {
//     const years = [];
//     const createdYear = userCreatedDate.getFullYear();
//     for (let i = createdYear; i <= new Date().getFullYear(); i++) {
//         years.push(i);
//     }
//     return years;
// };

export function EmployeeAppProvider({ children, userID = null }) {
    // let { user } = useAuthState();
    // const [userInfo, setUserInfo] = useState({
    //     createdAt: new Date(),
    // });
    // const [year, setYear] = useState(new Date().getFullYear());
    // const [yearsArray] = useState(getYearsArray(new Date(user.createdAt)));
    // const userQuery = useQuery(["getUser", userID], () => {
    //     if (userID) {
    //         return API.getUser(userID);
    //     }
    // });
    //
    // useEffect(() => {
    //     if (userID && userQuery.isSuccess) {
    //         setUserInfo(userQuery.data);
    //     } else {
    //         setUserInfo(user);
    //     }
    // }, [userID, user, userQuery]);

    // const meetingQuery = useQuery(["fetchMeetings", year, userInfo.id], () => {
    //     return API.getMeetings({ user: userInfo.id, year });
    // });
    // const goalQuery = useQuery(["fetchGoals", year, userInfo.id], () => {
    //     return API.getGoals({ user: userInfo.id, year });
    // });
    //
    // const coachQuery = useQuery(["fetchCoaches", userInfo.id], () => {
    //     return API.getCoaches(userInfo.coaches);
    // });
    //
    // const priorityActionQuery = useQuery(["fetchPriorityActions", year, userInfo.id], () => {
    //     return API.getPriorityActions({ user: userInfo.id, year });
    // });
    //
    // const takeawayQuery = useQuery(["fetchTakeaways", year, userInfo.id], () => {
    //     return API.getTakeaways({ user: userInfo.id, year });
    // });
    //
    // const companyQuery = useQuery("fetchCompany", () => {
    //     return API.getCompany();
    // });
    //
    // const resultQuery = useQuery(["fetchResults", year, userInfo.id], () => {
    //     return API.getResults({ user: userInfo.id, year });
    // });

    //const trackedItemRequest
    // const [state, setState] = useState({
    // year,
    // setYear,
    // yearsArray,
    // user: userInfo,
    // queries: {
    //     meetingQuery,
    //     goalQuery,
    //     coachQuery,
    //     priorityActionQuery,
    //     takeawayQuery,
    //     companyQuery,
    //     resultQuery,
    // },
    // });

    // useEffect(() => {
    //     setState({
    //         year,
    //         setYear,
    //         yearsArray,
    //         user: userInfo,
    //         queries: {
    //             meetingQuery,
    //             goalQuery,
    //             coachQuery,
    //             priorityActionQuery,
    //             takeawayQuery,
    //             companyQuery,
    //             resultQuery,
    //         },
    //     });
    // }, [
    //     userInfo,
    //     meetingQuery,
    //     goalQuery,
    //     coachQuery,
    //     priorityActionQuery,
    //     takeawayQuery,
    //     companyQuery,
    //     resultQuery,
    //     year,
    //     yearsArray,
    // ]);

    const state = {};

    return <EmployeeAppContext.Provider value={state}>{children}</EmployeeAppContext.Provider>;
}
