import { useEffect, useState } from "react";
import { User } from "../interfaces/user";
import { AxiosClient } from "../util/api/classes/axios-client";
import { userAPI } from "../util/api";
import { useDateTimeFormat } from "./use-date-time-format";

export const useAutodetectDateTimeFormat = () => {
    const [user, setUser] = useState<User | null>(null);
    const [updated, setUpdated] = useState<boolean>(false);
    const { locale, timeZone } = useDateTimeFormat();

    const fetchCurrentUser = async () => {
        const response = await new AxiosClient().get("/api/auth/currentuser", {});
        setUser(response.currentUser);
    };

    useEffect(() => {
        fetchCurrentUser();
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }

        const updateUser = async () => {
            if (!user) {
                return;
            }
            await userAPI.update(
                {
                    settings: {
                        dateTimeFormat: {
                            locale,
                            timeZone,
                        },
                    },
                },
                user.id
            );
        };

        if (!user?.settings?.dateTimeFormat?.autoDetected) {
            updateUser();
            fetchCurrentUser();
            setUpdated(true);
            return;
        }

        const isNewLocation = (): boolean | undefined => {
            if (!user) {
                return;
            }
            return locale !== user.settings.dateTimeFormat.locale || timeZone !== user.settings.dateTimeFormat.timeZone;
        };

        if (isNewLocation() && !updated) {
            updateUser();
            fetchCurrentUser();
            setUpdated(true);
        }
    }, [user, updated, locale, timeZone]);
};
