import { Grid } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import { useStyles } from "./styles";
import { Meeting } from "../../../interfaces/meeting";
import { StatusCell } from "./cell/status-cell";
import { useFilledMeetings, useSortedMeetings } from "./hooks";
import { BlankCell } from "./cell/blank-cell";
import { HeaderCell } from "./cell/header-cell";
import { monthArr } from "../config";

interface SnapshotGridProps {
    meetings: Meeting[];
}

const SnapshotGrid = ({ meetings }: SnapshotGridProps) => {
    const classes = useStyles();
    const { url } = useRouteMatch();
    const sortedMeetings = useSortedMeetings(meetings);
    const sortedFilledMeetings = useFilledMeetings(sortedMeetings);

    const getLink = (year: number, month: number) => {
        if (url.startsWith("/employee/")) {
            return `monthly-check-in/${year}/${month}`;
        } else if (url.startsWith("/coach/")) {
            return `${url}/monthly-check-in/${year}/${month}`;
        }
    };

    return (
        <Grid container className={classes.gridContainer}>
            {sortedFilledMeetings.map((meeting, index) => (
                <Grid item xs={1} key={`snapshot-grid-${index}`}>
                    <HeaderCell>{monthArr[index]}</HeaderCell>
                    {meeting ? (
                        <StatusCell status={meeting.status} link={getLink(meeting.year, meeting.month)} />
                    ) : (
                        <BlankCell />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};
SnapshotGrid.propTypes = {
    meetings: PropTypes.array.isRequired,
};

export default SnapshotGrid;
