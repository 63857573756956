import { Card, Container, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddGoalDialog from "../components/add-goal-dialog";
import MonthSelector from "../components/month-selector";
import PageTitle from "../components/page-title";
import MyCheckinButton from "../components/my-checkin-button";
import TrackedItemRow from "../components/tracked-item-row";
import YearSelector from "../components/year-selector";
import { useAuthState } from "../hooks/use-auth-state";
import { useMonths } from "../hooks/use-months";
import { useYear } from "../hooks/use-year";
import { API } from "../util/api";

const useStyles = makeStyles((theme) => ({
    actionContainer: {
        padding: "16px 24px",
        marginBottom: theme.spacing(4),
    },
    trackedItemRow: {
        marginBottom: theme.spacing(4),
    },
}));

function MonthlyTracker(props) {
    const classes = useStyles();
    const { user } = useAuthState();
    const { year, yearsArray, setYear } = useYear(user);
    const months = useMonths({ user, year: parseInt(year) });
    const [month, setMonth] = useState(new Date().getMonth());
    const [addGoalDialogOpen, setAddGoalDialogOpen] = useState(false);
    const [trackedItems, setTrackedItems] = useState([]);
    const queryClient = useQueryClient();
    const trackedItemQuery = useQuery(["fetchTrackedItems", user.id, year, month], () => {
        return API.findTrackedItems({ user: user.id, year, month });
    });
    const trackedItemMutation = useMutation(
        (trackedItem) => {
            return API.createTrackedItem(trackedItem);
        },
        {
            onSuccess: () => queryClient.invalidateQueries("fetchTrackedItems"),
        }
    );

    useEffect(() => {
        setMonth(months[months.length - 1]?.index);
    }, [months]);

    useEffect(() => {
        if (trackedItemQuery.isSuccess) {
            const { trackedItems } = trackedItemQuery.data;
            setTrackedItems(trackedItems);
        } else {
            setTrackedItems([]);
        }
    }, [trackedItemQuery]);

    const handleYearSelect = (event) => {
        const { value } = event.target;
        setYear(value);
    };

    const handleMonthSelect = (event) => {
        const { value } = event.target;
        setMonth(value);
    };

    const openAddGoalDialog = () => {
        setAddGoalDialogOpen(true);
    };

    const closeAddGoalDialog = () => {
        setAddGoalDialogOpen(false);
    };

    const handleSaveGoal = (goal) => {
        trackedItemMutation.mutate({ ...goal, month, year });
    };

    return (
        <Container>
            <PageTitle>Monthly Tracker</PageTitle>
            <Card className={classes.actionContainer}>
                <Grid container justify="space-between">
                    <Grid item>
                        <MonthSelector month={month} monthsArr={months} handleSelect={handleMonthSelect} />
                        <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleYearSelect} />
                    </Grid>
                    <Grid item>
                        <MyCheckinButton
                            variant="contained"
                            color="primary"
                            onClick={openAddGoalDialog}
                            disabled={trackedItems.length >= 3}
                        >
                            + New Key Commitment
                        </MyCheckinButton>
                    </Grid>
                </Grid>
                <AddGoalDialog open={addGoalDialogOpen} onClose={closeAddGoalDialog} onSave={handleSaveGoal} />
            </Card>
            {trackedItems?.map((trackedItem) => (
                <TrackedItemRow
                    trackedItem={trackedItem}
                    className={classes.trackedItemRow}
                    key={`tracked-item-${trackedItem.id}`}
                />
            ))}
        </Container>
    );
}

export default MonthlyTracker;
