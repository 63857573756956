import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import TermsAndConditions from "../legal/terms-and-conditions";
import PropTypes from "prop-types";

function TermsAndConditionsDialog({ isOpen, onClose }) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Terms & Conditions</DialogTitle>
            <DialogContent>
                <TermsAndConditions />
            </DialogContent>
        </Dialog>
    );
}

TermsAndConditionsDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default TermsAndConditionsDialog;
