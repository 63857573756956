import { isAfter, isBefore } from "date-fns";
import { Meeting } from "../interfaces/meeting";

interface CompareByDateFunction {
    (date: Date, dateToCompare: Date): boolean;
}

const MEETING_STATUS_COMPLETE = "complete";
const MEETING_STATUS_SCHEDULED = "scheduled";

export const useMeetingLookupFunctions = () => {
    const isEmptyArray = (array: Meeting[]) => {
        return array && array.length === 0;
    };

    const getMeetingDate = (meeting: Meeting | null) => {
        if (!meeting) {
            return null;
        }
        return new Date(meeting.date);
    };

    const getCompleteMeetings = (meetings: Meeting[]) => {
        return meetings.filter((meeting) => meeting.status === MEETING_STATUS_COMPLETE);
    };

    const getScheduledMeetings = (meetings: Meeting[]) => {
        return meetings.filter((meeting) => meeting.status === MEETING_STATUS_SCHEDULED);
    };

    const compareMeetingsByDate = (
        meeting: Meeting | null,
        meetingToCompare: Meeting,
        compareFn: CompareByDateFunction
    ) => {
        const meetingDate = getMeetingDate(meeting);
        const meetingToCompareDate = getMeetingDate(meetingToCompare);
        if (!meetingDate) {
            return meetingToCompare;
        }
        return compareFn(meetingDate, meetingToCompareDate!) ? meeting : meetingToCompare;
    };

    const findMeetingByDateComparison = (meetings: Meeting[], compareFn: CompareByDateFunction) => {
        let lastMeeting: Meeting | null = null;
        meetings.forEach((meeting) => {
            if (meeting.date) {
                lastMeeting = compareMeetingsByDate(lastMeeting, meeting, compareFn);
            }
        });
        return lastMeeting;
    };

    const getLastMeeting = (meetings: Meeting[]) => {
        if (isEmptyArray(meetings)) {
            return null;
        }
        const completeMeetings = getCompleteMeetings(meetings);
        return findMeetingByDateComparison(completeMeetings, isAfter);
    };

    const getLastMeetingDate = (meetings: Meeting[]) => {
        const lastMeeting = getLastMeeting(meetings);
        return getMeetingDate(lastMeeting);
    };

    const getNextMeeting = (meetings: Meeting[]) => {
        if (isEmptyArray(meetings)) {
            return null;
        }
        const scheduledMeetings = getScheduledMeetings(meetings);
        return findMeetingByDateComparison(scheduledMeetings, isBefore);
    };

    const getNextMeetingDate = (meetings: Meeting[]) => {
        const nextMeeting = getNextMeeting(meetings);
        return getMeetingDate(nextMeeting);
    };

    const getMeetingByMonthAndYear = (meetings: Meeting[], month: number, year: number) => {
        return meetings.find((meeting) => meeting.month === month && meeting.year === year);
    };

    const getMeetingsByStatus = (meetings: Meeting[], status: string) => {
        return meetings.filter((meeting) => meeting.status === status);
    };

    return {
        getNextMeeting,
        getNextMeetingDate,
        getLastMeeting,
        getLastMeetingDate,
        getMeetingByMonthAndYear,
        getMeetingsByStatus,
    };
};
