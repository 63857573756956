import React from "react";
import { View } from "@react-pdf/renderer";

interface LineAttrs {
    orientation: string;
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
    color: string;
}

const LineDecoration = () => {
    const blue = "#4fa9b3";
    const gold = "#ffb84c";
    const grey = "#929497";
    const length = 275;
    const thickness = 3;
    const radius = thickness / 2;
    const baseSpacing = 8;

    const getLine = (attrs: LineAttrs) => {
        return (
            <View
                style={{
                    position: "absolute",
                    width: attrs.orientation === "horizontal" ? length : thickness,
                    height: attrs.orientation === "vertical" ? length : thickness,
                    top: attrs.top || "auto",
                    right: attrs.right || "auto",
                    bottom: attrs.bottom || "auto",
                    left: attrs.left || "auto",
                    borderRadius: radius,
                    backgroundColor: attrs.color,
                }}
                fixed
            />
        );
    };

    const spacing = (scalar: number) => {
        return scalar * baseSpacing;
    };

    return (
        <View style={{ width: 842, height: 595, position: "absolute" }} fixed>
            {getLine({ orientation: "horizontal", top: spacing(1), right: spacing(3), color: blue })}
            {getLine({ orientation: "vertical", top: spacing(1), right: spacing(3), color: blue })}
            {getLine({ orientation: "horizontal", top: spacing(3), right: spacing(2), color: grey })}
            {getLine({ orientation: "vertical", top: spacing(3), right: spacing(2), color: grey })}
            {getLine({ orientation: "horizontal", top: spacing(2), right: spacing(1), color: gold })}
            {getLine({ orientation: "vertical", top: spacing(2), right: spacing(1), color: gold })}
            {getLine({ orientation: "horizontal", bottom: spacing(1), left: spacing(3), color: blue })}
            {getLine({ orientation: "vertical", bottom: spacing(1), left: spacing(3), color: blue })}
            {getLine({ orientation: "horizontal", bottom: spacing(3), left: spacing(2), color: grey })}
            {getLine({ orientation: "vertical", bottom: spacing(3), left: spacing(2), color: grey })}
            {getLine({ orientation: "horizontal", bottom: spacing(2), left: spacing(1), color: gold })}
            {getLine({ orientation: "vertical", bottom: spacing(2), left: spacing(1), color: gold })}
        </View>
    );
};

export { LineDecoration };
