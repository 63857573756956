import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDeleteUser } from "../../hooks/deletes/use-delete-user";
import { useUserMutation } from "../../hooks/mutations/use-user-mutation";
import usePermissions from "../../hooks/use-permissions";
import DateFormat from "../date-format";
import MyCheckinButton from "../my-checkin-button";
import TabPanel from "./tab-panel";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600,
    },
    description: {
        marginLeft: 24,
        fontWeight: 400,
        marginBottom: 16,
    },
}));

function AccountTab(props) {
    const { index, value, user } = props;
    const permissions = usePermissions();
    const classes = useStyles();
    const queryClient = useQueryClient();
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [registrationSent, setRegistrationSent] = useState(false);
    const [deleteSuccessful, setDeleteSuccessful] = useState(false);
    const [restoreSuccessful, setRestoreSuccessful] = useState(false);
    const invalidateQueries = () => {
        queryClient.invalidateQueries("fetchUsers");
        queryClient.invalidateQueries("fetchTeam");
        queryClient.invalidateQueries("getUser");
    };
    const deleteUserMutation = useDeleteUser({
        onSuccess: () => {
            setDeleteSuccessful(true);
            invalidateQueries();
        },
    });
    const userMutation = useUserMutation({
        onSuccess: () => {
            setRestoreSuccessful(true);
            invalidateQueries();
        },
    });

    const resetSuccessMessages = () => {
        setDeleteSuccessful(false);
        setRestoreSuccessful(false);
    };

    const handleSendPasswordReset = async () => {
        const response = await axios.post("/api/auth/forgotpassword", { email: user.email });
        if (response.status === 200) {
            setPasswordResetSent(true);
        }
    };

    const handleSendRegistrationEmail = async () => {
        const response = await axios.post("/api/auth/sendregistrationemail", { email: user.email });
        if (response.status === 200) {
            setRegistrationSent(true);
        }
    };

    const handleDeleteUser = () => {
        resetSuccessMessages();
        deleteUserMutation.mutate(user.id);
    };

    const handleRestoreUser = () => {
        resetSuccessMessages();
        userMutation.mutate({ id: user.id, isDeleted: false });
    };

    return (
        <TabPanel index={index} value={value}>
            <Grid container style={{ padding: 48 }}>
                <Grid container item>
                    <Typography className={classes.title}>Username (Email)</Typography>
                </Grid>
                <Grid container item>
                    <Typography className={classes.description}>{user.email}</Typography>
                </Grid>
                <Grid container item>
                    <Typography className={classes.title}>Last Login</Typography>
                </Grid>
                <Grid container item>
                    <Typography className={classes.description}>
                        {user.lastLogin ? <DateFormat date={user.lastLogin} /> : "--User has not logged in yet--"}
                    </Typography>
                </Grid>
                <Grid container item>
                    <Typography className={classes.title}>Status</Typography>
                </Grid>
                <Grid container item>
                    <Typography className={classes.description}>{user.isLocked ? "Locked" : "Active"}</Typography>
                </Grid>
                <Grid container item>
                    <MyCheckinButton variant="outlined" onClick={handleSendPasswordReset}>
                        Send Password Reset
                    </MyCheckinButton>{" "}
                    {passwordResetSent && <Alert severity="success">Password reset sent</Alert>}
                </Grid>
                {user.registrationToken && (
                    <Grid container item style={{ marginTop: 16 }}>
                        <MyCheckinButton variant="outlined" onClick={handleSendRegistrationEmail}>
                            Send Registration Email
                        </MyCheckinButton>{" "}
                        {registrationSent && <Alert severity="success">Registration email sent</Alert>}
                    </Grid>
                )}
                {permissions.isAdmin && !user.isDeleted && (
                    <Grid container item style={{ marginTop: 16 }}>
                        <MyCheckinButton variant="outlined" onClick={handleDeleteUser}>
                            Delete User
                        </MyCheckinButton>{" "}
                        {deleteSuccessful && <Alert severity="success">User deleted</Alert>}
                    </Grid>
                )}
                {permissions.isAdmin && user.isDeleted && (
                    <Grid container item style={{ marginTop: 16 }}>
                        <MyCheckinButton variant="outlined" onClick={handleRestoreUser}>
                            Restore User
                        </MyCheckinButton>{" "}
                        {restoreSuccessful && <Alert severity="success">User restored</Alert>}
                    </Grid>
                )}
            </Grid>
        </TabPanel>
    );
}

AccountTab.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default AccountTab;
