import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import MyCheckinButton from "../my-checkin-button";

interface DeleteJotConfirmationDialogProps {
    isOpen: boolean;
    onClose: (isConfirmed: boolean) => void;
}

const DeleteJotConfirmationDialog = ({ isOpen, onClose }: DeleteJotConfirmationDialogProps) => {
    const handleDelete = () => onClose(true);
    const handleCancel = () => onClose(false);

    return (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <Typography>This Jot will be permanently deleted.</Typography>
            </DialogContent>
            <DialogActions>
                <MyCheckinButton color="primary" name="cancel" onClick={handleCancel}>
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton color="primary" variant="outlined" name="confirm" onClick={handleDelete}>
                    Delete
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

DeleteJotConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export { DeleteJotConfirmationDialog };
