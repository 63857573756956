import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUsersState } from "../hooks/states/use-users-state";
import MonthlyCheckIn from "./monthly-check-in";

function ViewMonthlyCheckIn() {
    const { userId } = useParams();
    const { users } = useUsersState({ id: userId });
    const [user, setUser] = useState();

    useEffect(() => {
        if (users.length > 0) {
            setUser(users[0]);
        }
    }, [users]);

    return user ? <MonthlyCheckIn viewingAs="coach" user={user} /> : <CircularProgress />;
}

export default ViewMonthlyCheckIn;
