import React, { ChangeEvent, useEffect, useState } from "react";
import MyCheckinButton from "../../my-checkin-button";
import { MeetingStatus } from "../../../types/meeting-status";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";
import { Tooltip } from "@material-ui/core";

interface MeetingSummaryWrapUpButtonProps {
    meetingStatus: MeetingStatus;
    onClick: (event?: ChangeEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
}

const meetingNotStartedTooltipText = "Please click Start Meeting above to enable the Meeting Summary & Wrap-Up";

function MeetingSummaryWrapUpButton({ meetingStatus, onClick }: MeetingSummaryWrapUpButtonProps) {
    const { isMeetingStarted } = useMonthlyCheckInState();
    const [isDisabled, setIsDisabled] = useState(meetingStatus !== "scheduled" || !isMeetingStarted);

    useEffect(() => {
        setIsDisabled(meetingStatus !== "scheduled" || !isMeetingStarted);
    }, [isMeetingStarted, meetingStatus]);

    return (
        <Tooltip title={isDisabled ? meetingNotStartedTooltipText : ""}>
            <div>
                <MyCheckinButton variant="contained" color="primary" disabled={isDisabled} onClick={onClick}>
                    Meeting Summary & Wrap-Up
                </MyCheckinButton>
            </div>
        </Tooltip>
    );
}

export { MeetingSummaryWrapUpButton };
