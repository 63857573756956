import React from "react";
import PropTypes from "prop-types";
import { QueryClientProvider, useQueryClient } from "react-query";
import { MasterPlanPdf } from "../pdf/master-plan/master-plan-pdf";
import { PdfDownloadButton } from "../pdf/pdf-download-button";

interface DownloadMasterPlanPdfButtonProps {
    userId: string;
    firstName: string;
    lastName: string;
    year: number;
}

const DownloadMasterPlanPdfButton = ({ userId, firstName, lastName, year }: DownloadMasterPlanPdfButtonProps) => {
    const queryClient = useQueryClient();

    return (
        <PdfDownloadButton fileName={`${firstName}_${lastName}_${year}_Master_Plan.pdf`}>
            <QueryClientProvider client={queryClient}>
                <MasterPlanPdf userId={userId} year={year} />
            </QueryClientProvider>
        </PdfDownloadButton>
    );
};

DownloadMasterPlanPdfButton.propTypes = {
    userId: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
};

export { DownloadMasterPlanPdfButton };
