import { useState } from "react";

export function useDialog({ initialOpenState = false }) {
    const [isOpen, setIsOpen] = useState(initialOpenState);
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return {
        isOpen,
        handleOpen,
        handleClose,
    };
}
