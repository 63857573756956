import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Goal } from "../../../interfaces/goal";
import { PriorityAction } from "../../../interfaces/priority-action";
import { Objective } from "../../../interfaces/objective";
import { Heading } from "../heading";
import { Section } from "../section";
import { useMasterPlanTemplate } from "../../../hooks/use-master-plan-template";
import { objectiveDescriptions } from "../../../config/objective-descriptions";
import { MasterPlanCategory } from "./master-plan-category";
import { PdfBlockLetter } from "../pdf-block-letter";
import { useMontserrat } from "../../../hooks/pdf/use-montserrat";
import { PdfDoc } from "../pdf-doc";
import { PdfPageTitle } from "../pdf-page-title";
import { PdfPageSubtitle } from "../pdf-page-subtitle";

interface MasterPlanPdfDocProps {
    objective?: Objective;
    goals?: Goal[];
    priorityActions?: PriorityAction[];
    year: number;
    employeeName: string;
}

const styles = StyleSheet.create({
    sectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    category: {
        marginLeft: 16,
        marginTop: 16,
        width: "100%",
    },
});

function MasterPlanPdfDoc({ goals = [], priorityActions = [], year, objective, employeeName }: MasterPlanPdfDocProps) {
    useMontserrat();
    const masterPlanTemplate = useMasterPlanTemplate();
    const categoryIndexArray = [0, 1, 2, 3];

    const renderObjective = () => {
        return <Text>{getObjective()}</Text>;
    };

    const getObjective = () => {
        return objective?.description || "(No objective entered)";
    };

    const filterByCategory = (category: number) => (item: Goal | PriorityAction) => item.category === category;

    return (
        <PdfDoc>
            <View>
                <PdfPageTitle>The Plan {year}</PdfPageTitle>
                <PdfPageSubtitle>{employeeName}</PdfPageSubtitle>

                <Section>
                    <Heading>{objectiveDescriptions.masterPlan.description}</Heading>
                    {renderObjective()}
                </Section>

                {categoryIndexArray.map((categoryIndex) => (
                    <View style={styles.sectionWrapper} key={`category-${categoryIndex}`} wrap={false}>
                        <PdfBlockLetter>{masterPlanTemplate[categoryIndex].name}</PdfBlockLetter>
                        <MasterPlanCategory
                            heading={masterPlanTemplate[categoryIndex].masterPlan.description}
                            subheading={masterPlanTemplate[categoryIndex].masterPlan.priorityActionDescription}
                            goals={goals.filter(filterByCategory(categoryIndex))}
                            priorityActions={priorityActions.filter(filterByCategory(categoryIndex))}
                            style={styles.category}
                        />
                    </View>
                ))}
            </View>
        </PdfDoc>
    );
}

MasterPlanPdfDoc.propTypes = {
    objective: PropTypes.object,
    goals: PropTypes.array.isRequired,
    priorityActions: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    employeeName: PropTypes.string.isRequired,
};

export { MasterPlanPdfDoc };
