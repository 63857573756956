export const avatarProps = {
    diameter: 48,
    borderWidth: 1,
};

export const dialogMaxWidth = "sm";

export const confirmButtonProps = {
    variant: "contained",
    color: "primary",
};

export const dialogTitle = "Unassigned Users";
export const cancelButtonText = "Cancel";
export const confirmButtonText = "Save & Close";
