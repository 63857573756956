import React, { ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MyCheckinTextField from "../my-checkin-text-field";
import { useMaxLength } from "../../hooks/use-max-length";
import { useUpdateApi } from "../../hooks/api/use-update-api";
import { Objective } from "../../interfaces/objective";
import { objectiveAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useQueryClient } from "react-query";
import MyCheckinButton from "../my-checkin-button";

interface CompleteObjectiveDialogProps {
    isOpen: boolean;
    onClose: () => any;
    id: number | string;
}

const CompleteObjectiveDialog = ({ isOpen, onClose, id }: CompleteObjectiveDialogProps) => {
    const [accomplishment, setAccomplishment] = useState("");
    const { handleFocus, handleBlur, error, helperText } = useMaxLength({ value: accomplishment });
    const queryClient = useQueryClient();
    const updateObjective = useUpdateApi<Objective>(objectiveAPI, queryKeys.objective.update, {
        onSuccess: () => queryClient.invalidateQueries(queryKeys.objective.find),
    });

    const handleClose = () => {
        onClose();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setAccomplishment(value);
    };

    const handleCancel = () => {
        setAccomplishment("");
        onClose();
    };

    const handleConfirm = () => {
        updateObjective.update(id, { isAccomplished: true, accomplishment });
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Objective Accomplished</DialogTitle>
            <DialogContent>
                <MyCheckinTextField
                    multiline={true}
                    rows={4}
                    value={accomplishment}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoFocus
                    label="Please describe what was accomplished"
                    fullWidth
                    helperText={helperText}
                    error={error}
                />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton color="primary" onClick={handleCancel}>
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton color="primary" variant="outlined" onClick={handleConfirm}>
                    Confirm
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

CompleteObjectiveDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.string,
};

export { CompleteObjectiveDialog };
