import React, { ChangeEvent } from "react";
import PropTypes from "prop-types";
import { Grid, MenuItem } from "@material-ui/core";
import { SearchFilter } from "../config";
import MyCheckinTextField from "../../my-checkin-text-field";
import { useAuthState } from "../../../hooks/use-auth-state";
import { useFindApi } from "../../../hooks/api/use-find-api";
import { Division } from "../../../interfaces/division";
import { divisionAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface CoachTeamMeetingFiltersProps {
    value: SearchFilter;
    onChange: (event: ChangeEvent<HTMLInputElement>) => unknown;
}

function CoachTeamMeetingFilters({ value, onChange }: CoachTeamMeetingFiltersProps) {
    const { user } = useAuthState();
    const findDivisionsResponse = useFindApi<Division>(
        divisionAPI,
        queryKeys.division.find,
        { company: user?.company },
        "divisions",
        { enabled: !!user }
    );

    const divisions = findDivisionsResponse.data.result;

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
                <MyCheckinTextField
                    id="check-in-status-select"
                    select
                    label="Check-In Status"
                    value={value.checkInStatus}
                    onChange={onChange}
                    fullWidth
                    variant={undefined}
                    name="checkInStatus"
                >
                    <MenuItem key="check-in-status-all" value="all">
                        Show All
                    </MenuItem>
                    <MenuItem key="check-in-status-completed" value="completed">
                        Completed
                    </MenuItem>
                    <MenuItem key="check-in-status-pending" value="pending">
                        Pending Submission
                    </MenuItem>
                </MyCheckinTextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <MyCheckinTextField
                    id="division-select"
                    select
                    label="Division"
                    value={value.division}
                    onChange={onChange}
                    fullWidth
                    variant={undefined}
                    name="division"
                >
                    <MenuItem key="check-in-status-all" value="all">
                        Show All
                    </MenuItem>
                    {divisions.map((division) => (
                        <MenuItem key={division.id} value={division.id}>
                            {division.name}
                        </MenuItem>
                    ))}
                </MyCheckinTextField>
            </Grid>
        </Grid>
    );
}

CoachTeamMeetingFilters.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default CoachTeamMeetingFilters;
