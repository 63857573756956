import { MasterPlanTemplate } from "../interfaces/master-plan-template";

const defaultCheckInResultsSubheading = "Goals prefill here from your Master Plan";

const defaults = {
    goals: {
        goalDescription: "Key Results. When these are complete, I will have accomplished my Objective:",
        checkInSubheading: defaultCheckInResultsSubheading,
    },
    obstacles: {
        goalDescription: "Critical issues, obstacles or communications I must address to achieve my Goal",
        checkInSubheading: defaultCheckInResultsSubheading,
    },
    anyIdeas: {
        goalDescription: "Any ideas to make the work easier, faster or more profitable?",
        checkInSubheading: defaultCheckInResultsSubheading,
    },
    levelUp: {
        goalDescription: 'My "next level" career growth goals:',
        checkInSubheading: defaultCheckInResultsSubheading,
    },
    priorityActions: {
        masterPlan: {
            description: "The Plan of Action:",
        },
        checkIn: {
            description: "The Plan of Action:",
            subheading: "Priority Actions prefill here from your Master Plan",
        },
    },
    takeaways: {
        description: "Takeaways",
        subheading: "Takeaways are comments and recommendations entered by your Coach at the Check-In Meeting.",
    },
};

export const masterPlanDescriptions: MasterPlanTemplate = [
    {
        name: "Goal",
        masterPlan: {
            description: defaults.goals.goalDescription,
            subheading:
                "Each goal should include a number and a deadline date so it's clear what needs to get done and by when (measurable and verifiable).",
            priorityActionDescription: defaults.priorityActions.masterPlan.description,
        },
        checkIn: {
            goal: {
                description: defaults.goals.goalDescription,
                subheading: defaults.goals.checkInSubheading,
            },
            results: {
                description: "Results",
                subheading: "",
            },
            priorityAction: {
                description: defaults.priorityActions.checkIn.description,
                subheading: "Priority Actions from all sections prefill here",
            },
            takeaways: {
                description: defaults.takeaways.description,
                subheading: defaults.takeaways.subheading,
            },
        },
    },
    {
        name: "Obstacles",
        masterPlan: {
            description: defaults.obstacles.goalDescription,
            subheading: "",
            priorityActionDescription: defaults.priorityActions.masterPlan.description,
        },
        checkIn: {
            goal: {
                description: defaults.obstacles.goalDescription,
                subheading: "Obstacles prefill here from your Master Plan",
            },
            results: {
                description: "Resolutions & New Issues",
                subheading: "",
            },
            priorityAction: {
                description: defaults.priorityActions.checkIn.description,
                subheading: defaults.priorityActions.checkIn.subheading,
            },
            takeaways: {
                description: defaults.takeaways.description,
                subheading: defaults.takeaways.subheading,
            },
        },
    },
    {
        name: "Any Ideas",
        masterPlan: {
            description: defaults.anyIdeas.goalDescription,
            subheading: "",
            priorityActionDescription: defaults.priorityActions.masterPlan.description,
        },
        checkIn: {
            goal: {
                description: defaults.anyIdeas.goalDescription,
                subheading: "Ideas prefill here from your Master Plan",
            },
            results: {
                description: "Updates & New Ideas",
                subheading: "",
            },
            priorityAction: {
                description: defaults.priorityActions.checkIn.description,
                subheading: defaults.priorityActions.checkIn.subheading,
            },
            takeaways: {
                description: defaults.takeaways.description,
                subheading: defaults.takeaways.subheading,
            },
        },
    },
    {
        name: "Level-Up",
        masterPlan: {
            description: defaults.levelUp.goalDescription,
            subheading: "",
            priorityActionDescription: defaults.priorityActions.masterPlan.description,
        },
        checkIn: {
            goal: {
                description: defaults.levelUp.goalDescription,
                subheading: "Level-Up items prefill here from your Master Plan",
            },
            results: {
                description: "Updates & Thoughts",
                subheading: "",
            },
            priorityAction: {
                description: defaults.priorityActions.checkIn.description,
                subheading: defaults.priorityActions.checkIn.subheading,
            },
            takeaways: {
                description: defaults.takeaways.description,
                subheading: defaults.takeaways.subheading,
            },
        },
    },
];
