import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useDialog } from "../../../hooks/use-dialog";
import { closingParagraphs, dialogParagraphs, dialogTitle, dialogWidth } from "./config";
import ChecklistLinks from "./checklist-links";
import { CloseButton } from "./close-button";
import { DismissCheckbox } from "./dismiss-checkbox";
import { useCloseButton, useDismissCheckbox } from "./hooks";

interface IndexProps {
    userId: string;
    isCoach: boolean;
    isAdmin: boolean;
}

const NewUserWelcomeDialog = ({ userId, isCoach, isAdmin }: IndexProps) => {
    const dialog = useDialog({ initialOpenState: true });
    const dismissCheckbox = useDismissCheckbox();
    const closeButton = useCloseButton(dismissCheckbox.checked, userId, dialog.handleClose);

    //TODO - move this out to a separate component
    const paragraphMapFunction = (paragraph: string, index: number) => (
        <Typography
            gutterBottom
            align="center"
            key={`new-user-welcome-dialog-paragraph-${index}`}
            // Support for text formatting in the dialog paragraphs
            dangerouslySetInnerHTML={{ __html: paragraph }}
        />
    );

    return (
        <Dialog open={dialog.isOpen} onClose={closeButton.handleCloseButtonClick} maxWidth={dialogWidth} fullWidth>
            <DialogTitle style={{ textAlign: "center" }}>{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogParagraphs.map(paragraphMapFunction)}
                <ChecklistLinks isCoach={isCoach} isAdmin={isAdmin} />
                {closingParagraphs.map(paragraphMapFunction)}
            </DialogContent>
            <DialogActions>
                <DismissCheckbox checked={dismissCheckbox.checked} onChange={dismissCheckbox.handleChange} />
                <CloseButton onClick={closeButton.handleCloseButtonClick} />
            </DialogActions>
        </Dialog>
    );
};

NewUserWelcomeDialog.propTypes = {
    userId: PropTypes.string,
    isCoach: PropTypes.bool,
    isAdmin: PropTypes.bool,
};

export default NewUserWelcomeDialog;
