import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useAuthState } from "../hooks/use-auth-state";
import CoachNav from "./coach-nav";
import EmployeeNav from "./employee-nav";

const useStyles = makeStyles((theme) => ({
    subNav: {
        display: "flex",
        height: theme.spacing(7),
        flexGrow: 1,
        alignItems: "center",
        marginBottom: theme.spacing(8),
    },
    navLinkContainer: {
        height: theme.spacing(7),
        display: "flex",
        flexDirection: "row",
    },
}));

function SubNav(props) {
    const classes = useStyles();
    const { user } = useAuthState();
    const { coach = false, employee = false } = props;

    const isCoachAuthorized = () => {
        return user.permissions.isCoach || user.permissions.isAdmin;
    };

    const isAdmin = () => {
        return user.permissions.isAdmin;
    };

    const renderEmployeeNav = () => {
        return <EmployeeNav isCoachAuthorized={isCoachAuthorized()} />;
    };

    const renderCoachNav = () => {
        return <CoachNav isAdmin={isAdmin()} />;
    };

    return (
        <Box className={classes.subNav} boxShadow={3} bgcolor="neutral.main" color="neutral.light">
            {employee && renderEmployeeNav()}
            {coach && renderCoachNav()}
        </Box>
    );
}

export default SubNav;
