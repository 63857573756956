import React from "react";

interface ManageBillingFormProps {
    children: any;
    style?: React.CSSProperties;
}

export const manageBillingFormId = "subscription-end-warning-portal-session-form";

function ManageBillingForm({ children, style }: ManageBillingFormProps) {
    return (
        <form method="POST" action="/api/create-customer-portal-session" id={manageBillingFormId} style={style}>
            {children}
        </form>
    );
}

export { ManageBillingForm };
