import React, { useState } from "react";
import { Card, Container } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import { useStyles } from "./division.styles";
import { DivisionName } from "../../components/division/division-name";
import { UserList } from "../../components/division/user-list";
import { UserListItem } from "../../components/division/user-list-item";
import { User } from "../../interfaces/user";
import { divisionAPI, objectiveAPI, userAPI } from "../../util/api";
import { useFindApi } from "../../hooks/api/use-find-api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useGetApi } from "../../hooks/api/use-get-api";
import { Division as IDivision } from "../../interfaces/division";
import { Objective } from "../../interfaces/objective";
import { Pagination } from "../../components/division/pagination";
import { usePagination } from "../../hooks/use-pagination";
import { LoadingIndicator } from "../../components/loading-indicator";
import { useLocation } from "react-router-dom";
import { AddEmployeeButton } from "../../components/division/add-employee-button";
import { CoachActions } from "../../components/division/coach-actions";
import { useDialog } from "../../hooks/use-dialog";
import AddEmployeeDialog from "../../components/division/add-employee-dialog";
import { useUpdateApi } from "../../hooks/api/use-update-api";
import { useQueryClient } from "react-query";
import { DivisionOverflowMenu } from "../../components/divisions/division-overflow-menu";
import { DivisionOverflowMenuItem } from "../../components/divisions/division-overflow-menu-item";
import { useSortArray } from "../../hooks/use-sort-array";

interface DivisionProps {
    divisionId: string;
}

const Division = ({ divisionId }: DivisionProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedUserId, setSelectedUserId] = useState<string>("");
    const viewingAsCoach = useLocation().pathname.includes("coach");
    const addEmployeeDialog = useDialog({ initialOpenState: false });
    const getDivisionResult = useGetApi<IDivision>(divisionAPI, queryKeys.division.get, divisionId);
    const findUsersResult = useFindApi<User>(userAPI, queryKeys.user.find, { division: divisionId }, "users", {
        enabled: !!getDivisionResult.data,
    });
    const allUsersResult = useFindApi<User>(userAPI, queryKeys.user.find, { isDeleted: false }, "users", {
        enabled: viewingAsCoach,
    });
    const users = findUsersResult.data.result;
    const allUsers = allUsersResult.data.result;
    const findObjectivesResult = useFindApi<Objective>(
        objectiveAPI,
        queryKeys.objective.find,
        {
            //@ts-ignore
            user: users.map((user) => user.id),
            year: new Date().getFullYear(),
            isAccomplished: false,
        },
        "objectives",
        {
            enabled: users.length > 0,
        }
    );
    const queryClient = useQueryClient();
    const updateUser = useUpdateApi<User>(userAPI, queryKeys.user.update, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.user.get);
            queryClient.invalidateQueries(queryKeys.user.find);
            queryClient.invalidateQueries(queryKeys.division.get);
            queryClient.invalidateQueries(queryKeys.division.find);
        },
    });

    const sortedUsers = useSortArray<User>({ array: users, field: "lastName", order: "asc" });
    const sortedAllUsers = useSortArray<User>({ array: allUsers, field: "lastName", order: "asc" });

    const { limit, offset, onClickNext, onClickPrevious, paginatedArray: paginatedUsers } = usePagination({
        objectArr: sortedUsers,
    });

    const findObjective = ({ user }: { user: User }): Objective | undefined => {
        return findObjectivesResult.data.result?.find((objective) => objective.user === user.id);
    };

    const handleAddUserSave = async (userId: string) => {
        await updateUser.update(userId, { division: divisionId });
    };

    const handleOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, userId: string) => {
        setSelectedUserId(userId);
        setAnchorEl(event.currentTarget);
    };

    const handleRemoveFromDivision = async () => {
        //@ts-ignore
        await updateUser.update(selectedUserId, { division: "" });
        setAnchorEl(null);
    };

    return (
        <Container>
            <PageTitle>TeamShare</PageTitle>
            <Card className={classes.contentWrapper}>
                <DivisionName>
                    {getDivisionResult.status === "loading"
                        ? "Loading..."
                        : getDivisionResult.data?.name || "[Division Not Found]"}
                </DivisionName>
                {viewingAsCoach && (
                    <CoachActions>
                        <AddEmployeeButton onClick={addEmployeeDialog.handleOpen} />
                    </CoachActions>
                )}
                <UserList>
                    {findUsersResult.status === "loading" && <LoadingIndicator />}
                    {paginatedUsers.map((user) => (
                        <UserListItem
                            userId={user.id}
                            name={user.firstName + " " + user.lastName}
                            jobTitle={user.jobTitle || ""}
                            objective={findObjective({ user })?.description || "No objective entered."}
                            key={user.id}
                            showOverflow={viewingAsCoach}
                            onOverflowMenuClick={handleOverflowMenuClick}
                        />
                    ))}
                </UserList>
                <Pagination
                    offset={offset}
                    limit={limit}
                    max={users.length}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrevious}
                />
            </Card>
            <AddEmployeeDialog
                open={addEmployeeDialog.isOpen}
                onClose={addEmployeeDialog.handleClose}
                users={sortedAllUsers}
                onSave={handleAddUserSave}
            />
            <DivisionOverflowMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <DivisionOverflowMenuItem onClick={handleRemoveFromDivision}>
                    Remove from Division
                </DivisionOverflowMenuItem>
            </DivisionOverflowMenu>
        </Container>
    );
};

Division.propTypes = {};

export { Division };
