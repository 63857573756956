import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    navLink: {
        display: "flex",
        alignItems: "center",
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.neutral.light,
        padding: "0 32px",
        "&:hover": {
            textDecoration: "none",
            backgroundColor: "#ACADAF",
        },
    },
}));

function NavLink({ to, text, external = false }) {
    const classes = useStyles();

    return external ? (
        <Link className={classes.navLink} href={to} target="_blank">
            <span>{text}</span>
        </Link>
    ) : (
        <Link className={classes.navLink} component={RouterLink} to={to}>
            <span>{text}</span>
        </Link>
    );
}

NavLink.propTypes = {
    to: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
};

export default NavLink;
