import { CellBackgroundColor } from "./index";
import { MeetingStatus } from "../../../../types/meeting-status";

export const getColor = (status: MeetingStatus): CellBackgroundColor => {
    let color: CellBackgroundColor;
    switch (status) {
        case "submitted":
        case "scheduling":
        case "scheduled":
        case "returned":
            color = "primary";
            break;
        case "no meeting":
            color = "neutral";
            break;
        case "complete":
            color = "success";
            break;
        default:
            color = undefined;
    }

    return color;
};
