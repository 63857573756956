import React from "react";
import { useAuthState } from "../hooks/use-auth-state";
import AuthenticatedApp from "../authenticated-app";
import UnauthenticatedApp from "../unauthenticated-app";

function Home() {
    const { user } = useAuthState();
    return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export default Home;
