import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

interface KeyResultListProps {
    children?: React.ReactNode;
}

const KeyResultList = ({ children }: KeyResultListProps) => {
    if (React.Children.count(children) === 0) {
        return (
            <Typography align="center" style={{ width: "100%" }}>
                No Key Results found.
            </Typography>
        );
    }

    return <Box style={{ width: "100%" }}>{children}</Box>;
};

KeyResultList.propTypes = {
    children: PropTypes.node,
};

export { KeyResultList };
