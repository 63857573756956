import React from "react";
import { SubmissionNote as ISubmissionNote } from "../../../interfaces/submission-note";
import SubmissionNote from "../submission-note";
import { Box, makeStyles, Typography } from "@material-ui/core";

interface SubmissionNoteListProps {
    submissionNotes: ISubmissionNote[];
}

const useStyles = makeStyles((theme) => ({
    submissionNote: { marginBottom: theme.spacing(3) },
}));

function SubmissionNoteList({ submissionNotes }: SubmissionNoteListProps) {
    const classes = useStyles();

    const renderSubmissionNotes = () => {
        if (submissionNotes.length === 0) {
            return (
                <Typography align="center" gutterBottom>
                    No notes found
                </Typography>
            );
        }
        return submissionNotes.map((submissionNote: ISubmissionNote) => (
            <SubmissionNote
                authorName={submissionNote.authorName}
                note={submissionNote.note}
                date={submissionNote.createdAt}
                key={submissionNote.id}
                authorId={submissionNote.authorId}
                className={classes.submissionNote}
            />
        ));
    };

    return <Box>{renderSubmissionNotes()}</Box>;
}

export { SubmissionNoteList };
