import React from "react";
import PropTypes from "prop-types";
import { Box, MenuItem } from "@material-ui/core";
import { useStyles } from "./top-nav.styles";
import { Link as RouterLink } from "react-router-dom";

interface NotificationListItemProps {
    isUnread: boolean;
    children: React.ReactNode;
    link: string;
    onClick: () => unknown;
}

const NotificationListItem = ({ isUnread, children, link, onClick }: NotificationListItemProps) => {
    const classes = useStyles();

    const handleClick = () => {
        onClick();
    };

    return (
        <MenuItem
            className={classes.notificationListItem}
            style={isUnread ? {} : { background: "#f2f3f4" }}
            component={RouterLink}
            to={link}
            onClick={handleClick}
        >
            {isUnread ? <Box className={classes.newNotificationRectangle} /> : <Box className={classes.readSpacer} />}
            <Box className={classes.notificationContent}>{children}</Box>
        </MenuItem>
    );
};

NotificationListItem.propTypes = {
    isUnread: PropTypes.bool,
    children: PropTypes.node,
    link: PropTypes.string,
    onClick: PropTypes.func,
};

export { NotificationListItem };
