import { ContainerProps } from "./container-props";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
    sectionTitle: {
        fontSize: 36,
        fontWeight: 500,
        letterSpacing: "-2px",
    },
}));

export const TodoSectionTitle = ({ children }: ContainerProps) => {
    const classes = useStyles();

    return (
        <Typography variant="h2" className={classes.sectionTitle}>
            {children}
        </Typography>
    );
};
