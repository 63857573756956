import { API } from "./api-old";
import { CRUDEndpoint } from "./classes/crud-endpoint";
import { AxiosClient } from "./classes/axios-client";
import { ActivityRecordEndpoint } from "./classes/activity-record-endpoint";
import { DiscussionEndpoint } from "./classes/discussion-endpoint";

const httpClient = new AxiosClient();
const endpointUrls = {
    v1: {
        activityRecord: "/api/activityrecord",
        additionalResources: "/api/additionalresources",
        auth: "/api/auth",
        company: "/api/company",
        discussion: "/api/discussion",
        division: "/api/division",
        goal: "/api/goal",
        jot: "/api/jot",
        meeting: "/api/meeting",
        notification: "/api/notification",
        objective: "/api/objective",
        priorityAction: "/api/priorityaction",
        result: "/api/result",
        stripe: "/api/stripe",
        submissionNote: "/api/submissionnote",
        takeaway: "/api/takeaway",
        todo: "/api/todo",
        topDiscussionTopic: "/api/topdiscussiontopic",
        trackedItem: "/api/trackedItem",
        user: "/api/user",
    },
};

const goalAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.goal);
const jotAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.jot);
const meetingAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.meeting);
const priorityActionAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.priorityAction);
const resultAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.result);
const submissionNoteAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.submissionNote);
const takeawayAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.takeaway);
const trackedItemAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.trackedItem);
const userAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.user);
const activityRecordAPI = new ActivityRecordEndpoint({ httpClient });
const objectiveAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.objective);
const topDiscussionTopicAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.topDiscussionTopic);
const divisionAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.division);
const discussionAPI = new DiscussionEndpoint({ httpClient });
const companyAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.company);
const notificationAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.notification);
const additionalResourcesAPI = new CRUDEndpoint(httpClient, endpointUrls.v1.additionalResources);
const todoApi = new CRUDEndpoint(httpClient, endpointUrls.v1.todo);

export {
    API,
    goalAPI,
    jotAPI,
    meetingAPI,
    priorityActionAPI,
    resultAPI,
    submissionNoteAPI,
    takeawayAPI,
    trackedItemAPI,
    userAPI,
    activityRecordAPI,
    objectiveAPI,
    topDiscussionTopicAPI,
    divisionAPI,
    discussionAPI,
    companyAPI,
    notificationAPI,
    additionalResourcesAPI,
    todoApi,
};
