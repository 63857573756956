import React from "react";
import MyCheckinButton from "../../my-checkin-button";
import { useDialog } from "../../../hooks/use-dialog";
import { Box } from "@material-ui/core";
import ViewSubmissionNotesDialog from "../view-submission-notes-dialog";

interface ViewSubmissionNotesButtonProps {
    meetingId: string | undefined;
}

function ViewSubmissionNotesButton({ meetingId }: ViewSubmissionNotesButtonProps) {
    const submissionNoteDialog = useDialog({});
    const isDisabled = (): boolean => {
        return !meetingId;
    };

    return (
        <Box>
            <MyCheckinButton
                variant="contained"
                color="primary"
                disabled={isDisabled()}
                onClick={submissionNoteDialog.handleOpen}
            >
                View Submission Notes
            </MyCheckinButton>
            <ViewSubmissionNotesDialog
                isOpen={submissionNoteDialog.isOpen}
                onClose={submissionNoteDialog.handleClose}
                meetingId={meetingId}
            />
        </Box>
    );
}

export { ViewSubmissionNotesButton };
