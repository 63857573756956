import { Grid, MenuItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { API, companyAPI, divisionAPI } from "../../util/api";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";
import TabPanel from "./tab-panel";
import PropTypes from "prop-types";
import { User } from "../../interfaces/user";
import { useFindApi } from "../../hooks/api/use-find-api";
import { Division } from "../../interfaces/division";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useGetApi } from "../../hooks/api/use-get-api";
import { Company } from "../../interfaces/company/company";

interface EmployeeInfoTabProps {
    index: number;
    value: number;
    user: User;
}

function EmployeeInfoTab({ index, value, user }: EmployeeInfoTabProps) {
    const queryClient = useQueryClient();
    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");
    const [state, setState] = useState<User | null>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const userMutation: UseMutationResult<any, unknown, Partial<User>, unknown> = useMutation(
        (user) => {
            return API.updateUser(user);
        },
        {
            onSuccess: (data) => queryClient.setQueryData(["getUser", user.id], data),
        }
    );
    const findDivisionsResult = useFindApi<Division>(
        divisionAPI,
        queryKeys.division.find,
        { company: user.company },
        "divisions",
        { enabled: !!user }
    );
    const divisions = findDivisionsResult.data.result || [];

    useEffect(() => {
        if (user) {
            setState(user);
        }
    }, [user]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        //@ts-ignore
        setState({ ...state, [name]: value });
    };

    const validate = () => {
        if (!state) {
            return false;
        }
        if (!state.firstName) {
            setErrorMessage("First Name is required");
            return false;
        }
        if (!state.lastName) {
            setErrorMessage("Last Name is required");
            return false;
        }
        if (!state.email) {
            setErrorMessage("Email Address is required");
            return false;
        }
        return true;
    };

    const handleSave = () => {
        setErrorMessage("");
        if (!validate()) {
            return;
        }
        userMutation.mutate(state!);
    };

    return (
        <TabPanel index={index} value={value}>
            <Grid container style={{ padding: 48 }} spacing={6}>
                {errorMessage && (
                    <Grid item>
                        <Alert severity="warning">{errorMessage}</Alert>
                    </Grid>
                )}
                <Grid container item xs={12}>
                    <MyCheckinTextField
                        name="firstName"
                        value={state?.firstName}
                        label="First Name*"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <MyCheckinTextField
                        name="lastName"
                        value={state?.lastName}
                        label="Last Name*"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <MyCheckinTextField
                        name="email"
                        value={state?.email}
                        label="Email Address*"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <MyCheckinTextField
                        name="jobTitle"
                        value={state?.jobTitle}
                        label="Job Title"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <MyCheckinTextField
                        name="division"
                        select
                        label={company?.organizationalUnitName?.singular || "Division"}
                        fullWidth
                        value={state?.division}
                        onChange={handleChange}
                    >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {divisions.map((division) => (
                            <MenuItem key={division.id} value={division.id}>
                                {division.name}
                            </MenuItem>
                        ))}
                    </MyCheckinTextField>
                </Grid>
                <Grid container justify="flex-end">
                    <Grid item>
                        <MyCheckinButton variant="outlined" onClick={handleSave}>
                            Save
                        </MyCheckinButton>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    );
}

EmployeeInfoTab.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
};

export default EmployeeInfoTab;
