import React from "react";
import PropTypes from "prop-types";
import { Goal } from "../../../interfaces/goal";
import { PdfDoc } from "../pdf-doc";
import { useMontserrat } from "../../../hooks/pdf/use-montserrat";
import { LineDecoration } from "./line-decoration";
import { YearDisplay } from "./year-display";
import { CompanyName } from "./company-name";
import { CertificateHeading } from "./certificate-heading";
import { useMonsieurLaDoulaise } from "../../../hooks/pdf/use-monsieur-la-doulaise";
import { Achievements } from "./achievements";
import { Objective } from "../../../interfaces/objective";

interface MajorAchievementsPdfDocProps {
    achievements: Goal[];
    objectives: Objective[];
    year: number;
    companyName: string;
    employeeName: string;
}

interface BasicAchievement {
    description: string;
    isObjective: boolean;
}

const MajorAchievementsPdfDoc = ({
    achievements,
    objectives,
    year,
    companyName,
    employeeName,
}: MajorAchievementsPdfDocProps) => {
    useMontserrat();
    useMonsieurLaDoulaise();

    const combinedAchievements: BasicAchievement[] = [];

    objectives.forEach((objective) =>
        combinedAchievements.push({ description: objective.accomplishment, isObjective: true })
    );
    achievements.forEach((achievement) =>
        combinedAchievements.push({ description: achievement.accomplishment || "", isObjective: false })
    );

    return (
        <PdfDoc orientation="landscape" style={{ padding: 0, paddingBottom: 32 }}>
            <LineDecoration />
            <YearDisplay year={year} />
            <CertificateHeading name={employeeName} />
            <Achievements achievements={combinedAchievements} />
            <CompanyName>{companyName}</CompanyName>
        </PdfDoc>
    );
};

MajorAchievementsPdfDoc.propTypes = {
    achievements: PropTypes.array,
    year: PropTypes.number,
    companyName: PropTypes.string,
    employeeName: PropTypes.string,
};

export { MajorAchievementsPdfDoc };
