import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useStyles } from "./division.styles";

interface PaginationProps {
    offset: number;
    limit: number;
    max: number;
    onClickNext: () => unknown;
    onClickPrevious: () => unknown;
}

const Pagination = ({ offset, limit, max, onClickPrevious, onClickNext }: PaginationProps) => {
    const classes = useStyles();

    const isPreviousDisabled = offset === 0;
    const isNextDisabled = offset + limit >= max;

    const lowerBound = max ? offset + 1 : 0;
    const upperBound = isNextDisabled ? max : offset + limit;

    return (
        <Box className={classes.paginationWrapper}>
            <Typography>
                {lowerBound}-{upperBound} of {max}
            </Typography>
            <IconButton onClick={onClickPrevious} disabled={isPreviousDisabled}>
                <ChevronLeft />
            </IconButton>
            <IconButton onClick={onClickNext} disabled={isNextDisabled}>
                <ChevronRight />
            </IconButton>
        </Box>
    );
};

Pagination.propTypes = {
    offset: PropTypes.number,
    limit: PropTypes.number,
    max: PropTypes.number,
    onClickNext: PropTypes.func,
    onClickPrevious: PropTypes.func,
};

export { Pagination };
