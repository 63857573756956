import React, { useEffect, useRef, useState } from "react";
import { Button, ClickAwayListener, Grow, makeStyles, MenuList, Paper, Popper } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { KeyboardArrowDown } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    navMenu: {
        display: "flex",
        alignItems: "center",
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.neutral.light,
        padding: "0 32px",
        textTransform: "none",
        "&:hover": {
            textDecoration: "none",
            backgroundColor: "#ACADAF",
        },
    },
}));

function NavMenu({ text, children, to }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
                className={classes.navMenu}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                onMouseEnter={handleToggle}
                onMouseLeave={handleClose}
                component={to ? RouterLink : Button}
                to={to}
            >
                <span>{text}</span>
                <KeyboardArrowDown />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                onMouseLeave={handleClose}
                style={{ zIndex: 1000 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onClick={handleClose}>
                                    {children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default NavMenu;
