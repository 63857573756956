import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    divisionListItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "12px 16px",
        background: "#FFF",
        boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.14);",
        borderRadius: "4px",
        // margin: "24px 0px",
        alignSelf: "stretch",
        flexWrap: "wrap",
        gap: "48px",
    },
    avatarGroup: {
        display: "flex",
        flexGrow: 1,
        maxWidth: "192px",
        width: "192px",
    },
    avatar: {
        background: "#929497",
        width: "48px",
        height: "48px",
    },
    divisionName: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        maxWidth: "512px",
        // width: "512px",
        alignItems: "center",
    },
    userCount: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        maxWidth: "128px",
        width: "128px",
    },
    divisionActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        flexGrow: 1,
        gap: 12,
        maxWidth: "192px",
        width: "192px",
    },
    divisionActionButton: {
        width: "36px",
        height: "36px",
    },
    divisionText: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "24px",
    },
}));
