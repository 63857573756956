import React from "react";
import PropTypes from "prop-types";
import { Section } from "../section";
import { Heading } from "../heading";
import { List } from "../list";
import { ListItem } from "../list-item";
import { Goal } from "../../../interfaces/goal";
import { PriorityAction } from "../../../interfaces/priority-action";

interface CategoryProps {
    heading: string;
    subheading: string;
    goals: Goal[];
    priorityActions: PriorityAction[];
    style?: any;
}

const MasterPlanCategory = ({ heading, subheading, goals, priorityActions, style }: CategoryProps) => {
    const toListItemDescription = (goal: Goal | PriorityAction) => (
        <ListItem key={`goal-${goal.id}`}>{goal.description}</ListItem>
    );

    return (
        <Section style={style}>
            <Heading>{heading}</Heading>
            <List>{goals.map(toListItemDescription)}</List>
            <Heading>{subheading}</Heading>
            <List>{priorityActions.map(toListItemDescription)}</List>
        </Section>
    );
};

MasterPlanCategory.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    goals: PropTypes.array.isRequired,
    priorityActions: PropTypes.array.isRequired,
    style: PropTypes.object,
};

export { MasterPlanCategory };
