import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";
import PropTypes from "prop-types";

interface SectionTitleProps {
    children: React.ReactNode;
}

const SectionTitle = ({ children }: SectionTitleProps) => {
    const classes = useStyles();
    return <Typography className={classes.sectionTitle}>{children}</Typography>;
};

SectionTitle.propTypes = {
    children: PropTypes.node,
};

export { SectionTitle };
