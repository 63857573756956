import { useMeetings } from "../use-meetings";
import { useQueryStatus } from "../use-query-status";
import { useQueryState } from "./use-query-state";

export const useMeetingState = (params) => {
    const meetingQuery = useMeetings(params);
    const meetings = useQueryState(meetingQuery, "meetings");
    const queryStatus = useQueryStatus(meetingQuery);

    return {
        meetings,
        ...queryStatus,
    };
};
