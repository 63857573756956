import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, List, ListItem, makeStyles } from "@material-ui/core";
import BlockLetter from "../block-letter";
import ReminderListItem from "../reminders-card/reminder-list-item";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import { Takeaway } from "../../interfaces/takeaway";
import { useDialog } from "../../hooks/use-dialog";
import ConfirmDismissDialog from "../reminders-card/confirm-dismiss-dialog";

interface OpenRemindersProps {
    reminders: Takeaway[];
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(5),
    },
}));

const OpenReminders = ({ reminders }: OpenRemindersProps) => {
    const classes = useStyles();
    const masterPlanTemplate = useMasterPlanTemplate();
    const confirmDismissDialog = useDialog({});
    const [selectedId, setSelectedId] = useState("");

    const handleDismissDialogOpen = (id: string) => {
        setSelectedId(id);
        confirmDismissDialog.handleOpen();
    };

    const getRemindersByCategory = (category: number) => {
        return reminders.filter((reminder) => reminder.category === category);
    };

    return (
        <div style={{ width: "100%" }}>
            {masterPlanTemplate.map((category, index) => {
                const name = category.name;
                const reminders = getRemindersByCategory(index);

                return (
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.container}
                        key={`${category.name}-container-${index}`}
                    >
                        <Grid item xs={3}>
                            <BlockLetter title={name} disabled />
                        </Grid>
                        <Grid item xs={9}>
                            <List component="ol">
                                {reminders.map((reminder, index) => (
                                    <ListItem key={`${category.name}-list-item-${index}`}>
                                        <ReminderListItem
                                            description={`${index + 1}. ${reminder.description}`}
                                            onDismiss={() => handleDismissDialogOpen(reminder.id)}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                );
            })}
            <ConfirmDismissDialog
                id={selectedId}
                isOpen={confirmDismissDialog.isOpen}
                handleClose={confirmDismissDialog.handleClose}
            />
        </div>
    );
};

OpenReminders.propTypes = {
    reminders: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            user: PropTypes.string,
            month: PropTypes.number,
            year: PropTypes.number,
            category: PropTypes.number,
            description: PropTypes.string,
            isReminder: PropTypes.bool,
            isDismissed: PropTypes.bool,
        })
    ),
};

export { OpenReminders };
