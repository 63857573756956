export const useQueryStatus = (query) => {
    const { isLoading, isSuccess, isError, isIdle, error } = query;
    return {
        isLoading,
        isSuccess,
        isError,
        isIdle,
        error,
    };
};
