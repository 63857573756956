import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px 32px",
        width: "100%",
        gap: 24,
    },
    title: {
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "29px",
    },
}));
