import React from "react";
import PropTypes from "prop-types";
import { Document, Page, StyleSheet } from "@react-pdf/renderer";

interface PdfDocProps {
    children: React.ReactNode;
    orientation?: "portrait" | "landscape";
    style?: any;
}

const styles = StyleSheet.create({
    wrapper: {
        fontFamily: "Montserrat",
        padding: 24,
        fontSize: 12,
    },
});

const PdfDoc = ({ children, orientation = "portrait", style }: PdfDocProps) => {
    return (
        <Document>
            <Page style={[styles.wrapper, style]} orientation={orientation}>
                {children}
            </Page>
        </Document>
    );
};

PdfDoc.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PdfDoc };
