import { useQuery } from "react-query";
import { API } from "../../util/api";

export function useCurrentUser(options) {
    return useQuery(
        ["fetchCurrentUser"],
        () => {
            return API.getCurrentUser();
        },
        options
    );
}
