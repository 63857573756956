import { Endpoint } from "../interfaces/endpoint";
import { HttpClient } from "../interfaces/http-client";
import { ActivityRecord } from "../../../interfaces/activity-record/activity-record";
import { ActivityRecordCRUD } from "../interfaces/activity-record-crud";
import { ActivityRecordRequiredAttrs } from "../interfaces/activity-record-required-attrs";

export class ActivityRecordEndpoint implements ActivityRecordCRUD, Endpoint {
    httpClient: HttpClient;
    url = "/api/activityrecord";

    constructor({ url, httpClient }: { url?: string; httpClient: HttpClient }) {
        this.httpClient = httpClient;
        if (url) {
            this.url = url;
        }
    }

    create(attrs: ActivityRecordRequiredAttrs): Promise<ActivityRecord> {
        return this.httpClient.post(this.url, attrs);
    }

    get(params: ActivityRecordRequiredAttrs): Promise<ActivityRecord> {
        return this.httpClient.get(this.url, params);
    }

    update(params: ActivityRecordRequiredAttrs, data: Partial<ActivityRecord>): Promise<any> {
        const updateUrl = this.createUpdateUrl(params);
        return this.httpClient.patch(updateUrl, data);
    }

    private createUpdateUrl({ user, month, year }: ActivityRecordRequiredAttrs) {
        return `${this.url}/${user}/${year}/${month}`;
    }
}
