import React from "react";
import Avatar from "../../avatar";
import { avatarBorderWidth, avatarDiameter } from "../config";
import { Box } from "@material-ui/core";
import { useStyles } from "../division.styles";
import { UserInfo } from "./user-info";
import PropTypes from "prop-types";

interface UserProps {
    userId: string;
    name: string;
    jobTitle: string;
}

const UserSection = ({ userId, name, jobTitle }: UserProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.user}>
            <Avatar id={userId} diameter={avatarDiameter} borderWidth={avatarBorderWidth} />
            <UserInfo name={name} jobTitle={jobTitle} />
        </Box>
    );
};

UserSection.propTypes = {
    userId: PropTypes.string,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
};

export { UserSection };
