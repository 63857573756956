interface FillGoalArrayArgs {
    array: any[];
    object: unknown;
    maxSize?: number;
}

export const useFillGoalArray = ({ array = [], object, maxSize = 3 }: FillGoalArrayArgs) => {
    const fillArray = (array: any[]) => {
        for (let i = array.length; i < maxSize; i++) {
            array.push(object);
        }
        return array;
    };

    return fillArray(array);
};
