import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    listItem: {
        marginBottom: 16,
        fontSize: 16,
    },
}));

function ListCardItem(props) {
    const classes = useStyles();
    const { className, ...others } = props;

    return (
        <li className={classes.listItem + " " + props.className} {...others}>
            {props.children}
        </li>
    );
}

export default ListCardItem;
