export const useProgressPercentage = (progress: number, goal: number) => {
    const isProgressAndGoalValid = () => {
        return progress && goal;
    };

    const isProgressGreaterThanGoal = () => {
        return progress > goal;
    };

    if (!isProgressAndGoalValid()) {
        return 0;
    }
    if (isProgressGreaterThanGoal()) {
        return 100;
    }
    return Math.round((progress / goal) * 100);
};
