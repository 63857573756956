import { useObjectives } from "../queries/use-objectives";
import { useQueryState } from "./use-query-state";
import { useQueryStatus } from "../use-query-status";
import { Objective } from "../../interfaces/objective";

type UseObjectiveStateParams =
    | {
          id?: never;
          user: string;
          category: number;
          year: number;
          isAccomplished?: boolean;
      }
    | {
          id: string;
          user?: never;
          category?: never;
          year?: never;
          isAccomplished?: never;
      };

export const useObjectiveState = (params: UseObjectiveStateParams) => {
    const objectiveQuery = useObjectives(params);
    const objectives: Objective[] = useQueryState(objectiveQuery, "objectives");
    const queryStatus = useQueryStatus(objectiveQuery);

    return {
        objectives,
        ...queryStatus,
    };
};
