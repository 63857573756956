import { useQueryKey } from "../queries/use-query-key";
import { useMutation } from "react-query";
import { CRUD } from "../../util/api/interfaces/crud";

interface UpdateApiParams<T> {
    id: string | number;
    update: Partial<T>;
}

interface UpdateOptions<T> {
    preUpdate?: (params: UpdateApiParams<T>) => unknown;
    onSuccess?: (data: T) => any;
    onError?: (err: unknown) => any;
}

export const useUpdateApi = <T>(api: CRUD, key: string, options: UpdateOptions<T> = {}) => {
    const queryKey = useQueryKey(key);
    const updateMutationFn = (params: UpdateApiParams<T>) => api.update(params.update, params.id);

    const updateMutation = useMutation<T, unknown, UpdateApiParams<T>>(queryKey, updateMutationFn, {
        onMutate: options.preUpdate,
        onSuccess: options.onSuccess,
        onError: options.onError,
    });

    const update = (id: number | string, params: Partial<T>) => updateMutation.mutate({ id, update: params });
    const { data, status } = updateMutation;

    return {
        update,
        data,
        status,
    };
};
