import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Avatar from "./avatar";

const useStyles = makeStyles((theme) => ({
    coachCardRow: {
        marginTop: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    coachInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    coachName: {
        marginTop: 8,
        fontSize: 18,
        fontWeight: "normal",
    },
    coachEmail: {
        fontSize: 14,
    },
}));

function CoachCardRow({ coach }) {
    const classes = useStyles();

    return (
        <Box className={classes.coachCardRow}>
            <Avatar id={coach?.id} diameter={91} borderWidth={2} />
            <Box className={classes.coachInfo}>
                <Typography className={classes.coachName}>
                    {coach?.firstName} {coach?.lastName}
                </Typography>
                <Typography className={classes.coachEmail}>{coach?.email}</Typography>
            </Box>
        </Box>
    );
}

export default CoachCardRow;
