import React from "react";
import { Card } from "@material-ui/core";
import { useStyles } from "../division.styles";
import { UserSection } from "./user-section";
import { ObjectiveSection } from "./objective-section";
import { Actions } from "./actions";
import PropTypes from "prop-types";

interface UserListItemProps {
    userId: string;
    name: string;
    jobTitle: string;
    objective: string;
    showOverflow: boolean;
    onOverflowMenuClick: (event: React.MouseEvent<HTMLButtonElement>, userId: string) => unknown;
}

const UserListItem = ({ userId, name, jobTitle, objective, showOverflow, onOverflowMenuClick }: UserListItemProps) => {
    const classes = useStyles();

    const handleOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onOverflowMenuClick(event, userId);
    };

    return (
        <Card className={classes.userRow}>
            <UserSection userId={userId} name={name} jobTitle={jobTitle} />
            <ObjectiveSection objective={objective} />
            <Actions userId={userId} onOverflowMenuClick={handleOverflowMenuClick} showOverflow={showOverflow} />
        </Card>
    );
};

UserListItem.propTypes = {
    userId: PropTypes.string,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    objective: PropTypes.string,
};

export { UserListItem };
