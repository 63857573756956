import React from "react";
import PropTypes from "prop-types";
import ContainedIconButton from "../../contained-icon-button";
import { ChevronRight, MoreVert } from "@material-ui/icons";
import { Box, IconButton } from "@material-ui/core";
import { useStyles } from "../division.styles";
import { Link as RouterLink, useLocation } from "react-router-dom";

interface ActionsProps {
    userId: string;
    showOverflow: boolean;
    onOverflowMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
}

const Actions = ({ userId, showOverflow, onOverflowMenuClick }: ActionsProps) => {
    const classes = useStyles();
    const { pathname } = useLocation();

    const getLink = () => {
        return pathname.includes("coach") ? "/coach/teamshare/" : "/employee/teamshare/";
    };

    return (
        <Box className={classes.actions}>
            <ContainedIconButton component={RouterLink} to={getLink() + userId} disabled={!userId}>
                <ChevronRight />
            </ContainedIconButton>
            {showOverflow && (
                <IconButton onClick={onOverflowMenuClick}>
                    <MoreVert />
                </IconButton>
            )}
        </Box>
    );
};

Actions.propTypes = {
    userId: PropTypes.string,
};

export { Actions };
