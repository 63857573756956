import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { myCheckinTheme } from "./theme/my-checkin-theme";

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={myCheckinTheme}>
            <CssBaseline />
            <App />
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
