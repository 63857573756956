import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "../division.styles";
import { objectiveSectionTitle } from "../config";

interface ObjectiveSectionProps {
    objective: string;
}

const ObjectiveSection = ({ objective }: ObjectiveSectionProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.objectiveText}>
            <Typography className={classes.objectiveTitle}>{objectiveSectionTitle}</Typography>
            <Typography className={classes.objectiveContent}>{objective}</Typography>
        </Box>
    );
};

ObjectiveSection.propTypes = {
    objective: PropTypes.string,
};

export { ObjectiveSection };
