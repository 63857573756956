import React from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { useProgressPercentage } from "../hooks/use-progress-percentage";

interface Props {
    size: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: (props: Props) => props.size,
    },
    progressCircle: {
        position: "absolute",
    },
}));

interface TrackedItemProgressCircleProps {
    progress: number;
    goal: number;
    size?: number;
    thickness?: number;
    className?: string;
}

function TrackedItemProgressCircle({
    progress,
    goal,
    size = 150,
    thickness = 2,
    className = "",
}: TrackedItemProgressCircleProps) {
    const classes = useStyles({ size });
    const progressPercentage = useProgressPercentage(progress, goal);

    return (
        <Box className={`${classes.root} ${className}`}>
            <CircularProgress
                variant="determinate"
                value={100}
                style={{ color: "#c6c7c8" }}
                size={size}
                className={classes.progressCircle}
                thickness={thickness}
            />
            <CircularProgress
                variant="determinate"
                value={progressPercentage}
                style={{ color: "#000" }}
                size={size}
                className={classes.progressCircle}
                thickness={thickness}
            />
            <Box style={{ fontSize: 20 }}>{progressPercentage}%</Box>
        </Box>
    );
}

export default TrackedItemProgressCircle;
