import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.neutral.dark,
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

function ContainedIconButton(props) {
    const { children, ...others } = props;
    const classes = useStyles();

    return (
        <IconButton className={classes.root} {...others}>
            {children}
        </IconButton>
    );
}

export default ContainedIconButton;
