import { Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

function DateFormat(props) {
    const { date, prefixText = "", suffixText = "", ...others } = props;

    const getDateString = (date) => {
        return date.toLocaleDateString();
    };

    const getTimeString = (date) => {
        return date.toLocaleTimeString(undefined, { timeStyle: "short" });
    };

    const formatDateString = (date) => {
        const dateInstance = new Date(date);
        const dateString = getDateString(dateInstance);
        const timeString = getTimeString(dateInstance);
        return `${dateString} at ${timeString}`;
    };

    return date ? (
        <Typography {...others}>
            {prefixText}
            {formatDateString(date)}
            {suffixText}
        </Typography>
    ) : (
        <Typography />
    );
}

DateFormat.propTypes = {
    date: PropTypes.object,
    prefixText: PropTypes.string,
    suffixText: PropTypes.string,
};

export default DateFormat;
