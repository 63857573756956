import React from "react";
import { Typography } from "@material-ui/core";
import DateFormat from "../../date-format";

interface LastLoginDateProps {
    date: Date | string | undefined;
}

function LastLoginDate({ date }: LastLoginDateProps) {
    const getLastLoginDate = () => {
        if (!date) {
            return (
                <Typography variant="caption" style={{ fontStyle: "italic" }}>
                    -- Has not logged in --
                </Typography>
            );
        }
        const dateInstance = new Date(date);
        return <DateFormat prefixText="Last Login: " date={dateInstance} />;
    };

    return getLastLoginDate();
}

export default LastLoginDate;
