import React from "react";
import PropTypes from "prop-types";
import MyCheckinButton from "../my-checkin-button";
import { addEmployeeButtonText } from "./config";

interface AddEmployeeButtonProps {
    onClick: () => unknown;
}

const AddEmployeeButton = ({ onClick }: AddEmployeeButtonProps) => {
    return (
        <MyCheckinButton variant="contained" color="primary" onClick={onClick}>
            {addEmployeeButtonText}
        </MyCheckinButton>
    );
};

AddEmployeeButton.propTypes = {
    onClick: PropTypes.func,
};

export { AddEmployeeButton };
