import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { usePriorityActions } from "../../hooks/queries/use-priority-actions";
import { useMonthName } from "../../hooks/use-month-name";
import CategoryTitle from "./category-title";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import { GoalList } from "../goal-list/goal-list";
import { GoalListItem } from "../goal-list/goal-list-item";
import { useMonthlyCheckInState } from "../../hooks/use-monthly-check-in-state";

function PriorityActionSection() {
    const { user, month, year, meeting } = useMonthlyCheckInState();
    const userID = user?.id || "";
    const category = 0;

    const priorityActionsParams =
        meeting?.submission.goals.length && meeting?.submission.priorityActions.length
            ? { id: meeting?.submission.priorityActions }
            : { user: userID, year, isAccomplished: false };

    const { status, data } = usePriorityActions(priorityActionsParams);
    const nextMonthName = useMonthName(month + 1);
    const masterPlanTemplate = useMasterPlanTemplate();

    return (
        <Box>
            <CategoryTitle
                title={masterPlanTemplate[category].checkIn.priorityAction.description + " " + nextMonthName}
                subheading={masterPlanTemplate[category].checkIn.priorityAction.subheading}
                style={{
                    marginBottom: 24,
                }}
            />
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <GoalList style={{ marginTop: 0, marginBottom: 0 }}>
                    {data.priorityActions.map((priorityAction, index) => (
                        <GoalListItem key={`priority-action-${category}-${month}-${year}-${index}`}>
                            {priorityAction.description}
                        </GoalListItem>
                    ))}
                </GoalList>
            )}
        </Box>
    );
}

export default PriorityActionSection;
