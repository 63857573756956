import { useQuery } from "react-query";
import { API } from "../../util/api";

const fetchCompany = (params) => {
    return API.getCompany(params);
};

export function useCompany(params) {
    const queryKey = ["fetchCompany"];
    return useQuery(queryKey, () => fetchCompany());
}
