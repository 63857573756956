import { MeetingUpdateType } from "../types/meeting-update-type";

export const useMeetingUpdateType = () => {
    return {
        getUserFriendlyString: (meetingUpdateType: MeetingUpdateType) => {
            switch (meetingUpdateType) {
                case "results-submitted":
                    return "Results Submitted";
                case "meeting-scheduled":
                    return "Meeting Scheduled";
                case "results-returned":
                    return "Results Returned";
                case "meeting-scheduling":
                    return "Scheduling";
                case "no-meeting":
                    return "No Meeting";
                case "meeting-date-changed":
                    return "Meeting Date Changed";
                case "confirmed-by-employee":
                    return "Confirmed By Employee";
                case "confirmed-by-coach":
                    return "Confirmed By Coach";
                case "meeting-complete":
                    return "Meeting Complete";
            }
        },
    };
};
