import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUsersState } from "../hooks/states/use-users-state";
import { CircularProgress } from "@material-ui/core";
import CompletedItems from "./completed-items";
import { User } from "../interfaces/user";

interface ViewCompletedGoalsParams {
    userId: string;
}

function ViewCompletedItems() {
    const { userId }: ViewCompletedGoalsParams = useParams();
    const { users }: { users: User[] } = useUsersState({ id: userId });
    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (users.length > 0) {
            setUser(users[0]);
        }
    }, [users]);

    if (!user) {
        return <CircularProgress />;
    } else {
        return <CompletedItems viewingAs="coach" user={user} />;
    }
}

export { ViewCompletedItems };
