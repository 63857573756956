import { Box, CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useTakeawayMutation } from "../../hooks/mutations/use-takeaway-mutation";
import { useTakeaways } from "../../hooks/queries/use-takeaways";
import AutoSaveTextField from "../autosave-text-field";
import { useQueryClient } from "react-query";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import CategoryTitle from "./category-title";
import { GoalList } from "../goal-list/goal-list";
import { GoalListItem } from "../goal-list/goal-list-item";
import { useFillGoalArray } from "../../hooks/use-fill-goal-array";
import { useMonthlyCheckInState } from "../../hooks/use-monthly-check-in-state";
import { maxTakeaways } from "../../config/app-config";

function TakeawaySection({ category, disabled }) {
    const { user, year, month } = useMonthlyCheckInState();
    const userID = user?.id || "";
    const { status, data } = useTakeaways({ user: userID, year, month, category });
    const queryClient = useQueryClient();
    const takeawayMutation = useTakeawayMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchTakeawaysForWrapUp");
            queryClient.invalidateQueries("fetchTakeaways");
        },
    });
    const masterPlanTemplate = useMasterPlanTemplate();
    const takeaways = useFillGoalArray({
        array: data?.takeaways,
        object: { id: null, category, year, month, description: "", user: userID },
        maxSize: maxTakeaways,
    });

    return (
        <Box>
            <CategoryTitle
                title={masterPlanTemplate[category].checkIn.takeaways.description}
                subheading={masterPlanTemplate[category].checkIn.takeaways.subheading}
                style={{
                    marginBottom: 24,
                }}
            />
            {status === "loading" ? (
                <CircularProgress />
            ) : disabled ? (
                <GoalList>
                    {takeaways.map((takeaway, index) => (
                        <GoalListItem key={`takeaway-${category}-${month}-${year}-${index}`}>
                            {takeaway.description}
                        </GoalListItem>
                    ))}
                </GoalList>
            ) : (
                <Grid container spacing={5}>
                    {takeaways.map((takeaway, index) => (
                        <Grid container item key={takeaway.id || `takeaway-${category}-${month}-${year}-${index}`}>
                            <Grid item xs={12}>
                                <AutoSaveTextField
                                    object={takeaway}
                                    textFieldName="description"
                                    index={index}
                                    mutation={takeawayMutation}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

TakeawaySection.propTypes = {
    category: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default TakeawaySection;
