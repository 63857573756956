import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SubNav from "./components/sub-nav";
import TopNav from "./components/top-nav";
import { useAuthState } from "./hooks/use-auth-state";
import FourOhFour from "./pages/404";
import CompletedItems from "./pages/completed-items";
import EditJot from "./pages/jots/edit-jot";
import EmployeeDashboard from "./pages/employee-dashboard";
import MajorAchievements from "./pages/major-achievements";
import MasterPlan from "./pages/master-plan";
import MonthlyCheckIn from "./pages/monthly-check-in";
import MonthlyTracker from "./pages/monthly-tracker";
import Profile from "./pages/profile";
import JotsLog from "./pages/jots/jots-log";
import ViewJot from "./pages/jots/view-jot";
import ViewTrackedGoal from "./pages/view-tracked-goal";
import { employeePath, urls, wildcardPath } from "./config/urls";
import { MonthlyCheckInProvider } from "./providers/monthly-check-in-provider";
import { Division } from "./pages/division";
import { OkrProfile } from "./pages/okr-profile";
import { Discussion } from "./pages/discussion";
import { Notifications } from "./pages/notifications";
import Todo from "./pages/todo";
import QuickPlan from "./pages/quick-plan";

function EmployeeApp(props) {
    const { user } = useAuthState();

    const {
        completedItems,
        dashboard,
        editEntry,
        majorAchievements,
        masterPlan,
        monthlyCheckIn,
        monthlyTracker,
        profile,
        viewAllEntries,
        viewEntry,
        teamshare,
        discussion,
        notifications,
        todo,
        quickPlan,
    } = urls.employee;

    return (
        <>
            <TopNav employee />
            <SubNav employee />
            <Switch>
                <Route exact path={employeePath}>
                    <Redirect to={dashboard.baseUrl} />
                </Route>
                <Route path={dashboard.baseUrl}>
                    <EmployeeDashboard />
                </Route>
                <Route path={masterPlan.baseUrl}>
                    <MasterPlan viewingAs="employee" user={user} />
                </Route>
                <Route path={quickPlan.baseUrl}>
                    <QuickPlan user={user} />
                </Route>
                <Route path={employeePath + "/monthly-results/:year/:month"}>
                    <MonthlyCheckInProvider>
                        <MonthlyCheckIn viewingAs="employee" user={user} />
                    </MonthlyCheckInProvider>
                </Route>
                <Route path={monthlyCheckIn.baseUrl + monthlyCheckIn.params}>
                    <MonthlyCheckInProvider>
                        <MonthlyCheckIn viewingAs="employee" user={user} />
                    </MonthlyCheckInProvider>
                </Route>
                <Route exact path={monthlyCheckIn.baseUrl}>
                    <Redirect to={`${monthlyCheckIn.baseUrl}/${new Date().getFullYear()}/${new Date().getMonth()}`} />
                </Route>
                <Route path={completedItems.baseUrl}>
                    <CompletedItems viewingAs="employee" user={user} />
                </Route>
                <Route path={majorAchievements.baseUrl}>
                    <MajorAchievements user={user} />
                </Route>
                <Route path={editEntry.baseUrl + editEntry.params}>
                    <EditJot />
                </Route>
                <Route path={viewAllEntries.baseUrl}>
                    <JotsLog />
                </Route>
                <Route path={viewEntry.baseUrl + viewEntry.params}>
                    <ViewJot />
                </Route>
                <Route exact path={monthlyTracker.baseUrl}>
                    <MonthlyTracker />
                </Route>
                <Route path={monthlyTracker.baseUrl + monthlyTracker.params}>
                    <ViewTrackedGoal />
                </Route>
                <Route exact path={teamshare.baseUrl}>
                    <Division divisionId={user.division} />
                </Route>
                <Route exact path={teamshare.baseUrl + teamshare.params}>
                    <OkrProfile />
                </Route>
                <Route exact path={discussion.baseUrl + discussion.params}>
                    <Discussion />
                </Route>
                <Route path={notifications.baseUrl}>
                    <Notifications />
                </Route>
                <Route path={profile.baseUrl}>
                    <Profile />
                </Route>
                <Route path={todo.baseUrl}>
                    <Todo userId={user.id} />
                </Route>
                <Route path={wildcardPath}>
                    <FourOhFour />
                </Route>
            </Switch>
        </>
    );
}

export default EmployeeApp;
