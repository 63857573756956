import React from "react";
import { Link } from "@material-ui/core";
import { manageBillingFormId } from "./manage-billing-form";

interface ManageBillingLinkProps {
    text?: string;
}

function ManageBillingLink({ text = "Click Here" }: ManageBillingLinkProps) {
    const getForm = () => {
        const form = document.getElementById(manageBillingFormId);
        if (!form) {
            return null;
        } else {
            return form as HTMLFormElement;
        }
    };

    return (
        <Link onClick={() => getForm()?.submit()} style={{ cursor: "pointer" }}>
            {text}
        </Link>
    );
}

export { ManageBillingLink };
