import React from "react";
import PropTypes from "prop-types";
import Avatar from "../avatar";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";
import { avatarConfig } from "./config";

interface ProfileSectionProps {
    userId: string;
    name: string;
    jobTitle: string;
}

const ProfileSection = ({ userId, name, jobTitle }: ProfileSectionProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.profileSection}>
            <Avatar id={userId} borderWidth={avatarConfig.borderWidth} diameter={avatarConfig.diameter} />
            <Box className={classes.profileWrapper}>
                <Typography className={classes.name}>{name}</Typography>
                <Typography className={classes.jobTitle}>{jobTitle}</Typography>
            </Box>
        </Box>
    );
};

ProfileSection.propTypes = {
    userId: PropTypes.string,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
};

export { ProfileSection };
