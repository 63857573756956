import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useDialog } from "../../hooks/use-dialog";
import { DeleteJotConfirmationDialog } from "./delete-jot-confirmation-dialog";
import { useDeleteApi } from "../../hooks/api/use-delete-api";
import { Jot } from "../../interfaces/jot";
import { jotAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useQueryClient } from "react-query";
import { urls } from "../../config/urls";
import { useHistory } from "react-router-dom";

interface DeleteJotButtonProps {
    id: string;
}

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        color: theme.palette.primary.dark,
    },
}));

const DeleteJotButton = ({ id }: DeleteJotButtonProps) => {
    const classes = useStyles();
    const confirmDialog = useDialog({});
    const queryClient = useQueryClient();
    const history = useHistory();

    const deleteJotApi = useDeleteApi<Jot>(jotAPI, queryKeys.jot.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.jot.find);
            queryClient.invalidateQueries(queryKeys.jot.get);
            history.push(urls.employee.viewAllEntries.baseUrl);
        },
    });

    const handleClose = (isConfirmed: boolean) => {
        if (isConfirmed) {
            deleteJotApi.deleteFn(id);
        }
        confirmDialog.handleClose();
    };

    return (
        <Box>
            <IconButton aria-label="delete" onClick={confirmDialog.handleOpen}>
                <DeleteOutlineIcon className={classes.deleteButton} />
            </IconButton>
            <DeleteJotConfirmationDialog isOpen={confirmDialog.isOpen} onClose={handleClose} />
        </Box>
    );
};

DeleteJotButton.propTypes = {
    id: PropTypes.string,
};

export { DeleteJotButton };
