import { Card, Container, Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useState } from "react";
import MyCheckinButton from "../components/my-checkin-button";
import MyCheckinTextField from "../components/my-checkin-text-field";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(17),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    wrapper: {
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
    },
    contentContainer: {
        padding: theme.spacing(4),
        maxWidth: theme.spacing(15),
        margin: "auto",
        // marginTop: theme.spacing(7),
        // [theme.breakpoints.only("xl")]: {
        //     marginTop: theme.spacing(7),
        // },
        // [theme.breakpoints.only("lg")]: {
        //     marginTop: theme.spacing(6),
        // },
        // [theme.breakpoints.only("md")]: {
        //     marginTop: theme.spacing(6),
        // },
        // [theme.breakpoints.only("sm")]: {
        //     marginTop: theme.spacing(6),
        // },
        // [theme.breakpoints.only("xs")]: {
        //     marginTop: theme.spacing(2),
        // },
    },
}));

function ForgotPassword() {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email) {
            return;
        }
        const lowercaseEmail = email.toLowerCase();
        await axios.post("/api/auth/forgotpassword", { email: lowercaseEmail });
        setSubmitted(true);
    };

    return (
        <Container>
            <Grid container justify="center" alignItems="center" className={classes.wrapper}>
                <Grid item xs={12}>
                    <Card className={classes.contentContainer}>
                        {submitted ? (
                            <Alert severity="info">
                                A password reset email has been sent to <strong>{email}</strong>. Please follow the link
                                provided to reset your password.
                            </Alert>
                        ) : (
                            <form>
                                <Grid container justify="center" spacing={5}>
                                    <Grid item xs={12}>
                                        <MyCheckinTextField
                                            label="Email Address"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <MyCheckinButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </MyCheckinButton>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ForgotPassword;
