import { useQuery } from "react-query";
import { API } from "../util/api";

const fetchMeetings = (params) => {
    return API.getMeetings(params);
};

export function useMeetings(params) {
    const queryKey = ["fetchMeetings"];
    if (params) {
        Object.values(params).forEach((value) => queryKey.push(value));
    }
    return useQuery(queryKey, () => fetchMeetings(params), { enabled: !!params });
}
