import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        marginBottom: 12,
        paddingBottom: 0,
    },
    dialogContent: {
        paddingTop: 0,
    },
    subtitle: {
        marginBottom: 24,
    },
    unassignedUsersList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",
        width: "100%",
        gap: "24px",
    },
    unassignedUsersListItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "stretch",
        padding: 0,
        gap: 24,
    },
    unassignedUserName: {
        width: "100%",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "22px",
    },
}));
