import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { useQueryClient } from "react-query";
import { useTakeawayMutation } from "../../hooks/mutations/use-takeaway-mutation";
import MyCheckinButton from "../my-checkin-button";

function ConfirmDismissDialog({ isOpen, handleClose, id }) {
    const queryClient = useQueryClient();
    const takeawayMutation = useTakeawayMutation({ onSuccess: () => queryClient.invalidateQueries("fetchTakeaways") });

    const dismiss = () => {
        takeawayMutation.mutate({ id, isDismissed: true });
        handleClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Dismiss Reminder?</DialogTitle>
            <DialogContent>
                Dismiss this reminder? It can still be seen from the Monthly Check-In page if dismissed.
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>Cancel</MyCheckinButton>
                <MyCheckinButton variant="outlined" onClick={dismiss}>
                    Confirm
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDismissDialog;
