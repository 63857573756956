import React from "react";
import { makeStyles, TextField } from "@material-ui/core";

const labelFontSize = 18;

const useStyles = makeStyles((theme) => ({
    label: {
        fontWeight: "bold",
        fontSize: labelFontSize,
        color: "black",
    },

    labelNotch: {
        fontSize: "1.18em",
    },
}));

function MyCheckinTextField(props) {
    const classes = useStyles();

    return (
        <TextField
            InputLabelProps={{ shrink: true, classes: { root: classes.label } }}
            variant={props.variant || "outlined"}
            InputProps={{ classes: { notchedOutline: classes.labelNotch }, style: { fontSize: 16, color: "#000000" } }}
            {...props}
        />
    );
}

export default MyCheckinTextField;
