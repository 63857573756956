import { Box, Card, CardHeader } from "@material-ui/core";
import React from "react";
import { useStyles } from "./account-deactivated.styles";

interface AccountDeactivatedProps {
    children: any;
}

function AccountDeactivated({ children }: AccountDeactivatedProps) {
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Card className={classes.contentContainer}>
                <CardHeader title="Account Deactivated" />
                {children}
            </Card>
        </Box>
    );
}

export { AccountDeactivated };
