import React from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useSubmissionNoteState } from "../../../hooks/states/use-submission-note-state";
import DialogAlert from "../dialog-alert";
import SubmissionNoteList from "../submission-note-list";
import MyCheckinButton from "../../my-checkin-button";

interface ViewSubmissionNotesDialogProps {
    isOpen: boolean;
    onClose: () => any;
    meetingId: string | undefined;
}

function ViewSubmissionNotesDialog({ isOpen, onClose, meetingId }: ViewSubmissionNotesDialogProps) {
    const { submissionNotes, isLoading, isError } = useSubmissionNoteState({ meetingId });

    const renderContent = () => {
        if (isLoading) {
            return <CircularProgress />;
        }
        if (isError) {
            return <DialogAlert error message="Error: Cannot load submission notes" />;
        }
        return <SubmissionNoteList submissionNotes={submissionNotes} />;
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
            <DialogTitle>Submission Notes</DialogTitle>
            <DialogContent>{renderContent()}</DialogContent>
            <DialogActions>
                <MyCheckinButton variant="contained" color="primary" onClick={onClose}>
                    Close
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export { ViewSubmissionNotesDialog };
