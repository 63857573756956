export const objectiveDescriptions = {
    masterPlan: {
        name: "Objective",
        description: "Objective. This is what I need to deliver high-value results.",
        subheading:
            "Briefly describe the goal. How will people know when it has been achieved? It should be qualitative, actionable, include a deadline, and be aggressive yet realistic.",
        completeButtonTooltip: "Click to complete Objective",
    },
    checkIn: {
        name: "Objective",
        description: "Objective. This is what I need to deliver high-value results.",
        subheading: "Objective prefills here from your Master Plan",
    },
    completedItems: {
        name: "Objectives",
    },
};
