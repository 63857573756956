import { useMutation } from "react-query";
import { submissionNoteAPI } from "../../util/api";
import { SubmissionNote } from "../../interfaces/submission-note";

export const useSubmissionNoteMutation = () => {
    return useMutation((submissionNote: Partial<SubmissionNote>) => {
        return submissionNote.id
            ? submissionNoteAPI.update(submissionNote, submissionNote.id)
            : submissionNoteAPI.create(submissionNote);
    });
};
