import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./notifications.styles";
import { Box, Typography } from "@material-ui/core";

interface NotificationListProps {
    children: React.ReactNode;
}

const NotificationList = ({ children }: NotificationListProps) => {
    const classes = useStyles();

    if (React.Children.count(children) === 0) {
        return (
            <Typography style={{ width: "100%" }} align="center">
                No notifications found.
            </Typography>
        );
    }

    return <Box className={classes.notificationList}>{children}</Box>;
};

NotificationList.propTypes = {
    children: PropTypes.node,
};

export { NotificationList };
