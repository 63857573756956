import React from "react";
import PropTypes from "prop-types";
import { Cell } from "./index";
import { MeetingStatus } from "../../../../types/meeting-status";
import { statusTooltips } from "../../config";
import { Tooltip } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { getColor } from "./util";

interface StatusCellProps {
    status: MeetingStatus;
    link?: string;
}

interface StatusIcons {
    [index: string]: React.ReactNode;
}

const statusIcons: StatusIcons = {
    submitted: <WarningIcon />,
    scheduling: <WarningIcon />,
    scheduled: <WarningIcon />,
    complete: <CheckIcon />,
    "no meeting": <ArrowForwardRoundedIcon />,
    returned: <WarningIcon />,
};

const StatusCell = ({ status, link }: StatusCellProps) => {
    const color = getColor(status);

    const getIcon = () => {
        return status ? statusIcons[status] : null;
    };

    const getTooltip = () => {
        return status ? statusTooltips[status] : "";
    };

    return (
        <Tooltip title={getTooltip()}>
            {/*wrapping content in div so tooltip appears in proper location*/}
            <div>
                <RouterLink to={link || "#"}>
                    <Cell color={color}>{getIcon()}</Cell>
                </RouterLink>
            </div>
        </Tooltip>
    );
};

StatusCell.propTypes = {
    status: PropTypes.string,
    link: PropTypes.string,
};

export { StatusCell };
