import { CircularProgress, FormControlLabel, Grid, makeStyles, Switch, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useCompanyMutation } from "../../hooks/mutations/use-company-mutation";
import { useCompany } from "../../hooks/queries/use-company";
import TabPanel from "../coach-station/tab-panel";
import { queryKeys } from "../../hooks/api/config/query-keys";
import AutosaveTextField from "../autosave-text-field";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6),
    },
}));

const settings = [
    {
        name: "isGoalPointsEnabled",
        label: "Goal Points",
        description:
            "Turning on Goal Points enables assigning point values to Employee Goals. Coaches will be able to assign these values when viewing an Employees Master Plan",
    },
    {
        name: "isSendLoginRemindersEnabled",
        label: "Login Reminders",
        description: "Send an email reminder if an Employee has not logged in for 10 days, and every 5 days thereafter",
    },
    {
        name: "isTeamshareEnabled",
        label: "TeamShare",
        description:
            "TeamShare is a collaborative feature that allows employees to see and discuss the Objectives and Key Results of others in the same organizational unit.",
    },
];

function CompanySettingsTab(props) {
    const classes = useStyles();
    const { index, value } = props;
    const { status, data } = useCompany();
    const [company, setCompany] = useState({});
    const [state, setState] = useState({
        isGoalPointsEnabled: false,
        isSendLoginRemindersEnabled: false,
        isTeamshareEnabled: false,
    });
    const queryClient = useQueryClient();
    const companyMutation = useCompanyMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchCompany");
            queryClient.invalidateQueries(queryKeys.company.get);
        },
    });

    useEffect(() => {
        if (status === "success") {
            setCompany(data || {});
            setState(data.settings);
        } else {
            setCompany({});
        }
    }, [status, data]);

    const handleChange = (event) => {
        const { name, checked } = event.target;
        companyMutation.mutate({ id: company.id, $set: { [`settings.${name}`]: checked } });
        setState({ ...state, [name]: checked });
    };

    const createSwitch = (name) => {
        return <Switch checked={state[name]} onChange={handleChange} name={name} color="primary" />;
    };

    const createSwitchRow = ({ name, label, description }) => {
        return (
            <Grid container item>
                <Grid item md={4}>
                    <FormControlLabel control={createSwitch(name)} label={label} />
                </Grid>
                <Grid item md={8}>
                    <Typography>{description}</Typography>
                </Grid>
            </Grid>
        );
    };

    const getOrganizationalUnitMutation = () => {
        return {
            mutate: (organizationalUnitName) => {
                if (organizationalUnitName.singular === "") {
                    organizationalUnitName.singular = "Division";
                }
                if (organizationalUnitName.plural === "") {
                    organizationalUnitName.plural = "Divisions";
                }
                companyMutation.mutate({ ...company, organizationalUnitName });
            },
        };
    };

    return (
        <TabPanel index={index} value={value}>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container className={classes.container} spacing={5}>
                    {settings.map((setting) => createSwitchRow(setting))}
                    {state.isTeamshareEnabled && (
                        <Grid container item>
                            <Grid item md={6}>
                                <Typography
                                    style={{ fontWeight: 600, fontSize: 16, lineHeight: "17px", margin: "8px 0px" }}
                                >
                                    Organizational Unit Name
                                </Typography>
                                <Typography>
                                    Customize the name of the organizational units for your company. (Default is
                                    "Division")
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <AutosaveTextField
                                    object={company.organizationalUnitName}
                                    textFieldName="singular"
                                    label="Singular"
                                    style={{ marginBottom: 16, marginTop: 16 }}
                                    rows={1}
                                    mutation={getOrganizationalUnitMutation()}
                                />
                                <AutosaveTextField
                                    object={company.organizationalUnitName}
                                    textFieldName="plural"
                                    label="Plural"
                                    style={{ height: 48 }}
                                    rows={1}
                                    mutation={getOrganizationalUnitMutation()}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </TabPanel>
    );
}

CompanySettingsTab.propTypes = {
    index: PropTypes.number,
    value: PropTypes.number,
};

export default CompanySettingsTab;
