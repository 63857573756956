import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import DateFormat from "../../date-format";
import Avatar from "../../avatar";

interface SubmissionNoteProps {
    authorName: string | undefined;
    authorId: string;
    note: string;
    date?: Date;
    className?: string;
}

function SubmissionNote({ authorName, authorId, note, date, className }: SubmissionNoteProps) {
    return (
        <Box className={className}>
            <Grid container alignItems="center" justify="space-around">
                <Grid item>
                    <Avatar id={authorId} diameter={48} borderWidth={1} />
                </Grid>
                <Grid item xs={10}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="subtitle1">{authorName}</Typography>
                        </Grid>
                        <Grid item>
                            <DateFormat date={date} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom>{note}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export { SubmissionNote };
