import { Grid, Link } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useUsers } from "../../hooks/queries/use-users";

const dateFormatter = (params) => {
    if (!params.value) {
        return "";
    }
    const date = new Date(params.value);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
    return `${dateString} ${timeString}`;
};

const columns = [
    {
        field: "lastName",
        headerName: "Last Name",
        flex: 3,
        renderCell: (params) => {
            return (
                <Link component={RouterLink} to={`/coach/coach-station/${params.row.id}`} color="inherit">
                    {params.getValue("lastName")}
                </Link>
            );
        },
    },
    {
        field: "firstName",
        headerName: "First Name",
        flex: 3,
        renderCell: (params) => {
            return (
                <Link component={RouterLink} to={`/coach/coach-station/${params.row.id}`} color="inherit">
                    {params.getValue("firstName")}
                </Link>
            );
        },
    },
    {
        field: "isCoach",
        headerName: "Is Coach",
        flex: 1,
        renderCell: (params) => {
            return params.row.permissions.isCoach ? <CheckIcon /> : "";
        },
    },
    {
        field: "isAdmin",
        headerName: "Is Admin",
        flex: 1,
        renderCell: (params) => {
            return params.row.permissions.isAdmin ? <CheckIcon /> : "";
        },
    },
    {
        field: "lastLogin",
        headerName: "Last Login",
        flex: 2,
        valueFormatter: dateFormatter,
    },
];

function EmployeeTable(props) {
    const { searchFilter, showDeleted } = props;
    const [users, setUsers] = useState([]);
    const userQuery = useUsers({});

    useEffect(() => {
        if (userQuery.isSuccess) {
            setUsers(
                userQuery.data.users.filter((user) => {
                    const nameIncludesSearchTerm =
                        user.firstName.toLowerCase().includes(searchFilter.toLowerCase()) ||
                        user.lastName.toLowerCase().includes(searchFilter.toLowerCase());

                    return nameIncludesSearchTerm && user.isDeleted === showDeleted;
                }) || []
            );
        } else {
            setUsers([]);
        }
    }, [searchFilter, showDeleted, userQuery]);

    return (
        <Grid container item xs={12} style={{ height: 512, width: "100%", marginTop: 32 }}>
            <DataGrid
                columns={columns}
                rows={users}
                pageSize={7}
                loading={userQuery.status === "loading"}
                sortModel={[{ field: "lastName", sort: "asc" }]}
                disableSelectionOnClick
            />
        </Grid>
    );
}

export default EmployeeTable;
