import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, MenuItem, Typography } from "@material-ui/core";
import MyCheckinTextField from "../../my-checkin-text-field";
import { useStyles } from "./unassigned-users-dialog.styles";
import Avatar from "../../avatar";
import { avatarProps } from "./config";

interface UnassignedUsersListItemProps {
    id: string;
    name: string;
    divisions: { id: string; name: string }[];
    onDivisionChange: ({ id, division }: { id: string; division: string }) => unknown;
}

const UnassignedUsersListItem = ({ id, name, divisions, onDivisionChange }: UnassignedUsersListItemProps) => {
    const classes = useStyles();
    const [value, setValue] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setValue(value);
        onDivisionChange({ id, division: value });
    };

    return (
        <Box className={classes.unassignedUsersListItem} key={id}>
            <Avatar id={id} {...avatarProps} />
            <Typography className={classes.unassignedUserName}>{name}</Typography>
            <MyCheckinTextField select variant={undefined} value={value} fullWidth onChange={handleChange}>
                <MenuItem value="">&nbsp;</MenuItem>
                {divisions.map((division) => (
                    <MenuItem key={division.id} value={division.id}>
                        {division.name}
                    </MenuItem>
                ))}
            </MyCheckinTextField>
        </Box>
    );
};

UnassignedUsersListItem.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    divisions: PropTypes.array,
    onDivisionChange: PropTypes.func,
};

export { UnassignedUsersListItem };
