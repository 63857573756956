import { Card, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import axios, { AxiosError } from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RegisterStepOne from "../components/register/register-step-one";
import RegisterStepTwo from "../components/register/register-step-two";

interface UserData {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    password1: string;
    password2: string;
}

interface CompanyData {
    id?: string;
    name: string;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
    },
    contentContainer: {
        padding: "24px 48px",
        maxWidth: theme.spacing(15),
        margin: "auto",
        marginBottom: theme.spacing(6),
    },
}));

const errorMessage = {
    PASSWORD_MISMATCH: "Passwords do not match.",
    PASSWORD_INVALID: "Password is required",
};

function Register() {
    const classes = useStyles();
    const [company, setCompany] = useState<CompanyData>({ id: "", name: "" });
    const [user, setUser] = useState<UserData>({
        password1: "",
        password2: "",
        email: "",
        firstName: "",
        id: "",
        lastName: "",
    });
    const [step, setStep] = useState(0);
    const [error, setError] = useState<string | undefined>("");
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();

    useEffect(() => {
        setLoading(false);
    }, [error]);

    const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
        setError("");
    };

    const handleCompanyChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCompany({ ...company, [name]: value });
        setError("");
    };

    const renderStep = () => {
        return steps[step];
    };

    const getAxiosErrorMessage = (err: AxiosError) => {
        if (!err.response) {
            return;
        }
        return err.response.data.errors.map((err: Error) => err.message).join(", ");
    };

    const getStripeErrorMessage = (err: StripeError) => {
        return (
            err.message ||
            "An unknown error has occurred while communicating with the payment service. Please try again later."
        );
    };

    const handleError = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
    };

    const passwordMatches = () => {
        return user.password1 === user.password2;
    };

    const passwordExists = () => {
        return user.password1 && user.password2;
    };

    const validatePassword = () => {
        if (!passwordExists()) {
            setError(errorMessage.PASSWORD_INVALID);
            return false;
        }

        if (!passwordMatches()) {
            setError(errorMessage.PASSWORD_MISMATCH);
            return false;
        }

        return true;
    };

    const getCardElement = () => {
        if (!elements) {
            return;
        }
        return elements.getElement(CardElement);
    };

    const createPaymentMethod = async () => {
        const cardElement = getCardElement();
        if (!cardElement || !stripe) {
            return;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        if (error) {
            throw error;
        }
        if (!paymentMethod) {
            return;
        }
        return paymentMethod.id;
    };

    const signIn = async () => {
        await axios.post("/api/auth/signin", {
            email: user.email,
            password: user.password1,
        });
    };

    const navigateToDashboard = () => {
        history.push("/employee/dashboard");
        window.location.reload();
    };

    const handleRegister = async () => {
        setLoading(true);
        let paymentMethodId = null;

        if (!validatePassword()) {
            return;
        }

        try {
            paymentMethodId = await createPaymentMethod();
        } catch (e) {
            handleError(getStripeErrorMessage(e));
            return;
        }

        try {
            await axios.post("/api/company-registration", {
                companyName: company.name,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                password1: user.password1,
                password2: user.password2,
                paymentId: paymentMethodId,
                isTermsOfServiceAccepted: true,
            });
            await signIn();
            navigateToDashboard();
        } catch (e) {
            handleError(getAxiosErrorMessage(e));
            return;
        }
    };

    const steps = [
        <RegisterStepOne
            company={company}
            user={user}
            onCompanyChange={handleCompanyChange}
            onUserChange={handleUserChange}
            nextStep={() => setStep(1)}
            key="registration-step-1"
        />,
        <RegisterStepTwo
            prevStep={() => setStep(0)}
            onSubmit={handleRegister}
            loading={loading}
            key="registration-step-2"
        />,
    ];

    return (
        <Container>
            <Grid container alignItems="center" justify="center" className={classes.wrapper}>
                <Grid item>
                    <Card className={classes.contentContainer}>
                        <Typography style={{ fontSize: 48, letterSpacing: -2 }}>Sign Up</Typography>
                        {error && (
                            <Grid container justify="center">
                                <Grid item>
                                    <Alert severity="error">{error}</Alert>
                                </Grid>
                            </Grid>
                        )}
                        {renderStep()}
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Register;
