import { ContainerProps } from "./container-props";
import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    section: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(5),
    },
}));

export const TodoSection = ({ children }: ContainerProps) => {
    const classes = useStyles();

    return <Box className={classes.section}>{children}</Box>;
};
