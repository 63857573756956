import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    blockLetter: {
        width: 192,
        height: 192,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.neutral.main,
        borderRadius: 5,
    },
    background: {
        color: theme.palette.neutral.light,
        fontSize: 144,
        fontWeight: 900,
        position: "absolute",
        zIndex: 1,
    },
    foreground: {
        color: theme.palette.neutral.dark,
        fontSize: 24,
        textTransform: "uppercase",
        zIndex: 2,
        fontWeight: "800",
        whiteSpace: "normal",
        textAlign: "center",
        lineHeight: "29px",
    },
    selected: {
        borderLeft: "6px solid #ffb84c",
        borderTop: "6px solid #ffb84c",
        borderBottom: "6px solid #ffb84c",
        borderRadius: "5px 0 0 5px",
        boxShadow: "12px 0px 0px -6px #ffffff",
    },
}));

function BlockLetter(props) {
    const classes = useStyles();
    const { title = "" } = props;

    return (
        <Button
            {...props}
            className={classes.blockLetter + " " + props.className + " " + (props.selected ? classes.selected : "")}
            onClick={props.onClick}
        >
            <Typography className={classes.background}>{title[0]}</Typography>
            <Typography className={classes.foreground}>{title}</Typography>
            {props.children}
        </Button>
    );
}

export default BlockLetter;
