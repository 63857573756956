import { Objective } from "../../interfaces/objective";
import { useMutation, UseMutationOptions } from "react-query";
import { objectiveAPI } from "../../util/api";

type UseObjectiveMutationParams =
    | (Partial<Objective> & {
          id?: never;
          user: string;
          category: number;
          year: number;
      })
    | (Partial<Objective> & {
          id: string;
      });

export const useObjectiveMutation = (options: UseMutationOptions<any, unknown, UseObjectiveMutationParams> = {}) => {
    return useMutation<any, unknown, UseObjectiveMutationParams>(
        (params) => (params.id ? objectiveAPI.update(params, params.id) : objectiveAPI.create(params)),
        options
    );
};
