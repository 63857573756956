import React, { useState } from "react";
import { Card, Container } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import { Pagination } from "../../components/division/pagination";
import { useStyles } from "./divisions.styles";
import { useDialog } from "../../hooks/use-dialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { CompanyName } from "../../components/divisions/company-name";
import { DivisionsContainer } from "../../components/divisions/divisions-container";
import { UnassignedUsersAlert } from "../../components/divisions/unassigned-users-alert";
import { Actions } from "../../components/divisions/actions";
import { DivisionList } from "../../components/divisions/division-list";
import { DivisionListItem } from "../../components/divisions/division-list-item";
import { UnassignedUsersDialog } from "../../components/divisions/unassigned-users-dialog";
import { EditDivisionDialog } from "../../components/divisions/edit-division-dialog";
import { AddDivisionDialog } from "../../components/divisions/add-division-dialog";
import { DeleteDivisionDialog } from "../../components/divisions/delete-division-dialog";
import { DivisionOverflowMenu } from "../../components/divisions/division-overflow-menu";
import { DivisionOverflowMenuItem } from "../../components/divisions/division-overflow-menu-item";
import { useFindApi } from "../../hooks/api/use-find-api";
import { Division } from "../../interfaces/division";
import { companyAPI, divisionAPI, userAPI } from "../../util/api";
import { useAuthState } from "../../hooks/use-auth-state";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { User } from "../../interfaces/user";
import { useUpdateApi } from "../../hooks/api/use-update-api";
import { useQueryClient } from "react-query";
import { useCreateApi } from "../../hooks/api/use-create-api";
import { usePagination } from "../../hooks/use-pagination";
import { useDeleteApi } from "../../hooks/api/use-delete-api";
import usePermissions from "../../hooks/use-permissions";
import { useGetApi } from "../../hooks/api/use-get-api";
import { Company } from "../../interfaces/company/company";
import { useSortArray } from "../../hooks/use-sort-array";

const Divisions = () => {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const { isAdmin } = usePermissions();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedDivision, setSelectedDivision] = useState<null | Pick<Division, "id" | "name">>(null);
    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");
    const { user } = useAuthState();
    const findDivisionsResponse = useFindApi<Division>(
        divisionAPI,
        queryKeys.division.find,
        { company: user?.company },
        "divisions",
        { enabled: !!user?.company }
    );

    const divisions = findDivisionsResponse.data.result;

    const findUsersResponse = useFindApi<User>(
        userAPI,
        queryKeys.user.find,
        //@ts-ignore
        { division: divisions.map((division) => division.id), company: user?.company },
        "users",
        { enabled: !!user }
    );

    const users = findUsersResponse.data.result;

    const findUnassignedUsersResponse = useFindApi<User>(
        userAPI,
        queryKeys.user.find,
        //@ts-ignore
        { division: null, company: user?.company },
        "users",
        { enabled: !!user }
    );

    const unassignedUsers = findUnassignedUsersResponse.data.result;
    const sortedUnassignedUsers = useSortArray<User>({ array: unassignedUsers, field: "lastName", order: "asc" });

    const updateUser = useUpdateApi<User>(userAPI, queryKeys.user.update, {
        onSuccess: () => queryClient.invalidateQueries(queryKeys.user.find),
    });

    const handleUnassignedUsersDialogSave = (users: Pick<User, "id" | "division">[]) => {
        users.forEach((user) => updateUser.update(user.id, { division: user.division }));
        unassignedUsersDialog.handleClose();
    };

    const updateDivision = useUpdateApi<Division>(divisionAPI, queryKeys.division.update, {
        onSuccess: invalidateDivisionFindQueries,
    });

    const handleEditDivisionDialogSave = ({ divisionId, name }: { divisionId: string; name: string }) => {
        updateDivision.update(divisionId, { name });
        editDivisionDialog.handleClose();
    };

    const createDivision = useCreateApi<Division>(divisionAPI, queryKeys.division.create, {
        onSuccess: invalidateDivisionFindQueries,
    });

    const handleAddDivisionDialogSave = ({ name }: { name: string }) => {
        createDivision.create({ name });
        addDivisionDialog.handleClose();
    };

    function invalidateDivisionFindQueries() {
        queryClient.invalidateQueries(queryKeys.division.find);
    }

    const deleteDivision = useDeleteApi<Division>(divisionAPI, queryKeys.division.delete, {
        onSuccess: () => {
            invalidateDivisionFindQueries();
            queryClient.invalidateQueries(queryKeys.user.find);
        },
    });

    const handleDeleteDivisionDialogConfirm = ({ divisionId }: { divisionId: string }) => {
        deleteDivision.deleteFn(divisionId);
        deleteDivisionDialog.handleClose();
    };

    const unassignedUsersDialog = useDialog({});
    const editDivisionDialog = useDialog({});
    const addDivisionDialog = useDialog({});
    const deleteDivisionDialog = useDialog({});

    const handleOverflowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, divisionId: string, name: string) => {
        setSelectedDivision({ id: divisionId, name });
        setAnchorEl(event.currentTarget);
    };

    const handleOverflowMenuClose = () => {
        setSelectedDivision(null);
        setAnchorEl(null);
    };

    const handleMenuItemClick = (callback: () => unknown) => {
        callback();
        setAnchorEl(null);
    };

    const openEditDivisionDialog = () => handleMenuItemClick(editDivisionDialog.handleOpen);
    const openDeleteDivisionDialog = () => handleMenuItemClick(deleteDivisionDialog.handleOpen);

    const sortedDivisions = useSortArray<Division>({ array: divisions, field: "name", order: "asc" });
    const pagination = usePagination({ objectArr: sortedDivisions });
    const paginatedDivisions = pagination.paginatedArray;

    return (
        <Container>
            <PageTitle>{company?.organizationalUnitName.plural || "Divisions"}</PageTitle>
            <Card className={classes.contentContainer}>
                <CompanyName name={company?.name || "Loading..."} />
                <DivisionsContainer>
                    {sortedUnassignedUsers.length && isAdmin ? (
                        <UnassignedUsersAlert
                            count={sortedUnassignedUsers.length}
                            onClick={unassignedUsersDialog.handleOpen}
                        />
                    ) : null}
                    {isAdmin && <Actions onAddDivisionClick={addDivisionDialog.handleOpen} />}
                    <DivisionList>
                        {paginatedDivisions.map((division) => (
                            <DivisionListItem
                                onOverflowMenuClick={handleOverflowMenuClick}
                                userIdArr={users.filter((user) => user.division === division.id).map((user) => user.id)}
                                name={division.name}
                                divisionId={division.id}
                                key={division.id}
                                showOverflow={isAdmin}
                            />
                        ))}
                    </DivisionList>
                </DivisionsContainer>
                <Pagination
                    offset={pagination.offset}
                    limit={pagination.limit}
                    max={divisions.length}
                    onClickNext={pagination.onClickNext}
                    onClickPrevious={pagination.onClickPrevious}
                />
            </Card>
            <UnassignedUsersDialog
                open={unassignedUsersDialog.isOpen}
                onClose={unassignedUsersDialog.handleClose}
                onSave={handleUnassignedUsersDialogSave}
                users={sortedUnassignedUsers.map((user) => ({
                    id: user.id,
                    name: `${user.firstName} ${user.lastName}`,
                }))}
                divisions={divisions}
            />
            <EditDivisionDialog
                open={editDivisionDialog.isOpen}
                onClose={editDivisionDialog.handleClose}
                onSave={handleEditDivisionDialogSave}
                name={selectedDivision?.name}
                divisionId={selectedDivision?.id}
            />
            <AddDivisionDialog
                open={addDivisionDialog.isOpen}
                onClose={addDivisionDialog.handleClose}
                onSave={handleAddDivisionDialogSave}
            />
            <DeleteDivisionDialog
                open={deleteDivisionDialog.isOpen}
                onClose={deleteDivisionDialog.handleClose}
                onDelete={handleDeleteDivisionDialogConfirm}
                name={selectedDivision?.name}
                divisionId={selectedDivision?.id}
            />
            <DivisionOverflowMenu anchorEl={anchorEl} onClose={handleOverflowMenuClose}>
                <DivisionOverflowMenuItem onClick={openEditDivisionDialog}>
                    <EditIcon />
                    Edit
                </DivisionOverflowMenuItem>
                <DivisionOverflowMenuItem onClick={openDeleteDivisionDialog} color="#e12d39">
                    <DeleteOutlineIcon />
                    Delete
                </DivisionOverflowMenuItem>
            </DivisionOverflowMenu>
        </Container>
    );
};

export { Divisions };
