import { Card, Checkbox, Container, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import PrivacyNoticeDialog from "../components/register/privacy-notice-dialog";
import TermsAndConditionsDialog from "../components/register/terms-and-conditions-dialog";
import MyCheckinButton from "../components/my-checkin-button";
import MyCheckinTextField from "../components/my-checkin-text-field";
import { useDialog } from "../hooks/use-dialog";
import { API } from "../util/api";
import { TokenType, useValidateToken } from "../hooks/use-validate-token";
import { AxiosError } from "axios";
import { LoadingIndicator } from "../components/loading-indicator";

interface ResetPasswordProps {
    register?: boolean;
}

interface ResetPasswordRouteParams {
    token: string;
}

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(17),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    contentContainer: {
        padding: theme.spacing(4),
        maxWidth: theme.spacing(15),
        margin: "auto",
    },
    wrapper: {
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
    },
}));

const REDIRECT_DELAY = 3000;
const LOADING_INDICATOR_SIZE = 17;

const ResetPassword = ({ register = false }: ResetPasswordProps) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        params: { token },
    } = useRouteMatch<ResetPasswordRouteParams>();
    const tokenType: TokenType = register ? "registration" : "password-reset";
    const tokenValidation = useValidateToken({ token, tokenType });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [state, setState] = useState({ password1: "", password2: "" });
    const [error, setError] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const updatePasswordMutation: UseMutationResult<
        any,
        AxiosError,
        { password1: string; password2: string; token: string }
    > = useMutation(
        (params) => {
            return register ? API.registerPassword(params) : API.updatePassword(params);
        },
        {
            onError: (error) => {
                setLoading(false);
                setError(
                    `Error: Server responded with "${error?.response?.data.errors
                        .map((err: { message: string }) => err.message)
                        .join(", ")}"`
                );
            },
            onSuccess: () => {
                setLoading(false);
                setSuccess(true);
            },
        }
    );
    const [tosChecked, setTosChecked] = useState(false);
    const termsAndConditionsDialog = useDialog({});
    const privacyNoticeDialog = useDialog({});

    useEffect(() => {
        if (updatePasswordMutation.isSuccess) {
            setShowSuccess(true);
            setTimeout(() => {
                history.push("/");
            }, REDIRECT_DELAY);
        }
    }, [history, updatePasswordMutation]);

    const showValidating = () => {
        return <Typography align="center">Validating token, please wait...</Typography>;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
        setError("");
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const { password1, password2 } = state;
        if (password1 !== password2) {
            setError("Passwords must match!");
            setLoading(false);
            return;
        }
        updatePasswordMutation.mutate({ password1: state.password1, password2: state.password2, token });
    };

    const handleTosCheck = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setTosChecked(checked);
    };

    const showValid = () => {
        let disabled = (register && !tosChecked) || loading || success;
        return (
            <form>
                <Grid container spacing={4}>
                    <Grid container item>
                        <Typography variant="subtitle1">Please enter a new password:</Typography>
                    </Grid>
                    {error && (
                        <Grid container item>
                            <Alert severity="error" style={{ width: "100%" }}>
                                {error}
                            </Alert>{" "}
                        </Grid>
                    )}
                    {showSuccess && (
                        <Grid container item>
                            <Alert severity="success" style={{ width: "100%" }}>
                                Success! Redirecting to login page...
                            </Alert>
                        </Grid>
                    )}
                    <Grid container item>
                        <MyCheckinTextField
                            label="Password"
                            name="password1"
                            value={state.password1}
                            onChange={handleChange}
                            fullWidth
                            type="password"
                        />
                    </Grid>
                    <Grid container item>
                        <MyCheckinTextField
                            label="Repeat Password"
                            name="password2"
                            value={state.password2}
                            onChange={handleChange}
                            fullWidth
                            type="password"
                        />
                    </Grid>
                    {register && (
                        <Grid container item>
                            <Grid item xs={2} sm={1}>
                                <Checkbox color="primary" checked={tosChecked} onChange={handleTosCheck} />
                            </Grid>
                            <Grid item xs={10} sm={11}>
                                <Typography style={{ display: "inline" }}>
                                    I have reviewed and accept the{" "}
                                    <Link href="#" onClick={termsAndConditionsDialog.handleOpen}>
                                        Terms & Conditions
                                    </Link>{" "}
                                    and{" "}
                                    <Link href="#" onClick={privacyNoticeDialog.handleOpen}>
                                        Privacy Notice
                                    </Link>
                                </Typography>
                                <TermsAndConditionsDialog
                                    isOpen={termsAndConditionsDialog.isOpen}
                                    onClose={termsAndConditionsDialog.handleClose}
                                />
                                <PrivacyNoticeDialog
                                    isOpen={privacyNoticeDialog.isOpen}
                                    onClose={privacyNoticeDialog.handleClose}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container justify="flex-end">
                        <Grid item>
                            <MyCheckinButton
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                type="submit"
                                disabled={disabled}
                            >
                                {loading ? <LoadingIndicator size={LOADING_INDICATOR_SIZE} /> : "Submit"}
                            </MyCheckinButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    };

    const showInvalid = () => {
        return register ? (
            <Typography align="center">Invalid token, please contact your administrator.</Typography>
        ) : (
            <Typography align="center">Invalid token, please request a new password reset.</Typography>
        );
    };

    return (
        <Container>
            <Grid container justify={"center"} alignItems="center" className={classes.wrapper}>
                <Grid item xs={12}>
                    <Card className={classes.contentContainer}>
                        {tokenValidation.isLoading
                            ? showValidating()
                            : tokenValidation.isSuccess
                            ? showValid()
                            : tokenValidation.isError
                            ? showInvalid()
                            : showInvalid()}
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

ResetPassword.propTypes = {
    register: PropTypes.bool,
};

export default ResetPassword;
