import { Card, CircularProgress, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/page-title";
import TrackedItemCalendar from "../../components/tracked-item-calendar";
import TrackedItemNotes from "../../components/tracked-item-notes";
import { API } from "../../util/api";
import { useStyles } from "./view-tracked-goal.styles";
import { TrackedItem } from "../../interfaces/tracked-item";
import { TrackedItemInfo } from "../../components/view-tracked-goal/tracked-item-info";
import { TrackedItemProgress } from "../../components/view-tracked-goal/tracked-item-progress";
import { TrackedItemCounter } from "../../components/view-tracked-goal/tracked-item-counter";

interface ViewTrackedGoalParams {
    id: string;
}

function ViewTrackedGoal() {
    const { id } = useParams<ViewTrackedGoalParams>();
    const today = new Date();
    const classes = useStyles();
    const [trackedItem, setTrackedItem] = useState<TrackedItem>();
    const trackedItemQuery = useQuery(["getTrackedItem", id], () => {
        return API.getTrackedItem(id);
    });

    useEffect(() => {
        if (trackedItemQuery.isSuccess) {
            const trackedItem = trackedItemQuery.data;
            setTrackedItem(trackedItem);
        }
    }, [trackedItemQuery]);

    return !trackedItem ? (
        <CircularProgress />
    ) : (
        <Container>
            <PageTitle>Monthly Tracker</PageTitle>
            <Card className={classes.root}>
                <Grid container>
                    <Grid item md={5}>
                        <TrackedItemInfo
                            id={trackedItem.id}
                            name={trackedItem.name}
                            description={trackedItem.description}
                        />
                        <Grid container item xs={11} justify="center" alignItems="center" style={{ marginTop: 96 }}>
                            <TrackedItemProgress goal={trackedItem.goal} progress={trackedItem.progress} />
                        </Grid>
                    </Grid>
                    <Grid item md={7}>
                        <Grid container justify="center">
                            <Grid item>
                                {today.toLocaleDateString(undefined, {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })}
                            </Grid>
                        </Grid>
                        <TrackedItemCounter
                            id={trackedItem.id}
                            dailyProgress={trackedItem.dailyProgress}
                            progress={trackedItem.progress}
                            month={trackedItem.month}
                            goal={trackedItem.goal}
                        />
                        <Grid container style={{ marginTop: 24 }} justify="center">
                            <Grid item>
                                <TrackedItemCalendar
                                    dailyProgress={trackedItem.dailyProgress}
                                    weeklyGoals={trackedItem.weeklyGoals}
                                    month={trackedItem.month}
                                    year={trackedItem.year}
                                    trackedItemId={trackedItem.id}
                                    progress={trackedItem.progress}
                                    totalGoal={trackedItem.goal}
                                />
                            </Grid>
                        </Grid>
                        <TrackedItemNotes notes={trackedItem.notes} trackedItemId={trackedItem.id} />
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export { ViewTrackedGoal };
