import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";
import { sectionTitles } from "./config";
import { SectionTitle } from "./section-title";
import { Spacer } from "./spacer";

interface ObjectiveSectionProps {
    objective: string;
}

const ObjectiveSection = ({ objective }: ObjectiveSectionProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.okrSection}>
            <SectionTitle>{sectionTitles.objective}</SectionTitle>
            <Box className={classes.okrContentWrapper}>
                <Spacer />
                <Typography className={classes.okrContent}>{objective}</Typography>
                <Spacer />
            </Box>
        </Box>
    );
};

ObjectiveSection.propTypes = {
    objective: PropTypes.string,
};

export { ObjectiveSection };
