import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import InfoCard from "./info-card";
import { useMeetingLookupFunctions } from "../../hooks/use-meeting-lookup-functions";
import { subMonths } from "date-fns";
import { Meeting } from "../../interfaces/meeting";

interface MeetingSet {
    meetings: Meeting[];
    user: string;
}

interface User {
    id: string;
}

interface EmployeePanelInfoCardsProps {
    teamMeetingSetList: MeetingSet[];
    team: User[];
    className?: string;
}

function EmployeePanelInfoCards({ teamMeetingSetList, team, className }: EmployeePanelInfoCardsProps) {
    const { getMeetingByMonthAndYear, getMeetingsByStatus } = useMeetingLookupFunctions();
    const lastMonth = useMemo(() => subMonths(new Date(), 1), []);

    const getLastMonthAndYear = () => {
        return [lastMonth.getMonth(), lastMonth.getFullYear()];
    };

    const isLastMonthMeetingComplete = (meetings: Meeting[]) => {
        const [month, year] = getLastMonthAndYear();
        const completeMeetings = getMeetingsByStatus(meetings, "complete");
        const meeting = getMeetingByMonthAndYear(completeMeetings, month, year);
        return !!meeting;
    };

    const isLastMonthSubmitted = (meetings: Meeting[]) => {
        const [month, year] = getLastMonthAndYear();
        const meeting = meetings.find((meeting) => meeting.month === month && meeting.year === year);
        return !!meeting;
    };

    const countMeetingsWithCondition = (conditionFunction: (meetings: Meeting[]) => boolean) => {
        let count = 0;
        teamMeetingSetList.forEach((meetingSet) => {
            const { meetings } = meetingSet;
            if (conditionFunction(meetings)) {
                count++;
            }
        });
        return count;
    };

    const countCompletedMeetingsLastMonth = () => {
        return countMeetingsWithCondition(isLastMonthMeetingComplete);
    };

    const countSubmittedAndAwaitingSubmission = () => {
        return countSubmitted() + countAwaitingSubmission();
    };

    const countSubmitted = () => {
        return countMeetingsWithCondition(
            (meetings) => isLastMonthSubmitted(meetings) && !isLastMonthMeetingComplete(meetings)
        );
    };

    const countAwaitingSubmission = () => {
        return countMeetingsWithCondition((meetings) => !isLastMonthSubmitted(meetings));
    };

    return (
        <Grid container spacing={8} className={className}>
            <Grid item xs={12} sm={6} md={4}>
                <InfoCard number={team.length} description={`Assigned Employee${team?.length === 1 ? "" : "s"}`} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <InfoCard
                    number={countCompletedMeetingsLastMonth()}
                    description={`Meeting${teamMeetingSetList.length === 1 ? "" : "s"} Completed This Month`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <InfoCard
                    number={countSubmittedAndAwaitingSubmission()}
                    description="Submitted or Awaiting Submission"
                />
            </Grid>
        </Grid>
    );
}

export default EmployeePanelInfoCards;
