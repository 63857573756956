import { useEffect } from "react";

export const useDebouncer = (onTimeout: () => any, timeoutInMilliseconds: number) => {
    const debounceEffect = () => {
        const timeoutId = setTimeout(onTimeout, timeoutInMilliseconds);
        return () => {
            clearTimeout(timeoutId);
        };
    };

    useEffect(debounceEffect, [onTimeout, timeoutInMilliseconds]);
};
