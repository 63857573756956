import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    companyName: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "36px",
        lineHeight: "44px",
        letterSpacing: "-2px",
        margin: "0px 0px",
    },
    divisionsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "12px",
        margin: "24px 0px",
        width: "100%",
    },
    unassignedUsersAlert: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
        background: theme.palette.primary.main,
        color: "black",
        boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.14);",
        width: "100%",
        minHeight: "24px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "20px",
        "& *": {
            padding: "0px",
        },
    },
    actionsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px",
        margin: "24px 0px",
        width: "100%",
    },
    divisionList: {
        display: "flex",
        flexDirection: "column",
        gap: 24,
        width: "100%",
    },
    overflowMenuItem: { display: "flex", flexDirection: "row", gap: 12 },
}));
