import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useUserMutation } from "../../hooks/mutations/use-user-mutation";
import { useCompany } from "../../hooks/queries/use-company";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";

const fields = [
    { label: "Email", name: "email", isRequired: true },
    { label: "First Name", name: "firstName", isRequired: true },
    { label: "Last Name", name: "lastName", isRequired: true },
];

const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    sendRegistrationEmail: true,
};

function AddEmployeeDialog(props) {
    const history = useHistory();
    const company = useCompany();
    const { open, onClose } = props;
    const [state, setState] = useState(initialState);
    const [errorMessage, setErrorMessage] = useState(null);
    const queryClient = useQueryClient();
    const userMutation = useUserMutation({
        onSuccess: (data) => {
            queryClient.invalidateQueries("fetchUsers");
            history.push(`/coach/coach-station/${data.id}`);
        },
        onError: (error) => setErrorMessage(error.response.data.errors.map((error) => error.message).join(", ")),
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setState({ ...state, [name]: checked });
    };

    const handleClose = () => {
        setState({ ...initialState });
        setErrorMessage("");
        onClose();
    };

    const handleSave = () => {
        const errorFields = [];
        fields.forEach((field) => {
            if (field.isRequired && !state[field.name]) {
                errorFields.push(field.label);
            }
        });
        if (errorFields.length > 0) {
            setErrorMessage(errorFields.join(", ") + " required.");
        } else {
            userMutation.mutate({ ...state, company: company.data.id });
            setErrorMessage("");
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" scroll="body">
            <DialogTitle>Add Employee</DialogTitle>
            <DialogContent>
                <Grid container spacing={6} style={{ marginTop: 8, marginBottom: 8 }}>
                    {fields.map((field) => (
                        <Grid container item key={field.name}>
                            <MyCheckinTextField
                                label={field.label + (field.isRequired ? " *" : "")}
                                name={field.name}
                                value={state[field.name]}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    ))}
                    <Grid container item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="sendRegistrationEmail"
                                    value={state.sendRegistrationEmail}
                                    onChange={handleCheckboxChange}
                                    checked={state.sendRegistrationEmail}
                                    color="primary"
                                />
                            }
                            label="Send registration email when user is added"
                        />
                    </Grid>
                    {errorMessage && (
                        <Grid container item>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>Cancel</MyCheckinButton>
                <MyCheckinButton variant="outlined" onClick={handleSave}>
                    Save
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

AddEmployeeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default AddEmployeeDialog;
