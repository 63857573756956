import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Logo from "./components/logo";
import ForgotPassword from "./pages/forgot-password";
import Login from "./pages/login";
import { Grid, makeStyles } from "@material-ui/core";
import backgroundImage from "./assets/images/backgrounds/login-background.jpeg";
import Register from "./pages/register";
import ResetPassword from "./pages/reset-password";

const devMode = process.env.NODE_ENV === "development";
const stripePKey = devMode
    ? "pk_test_51IENTqJqLtG9xRPFR7UuxPCUVAB5oaU6uDdqkG8IiOOYpV6CKYKt15P9WWF3tm4W1G06H4sDcPAxC8i2Ad2rd9zA005MkUz2kJ"
    : "pk_live_51IENTqJqLtG9xRPFpD6eXmWKUSpAKDDXHg8R7MFp9wJoTB8wgnsp62Ukn9LRVncSSYvJ728DD1anx7RRCIk6128700iaY1GqTc";

const stripePromise = loadStripe(stripePKey);

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        overflowY: "hidden",
    },
    logo: {
        width: theme.spacing(12),
        marginTop: theme.spacing(7),
        marginLeft: theme.spacing(8),
        marginBottom: theme.spacing(7),
    },
}));

function UnauthenticatedApp() {
    const classes = useStyles();
    return (
        <div className={classes.background}>
            <Router>
                <Grid container>
                    <Grid item>
                        <Link to="/">
                            <Logo className={classes.logo} />
                        </Link>
                    </Grid>
                </Grid>
                <Switch>
                    <Route exact path="/register">
                        <Elements stripe={stripePromise}>
                            <Register />
                        </Elements>
                    </Route>
                    <Route exact path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Route path="/forgot-password/:token">
                        <ResetPassword />
                    </Route>
                    <Route path="/register/:token">
                        <ResetPassword register />
                    </Route>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default UnauthenticatedApp;
