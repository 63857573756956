import React, { useState } from "react";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    alert: {
        margin: theme.spacing(1),
    },
}));

const useRequest = ({ url, method, body, onSuccess }) => {
    const [errors, setErrors] = useState();
    const classes = useStyles();

    const doRequest = async () => {
        setErrors(null);
        try {
            const response = await axios[method](url, body);

            if (onSuccess) {
                onSuccess(response.data);
            }

            return response.data;
        } catch (err) {
            setErrors(
                <Alert className={classes.alert} severity="error">
                    {err.response.data.errors.map((error) => (
                        <Typography key={error.message}>{error.message}</Typography>
                    ))}
                </Alert>
            );
        }
    };

    return { doRequest, errors };
};

export default useRequest;
