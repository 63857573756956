import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useStyles } from "./divisions.styles";

interface CompanyNameProps {
    name: string;
}

const CompanyName = ({ name }: CompanyNameProps) => {
    const classes = useStyles();

    return <Typography className={classes.companyName}>{name}</Typography>;
};

CompanyName.propTypes = {
    name: PropTypes.string,
};

export { CompanyName };
