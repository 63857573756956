import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "12px 32px 24px 32px",
        margin: "32px 0px",
    },
    breadcrumbsWrapper: {
        width: "100%",
        height: "18px",
        padding: "2px 0px",
    },
    breadcrumbLink: {
        color: "#202122",
    },
    discussionCardTitle: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "36px",
        lineHeight: "44px",
        textAlign: "center",
        width: "100%",
        letterSpacing: "-2px",
        margin: "24px 0px",
    },
    sectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        width: "100%",
        maxWidth: 768,
        margin: "12px 0px",
    },
    sectionTitle: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "22px",
        margin: "8px 0px",
    },
    okrContent: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "20px",
        margin: "0px 0px 0px 64px",
    },
    discussionSectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "8px 24px 24px",
        width: "100%",
    },
    sectionDivider: {
        border: "none",
        borderTop: "1px solid #c6c7c8",
    },
    postButton: {
        marginTop: "24px",
        alignSelf: "flex-end",
    },
    commentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        marginBottom: "24px",
    },
    commentHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "4px 0px",
    },
    commenterName: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        marginLeft: "12px",
    },
    commentDate: {
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "17px",
        marginLeft: "12px",
    },
    commentContentWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        padding: "0px",
        margin: "4px 0px",
    },
    commentContent: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "17px",
        marginLeft: "44px",
    },
}));
