import React from "react";
import ResultSection from "../result-section";
import TopDiscussionTopicSection from "../top-discussion-topic-section";
import Section from "../section";
import { useIsResultSectionDisabled, useIsTopDiscussionTopicDisabled } from "../monthly-check-in-tab-panel/hooks";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";
import { useViewingAs } from "../../../hooks/use-viewing-as";

const ResultsSection = () => {
    const { meeting, viewingAs } = useMonthlyCheckInState();
    const { isViewingAsCoach } = useViewingAs({ viewingAs });

    const isReturned = () => {
        return meeting?.status === "returned";
    };

    const isSubmitted = () => {
        if (isReturned()) {
            return false;
        }
        return !!meeting?.status;
    };

    const isResultSectionDisabled = useIsResultSectionDisabled({
        isSubmitted: isSubmitted(),
        viewingAsCoach: isViewingAsCoach,
    });

    const isTopDiscussionTopicDisabled = useIsTopDiscussionTopicDisabled({
        isSubmitted: isSubmitted(),
        viewingAsCoach: isViewingAsCoach,
    });

    return (
        <Section withBorder borderColor={isResultSectionDisabled() ? "neutral" : "primary"}>
            <div style={{ marginBottom: 48 }}>
                <TopDiscussionTopicSection disabled={isTopDiscussionTopicDisabled()} />
            </div>
            <ResultSection disabled={isResultSectionDisabled()} category={0} />
        </Section>
    );
};

export default ResultsSection;
