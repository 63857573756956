import React, { ChangeEvent } from "react";
import { Box } from "@material-ui/core";
import MonthSelector from "../../month-selector";
import YearSelector from "../../year-selector";
import { useYear } from "../../../hooks/use-year";
import { User } from "../../../interfaces/user";
import { Role } from "../../../types/role";
import { useHistory } from "react-router-dom";
import { useMonths } from "../../../hooks/use-months";

interface MonthlyCheckInNavigationProps {
    user: User;
    viewingAs: Role;
    month: number;
    year: number;
}

function MonthlyCheckInNavigation({ user, viewingAs, month, year }: MonthlyCheckInNavigationProps) {
    const { yearsArray } = useYear(user);
    const history = useHistory();
    const months = useMonths({ user, year: year });

    const navigateTo = (year: number, month: number) => {
        let pathString = `/${viewingAs}/`;
        pathString +=
            viewingAs === "coach"
                ? `coach-station/${user.id}/monthly-check-in/${year}/${month}`
                : `monthly-check-in/${year}/${month}`;
        history.push(pathString);
    };

    const handleYearSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        navigateTo(parseInt(value), month);
    };

    const handleMonthSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        navigateTo(year, parseInt(value));
    };

    const isMonthOutOfBounds = () => {
        return months.length > 0 && !months.find((indexedMonth) => indexedMonth.index === month);
    };

    if (isMonthOutOfBounds()) {
        const mostRecentMonth = months[months.length - 1]?.index || 0;
        navigateTo(year, mostRecentMonth);
    }

    return (
        <Box>
            {months.length > 0 && <MonthSelector month={month} monthsArr={months} handleSelect={handleMonthSelect} />}
            <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleYearSelect} />
        </Box>
    );
}

export { MonthlyCheckInNavigation };
