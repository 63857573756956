import React, { ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import MyCheckinTextField from "../my-checkin-text-field";
import MyCheckinButton from "../my-checkin-button";
import { Section } from "./section";
import { postButtonStyles } from "./config";
import { discussionAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useAuthState } from "../../hooks/use-auth-state";
import { LoadingIndicator } from "../loading-indicator";
import { Alert } from "@material-ui/lab";

interface NewCommentFormProps {
    discussionId?: string;
}

const NewCommentForm = ({ discussionId }: NewCommentFormProps) => {
    const [comment, setComment] = useState<string>("");
    const { goalId } = useParams<{ goalId: string }>();
    const queryClient = useQueryClient();
    const { user: currentUser } = useAuthState();
    const [posting, setPosting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const clearError = () => setError(null);

    const onCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
        if (error) {
            clearError();
        }
    };

    const handleClick = async () => {
        setPosting(true);
        if (!discussionId) {
            discussionId = (await discussionAPI.create({ goal: goalId })).id;
            queryClient.invalidateQueries(queryKeys.discussion.find);
        }

        if (!currentUser) {
            return;
        }

        try {
            await discussionAPI.addComment({ comment, author: currentUser.id }, discussionId);
            await queryClient.invalidateQueries(queryKeys.discussion.find);
            setComment("");
        } catch (e) {
            const errors = e.response.data.errors
                .map((error: { message: string; field?: string }) => error.message)
                .join(" ");
            setError(errors);
        } finally {
            setPosting(false);
        }
    };

    return (
        <Section>
            <MyCheckinTextField
                multiline
                fullWidth
                rows={3}
                label="Comment"
                name="comment"
                value={comment}
                onChange={onCommentChange}
            />
            <MyCheckinButton
                variant="outlined"
                color="primary"
                style={postButtonStyles}
                onClick={handleClick}
                disabled={posting}
            >
                Post
            </MyCheckinButton>
            {posting && <LoadingIndicator />}
            {error && !posting && <Alert severity="error">{error}</Alert>}
        </Section>
    );
};

NewCommentForm.propTypes = {
    discussionId: PropTypes.string,
};

export { NewCommentForm };
