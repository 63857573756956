import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { useGoalDelete } from "../hooks/mutations/use-goal-delete";
import { useGoalMutation } from "../hooks/mutations/use-goal-mutation";
import { useMaxLength } from "../hooks/use-max-length";
import MyCheckinTextField from "./my-checkin-text-field";
import MyCheckinButton from "./my-checkin-button";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(6),
    },
}));

function EditGoalDialog(props) {
    const { goal, open, onClose } = props;
    const [state, setState] = useState({
        description: goal?.description,
        accomplishment: goal?.accomplishment,
    });
    const classes = useStyles();
    const [showWarning, setShowWarning] = useState(false);
    const queryClient = useQueryClient();
    const mutationOptions = { onSuccess: () => queryClient.invalidateQueries("fetchGoals") };
    const goalMutation = useGoalMutation(mutationOptions);
    const deleteGoalMutation = useGoalDelete(mutationOptions);
    useEffect(() => {
        setState({
            description: goal?.description,
            accomplishment: goal?.accomplishment,
        });
    }, [goal]);
    const descriptionConstraint = useMaxLength({ value: state.description });
    const accomplishmentConstraint = useMaxLength({ value: state.accomplishment });
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setHasError(descriptionConstraint.error || accomplishmentConstraint.error);
    }, [descriptionConstraint.error, accomplishmentConstraint.error]);

    const handleChange = (event) => {
        const { value, name } = event.target;
        setState({ ...state, [name]: value });
    };

    const handleSave = () => {
        const updatedGoal = { ...goal, description: state.description, accomplishment: state.accomplishment };
        setShowWarning(false);
        goalMutation.mutate(updatedGoal);
        // onSubmit(updatedGoal);
        onClose();
    };

    const handleDelete = () => {
        if (!showWarning) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
            // onDelete(goal);
            deleteGoalMutation.mutate(goal);
            onClose();
        }
    };

    const handleCancel = () => {
        setState({
            description: goal?.description,
            accomplishment: goal?.accomplishment,
        });
        setShowWarning(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
            <DialogTitle>Edit Goal</DialogTitle>
            <DialogContent>
                <MyCheckinTextField
                    value={state.description}
                    label="Description"
                    fullWidth
                    multiline
                    rows={2}
                    onChange={handleChange}
                    name="description"
                    className={classes.input}
                    onFocus={descriptionConstraint.handleFocus}
                    onBlur={descriptionConstraint.handleBlur}
                    error={descriptionConstraint.error}
                    helperText={descriptionConstraint.helperText}
                />
                <MyCheckinTextField
                    name="accomplishment"
                    value={state.accomplishment}
                    label="Accomplishment"
                    fullWidth
                    multiline
                    rows={2}
                    onChange={handleChange}
                    className={classes.input}
                    onFocus={accomplishmentConstraint.handleFocus}
                    onBlur={accomplishmentConstraint.handleBlur}
                    error={accomplishmentConstraint.error}
                    helperText={accomplishmentConstraint.helperText}
                />
                {showWarning && (
                    <Alert color="warning">
                        Delete this goal? <strong>This cannot be undone.</strong> Click again to proceed.
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handleDelete} color="primary">
                    <DeleteIcon />
                </IconButton>
                <MyCheckinButton color="primary" onClick={handleCancel}>
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton variant="outlined" color="primary" onClick={handleSave} disabled={hasError}>
                    Save
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default EditGoalDialog;
