import React from "react";
import { useParams } from "react-router-dom";
import { Division } from "../../pages/division";

const DivisionParamsWrapper = () => {
    const { divisionId } = useParams<{ divisionId: string }>();
    return <Division divisionId={divisionId} />;
};

export { DivisionParamsWrapper };
