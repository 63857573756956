import { createMuiTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        neutral: Palette["primary"];
    }
    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
    }
}

const myCheckinTheme = createMuiTheme({
    palette: {
        primary: {
            light: "#f6ce8e",
            main: "#ffb84c",
            dark: "#f1960e",
        },
        secondary: {
            light: "#67cad5",
            main: "#4fa9b3",
            dark: "#458a92",
        },
        neutral: {
            light: "#ffffff",
            main: "#929497",
            dark: "#000000",
        },
        text: {
            primary: "#000000",
            // disabled: "#000000",
        },
    },
    typography: {
        fontFamily: "Montserrat, Open Sans, sans-serif",
        fontSize: 16,
        button: {
            fontWeight: "bold",
            fontSize: 14,
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 500,
        },
        body1: {
            fontSize: 16,
        },
    },
    spacing: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768, 960],
    overrides: {
        MuiCssBaseline: {
            "@global": {
                // "@font-face": "Montserrat, Open Sans, sans-serif",
                "@font-face": [{ fontFamily: "Montserrat" }, { fontFamily: "Open Sans" }, { fontFamily: "sans-serif" }],
            },
        },
    },
});

export { myCheckinTheme };
