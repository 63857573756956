import { useQueryKey } from "../queries/use-query-key";
import { useQuery } from "react-query";
import { CRUD } from "../../util/api/interfaces/crud";

interface GetOptions<T> {
    onSuccess?: (data: T) => any;
    onError?: (err: unknown) => any;
    enabled?: boolean;
}

export const useGetApi = <T>(api: CRUD, key: string, id: string | number, options: GetOptions<T> = {}) => {
    const { onSuccess, onError, enabled } = options;
    const queryKey = useQueryKey(key, { id });
    const query = useQuery<string | number, unknown, T>(queryKey, () => api.get(id), {
        onSuccess,
        onError,
        enabled,
    });

    const { data, status, error } = query;

    return {
        data,
        status,
        error,
    };
};
