import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SubNav from "./components/sub-nav";
import TopNav from "./components/top-nav";
import usePermissions from "./hooks/use-permissions";
import FourOhFour from "./pages/404";
import CoachStation from "./pages/coach-station";
import CompanySettings from "./pages/company-settings";
import EmployeePanel from "./pages/employee-panel";
import Employees from "./pages/employees";
import Profile from "./pages/profile";
import ViewMonthlyCheckIn from "./pages/view-monthly-check-in";
import { CoachAppProvider } from "./providers/coach-app-provider";
import { EmployeeAppProvider } from "./providers/employee-app-provider";
import { ViewCompletedItems } from "./pages/view-completed-items";
import { ViewMasterPlan } from "./pages/view-master-plan";
import { useAuthState } from "./hooks/use-auth-state";
import { coachPath, urls, wildcardPath } from "./config/urls";
import { ViewMajorAchievements } from "./pages/view-major-achievements";
import { MonthlyCheckInProvider } from "./providers/monthly-check-in-provider";
import { Divisions } from "./pages/divisions";
import { DivisionParamsWrapper } from "./components/division/division-params-wrapper";
import { OkrProfile } from "./pages/okr-profile";
import { Discussion } from "./pages/discussion";

function CoachApp(props) {
    const { isAdmin } = usePermissions();
    const { user } = useAuthState();

    const {
        employeePanel,
        monthlyCheckIn,
        masterPlan,
        completedItems,
        coachStation,
        profile,
        employees,
        companySettings,
        majorAchievements,
        divisions,
        teamshare,
        discussion,
    } = urls.coach;

    return (
        <CoachAppProvider>
            <TopNav coach />
            <SubNav coach />
            <Switch>
                <Route exact path={coachPath}>
                    <Redirect to={employeePanel.baseUrl} />
                </Route>
                <Route path={employeePanel.baseUrl}>
                    <EmployeePanel userId={user.id} />
                </Route>
                <Route exact path={divisions.baseUrl}>
                    <Divisions />
                </Route>
                <Route path={divisions.baseUrl + divisions.params}>
                    <DivisionParamsWrapper />
                </Route>
                <Route exact path={teamshare.baseUrl + teamshare.params}>
                    <OkrProfile />
                </Route>
                <Route path={discussion.baseUrl + discussion.params}>
                    <Discussion />
                </Route>
                <Route
                    exact
                    path={coachStation.baseUrl + coachStation.params + "/monthly-results" + monthlyCheckIn.params}
                >
                    <MonthlyCheckInProvider>
                        <ViewMonthlyCheckIn />
                    </MonthlyCheckInProvider>
                </Route>
                <Route exact path={monthlyCheckIn.baseUrl + monthlyCheckIn.params}>
                    <MonthlyCheckInProvider>
                        <ViewMonthlyCheckIn />
                    </MonthlyCheckInProvider>
                </Route>
                <Route exact path={masterPlan.baseUrl + masterPlan.params}>
                    <EmployeeAppProvider>
                        <ViewMasterPlan />
                    </EmployeeAppProvider>
                </Route>
                <Route exact path={completedItems.baseUrl + completedItems.params}>
                    <EmployeeAppProvider>
                        <ViewCompletedItems />
                    </EmployeeAppProvider>
                </Route>
                <Route exact path={majorAchievements.baseUrl + majorAchievements.params}>
                    <ViewMajorAchievements />
                </Route>
                <Route path={coachStation.baseUrl + coachStation.params}>
                    <CoachStation />
                </Route>
                <Route path={profile.baseUrl}>
                    <Profile />
                </Route>

                {isAdmin && (
                    <>
                        <Route path={employees.baseUrl}>
                            <Employees />
                        </Route>
                        <Route path={companySettings.baseUrl}>
                            <CompanySettings />
                        </Route>
                    </>
                )}
                <Route path={wildcardPath}>
                    <FourOhFour />
                </Route>
            </Switch>
        </CoachAppProvider>
    );
}

export default CoachApp;
