import React from "react";
import PropTypes from "prop-types";
import { MajorAchievementsPdfDoc } from "./major-achievements-pdf-doc";
import { useGoalState } from "../../../hooks/states/use-goal-state";
import { useUsersState } from "../../../hooks/states/use-users-state";
import { useCompanyState } from "../../../hooks/use-company-state";
import { useObjectiveState } from "../../../hooks/states/use-objective-state";

interface MajorAchievementsPdfProps {
    userId: string;
    year: number;
}

const MajorAchievementsPdf = ({ userId, year }: MajorAchievementsPdfProps) => {
    const { users } = useUsersState({ id: userId });
    const { goals: achievements } = useGoalState({ user: userId, isAccomplished: true, category: 0, year });
    const { objectives: completedObjectives } = useObjectiveState({
        user: userId,
        isAccomplished: true,
        category: 0,
        year,
    });
    const { company } = useCompanyState();

    const getEmployeeName = () => {
        return users ? `${users[0]?.firstName} ${users[0]?.lastName}` : "";
    };

    return (
        <MajorAchievementsPdfDoc
            achievements={achievements}
            objectives={completedObjectives}
            year={year}
            companyName={company.name}
            employeeName={getEmployeeName()}
        />
    );
};

MajorAchievementsPdf.propTypes = {
    userId: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
};

export { MajorAchievementsPdf };
