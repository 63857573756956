import React from "react";
import { Box, Card, Container, Grid } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import { useStyles } from "./quick-plan.styles";
import { useYear } from "../../hooks/use-year";
import YearSelector from "../../components/year-selector";
import { User } from "../../interfaces/user";
import MasterPlanTabPanel from "../../components/master-plan/master-plan-tab-panel";
import MonthlyCheckInButton from "../../components/master-plan/monthly-check-in-button";
import { DownloadMasterPlanPdfButton } from "../../components/master-plan/download-master-plan-pdf-button";

interface QuickPlanProps {
    user: User;
}

const QuickPlan = ({ user }: QuickPlanProps) => {
    const classes = useStyles();
    const { year, setYear, yearsArray } = useYear(user);

    const handleYearSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setYear(parseInt(value));
    };

    return (
        <Container>
            <PageTitle>Quick Plan</PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container item className={classes.selectRow}>
                    <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleYearSelect} />
                </Grid>
                <Box>
                    <MasterPlanTabPanel value={0} userID={user.id} disabled={false} year={year} />
                    <Grid container justify="flex-end" className={classes.downloadPdfContainer}>
                        <Grid item style={{ marginRight: 32 }}>
                            <MonthlyCheckInButton />
                        </Grid>
                        <Grid item>
                            <DownloadMasterPlanPdfButton
                                userId={user.id}
                                firstName={user.firstName}
                                lastName={user.lastName}
                                year={year}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Container>
    );
};

export default QuickPlan;
