import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./okr-profile.styles";
import { sectionTitles } from "./config";
import { SectionTitle } from "./section-title";
import { KeyResultList } from "./key-result-list";
import { KeyResultListItem } from "./key-result-list-item";
import { Goal } from "../../interfaces/goal";

interface KeyResultsSectionProps {
    keyResults: Goal[];
    isLoading: boolean;
}

const KeyResultsSection = ({ keyResults, isLoading }: KeyResultsSectionProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.okrSection}>
            <SectionTitle>{sectionTitles.keyResults}</SectionTitle>
            <KeyResultList>
                {isLoading ? (
                    <Typography align="center">Loading...</Typography>
                ) : (
                    keyResults.map((keyResult) => (
                        <KeyResultListItem description={keyResult.description} id={keyResult.id} key={keyResult.id} />
                    ))
                )}
            </KeyResultList>
        </Box>
    );
};

KeyResultsSection.propTypes = {
    keyResults: PropTypes.array,
};

export { KeyResultsSection };
