import { createMuiTheme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
    },
    centered: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    centeredX: {
        display: "flex",
        justifyContent: "center",
    },
    centeredY: {
        display: "flex",
        alignItems: "center",
    },
    rightBorder: {
        // borderRight: "1px solid #929497",
    },
    smallText: {
        fontSize: 12,
    },
    statusIcon: {
        marginRight: theme.spacing(6),
        width: 15,
        height: 15,
    },
    label: {
        fontWeight: "bold",
        fontSize: 14,
        color: "black",
    },
    title: {
        fontWeight: "bold",
    },
}));

export const dateTimePickerTheme = createMuiTheme({
    palette: {
        primary: {
            light: "#f6ce8e",
            main: "#ffb84c",
            dark: "#f1960e",
        },
        secondary: {
            light: "#67cad5",
            main: "#4fa9b3",
            dark: "#458a92",
        },
        neutral: {
            light: "#ffffff",
            main: "#929497",
            dark: "#000000",
        },
        text: {
            primary: "#000000",
        },
    },
    typography: {
        fontFamily: "Montserrat, Open Sans, sans-serif",
    },
});
