import React from "react";
import PropTypes from "prop-types";
import { useMonthName } from "../../../hooks/use-month-name";
import { useUsersState } from "../../../hooks/states/use-users-state";
import { MonthlyCheckInPdfDoc } from "./monthly-check-in-pdf-doc";
import { Result } from "../../../interfaces/result";
import { resultAPI, takeawayAPI, topDiscussionTopicAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";
import { useFindApi } from "../../../hooks/api/use-find-api";
import { TopDiscussionTopic } from "../../../interfaces/top-discussion-topic";
import { Takeaway } from "../../../interfaces/takeaway";

interface MonthlyCheckInPdfProps {
    userId: string;
    month: number;
    year: number;
}

const MonthlyCheckInPdf = ({ userId, month, year }: MonthlyCheckInPdfProps) => {
    const { users } = useUsersState({ id: userId });
    // const { results } = useResultsState({ user: userId, month, year });
    const findResultsQuery = useFindApi<Result>(
        resultAPI,
        queryKeys.result.get,
        { user: userId, month, year },
        "results"
    );
    const findPriorityDiscussionQuery = useFindApi<TopDiscussionTopic>(
        topDiscussionTopicAPI,
        queryKeys.topDiscussionTopic.get,
        { user: userId, month, year },
        "topDiscussionTopics"
    );
    const findTakeawaysQuery = useFindApi<Takeaway>(
        takeawayAPI,
        queryKeys.takeaway.get,
        { user: userId, month, year },
        "takeaways"
    );

    const results = findResultsQuery.data.result;
    const priorityDiscussionTopic =
        findPriorityDiscussionQuery.data.result[0]?.text || "No topic entered for this month.";
    const takeaways = findTakeawaysQuery.data.result;

    const monthName = useMonthName(month);

    const getEmployeeName = () => {
        return users ? `${users[0]?.firstName} ${users[0]?.lastName}` : "";
    };

    return (
        <MonthlyCheckInPdfDoc
            results={results}
            priorityDiscussionTopic={priorityDiscussionTopic}
            takeaways={takeaways}
            monthName={monthName}
            year={year}
            employeeName={getEmployeeName()}
        />
    );
};

MonthlyCheckInPdf.propTypes = {
    userId: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
};

export { MonthlyCheckInPdf };
