import React from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { PdfDownloadButton } from "../pdf/pdf-download-button";
import { useMonthName } from "../../hooks/use-month-name";
import { MonthlyCheckInPdf } from "../pdf/monthly-check-in/monthly-check-in-pdf";

interface DownloadMonthlyCheckInPdfProps {
    userId: string;
    month: number;
    year: number;
    firstName: string;
    lastName: string;
}

const DownloadMonthlyCheckInPdfButton = ({
    userId,
    month,
    year,
    lastName,
    firstName,
}: DownloadMonthlyCheckInPdfProps) => {
    const queryClient = useQueryClient();
    const monthName = useMonthName(month);

    return (
        <PdfDownloadButton fileName={`${firstName}_${lastName}_${monthName}_${year}_Monthly_Check-In.pdf`}>
            <QueryClientProvider client={queryClient}>
                <MonthlyCheckInPdf userId={userId} month={month} year={year} />
            </QueryClientProvider>
        </PdfDownloadButton>
    );
};

DownloadMonthlyCheckInPdfButton.propTypes = {};

export { DownloadMonthlyCheckInPdfButton };
