import React from "react";

export const CoachAppContext = React.createContext({
    user: {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        permissions: {
            isCoach: true,
            isAdmin: false,
        },
        lastLogin: "",
        coaches: [],
        profileImage: "",
    },
    queries: {},
});
