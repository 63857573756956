import React from "react";
import { CardContent } from "@material-ui/core";

interface AccountDeactivatedContentProps {
    children: any;
}

function AccountDeactivatedContent({ children }: AccountDeactivatedContentProps) {
    return <CardContent>{children}</CardContent>;
}

export { AccountDeactivatedContent };
