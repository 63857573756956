import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import MyCheckinButton from "../my-checkin-button";
import { makeStyles } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

interface PdfDownloadButtonProps {
    fileName: string;
    children: any;
    className?: string;
    linkText?: string;
}

const useStyles = makeStyles((theme) => ({
    pdfDownloadLink: {
        textDecoration: "none",
    },
}));

function PdfDownloadButton({ fileName, children, className, linkText = "Download PDF" }: PdfDownloadButtonProps) {
    const classes = useStyles();

    return (
        <PDFDownloadLink document={children} fileName={fileName} className={`${classes.pdfDownloadLink} ${className}`}>
            {({ blob, url, loading, error }) =>
                loading ? (
                    "Loading..."
                ) : (
                    <MyCheckinButton variant="contained" color="primary">
                        <PictureAsPdfIcon fontSize="inherit" style={{ marginRight: 4 }} />
                        {linkText}
                    </MyCheckinButton>
                )
            }
        </PDFDownloadLink>
    );
}

export { PdfDownloadButton };
