import React from "react";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddGoalDialog from "../add-goal-dialog";
import { useDialog } from "../../hooks/use-dialog";
import { useMutation, useQueryClient } from "react-query";
import { TrackedItem } from "../../interfaces/tracked-item";
import { API } from "../../util/api";

interface TrackedItemInfoProps {
    id: string;
    name: string;
    description: string;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: theme.spacing(4),
    },
    description: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
    },
}));

function TrackedItemInfo({ id, name, description }: TrackedItemInfoProps) {
    const classes = useStyles();
    const addGoalDialog = useDialog({});
    const queryClient = useQueryClient();
    const trackedItemMutation = useMutation<any, unknown, Partial<TrackedItem>>(
        (trackedItem) => {
            return API.updateTrackedItem(trackedItem);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("fetchTrackedItems");
                queryClient.invalidateQueries("getTrackedItem");
            },
            onError: () => queryClient.invalidateQueries(["getTrackedItem", id]),
        }
    );

    const handleSaveGoal = ({ name, description }: { name: string; description: string }) => {
        trackedItemMutation.mutate({ id, name, description });
    };

    return (
        <Grid container item xs={11}>
            <Grid item xs={11}>
                <Typography align="center" className={classes.name}>
                    {name}
                </Typography>
                <Typography className={classes.description}>{description}</Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton size="small" onClick={addGoalDialog.handleOpen}>
                    <EditIcon color="disabled" />
                </IconButton>
            </Grid>
            <AddGoalDialog
                open={addGoalDialog.isOpen}
                onClose={addGoalDialog.handleClose}
                onSave={handleSaveGoal}
                name={name}
                description={description}
                id={id}
            />
        </Grid>
    );
}

export { TrackedItemInfo };
