import { useCallback, useEffect, useState } from "react";

export const useMaxLength = ({ maxLength = 165, value = "" }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");

    const getHelperText = useCallback(() => {
        return maxLength && isFocused ? `${value.length} characters used / ${maxLength} characters maximum` : "";
    }, [isFocused, maxLength, value]);

    useEffect(() => {
        setError(value.length > maxLength && maxLength !== 0);
        setHelperText(getHelperText);
    }, [value, maxLength, getHelperText]);

    const handleFocus = () => {
        setIsFocused(true);
        setHelperText(getHelperText);
    };

    const handleBlur = () => {
        setHelperText("");
        setIsFocused(false);
    };

    return {
        handleFocus,
        handleBlur,
        error,
        helperText,
    };
};
