import { ActivityRecord } from "../../interfaces/activity-record/activity-record";
import { useMutation, UseMutationOptions } from "react-query";
import { activityRecordAPI } from "../../util/api";
import { ActivityRecordRequiredAttrs } from "../../util/api/interfaces/activity-record-required-attrs";

export const useActivityRecordMutation = (
    params: ActivityRecordRequiredAttrs,
    options: UseMutationOptions<any, unknown, Partial<ActivityRecord>>
) => {
    return useMutation((data: Partial<ActivityRecord>) => {
        return activityRecordAPI.update(params, data);
    }, options);
};
