import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, Text } from "@react-pdf/renderer";

interface PdfPageTitleProps {
    children: React.ReactNode;
}

const styles = StyleSheet.create({
    title: {
        textAlign: "center",
        fontSize: 24,
        marginBottom: 4,
    },
});

const PdfPageTitle = ({ children }: PdfPageTitleProps) => {
    return <Text style={styles.title}>{children}</Text>;
};

PdfPageTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PdfPageTitle };
