import React from "react";
import { MenuItem } from "@material-ui/core";
import { useStyles } from "./top-nav.styles";
import { Link as RouterLink } from "react-router-dom";

const ViewAllLink = () => {
    const classes = useStyles();
    return (
        <MenuItem className={classes.viewAllNotificationsLink} component={RouterLink} to="/employee/notifications">
            View All
        </MenuItem>
    );
};

export { ViewAllLink };
