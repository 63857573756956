import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "../division.styles";

interface UserInfoProps {
    name: string;
    jobTitle: string;
}

const UserInfo = ({ name, jobTitle }: UserInfoProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.userInfo}>
            <Typography className={classes.userName}>{name}</Typography>
            <Typography className={classes.jobTitle}>{jobTitle}</Typography>
        </Box>
    );
};

UserInfo.propTypes = {
    name: PropTypes.string,
    jobTitle: PropTypes.string,
};

export { UserInfo };
