import React, { ChangeEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";
import { useMaxLength } from "../../hooks/use-max-length";
import { useQueryClient } from "react-query";
import { useObjectiveMutation } from "../../hooks/mutations/use-objective-mutation";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import { useObjectiveDelete } from "../../hooks/mutations/use-objective-delete";

interface EditObjectiveDialogProps {
    isOpen: boolean;
    onClose: () => any;
    objectiveId: string;
    description: string;
    accomplishment: string;
}

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(6),
    },
}));

const EditObjectiveDialog = ({
    isOpen,
    onClose,
    objectiveId,
    description,
    accomplishment,
}: EditObjectiveDialogProps) => {
    const classes = useStyles();
    const [state, setState] = useState({ id: objectiveId, description, accomplishment });
    const descriptionConstraint = useMaxLength({ value: state.description });
    const accomplishmentConstraint = useMaxLength({ value: state.accomplishment });
    const queryClient = useQueryClient();
    const refetchObjectives = () => queryClient.invalidateQueries("fetchObjectives");
    const objectiveMutation = useObjectiveMutation({
        onSuccess: refetchObjectives,
    });
    const deleteObjectiveMutation = useObjectiveDelete({
        onSuccess: refetchObjectives,
    });
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        setState({ id: objectiveId, description, accomplishment });
    }, [objectiveId, description, accomplishment]);

    const resetForm = () => {
        setState({ ...state, description, accomplishment });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setState({ ...state, [name]: value });
    };

    const handleSave = () => {
        objectiveMutation.mutate(state);
        onClose();
    };

    const handleDelete = () => {
        if (!showWarning) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
            deleteObjectiveMutation.mutate({ id: objectiveId });
            onClose();
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Objective</DialogTitle>
            <DialogContent>
                <MyCheckinTextField
                    label="Description"
                    name="description"
                    value={state.description}
                    fullWidth
                    multiline
                    rows={2}
                    onFocus={descriptionConstraint.handleFocus}
                    onBlur={descriptionConstraint.handleBlur}
                    error={descriptionConstraint.error}
                    helperText={descriptionConstraint.helperText}
                    onChange={handleChange}
                    className={classes.input}
                />
                <MyCheckinTextField
                    label="Accomplishment"
                    name="accomplishment"
                    value={state.accomplishment}
                    fullWidth
                    multiline
                    rows={2}
                    onFocus={accomplishmentConstraint.handleFocus}
                    onBlur={accomplishmentConstraint.handleBlur}
                    error={accomplishmentConstraint.error}
                    helperText={accomplishmentConstraint.helperText}
                    onChange={handleChange}
                    className={classes.input}
                />
                {showWarning && (
                    <Alert color="warning">
                        Delete this objective? <strong>This cannot be undone.</strong> Click again to proceed.
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handleDelete} color="primary">
                    <DeleteIcon />
                </IconButton>
                <MyCheckinButton onClick={handleClose}>Cancel</MyCheckinButton>
                <MyCheckinButton variant="contained" color="primary" onClick={handleSave}>
                    Save
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

EditObjectiveDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    objectiveId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export { EditObjectiveDialog };
