import { Box, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useCurrentUser } from "../../hooks/queries/use-current-user";
import { API } from "../../util/api";
import Avatar from "../avatar";
import TabPanel from "../coach-station/tab-panel";
import MyCheckinButton from "../my-checkin-button";

const useStyles = makeStyles((theme) => ({
    formContainer: { padding: theme.spacing(8) },
}));

function ProfileImageTab(props) {
    const classes = useStyles();
    const { index, value } = props;
    const { status, data } = useCurrentUser();
    const [user, setUser] = useState({});
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const queryClient = useQueryClient();
    const invalidateProfileImageQuery = () => queryClient.invalidateQueries(["fetchProfileImage", user.id]);
    const profileImageMutation = useMutation(
        (image) => {
            return API.uploadProfileImage(image);
        },
        {
            onSuccess: () => {
                invalidateProfileImageQuery();
                queryClient.setQueryData(["fetchProfileImage", user.id], uploadedImage);
                setUploadedImage(null);
                setIsSaving(false);
            },
            onError: () => {
                invalidateProfileImageQuery();
                setUploadedImage(null);
                setIsSaving(false);
            },
        }
    );

    useEffect(() => {
        if (status === "success") {
            setUser(data);
        } else {
            setUser({});
        }
    }, [status, data]);

    const handleChooseFileClick = (event) => {
        document.getElementById("image-input").click();
    };

    const handleImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUploadedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCancel = (event) => {
        setUploadedImage(null);
    };

    const handleSave = (event) => {
        profileImageMutation.mutate(uploadedImage);
        setIsSaving(true);
    };

    return (
        <TabPanel index={index} value={value}>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Grid container justify="center" className={classes.formContainer}>
                    <Grid item md={6}>
                        <Grid container justify="center" spacing={3}>
                            <Grid container item justify="center">
                                {isSaving ? (
                                    <Box
                                        style={{
                                            width: 150,
                                            height: 150,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Avatar diameter={150} id={user.id} borderWidth={5} />
                                )}
                            </Grid>
                            <Grid container item justify="center">
                                <Typography style={{ fontWeight: 600 }}>Current Profile Image</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container justify="center" spacing={3}>
                            <Grid container item justify="center">
                                <Avatar diameter={150} url={uploadedImage} borderWidth={5} />
                            </Grid>
                            <Grid container item justify="center">
                                <Typography style={{ fontWeight: 600 }}>Preview</Typography>
                            </Grid>
                            <Grid container item>
                                {uploadedImage ? (
                                    isSaving ? (
                                        <Typography style={{ margin: "auto" }} variant="subtitle1">
                                            Saving...
                                        </Typography>
                                    ) : (
                                        <Grid container justify="center" alignItems="center" spacing={3}>
                                            <Grid item>
                                                <MyCheckinButton onClick={handleCancel}>Cancel</MyCheckinButton>
                                            </Grid>
                                            <Grid item>
                                                <MyCheckinButton variant="outlined" onClick={handleSave}>
                                                    Save
                                                </MyCheckinButton>
                                            </Grid>
                                        </Grid>
                                    )
                                ) : (
                                    <Grid container item justify="center">
                                        <MyCheckinButton
                                            variant="outlined"
                                            onClick={handleChooseFileClick}
                                            style={{ marginBottom: 8 }}
                                        >
                                            Choose File...
                                        </MyCheckinButton>
                                        <Typography style={{ fontSize: 12 }}>
                                            Tip: Upload a square image for best results!
                                        </Typography>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="image-input"
                                            style={{ display: "none" }}
                                            onChange={handleImageUpload}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </TabPanel>
    );
}

ProfileImageTab.propTypes = {
    index: PropTypes.number,
    value: PropTypes.number,
};

export default ProfileImageTab;
