import React from "react";
import DateFormat from "../../date-format";
import { Typography } from "@material-ui/core";
import { Meeting } from "../../../interfaces/meeting";
import { useMeetingLookupFunctions } from "../../../hooks/use-meeting-lookup-functions";

interface ScheduledMeetingDateProps {
    meetings: Meeting[];
}

function ScheduledMeetingDate({ meetings }: ScheduledMeetingDateProps) {
    const { getNextMeetingDate, getLastMeetingDate } = useMeetingLookupFunctions();

    const isNextMeetingScheduled = () => {
        return !!getNextMeetingDate(meetings);
    };

    const isLastCompletedMeetingExists = () => {
        return !!getLastMeetingDate(meetings);
    };

    const renderNextMeetingDate = () => {
        const nextMeetingDate = getNextMeetingDate(meetings);
        return <DateFormat prefixText="Next Meeting: " date={nextMeetingDate} />;
    };

    const renderLastMeetingDate = () => {
        const lastMeetingDate = getLastMeetingDate(meetings);
        return <DateFormat prefixText="Last Meeting: " date={lastMeetingDate} />;
    };

    const renderNoMeetingsScheduled = () => {
        return (
            <Typography variant="caption" style={{ fontStyle: "italic" }}>
                -- No meetings scheduled --
            </Typography>
        );
    };

    const renderNextOrLastMeetingDate = () => {
        if (isNextMeetingScheduled()) {
            return renderNextMeetingDate();
        } else if (isLastCompletedMeetingExists()) {
            return renderLastMeetingDate();
        } else {
            return renderNoMeetingsScheduled();
        }
    };

    return renderNextOrLastMeetingDate();
}

export default ScheduledMeetingDate;
