import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API } from "../../util/api";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";
import DialogCancelButton from "./dialog-cancel-button";

function TakeawayRow(props) {
    const { takeaway, onChange } = props;

    return (
        <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ paddingLeft: 16, paddingRight: 16, marginBottom: 2 }}
        >
            <Grid item xs={11}>
                {takeaway.description}
            </Grid>
            <Grid item xs={1}>
                <Checkbox
                    checked={takeaway.isReminder}
                    name="isReminder"
                    color="primary"
                    onChange={(event) => onChange(event, takeaway.id)}
                />
            </Grid>
        </Grid>
    );
}

function MeetingWrapUpDialog(props) {
    const { isOpen, handleClose, month, year, userID, onFinish } = props;
    const queryClient = useQueryClient();
    const [takeaways, setTakeaways] = useState([]);
    const [notes, setNotes] = useState("");
    const takeawayQuery = useQuery(["fetchTakeawaysForWrapUp", month, year, userID], () => {
        return API.getTakeaways({ user: userID, month, year });
    });
    const takeawayMutation = useMutation(
        (takeaway) => {
            return API.updateTakeaway(takeaway);
        },
        {
            onError: () => {
                queryClient.invalidateQueries(["fetchTakeaways", month, year, userID]);
            },
        }
    );

    useEffect(() => {
        if (takeawayQuery.isSuccess) {
            setTakeaways(takeawayQuery.data.takeaways);
        }
    }, [takeawayQuery]);

    const handleReminderCheckbox = (event, id) => {
        const { checked } = event.target;
        takeawayMutation.mutate({
            id,
            isReminder: checked,
        });
        const updatedTakeaways = takeaways.map((takeaway) =>
            takeaway.id === id ? { ...takeaway, isReminder: checked } : takeaway
        );
        setTakeaways(updatedTakeaways);
    };

    const handleNotesChange = (event) => {
        const { value } = event.target;
        setNotes(value);
    };

    const handleFinish = () => {
        onFinish(notes);
        handleClose();
    };

    const showTakeaway = (takeaway) => {
        return <TakeawayRow takeaway={takeaway} onChange={handleReminderCheckbox} />;
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Summary & Wrap-Up</DialogTitle>
            <DialogContent>
                <Grid container justify="space-between" alignItems="center" style={{ padding: 16 }}>
                    <Grid item>
                        <Typography variant="subtitle1">Takeaways</Typography>
                    </Grid>
                    <Grid item>Reminder?</Grid>
                </Grid>
                {takeaways.map((takeaway) => showTakeaway(takeaway))}
                <Grid container alignItems="center" style={{ padding: 16 }}>
                    <Grid item xs={12}>
                        <MyCheckinTextField
                            multiline
                            rows={3}
                            name="notes"
                            value={notes}
                            onChange={handleNotesChange}
                            label="Final Notes"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <DialogCancelButton onClick={handleClose} />
                <MyCheckinButton variant="outlined" onClick={handleFinish}>
                    Finish
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

MeetingWrapUpDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    userID: PropTypes.string.isRequired,
};

export default MeetingWrapUpDialog;
