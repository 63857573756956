import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useMonthlyCheckInState } from "../../../hooks/use-monthly-check-in-state";

const StartMeetingButton = () => {
    const { isMeetingStarted, toggleIsMeetingStarted, meeting } = useMonthlyCheckInState();
    const meetingNotStartedButtonText = "Start Meeting";
    const meetingStartedButtonText = "Stop Meeting";

    const canMeetingStart = meeting?.status === "scheduled";

    const meetingCanStartTooltipText = "Click here to start the meeting and enable the Takeaways section.";
    const meetingCannotStartTooltipText =
        'Cannot start the meeting. The meeting must have a status of "Scheduled" and be confirmed by both the Employee and Coach.';

    return (
        <Tooltip
            title={
                canMeetingStart ? (isMeetingStarted ? "" : meetingCanStartTooltipText) : meetingCannotStartTooltipText
            }
        >
            <div>
                <Button
                    variant="contained"
                    style={{ backgroundColor: canMeetingStart ? "#31B237" : "rgba(0,0,0,0.12)", height: 33 }}
                    onClick={toggleIsMeetingStarted}
                    disabled={!canMeetingStart}
                >
                    {isMeetingStarted ? meetingStartedButtonText : meetingNotStartedButtonText}
                </Button>
            </div>
        </Tooltip>
    );
};

export default StartMeetingButton;
