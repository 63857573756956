import { useEffect, useState } from "react";
import { eachMonthOfInterval } from "date-fns";
import { User } from "../interfaces/user";
import { monthNames } from "../util/month-names";

interface UseMonthsParams {
    user: User;
    year: number;
}

interface IndexedMonthNames {
    index: number;
    name: string;
}

export function useMonths({ user, year }: UseMonthsParams) {
    const [months, setMonths] = useState<IndexedMonthNames[]>([]);

    useEffect(() => {
        if (!user.createdAt) {
            return;
        }
        const startDate = new Date(user.createdAt).setMonth(0);
        const allMonths = eachMonthOfInterval({
            start: startDate,
            end: new Date(),
        });
        setMonths(
            allMonths
                .filter((date) => date.getFullYear() === year)
                .map((date) => {
                    return {
                        index: date.getMonth(),
                        name: monthNames[date.getMonth()],
                    };
                })
        );
    }, [year, user]);

    return months;
}
