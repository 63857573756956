import React from "react";
import { Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    link: {
        fontWeight: 600,
    },
});

const QuestionsLink = () => {
    const classes = useStyles();

    return (
        <Link
            className={classes.link}
            href="https://mycheckin.zendesk.com/hc/en-us/articles/360057485791-Onboarding-New-Company-Registration-Process"
            target="_blank"
        >
            Questions?
        </Link>
    );
};

export { QuestionsLink };
