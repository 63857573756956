import { useUsers } from "../queries/use-users";
import { useQueryStatus } from "../use-query-status";
import { useQueryState } from "./use-query-state";
import { User } from "../../interfaces/user";

export const useUsersState = (params: Partial<User>) => {
    const userQuery = useUsers(params);
    const users: User[] = useQueryState(userQuery, "users");
    const queryStatus = useQueryStatus(userQuery);

    return {
        users,
        ...queryStatus,
    };
};
