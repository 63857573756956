import React from "react";
import PropTypes from "prop-types";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

interface PdfBlockLetterProps {
    children: string;
    style?: any;
}

const styles = StyleSheet.create({
    wrapper: {
        width: 64,
        height: 64,
        minWidth: 64,
        minHeight: 64,
        borderRadius: 5,
        backgroundColor: "#929497",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    backgroundText: {
        fontSize: 64,
        color: "#fff",
        position: "absolute",
        fontWeight: 800,
    },
    foregroundText: {
        fontSize: 12,
        color: "#000",
        position: "absolute",
        fontWeight: "bold",
        textAlign: "center",
        width: 64,
        wordBreak: "keep-all",
    },
});

const removeHyphenation = (word: string) => [word];

Font.registerHyphenationCallback(removeHyphenation);

const PdfBlockLetter = ({ children, style }: PdfBlockLetterProps) => {
    return (
        <View style={[styles.wrapper, style]} wrap={false}>
            <Text style={styles.backgroundText}>{children[0]}</Text>
            <Text style={styles.foregroundText}>{children}</Text>
        </View>
    );
};

PdfBlockLetter.propTypes = {
    children: PropTypes.string.isRequired,
};

export { PdfBlockLetter };
