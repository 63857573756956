import { useMutation } from "react-query";
import { API } from "../../util/api";

export function useTakeawayMutation(options) {
    return useMutation((takeaway) => {
        return takeaway.id
            ? takeaway.description
                ? API.updateTakeaway(takeaway)
                : API.deleteTakeaway(takeaway)
            : API.createTakeaway(takeaway);
    }, options);
}
