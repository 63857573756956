import React from "react";
import { Typography } from "@material-ui/core";

const Pricing = () => {
    return (
        <Typography>
            Your <span style={{ fontWeight: 700 }}>30-day free trial</span> will start immediately. After the trial
            period, your card will be charged $9.99 per month (includes the first 5 users), then $1.99 per additional
            user thereafter.
        </Typography>
    );
};

export { Pricing };
