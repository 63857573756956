import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import Avatar from "../avatar";
import { avatarProps } from "./config";
import { useStyles } from "./discussion.styles";

interface CommentListItemProps {
    userId: string;
    name: string;
    datePosted: Date;
    comment: string;
}

const CommentListItem = ({ userId, name, datePosted, comment }: CommentListItemProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.commentWrapper}>
            <Box className={classes.commentHeader}>
                <Avatar id={userId} {...avatarProps} />
                <Typography className={classes.commenterName}>{name}</Typography>
                <Typography className={classes.commentDate}>
                    {datePosted.toLocaleDateString() +
                        " " +
                        //@ts-ignore
                        datePosted.toLocaleTimeString(undefined, { timeStyle: "short" })}
                </Typography>
            </Box>
            <Box className={classes.commentContentWrapper}>
                <Typography className={classes.commentContent}>{comment}</Typography>
            </Box>
        </Box>
    );
};

CommentListItem.propTypes = {
    userId: PropTypes.string,
    name: PropTypes.string,
    datePosted: PropTypes.instanceOf(Date),
    comment: PropTypes.string,
};

export { CommentListItem };
