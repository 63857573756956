import React from "react";
import { Card, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import Avatar from "../../avatar";
import ScheduledMeetingDate from "./scheduled-meeting-date";
import ContainedIconButton from "../../contained-icon-button";
import { Link as RouterLink } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import { Meeting } from "../../../interfaces/meeting";
import LastLoginDate from "./last-login-date";
import { useMeetingLookupFunctions } from "../../../hooks/use-meeting-lookup-functions";
import { useLastMonth } from "../../../hooks/use-last-month";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ShowRemindersButton } from "./show-reminders-button";
import { Takeaway } from "../../../interfaces/takeaway";

interface TeamMemberInfoCardProps {
    id: string;
    firstName: string;
    lastName: string;
    lastLogin: Date | string | undefined;
    email: string;
    meetings: Meeting[];
    reminders: Takeaway[];
    onRemindersButtonClick: () => unknown;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
    },
}));

function TeamMemberInfoCard({
    id,
    firstName,
    lastName,
    lastLogin,
    email,
    meetings,
    reminders,
    onRemindersButtonClick,
}: TeamMemberInfoCardProps) {
    const classes = useStyles();
    const meetingLookupFunctions = useMeetingLookupFunctions();
    const lastMonthDate = useLastMonth();

    const getLastMonthMeeting = () => {
        const lastMonth = lastMonthDate.getMonth();
        const lastMonthYear = lastMonthDate.getFullYear();
        return meetingLookupFunctions.getMeetingByMonthAndYear(meetings, lastMonth, lastMonthYear);
    };

    const isLastMonthMeetingComplete = (): boolean => {
        const meeting = getLastMonthMeeting();
        if (!meeting) {
            return false;
        }
        return meeting.status === "complete";
    };

    const isLastMonthSubmitted = (): boolean => {
        const meeting = getLastMonthMeeting();
        if (!meeting) {
            return false;
        }
        return meeting.status === "submitted" || meeting.status === "scheduling";
    };

    const isMeetingScheduled = (): boolean => {
        const meeting = getLastMonthMeeting();
        if (!meeting) {
            return false;
        }
        return meeting.status === "scheduled";
    };

    const isNoMeetingNecessary = (): boolean => {
        const meeting = getLastMonthMeeting();
        if (!meeting) {
            return false;
        }
        return meeting.status === "no meeting";
    };

    const getBorderProperties = () => {
        const borderWidth = "4px";
        const borderStyle = "solid";
        let borderColor: string;
        let borderProperties: CSSProperties | undefined = undefined;
        if (isLastMonthMeetingComplete() || isNoMeetingNecessary()) {
            borderColor = "#8bc34a";
            borderProperties = { border: `${borderWidth} ${borderStyle} ${borderColor}` };
        } else if (isLastMonthSubmitted() || isMeetingScheduled()) {
            borderColor = "#ffb84c";
            borderProperties = { border: `${borderWidth} ${borderStyle} ${borderColor}` };
        }

        return borderProperties;
    };

    return (
        <Card className={classes.root} style={getBorderProperties()}>
            <Grid container>
                <Grid container item justify="center" style={{ marginBottom: 24 }}>
                    <Avatar diameter={150} id={id} />
                </Grid>
                <Grid container item justify="center" style={{ marginBottom: 8 }}>
                    <Typography style={{ fontSize: 24 }}>
                        {firstName} {lastName}
                    </Typography>
                </Grid>
                <Grid container item justify="center" style={{ marginBottom: 8 }}>
                    <LastLoginDate date={lastLogin} />
                </Grid>
                <Grid container item justify="center" style={{ marginBottom: 8 }}>
                    <ScheduledMeetingDate meetings={meetings} />
                </Grid>
                <Grid container item justify="center" spacing={5}>
                    <Grid item>
                        <ContainedIconButton size="small" component={RouterLink} to={`/coach/coach-station/${id}`}>
                            <PersonIcon fontSize="small" />
                        </ContainedIconButton>
                    </Grid>
                    <Grid item>
                        <ContainedIconButton size="small" component={Link} href={`mailto:${email}`}>
                            <MailIcon fontSize="small" />
                        </ContainedIconButton>
                    </Grid>
                    <Grid item>
                        <ShowRemindersButton
                            onClick={onRemindersButtonClick}
                            size="small"
                            badgeContent={reminders.length}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default TeamMemberInfoCard;
