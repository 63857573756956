import { useSubmissionNotes, UseSubmissionNotesParams } from "../queries/use-submission-notes";
import { useQueryState } from "./use-query-state";
import { useQueryStatus } from "../use-query-status";

export const useSubmissionNoteState = (params: UseSubmissionNotesParams) => {
    const submissionNotesQuery = useSubmissionNotes(params);
    const submissionNotes = useQueryState(submissionNotesQuery, "submissionNotes");
    const queryStatus = useQueryStatus(submissionNotesQuery);

    return {
        submissionNotes,
        ...queryStatus,
    };
};
