import { Box, CircularProgress, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useGoals } from "../../hooks/queries/use-goals";
import { useCompanyState } from "../../hooks/use-company-state";
import { useDialog } from "../../hooks/use-dialog";
import EditGoalDialog from "../edit-goal-dialog";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import { CompletedObjectivesSection } from "./completed-objectives-section";

/**
 * TODO - Refactor this component into smaller components
 * TODO - Update this file to TypeScript
 */

const DESCRIPTION_HEADER = "Accomplishment Description".toUpperCase();
const POINTS_HEADER = "Points".toUpperCase();

const useStyles = makeStyles((theme) => ({
    titleRow: {
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(3),
    },
    centeredY: {
        display: "flex",
        alignItems: "center",
    },
    centeredX: {
        display: "flex",
        justifyContent: "center",
    },
    priorityActions: {
        marginTop: theme.spacing(8),
    },
    wrapper: {
        marginBottom: theme.spacing(6),
    },
}));

function CompletedGoalSection({ userID, year, category, disabled }) {
    const classes = useStyles();
    const { company } = useCompanyState();
    const goalDialog = useDialog({});
    const [selectedGoal, setSelectedGoal] = useState();
    const { status, data } = useGoals({ user: userID, year, category, isAccomplished: true });
    const [goals, setGoals] = useState([]);
    const masterPlanDescriptions = useMasterPlanTemplate();

    useEffect(() => {
        if (status === "success") {
            setGoals(data.goals || []);
        } else {
            setGoals([]);
        }
    }, [status, data]);

    const showGoalPoints = () => {
        return company?.settings?.isGoalPointsEnabled && category === 0;
    };

    const getTotalGoalPoints = () => {
        return goals.reduce((previousValue, currentValue) => {
            return previousValue + (currentValue.points || 0);
        }, 0);
    };

    const handleEditGoalClick = (goal) => {
        setSelectedGoal(goal);
        goalDialog.handleOpen();
    };

    return (
        <Box className={classes.wrapper}>
            <Grid container className={classes.titleRow} spacing={4}>
                {category === 0 && (
                    <Grid container style={{ alignItems: "center" }}>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                            <Typography
                                variant="subtitle1"
                                style={{ textAlign: "center", fontWeight: 600, fontSize: 18 }}
                            >
                                {DESCRIPTION_HEADER}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            {showGoalPoints() && <Typography variant="subtitle1">{POINTS_HEADER}</Typography>}
                        </Grid>
                        <Grid item md={1}>
                            &nbsp;
                        </Grid>
                    </Grid>
                )}
                <Grid item md={5}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            color: "#FFB84C",
                            fontWeight: 700,
                            fontSize: 18,
                            borderLeft: "8px solid #FFB84C",
                            borderRadius: 4,
                            paddingLeft: 12,
                        }}
                    >
                        {masterPlanDescriptions[category]?.name.toUpperCase()}
                    </Typography>
                </Grid>
            </Grid>
            {status === "loading" ? (
                <CircularProgress />
            ) : (
                <Box>
                    {category === 0 && (
                        <>
                            <CompletedObjectivesSection userId={userID} category={0} year={year} />
                            <Typography variant="subtitle1" style={{ marginBottom: 16, marginLeft: 20 }}>
                                Goals
                            </Typography>
                        </>
                    )}
                    {goals.length ? (
                        goals.map((goal, index) => (
                            <Grid container spacing={5}>
                                <Grid item md={5} className={classes.centeredY}>
                                    {`${index + 1}. ${goal.description}`}
                                </Grid>
                                <Grid item md={5} className={classes.centeredY}>
                                    {`${index + 1}. ${goal.accomplishment}`}
                                </Grid>
                                <Grid item md={1} className={classes.centeredY}>
                                    {showGoalPoints() && (goal.points || 0)}
                                </Grid>
                                <Grid item md={1} className={[classes.centeredY, classes.centeredX].join(" ")}>
                                    {!disabled && (
                                        <IconButton onClick={() => handleEditGoalClick(goal)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <Typography variant="caption" style={{ fontStyle: "italic" }}>
                            No completed goals recorded in this category.
                        </Typography>
                    )}
                    {showGoalPoints() && (
                        <Grid container spacing={4} style={{ marginTop: 12 }}>
                            <Grid item md={10}>
                                <Typography align="right" style={{ fontWeight: 600 }}>
                                    Total Points:
                                </Typography>
                            </Grid>
                            <Grid item md={1}>
                                <Typography style={{ fontWeight: 600 }}>{getTotalGoalPoints()}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            )}
            {!disabled && (
                <EditGoalDialog goal={selectedGoal} open={goalDialog.isOpen} onClose={goalDialog.handleClose} />
            )}
        </Box>
    );
}

CompletedGoalSection.propTypes = {
    userID: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    category: PropTypes.number.isRequired,
};

export default CompletedGoalSection;
