import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Section } from "./section";
import { useStyles } from "./discussion.styles";

interface OkrSectionProps {
    title: string;
    content: string;
}

const OkrSection = ({ title, content }: OkrSectionProps) => {
    const classes = useStyles();

    return (
        <Section>
            <Typography className={classes.sectionTitle}>{title}</Typography>
            <Typography className={classes.okrContent}>{content}</Typography>
        </Section>
    );
};

OkrSection.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
};

export { OkrSection };
