import React, { ChangeEvent, useState } from "react";
import { Card, Container, Grid, Typography } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import PageTitle from "../../components/page-title";
import YearSelector from "../../components/year-selector";
import TabPanel from "../../components/tab-panel";
import MasterPlanTabPanel from "../../components/master-plan/master-plan-tab-panel";
import GoalTabs from "../../components/goal-tabs";
import { useYear } from "../../hooks/use-year";
import { useStyles } from "./master-plan.styles";
import { User } from "../../interfaces/user";
import { useViewingAs } from "../../hooks/use-viewing-as";
import { DownloadMasterPlanPdfButton } from "../../components/master-plan/download-master-plan-pdf-button";
import MonthlyCheckInButton from "../../components/master-plan/monthly-check-in-button";
import { Alert } from "@material-ui/lab";

interface MasterPlanProps {
    viewingAs: "employee" | "coach";
    user: User;
}

interface MasterPlanParams {
    userId: string;
}

function MasterPlan({ viewingAs, user }: MasterPlanProps) {
    const { params }: { params: MasterPlanParams } = useRouteMatch();
    const { year, setYear, yearsArray } = useYear(user);
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const { isViewingAsCoach } = useViewingAs({ viewingAs });

    const handleYearSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setYear(parseInt(value));
    };

    const renderEmployeeName = () => {
        return (
            <Typography variant="h6" align="center">
                {getUserFullName()}
            </Typography>
        );
    };

    const getUserFullName = () => {
        return `${user.firstName} ${user.lastName}`;
    };

    return (
        <Container>
            <PageTitle>
                The Plan {year} {isViewingAsCoach && renderEmployeeName()}
            </PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container item className={classes.selectRow}>
                    <YearSelector year={year} yearsArray={yearsArray} handleSelect={handleYearSelect} />
                </Grid>
                <Alert variant="filled" severity="warning">
                    This is a read-only version of the master plan. To make changes, please go to the{" "}
                    <a href="/employee/quick-plan">Quick Plan</a>.
                </Alert>
                <GoalTabs value={value} setValue={setValue}>
                    {[0, 1, 2, 3].map((index) => (
                        <TabPanel value={value} index={index} key={`mp-tab-panel-${index}`}>
                            <MasterPlanTabPanel value={value} userID={params.userId || user.id} disabled year={year} />
                        </TabPanel>
                    ))}
                </GoalTabs>
                <Grid container justify="flex-end" className={classes.downloadPdfContainer}>
                    <Grid item style={{ marginRight: 32 }}>
                        <MonthlyCheckInButton />
                    </Grid>
                    <Grid item>
                        <DownloadMasterPlanPdfButton
                            userId={user.id}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            year={year}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export { MasterPlan };
