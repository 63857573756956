import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./top-nav.styles";
import { Box } from "@material-ui/core";

interface NotificationListProps {
    children: React.ReactNode;
}

const NotificationList = ({ children }: NotificationListProps) => {
    const classes = useStyles();

    return <Box className={classes.notificationList}>{children}</Box>;
};

NotificationList.propTypes = {
    children: PropTypes.node,
};

export { NotificationList };
