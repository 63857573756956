import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { useQueryClient } from "react-query";
import { usePriorityActionMutation } from "../hooks/mutations/use-priority-action-mutation";
import MyCheckinButton from "./my-checkin-button";

function PriorityActionAccomplishedDialog(props) {
    const { open, onClose, priorityAction, ...others } = props;
    const queryClient = useQueryClient();
    const priorityActionMutation = usePriorityActionMutation({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchPriorityActions");
        },
    });

    const handleConfirm = () => {
        priorityActionMutation.mutate({
            id: priorityAction.id,
            isAccomplished: true,
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true} {...others}>
            <DialogTitle id="goal-accomplished-dialog-title">Priority Action Accomplished</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">Set this Priority Action as complete?</Typography>
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={onClose} color="primary">
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton onClick={handleConfirm} type="submit" color="primary" variant="outlined">
                    Confirm
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default PriorityActionAccomplishedDialog;
