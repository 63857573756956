import { Checkbox, CircularProgress, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { CardElement } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import React, { useImperativeHandle, useState } from "react";
import { useDialog } from "../../hooks/use-dialog";
import MyCheckinButton from "../my-checkin-button";
import MyCheckinTextField from "../my-checkin-text-field";
import PrivacyNoticeDialog from "./privacy-notice-dialog";
import TermsAndConditionsDialog from "./terms-and-conditions-dialog";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        maxWidth: theme.spacing(15),
        marginTop: theme.spacing(4),
    },
}));

function StripeInputComponent(props) {
    const { component: Component, inputRef, ...other } = props;

    useImperativeHandle(inputRef, () => ({
        focus: () => {
            Component.focus();
        },
    }));

    return <Component {...other} />;
}

function RegisterStepTwo(props) {
    const classes = useStyles();
    const { prevStep, onSubmit, loading } = props;
    const [tosChecked, setTosChecked] = useState(false);
    const termsAndConditionsDialog = useDialog({});
    const privacyNoticeDialog = useDialog({});

    const handleTosCheck = (event) => {
        const { checked } = event.target;
        setTosChecked(checked);
    };

    return (
        <Grid container spacing={5} className={classes.formWrapper}>
            <Grid container item>
                <Typography style={{ fontSize: 16, fontWeight: 600, marginBottom: 16 }}>Payment Information</Typography>
                <Grid item xs={12}>
                    <MyCheckinTextField
                        fullWidth
                        InputProps={{
                            inputComponent: StripeInputComponent,
                            inputProps: { component: CardElement },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item justify="center" spacing={5}>
                <Grid container item>
                    <Grid item xs={2} sm={1}>
                        <Checkbox color="primary" checked={tosChecked} onChange={handleTosCheck} />
                    </Grid>
                    <Grid item xs={10} sm={11}>
                        <Typography style={{ display: "inline" }}>
                            I have reviewed and accept the{" "}
                            <Link href="#" onClick={termsAndConditionsDialog.handleOpen}>
                                Terms & Conditions
                            </Link>{" "}
                            and{" "}
                            <Link href="#" onClick={privacyNoticeDialog.handleOpen}>
                                Privacy Notice
                            </Link>
                        </Typography>
                        <TermsAndConditionsDialog
                            isOpen={termsAndConditionsDialog.isOpen}
                            onClose={termsAndConditionsDialog.handleClose}
                        />
                        <PrivacyNoticeDialog
                            isOpen={privacyNoticeDialog.isOpen}
                            onClose={privacyNoticeDialog.handleClose}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="space-between">
                <Grid item>
                    <MyCheckinButton onClick={prevStep}>&lt; Back</MyCheckinButton>
                </Grid>
                <Grid item>
                    <MyCheckinButton
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={!tosChecked || loading}
                    >
                        {loading ? <CircularProgress color="primary" size={17} /> : "Register"}
                    </MyCheckinButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

RegisterStepTwo.propTypes = {
    prevStep: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
};

export default RegisterStepTwo;
