import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/page-title";
import { Breadcrumbs, Card, CardActions, Container, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import MyCheckinTextField from "../../components/my-checkin-text-field";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import MyCheckinButton from "../../components/my-checkin-button";
import { useMutation, useQueryClient } from "react-query";
import { API, jotAPI } from "../../util/api";
import { useCurrentUserState } from "../../hooks/states/use-current-user-state";
import { Jot } from "../../interfaces/jot";
import { urls } from "../../config/urls";
import { TextEditor } from "../../components/jots/text-editor";
import { useDebouncer } from "../../hooks/use-debouncer";
import { useGetApi } from "../../hooks/api/use-get-api";
import { queryKeys } from "../../hooks/api/config/query-keys";

interface EditJotParams {
    id: string;
}

const autosaveInterval = 5 * 1000; //milliseconds

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: 32,
    },
    titleInput: {
        marginBottom: 16,
    },
    flexEnd: {
        display: "flex",
        justifyContent: "flex-end",
    },
    breadcrumbLink: {
        color: theme.palette.neutral.dark,
        "&:visited": {
            color: theme.palette.neutral.dark,
        },
    },
}));

const EditJot = () => {
    const classes = useStyles();
    const { currentUser: user } = useCurrentUserState();
    const history = useHistory();
    const { id } = useParams<EditJotParams>();
    const getJotQuery = useGetApi<Jot>(jotAPI, queryKeys.jot.get, id, { enabled: !!id });
    const initialJotState = useRef<Partial<Jot>>({ title: "", body: "", user: user?.id });
    const [state, setState] = useState<Partial<Jot>>(initialJotState.current);
    const [message, setMessage] = useState<string | null>(null);
    const [isSaved, setIsSaved] = useState(true);
    const queryClient = useQueryClient();

    const handleSaveSuccess = (jot: Jot) => {
        const savedDate = new Date(jot.updatedAt);
        setMessage(`Saved on ${savedDate.toLocaleDateString()} at ${savedDate.toLocaleTimeString()}`);
        queryClient.invalidateQueries(queryKeys.jot.get);
        queryClient.invalidateQueries(queryKeys.jot.find);
    };

    const journalMutation = useMutation<Jot, unknown, Partial<Jot>>(
        (jot) => {
            return id ? API.updateJournalEntry(jot) : API.createJournalEntry(jot);
        },
        {
            onSuccess: handleSaveSuccess,
        }
    );

    useEffect(() => {
        if (!id) {
            setState(initialJotState.current);
        }
        if (getJotQuery.status === "success" && getJotQuery.data) {
            setState(getJotQuery.data);
        }
    }, [getJotQuery.status, getJotQuery.data, id]);

    useEffect(() => {
        if (journalMutation.isSuccess) {
            const { data } = journalMutation;
            if (!data) {
                return;
            }
            setIsSaved(true);
            setState(data);
            if (data.id && !history.location.pathname.endsWith(data.id)) {
                history.push(history.location.pathname + `/${data.id}`);
            }
            journalMutation.reset();
        }
    }, [history, journalMutation]);

    const autosaveFn = () => {
        if (!isSaved && state?.title) {
            setMessage("Autosaving...");
            journalMutation.mutate(state);
        }
    };

    useDebouncer(autosaveFn, autosaveInterval);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
        setIsSaved(false);
    };

    const handleEditorChange = (content: string) => {
        setState({ ...state, body: content });
        setIsSaved(false);
    };

    const handleSave = () => {
        journalMutation.mutate(state);
    };

    const getTitlePrefix = () => {
        return id ? "Edit" : "New";
    };

    const getPageTitle = () => {
        return getTitlePrefix() + " Jot Entry";
    };

    const getBody = () => {
        return state?.body || "";
    };

    return (
        <Container>
            <PageTitle>{getPageTitle()}</PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container item xs={12} style={{ marginBottom: 24 }}>
                    <Breadcrumbs separator={">"}>
                        <Link
                            component={RouterLink}
                            to={urls.employee.viewAllEntries.baseUrl}
                            className={classes.breadcrumbLink}
                        >
                            Jots
                        </Link>
                        <Typography>{getPageTitle()}</Typography>
                    </Breadcrumbs>
                </Grid>
                <MyCheckinTextField
                    name="title"
                    value={state?.title || ""}
                    fullWidth
                    onChange={handleChange}
                    label="Title"
                    className={classes.titleInput}
                    autoComplete="off"
                />
                <TextEditor value={getBody()} name="body" onChange={handleEditorChange} />
                <CardActions className={classes.flexEnd}>
                    {message && <Typography variant="caption">{message}</Typography>}
                    <MyCheckinButton variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </MyCheckinButton>
                </CardActions>
            </Card>
        </Container>
    );
};

export default EditJot;
