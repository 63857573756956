import React from "react";
import PageTitle from "../../components/page-title";
import { Card, Container, makeStyles } from "@material-ui/core";
import { TodoItem } from "../../components/todo/todo-item";
import { TodoItemContainer } from "../../components/todo/todo-item-container";
import { TodoSectionTitle } from "../../components/todo/todo-section-title";
import { TodoSection } from "../../components/todo/todo-section";
import { TodoLink } from "../../components/todo/todo-link";
import { Todo as ITodo } from "../../interfaces/todo";
import { todoApi } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useFindApi } from "../../hooks/api/use-find-api";
import { LoadingIndicator } from "../../components/loading-indicator";

const useStyles = makeStyles((theme) => ({
    contentCard: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: theme.spacing(5),
    },
}));

const pageTitle = "To-Do Today";
const inProgressTitle = "In-Process Items";
const completedTitle = "Completed";
const newItem = "+ New Item...";
const loadMore = "Load More...";

interface TodoProps {
    userId: string;
}

interface PaginatedTodo extends ITodo {
    limit?: number;
    offset?: number;
}

const Todo = ({ userId }: TodoProps) => {
    const classes = useStyles();
    // const [loadingMore, setLoadingMore] = React.useState(false);
    const [limit, setLimit] = React.useState(10);
    const increment = 10;

    const incompleteTodosQuery = useFindApi<ITodo>(
        todoApi,
        queryKeys.todo.get,
        { user: userId, isComplete: false },
        "todos"
    );
    const completeTodosQuery = useFindApi<PaginatedTodo>(
        todoApi,
        queryKeys.todo.get,
        { user: userId, isComplete: true, limit },
        "todos"
    );

    const [incompleteTodos, setIncompleteTodos] = React.useState<ITodo[]>([]);
    const [completeTodos, setCompleteTodos] = React.useState<ITodo[]>([]);

    React.useEffect(() => {
        if (incompleteTodosQuery.status === "success") {
            setIncompleteTodos(incompleteTodosQuery.data?.result ?? []);
        }
    }, [incompleteTodosQuery.status, incompleteTodosQuery.data?.result]);

    React.useEffect(() => {
        if (completeTodosQuery.status === "success") {
            setCompleteTodos(completeTodosQuery.data?.result ?? []);
        }
    }, [completeTodosQuery.status, completeTodosQuery.data?.result]);

    const handleNewTodoClick = () => {
        setIncompleteTodos([...incompleteTodos, { id: "", description: "", isComplete: false, user: userId }]);
    };

    const handleLoadMoreClick = () => {
        setLimit(limit + increment);
        // setLoadingMore(true);
    };

    console.log(completeTodos);

    return (
        <Container>
            <PageTitle>{pageTitle}</PageTitle>
            <Card className={classes.contentCard}>
                <TodoSection>
                    <TodoSectionTitle>{inProgressTitle}</TodoSectionTitle>
                    <TodoItemContainer>
                        {incompleteTodosQuery.status === "loading" && <LoadingIndicator />}
                        {incompleteTodosQuery.status === "error" && <div>Error: Could not load to-do's</div>}
                        {incompleteTodosQuery.status === "success" &&
                            incompleteTodos.map((todo: ITodo) => <TodoItem key={todo.id} todo={todo} />)}
                        <TodoLink onClick={handleNewTodoClick}>{newItem}</TodoLink>
                    </TodoItemContainer>
                </TodoSection>
                <TodoSection>
                    <TodoSectionTitle>{completedTitle}</TodoSectionTitle>
                    <TodoItemContainer>
                        {completeTodosQuery.status === "loading" && <LoadingIndicator />}
                        {completeTodosQuery.status === "error" && <div>Error: Could not load to-do's</div>}
                        {completeTodosQuery.status === "success" &&
                            completeTodos.map((todo: ITodo) => <TodoItem key={todo.id} todo={todo} />)}
                        <TodoLink centered onClick={handleLoadMoreClick} disabled={completeTodos.length !== limit}>
                            {loadMore}
                        </TodoLink>
                    </TodoItemContainer>
                </TodoSection>
            </Card>
        </Container>
    );
};

export default Todo;
