export const menuLabels = {
    dashboard: "Home",
    thePlanMenu: "The Plan",
    masterPlan: "The Plan - Archive",
    quickPlan: "Quick Plan",
    monthlyCheckIn: "Monthly Check-In",
    completedItems: "Completions List",
    majorAchievements: "Major Achievements",
    journal: "Journal",
    jots: "Jots",
    newJournalEntry: "New Entry",
    newJot: "New Jot Entry",
    viewAllJournalEntries: "View All Entries",
    jotsLog: "Jots Log",
    monthlyTracker: "Monthly Tracker",
    teamshare: "TeamShare",
    learnVault: "Learn",
    coachToggle: "Coach View",
    employeeToggle: "Employee View",
    profile: "Profile",
    signOut: "Sign Out",
    employeePanel: "Employee Panel",
    divisions: "Divisions",
    employees: "Employees",
    settings: "Settings",
    todo: "To-Do",
};
