import React from "react";
import TabPanel from "../coach-station/tab-panel";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ManageBillingButton from "./manage-billing-button";
import { ManageBillingForm } from "../manage-billing-form";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6),
    },
}));

interface BillingTabProps {
    index: number;
    value: number;
}

function BillingTab({ index, value }: BillingTabProps) {
    const classes = useStyles();

    return (
        <TabPanel index={index} value={value}>
            <Grid container spacing={6} className={classes.container}>
                <Grid container item>
                    <Typography>
                        To manage your billing, subscription, and payment method, click below to enter your secure
                        Billing Portal.
                    </Typography>
                </Grid>
                <Grid container justify="center">
                    <Grid item>
                        <ManageBillingForm>
                            <ManageBillingButton />
                        </ManageBillingForm>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    );
}

export { BillingTab };
