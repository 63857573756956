import React from "react";
import PropTypes from "prop-types";
import { View, StyleSheet } from "@react-pdf/renderer";

interface SectionProps {
    children: React.ReactNode;
    style?: any;
}

const styles = StyleSheet.create({
    section: {
        marginBottom: 24,
    },
});

const Section = ({ children, style }: SectionProps) => {
    return (
        <View style={[styles.section, style]} wrap={false}>
            {children}
        </View>
    );
};

Section.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
};

export { Section };
