import { makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

interface TodoTypographyProps {
    children: React.ReactNode;
    editing?: boolean;
    onClick?: () => void;
    onBlur?: () => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => ({
    todoItemText: {
        color: "#000",
        fontSize: 18,
        fontWeight: 600,
        width: "100%",
        textAlign: "left",
    },
    todoTextField: {
        "& .MuiInput-underline:after": {
            borderBottom: "none",
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        width: "100%",
    },
}));

export const TodoItemText = ({ children, editing, onClick, onBlur, onKeyPress, onChange }: TodoTypographyProps) => {
    const classes = useStyles();

    const handleClick = onClick;
    const handleBlur = onBlur;
    const handleKeyPress = onKeyPress;
    const handleChange = onChange;

    return editing ? (
        <TextField
            value={children as string}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            autoFocus
            className={classes.todoTextField}
            onChange={handleChange}
        />
    ) : (
        <Typography className={classes.todoItemText} onClick={handleClick}>
            {children}&nbsp;
        </Typography>
    );
};
