import React from "react";
import { AccountDeactivatedContent } from "./account-deactivated-content";
import { AccountDeactivatedActions } from "./account-deactivated-actions";
import { ManageBillingForm } from "../../components/manage-billing-form";
import { ManageBillingButton } from "../../components/company-settings/manage-billing-button/manage-billing-button";
import { AccountDeactivated } from "./account-deactivated";
import { SignOutButton } from "./sign-out-button";

function SubscriptionDeactivated() {
    return (
        <AccountDeactivated>
            <AccountDeactivatedContent>
                This subscription is no longer active. To reactivate, please access your Billing Portal below.
            </AccountDeactivatedContent>
            <AccountDeactivatedActions>
                <SignOutButton />
                <ManageBillingForm>
                    <ManageBillingButton variant="outlined" text="Billing Portal" />
                </ManageBillingForm>
            </AccountDeactivatedActions>
        </AccountDeactivated>
    );
}

export { SubscriptionDeactivated };
