import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "12px 32px 24px 32px",
        margin: "32px 0px",
    },
}));
