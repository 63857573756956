import { Company } from "../interfaces/company/company";
import { useGetApi } from "./api/use-get-api";
import { companyAPI } from "../util/api";
import { useAuthState } from "./use-auth-state";
import { queryKeys } from "./api/config/query-keys";

export const useCompanySettings = () => {
    const { user } = useAuthState();
    const getCompanyResponse = useGetApi<Company>(companyAPI, queryKeys.company.get, "", {
        enabled: !!user,
    });

    const company = getCompanyResponse.data;
    return (
        company?.settings || {
            isGoalPointsEnabled: false,
            isSendLoginRemindersEnabled: false,
            isTeamshareEnabled: false,
        }
    );
};
