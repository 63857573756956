import React from "react";
import PropTypes from "prop-types";
import MyCheckinButton from "../my-checkin-button";
import { Box } from "@material-ui/core";
import { useStyles } from "./divisions.styles";
import { useGetApi } from "../../hooks/api/use-get-api";
import { companyAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { Company } from "../../interfaces/company/company";

interface ActionsProps {
    onAddDivisionClick: () => unknown;
}

const Actions = ({ onAddDivisionClick }: ActionsProps) => {
    const classes = useStyles();

    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");

    return (
        <Box className={classes.actionsContainer}>
            <MyCheckinButton variant="contained" color="primary" onClick={onAddDivisionClick}>
                Add {company?.organizationalUnitName?.singular || "Division"}
            </MyCheckinButton>
        </Box>
    );
};

Actions.propTypes = {
    onAddDivisionClick: PropTypes.func,
};

export { Actions };
