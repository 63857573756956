import React from "react";
import { useDialog } from "../../hooks/use-dialog";
import MyCheckinButton from "../my-checkin-button";
import AddEmployeeDialog from "./add-employee-dialog";

function AddEmployeeButton() {
    const addEmployeeDialog = useDialog({});

    return (
        <>
            <MyCheckinButton variant="contained" color="primary" onClick={addEmployeeDialog.handleOpen}>
                Add Employee
            </MyCheckinButton>
            <AddEmployeeDialog open={addEmployeeDialog.isOpen} onClose={addEmployeeDialog.handleClose} />
        </>
    );
}

export default AddEmployeeButton;
