import React, { useEffect, useState } from "react";
import { AuthContext } from "../context/auth-context";
import { API } from "../util/api";
import { LoadingIndicator } from "../components/loading-indicator";

export function AuthProvider({ children }) {
    const [state, setState] = useState({
        status: "pending",
        error: null,
        user: null,
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentUser = await API.getCurrentUser();
                setState({ status: "success", user: currentUser, error: null });
            } catch (e) {
                const errors = e.response.data.errors.map((error) => <li key={error.message}>{error.message}</li>);
                setState({ status: "error", user: null, error: errors });
            }
        };
        fetchData();
    }, []);

    return (
        <AuthContext.Provider value={state}>
            {state.status === "pending" ? (
                <LoadingIndicator fullscreen />
            ) : state.status === "error" ? (
                <>{children}</>
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
}
