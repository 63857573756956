import React from "react";
import PropTypes from "prop-types";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

interface ListProps {
    children: React.ReactNode;
    showNumbering?: boolean;
}

const styles = StyleSheet.create({
    wrapper: {
        marginLeft: 16,
        marginBottom: 8,
        marginRight: 24,
    },
    row: {
        flexDirection: "row",
        marginBottom: 4,
        fontSize: 10,
    },
    index: {
        width: 16,
    },
});

const List = ({ children, showNumbering = true }: ListProps) => {
    return (
        <View style={styles.wrapper}>
            {React.Children.map(children, (listItem, index) => (
                <View style={styles.row}>
                    {showNumbering && <Text style={styles.index}>{index + 1}.</Text>}
                    <View>{listItem}</View>
                </View>
            ))}
        </View>
    );
};

List.propTypes = {
    children: PropTypes.node,
};

export { List };
