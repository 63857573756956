import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MyCheckinButton from "../my-checkin-button";
import { Takeaway } from "../../interfaces/takeaway";
import PropTypes from "prop-types";
import { OpenReminders } from "./open-reminders";

interface OpenRemindersDialogProps {
    isOpen: boolean;
    onClose: () => any;
    reminders: Takeaway[];
}

const OpenRemindersDialog = ({ isOpen, onClose, reminders }: OpenRemindersDialogProps) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Open Reminders</DialogTitle>
            <DialogContent>
                <OpenReminders reminders={reminders} />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleClose}>Close</MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

OpenRemindersDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    reminders: PropTypes.array,
};

export { OpenRemindersDialog };
