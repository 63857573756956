import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px 32px",
        lineHeight: "17px",
    },
    outlined: {
        border: "2px solid #f1960e",
        color: "#f1960e",
        "&:hover": {
            border: "2px solid #f1960e",
        },
    },
    text: {
        color: "#f1960e",
    },
}));

function MyCheckinButton(props) {
    const classes = useStyles();
    const { color, variant, ...others } = props;

    return (
        <Button
            className={`${classes.root} ${variant === "outlined" ? classes.outlined : ""} ${
                variant !== "contained" ? classes.text : ""
            }`}
            color={color}
            variant={variant}
            {...others}
        >
            {props.children}
        </Button>
    );
}

export default MyCheckinButton;
