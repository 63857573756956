import React from "react";
import PropTypes from "prop-types";
import { Text } from "@react-pdf/renderer";

interface ListItemProps {
    children: string;
}

const ListItem = ({ children }: ListItemProps) => {
    return <Text>{children}</Text>;
};

ListItem.propTypes = {
    children: PropTypes.string,
};

export { ListItem };
