interface UrlCollections {
    employee: UrlCollection;
    learnVault: UrlCollection;
    coach: UrlCollection;
}

interface UrlCollection {
    [property: string]: UrlAttributes;
}

interface UrlAttributes {
    baseUrl: string;
    params?: string;
}

export const employeePath = "/employee";
export const coachPath = "/coach";
export const coachStationPath = "/coach-station";
export const profilePath = "/profile";
export const wildcardPath = "*";

export const urls: UrlCollections = {
    employee: {
        dashboard: { baseUrl: employeePath + "/dashboard" },
        masterPlan: { baseUrl: employeePath + "/master-plan" },
        quickPlan: { baseUrl: employeePath + "/quick-plan" },
        monthlyCheckIn: { baseUrl: employeePath + "/monthly-check-in", params: "/:year/:month" },
        completedItems: { baseUrl: employeePath + "/completions-list" },
        majorAchievements: { baseUrl: employeePath + "/major-achievements" },
        editEntry: { baseUrl: employeePath + "/jots/edit", params: "/:id?" },
        viewAllEntries: { baseUrl: employeePath + "/jots/view-all" },
        viewEntry: { baseUrl: employeePath + "/jots/view", params: "/:id" },
        monthlyTracker: { baseUrl: employeePath + "/monthly-tracker", params: "/:id" },
        profile: { baseUrl: employeePath + profilePath },
        teamshare: { baseUrl: employeePath + "/teamshare", params: "/:id" },
        discussion: { baseUrl: employeePath + "/teamshare/:id/discussion", params: "/:goalId" },
        notifications: { baseUrl: employeePath + "/notifications" },
        todo: { baseUrl: employeePath + "/todo" },
    },
    learnVault: {
        homePage: { baseUrl: "https://mycheckin.zendesk.com/hc/en-us" },
    },
    coach: {
        employeePanel: { baseUrl: coachPath + "/employee-panel" },
        divisions: { baseUrl: coachPath + "/divisions", params: "/:divisionId" },
        teamshare: { baseUrl: coachPath + "/teamshare", params: "/:id" },
        discussion: { baseUrl: coachPath + "/teamshare/:id/discussion", params: "/:goalId" },
        monthlyCheckIn: {
            baseUrl: coachPath + coachStationPath + "/:userId/monthly-check-in",
            params: "/:year/:month",
        },
        masterPlan: { baseUrl: coachPath + coachStationPath + "/master-plan", params: "/:userId" },
        completedItems: { baseUrl: coachPath + coachStationPath + "/completions-list", params: "/:userId" },
        majorAchievements: { baseUrl: coachPath + coachStationPath + "/major-achievements", params: "/:userId" },
        coachStation: { baseUrl: coachPath + coachStationPath, params: "/:userId" },
        profile: { baseUrl: coachPath + profilePath },
        employees: { baseUrl: coachPath + "/employees" },
        companySettings: { baseUrl: coachPath + "/settings" },
    },
};
