import React from "react";
import MyCheckinButton from "../../my-checkin-button";
import { ManageBillingForm } from "../../manage-billing-form";

interface ManageBillingButtonProps {
    text?: string;
    variant?: string;
}

function ManageBillingButton({ text = "Manage Billing", variant = "contained" }: ManageBillingButtonProps) {
    return (
        <ManageBillingForm>
            <MyCheckinButton variant={variant} color="primary" type="submit">
                {text}
            </MyCheckinButton>
        </ManageBillingForm>
    );
}

export { ManageBillingButton };
