import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import CategoryTitle from "./category-title";
import { objectiveDescriptions } from "../../config/objective-descriptions";
import { useMonthlyCheckInState } from "../../hooks/use-monthly-check-in-state";
import { useFindApi } from "../../hooks/api/use-find-api";
import { Objective } from "../../interfaces/objective";
import { objectiveAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";

const category = 0;

const ObjectiveSection = () => {
    const { user, year, meeting } = useMonthlyCheckInState();
    const userId = user?.id || "";

    const objectiveParams =
        meeting?.submission && meeting.submission.objective
            ? { id: meeting.submission.objective!, user: userId }
            : { user: userId, category, year, isAccomplished: false };

    const findObjectivesResult = useFindApi<Objective>(
        objectiveAPI,
        queryKeys.objective.find,
        objectiveParams,
        "objectives",
        {
            enabled:
                meeting?.submission && meeting.submission.objective
                    ? !!objectiveParams.id && !!objectiveParams.user
                    : !!objectiveParams.user && !!year && objectiveParams.isAccomplished === false,
        }
    );
    const objectives = findObjectivesResult.data.result;
    const isLoading = findObjectivesResult.status === "loading";

    return (
        <Box>
            <CategoryTitle
                title={objectiveDescriptions.checkIn.description}
                subheading={objectiveDescriptions.checkIn.subheading}
                style={{ marginBottom: 12 }}
            />
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Grid container spacing={5}>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <Typography>
                            {objectives[0]?.description || (
                                <em>No Objective entered. Head to your Master Plan to enter your Objective.</em>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export { ObjectiveSection };
