import { Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CoachCard from "../components/coach-card";
import EmployeeCard from "../components/employee-card";
import PriorityActionsCard from "../components/priority-actions-card";
import RemindersCard from "../components/employee-dashboard/reminders-card";
import Snapshot from "../components/snapshot";
import TrackedItemCard from "../components/tracked-item-card";
import { useTrackedItems } from "../hooks/queries/use-tracked-items";
import { useMeetingState } from "../hooks/states/use-meeting-state";
import { usePriorityActionState } from "../hooks/states/use-priority-action-state";
import { useReminderState } from "../hooks/states/use-reminder-state";
import { useAuthState } from "../hooks/use-auth-state";
import { useYear } from "../hooks/use-year";
import NewUserWelcomeDialog from "../components/employee-dashboard/new-user-welcome-dialog";
import { useSortArray } from "../hooks/use-sort-array";
import { userAPI } from "../util/api";
import { queryKeys } from "../hooks/api/config/query-keys";
import { useFindApi } from "../hooks/api/use-find-api";
import WelcomeCard from "../components/welcome-card";

const useStyles = makeStyles((theme) => ({
    verticalSpacing: {
        marginBottom: theme.spacing(5),
    },
    cardPadding: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
}));

function EmployeeDashboard() {
    const classes = useStyles();
    const { user } = useAuthState();
    const { year, setYear, yearsArray } = useYear(user);
    const month = new Date().getMonth();
    const trackedItemQuery = useTrackedItems({ month, year, user: user.id });
    const { priorityActions } = usePriorityActionState({ year, user: user.id, category: 0, isAccomplished: false });
    const { reminders } = useReminderState({ isDismissed: false, user: user.id });
    // const { users: coaches } = useUsersState({ id: user.coaches });
    const findCoachesResult = useFindApi(
        userAPI,
        queryKeys.user.find,
        { id: user.coaches, isDeleted: false },
        "users",
        {
            enabled: user?.coaches?.length > 0,
        }
    );
    const { meetings } = useMeetingState({ year, user: user.id });

    const sortedCoaches = useSortArray({ array: findCoachesResult.data.result, field: "lastName", order: "asc" });

    const getTrackedItemCard = (index) => {
        const trackedItem = trackedItemQuery.data.trackedItems[index];
        const key = `tracked-item-card-${index}`;
        return trackedItem ? (
            <Grid item xs={12} sm={4} md={12} key={key}>
                <TrackedItemCard
                    current={trackedItem.progress || 0}
                    goal={trackedItem.goal || 1}
                    title={trackedItem.name}
                    exists={true}
                    className={classes.verticalSpacing}
                    id={trackedItem.id}
                    key={key}
                />
            </Grid>
        ) : (
            <Grid item xs={12} sm={4} md={12} key={key}>
                <TrackedItemCard className={classes.verticalSpacing} />
            </Grid>
        );
    };

    return (
        <Container>
            <Grid container spacing={5} justify="center">
                <Grid item md={3} sm={4} xs={12}>
                    <EmployeeCard
                        email={user.email}
                        name={user.firstName + " " + user.lastName}
                        imageUrl={user.profileImage}
                        className={classes.verticalSpacing}
                        userID={user.id}
                    />
                    <CoachCard coaches={sortedCoaches} />
                </Grid>
                <Grid item md={6} sm={8} xs={12}>
                    <WelcomeCard firstName={user.firstName} />
                    <Snapshot
                        user={user}
                        year={year}
                        yearsArray={yearsArray}
                        setYear={setYear}
                        meetings={meetings}
                        className={classes.verticalSpacing}
                    />
                    <PriorityActionsCard priorityActionsArr={priorityActions} className={classes.verticalSpacing} />
                    <RemindersCard remindersArr={reminders} />
                </Grid>
                <Grid item md={3} xs={12}>
                    <Grid container spacing={5} justify="center">
                        {trackedItemQuery.isSuccess && [0, 1, 2].map((index) => getTrackedItemCard(index))}
                    </Grid>
                </Grid>
            </Grid>
            {!user.isNewUserWelcomeDismissed && (
                <NewUserWelcomeDialog
                    userId={user.id}
                    isCoach={user.permissions.isCoach}
                    isAdmin={user.permissions.isAdmin}
                />
            )}
        </Container>
    );
}

export default EmployeeDashboard;
