import { Box, CircularProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useQueryClient } from "react-query";
import { useResultMutation } from "../../hooks/mutations/use-result-mutation";
import { useResults } from "../../hooks/queries/use-results";
import AutoSaveTextField from "../autosave-text-field";
import GoalField from "../inputs/goal-field";
import CategoryTitle from "./category-title";
import { useMonthName } from "../../hooks/use-month-name";
import { useMasterPlanTemplate } from "../../hooks/use-master-plan-template";
import { GoalList } from "../goal-list/goal-list";
import { GoalListItem } from "../goal-list/goal-list-item";
import { useFillGoalArray } from "../../hooks/use-fill-goal-array";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { useMonthlyCheckInState } from "../../hooks/use-monthly-check-in-state";

function ResultSection({ disabled, category }) {
    const { user, month, year } = useMonthlyCheckInState();
    const userID = user?.id || "";
    const { status, data } = useResults({ user: userID, year, month, category });
    const queryClient = useQueryClient();
    const resultMutation = useResultMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchResults"]);
            queryClient.invalidateQueries(queryKeys.result.find);
            queryClient.invalidateQueries(queryKeys.result.get);
        },
    });
    const monthName = useMonthName(month);
    const masterPlanTemplate = useMasterPlanTemplate();
    const results = useFillGoalArray({
        array: data?.results,
        object: { id: null, category, year, month, description: "" },
    });

    return (
        <Box>
            <CategoryTitle
                title={masterPlanTemplate[category].checkIn.results.description + " - " + monthName}
                subheading={masterPlanTemplate[category].checkIn.results.subheading}
                style={{ marginBottom: 24 }}
                fontWeight={700}
            />
            {status === "loading" ? (
                <CircularProgress />
            ) : disabled ? (
                <GoalList>
                    {results.map((result, index) => (
                        <GoalListItem key={`result-${0}-${month}-${year}-${index}`}>{result.description}</GoalListItem>
                    ))}
                </GoalList>
            ) : (
                <Grid container style={{ flexGrow: 1 }} spacing={5}>
                    {results.map((result, index) => (
                        <Grid container item key={result.id || `result-${0}-${month}-${year}-${index}`}>
                            <Grid item xs={12}>
                                {disabled ? (
                                    <GoalField value={result.description} label={index + 1 + "."} disabled />
                                ) : (
                                    <AutoSaveTextField
                                        object={result}
                                        textFieldName="description"
                                        index={index}
                                        mutation={resultMutation}
                                        maxLength={165}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

ResultSection.propTypes = {
    disabled: PropTypes.bool.isRequired,
    category: PropTypes.number,
};

export default ResultSection;
