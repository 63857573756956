import { Box, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import MyCheckinButton from "./my-checkin-button";
import TrackedItemProgressCircle from "./tracked-item-progress-circle";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "32px 24px",
    },
    name: {
        fontSize: 24,
        fontWeight: 500,
        marginBottom: theme.spacing(4),
    },
    progressContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 128,
        marginBottom: theme.spacing(5),
    },
    progressCircle: {
        position: "absolute",
    },
}));

function TrackedItemRow(props) {
    const classes = useStyles();
    const { trackedItem, className } = props;

    const getLocaleDateTimeString = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
    };

    return (
        <Card className={classes.root + " " + className}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <Box className={classes.progressContainer}>
                                <TrackedItemProgressCircle
                                    progress={trackedItem.progress}
                                    goal={trackedItem.goal}
                                    size={128}
                                    thickness={2}
                                />
                            </Box>
                            <Typography align="center" style={{ fontSize: 24, marginBottom: 16 }}>
                                {trackedItem.progress} / {trackedItem.goal}
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container item justify="center" className={classes.name}>
                                {trackedItem.name}
                            </Grid>
                            <Grid container item justify="center">
                                {trackedItem.description}
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Grid item md={6} style={{ marginTop: 16, fontSize: 14 }}>
                            <Typography align="center">
                                Entered: {getLocaleDateTimeString(trackedItem.createdAt)}
                            </Typography>
                            <Typography align="center">
                                Last Updated: {getLocaleDateTimeString(trackedItem.updatedAt)}
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <MyCheckinButton
                                component={RouterLink}
                                to={`/employee/monthly-tracker/${trackedItem.id}`}
                                variant="contained"
                                color="primary"
                            >
                                Manage
                                <ArrowRightAltIcon />
                            </MyCheckinButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default TrackedItemRow;
