import React from "react";
import { Alert } from "@material-ui/lab";
import { differenceInDays } from "date-fns";
import { ManageBillingForm } from "./manage-billing-form";
import { ManageBillingLink } from "./manage-billing-link";

interface SubscriptionEndWarningProps {
    endsOn: Date;
}

function SubscriptionEndWarning({ endsOn }: SubscriptionEndWarningProps) {
    const getSubscriptionDaysRemaining = () => {
        return differenceInDays(endsOn, new Date());
    };

    return (
        <Alert severity="warning" style={{ borderRadius: 0, justifyContent: "center" }}>
            Your subscription will end in {getSubscriptionDaysRemaining()} days. To reactivate, please{" "}
            <ManageBillingForm style={{ display: "inline" }}>
                <ManageBillingLink />
            </ManageBillingForm>{" "}
            to be taken to your Billing Portal.
        </Alert>
    );
}

export { SubscriptionEndWarning };
