import React from "react";
import { Box, Container, Link, makeStyles, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Logo from "./logo";
import UserMenu from "./user-menu";
import { useCompanyState } from "../hooks/use-company-state";
import { urls } from "../config/urls";
import { NotificationMenu } from "./top-nav/notification-menu";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        height: "96px",
        alignItems: "center",
    },
    navbar: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    navbarRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 24,
    },
    companyName: {
        fontWeight: "600",
        fontSize: 18,
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(7),
    },
}));

function TopNav(props) {
    const classes = useStyles();
    const { coach = false, employee = false } = props;
    const { company } = useCompanyState();
    const getHomeLink = () => {
        const employeeDashboardUrl = urls.employee.dashboard.baseUrl;
        const coachEmployeePanelUrl = urls.coach.employeePanel.baseUrl;

        return employee ? employeeDashboardUrl : coach ? coachEmployeePanelUrl : employeeDashboardUrl;
    };

    return (
        <Box bgcolor="neutral.light" className={classes.root}>
            <Container className={classes.navbar}>
                <Link component={RouterLink} to={getHomeLink()}>
                    <Logo height={48} />
                </Link>
                <Box className={classes.navbarRight}>
                    <Typography className={classes.companyName}>{company.name}</Typography>
                    <NotificationMenu />
                    <UserMenu />
                </Box>
            </Container>
        </Box>
    );
}

export default TopNav;
