import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MyCheckinButton from "./my-checkin-button";
import MyCheckinTextField from "./my-checkin-text-field";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTrackedItemDelete } from "../hooks/mutations/use-tracked-item-delete";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

function AddGoalDialog(props) {
    const { open, onClose, onSave, name = "", description = "", id } = props;
    const history = useHistory();
    const [state, setState] = useState({ name, description });
    const [showWarning, setShowWarning] = useState(false);
    const queryClient = useQueryClient();
    const trackedItemDeleteMutation = useTrackedItemDelete({
        onSuccess: () => {
            queryClient.invalidateQueries("fetchTrackedItems");
            queryClient.invalidateQueries("getTrackedItem");
            history.push("/employee/monthly-tracker");
        },
    });

    useEffect(() => {
        setState({ name, description });
    }, [name, description, open]);

    const clearState = () => {
        setState({ name: "", description: "" });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    };

    const handleClose = () => {
        clearState();
        onClose();
    };

    const handleSave = () => {
        onSave(state);
        clearState();
        onClose();
    };

    const handleDelete = () => {
        if (!showWarning) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
            trackedItemDeleteMutation.mutate(id);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Key Commitment</DialogTitle>
            <DialogContent>
                <Grid container style={{ flexDirection: "column" }} spacing={4}>
                    <Grid item>
                        <MyCheckinTextField
                            label="Key Commitment"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <MyCheckinTextField
                            label="Description (optional)"
                            name="description"
                            multiline
                            rows={3}
                            value={state.description}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                {showWarning && (
                    <Alert color="warning">
                        Delete this goal? <strong>This cannot be undone.</strong> Click again to proceed.
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                {id && (
                    <IconButton onClick={handleDelete} color="primary">
                        <DeleteIcon />
                    </IconButton>
                )}
                <MyCheckinButton color="primary" onClick={handleClose}>
                    Cancel
                </MyCheckinButton>
                <MyCheckinButton variant="outlined" color="primary" onClick={handleSave}>
                    Save
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddGoalDialog;
