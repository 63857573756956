import { usePriorityActions } from "../queries/use-priority-actions";
import { useQueryStatus } from "../use-query-status";
import { useQueryState } from "./use-query-state";

export const usePriorityActionState = (params) => {
    const priorityActionQuery = usePriorityActions(params);
    const priorityActions = useQueryState(priorityActionQuery, "priorityActions");
    const queryStatus = useQueryStatus(priorityActionQuery);

    return {
        priorityActions,
        ...queryStatus,
    };
};
