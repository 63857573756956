import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import PrivacyNotice from "../legal/privacy-notice";
import PropTypes from "prop-types";

function PrivacyNoticeDialog({ isOpen, onClose }) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Privacy Notice</DialogTitle>
            <DialogContent>
                <PrivacyNotice />
            </DialogContent>
        </Dialog>
    );
}

PrivacyNoticeDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default PrivacyNoticeDialog;
