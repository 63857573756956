import React from "react";

export const EmployeeAppContext = React.createContext({
    year: new Date().getFullYear(),
    setYear: () => {},
    yearsArray: [new Date().getFullYear()],
    user: {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        permissions: {
            isCoach: false,
            isAdmin: false,
        },
        lastLogin: "",
        coaches: [],
        profileImage: "",
    },
    queries: {
        meetingQuery: null,
        goalQuery: null,
        coachQuery: null,
        priorityActionQuery: null,
        takeawayQuery: null,
        companyQuery: null,
        resultQuery: null,
    },
});
