import React from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";
import { TodoCheckbox } from "./todo-checkbox";
import { TodoItemText } from "./todo-item-text";
import { queryKeys } from "../../hooks/api/config/query-keys";
import { todoApi } from "../../util/api";
import { useCreateApi } from "../../hooks/api/use-create-api";
import { Todo } from "../../interfaces/todo";
import { useUpdateApi } from "../../hooks/api/use-update-api";
import { useQueryClient } from "react-query";

export interface TodoItemProps {
    todo: Todo;
}

const useStyles = makeStyles((theme) => ({
    todoItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        gap: theme.spacing(2),
        height: theme.spacing(7),
        padding: theme.spacing(3),
    },
}));

export const TodoItem = ({ todo }: TodoItemProps) => {
    const classes = useStyles();
    const [editing, setEditing] = React.useState(false);
    const [description, setDescription] = React.useState(todo.description);
    const [isNew, setIsNew] = React.useState(todo.id === "");
    const [id, setId] = React.useState(todo.id);

    const onSuccess = () => {
        queryClient.invalidateQueries(queryKeys.todo.get);
    };

    const createTodoMutation = useCreateApi<Todo>(todoApi, queryKeys.todo.create, {
        onSuccess,
    });
    const updateTodoMutation = useUpdateApi<Todo>(todoApi, queryKeys.todo.update, {
        onSuccess,
    });

    const queryClient = useQueryClient();

    React.useEffect(() => {
        setEditing(todo.id === "");
        setIsNew(todo.id === "");
        setId(todo.id);
    }, [todo]);

    const handleClick = () => {
        setEditing(true);
    };

    const saveTodo = async () => {
        if (isNew) {
            await createTodoMutation.create({
                description,
                isComplete: false,
                user: todo.user,
            });
            setIsNew(false);
        } else {
            await updateTodoMutation.update(id, {
                description,
                isComplete: todo.isComplete,
                user: todo.user,
            });
        }
    };

    const handleBlur = async () => {
        setEditing(false);
        if (description === "") {
            onSuccess(); // invalidate queries
            return;
        }
        await saveTodo();
    };

    const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            setEditing(false);
            await saveTodo();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleCheckboxClick = async () => {
        await updateTodoMutation.update(id, {
            isComplete: !todo.isComplete,
        });
    };

    return (
        <Card className={classes.todoItem}>
            <TodoCheckbox checked={todo.isComplete} onClick={handleCheckboxClick} />
            <TodoItemText
                editing={editing}
                onClick={handleClick}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
            >
                {description}
            </TodoItemText>
            {todo.completedAt && todo.isComplete && (
                <Typography>{new Date(todo.completedAt).toLocaleDateString()}</Typography>
            )}
        </Card>
    );
};
