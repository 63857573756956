import { useQuery } from "react-query";
import { userAPI } from "../../util/api";
import { User } from "../../interfaces/user";

export function useUsers(params: Partial<User>) {
    const queryKey = ["fetchUsers"];
    if (params) {
        Object.values(params).forEach((value: unknown) => queryKey.push(value as string));
    }
    return useQuery(queryKey, () => userAPI.find(params), { enabled: !!params });
}
