import { Box } from "@material-ui/core";
import React from "react";
import GoalSection from "./goal-section";
import PriorityActionSection from "./priority-action-section";
import { ObjectiveSection } from "./objective-section";
import { AdditionalResourcesSection } from "./additional-resources-section";

interface MasterPlanTabPanelProps {
    value: number;
    userID: string;
    disabled: boolean;
    year: number;
}

function MasterPlanTabPanel({ value, userID, disabled, year }: MasterPlanTabPanelProps) {

    const shouldRenderObjectiveSection = () => {
        return value === 0;
    };

    const shouldRenderAdditionalResourcesSection = () => {
        return value === 1;
    }

    return (
        <Box>
            {shouldRenderObjectiveSection() && (
                <ObjectiveSection userId={userID} category={value} year={year} disabled={disabled} />
            )}
            <GoalSection category={value} year={year} userID={userID} disabled={disabled} />
            {shouldRenderAdditionalResourcesSection() && (
                <AdditionalResourcesSection userId={userID} category={value} year={year} disabled={disabled} />
            )}
            <PriorityActionSection category={value} year={year} userID={userID} disabled={disabled} />
        </Box>
    );
}



export default MasterPlanTabPanel;
