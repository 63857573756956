import { useQuery } from "react-query";
import { useQueryKey } from "../queries/use-query-key";
import { CRUD } from "../../util/api/interfaces/crud";

interface ApiFindResult<T> {
    count: number;
    result: T[];
}

interface FindOptions<T> {
    onSuccess?: (data: T) => any;
    onError?: (err: unknown) => any;
    enabled?: boolean;
    refetchInterval?: number;
}

export const useFindApi = <T>(
    api: CRUD,
    key: string,
    params: Partial<T>,
    resultKey: string,
    options: FindOptions<T> = {}
) => {
    const { onSuccess, onError, enabled, refetchInterval } = options;
    const queryKey = useQueryKey(key, params);
    const findQueryFn = () => api.find(params);
    const findQuery = useQuery<Partial<T>, unknown, any>(queryKey, findQueryFn, {
        onSuccess,
        onError,
        enabled,
        refetchInterval,
    });

    const { error, status } = findQuery;

    const result = findQuery.data ? (findQuery.data[resultKey] as T[]) : [];
    const data: ApiFindResult<T> = {
        count: findQuery.data?.count || 0,
        result,
    };

    return {
        data,
        error,
        status,
    };
};
