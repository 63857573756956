import React, { ChangeEvent } from "react";
import MyCheckinTextField from "../../my-checkin-text-field";
import PropTypes from "prop-types";

interface SubmissionNoteFieldProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => any;
    value: string;
}

function SubmissionNoteField({ onChange, value }: SubmissionNoteFieldProps) {
    return <MyCheckinTextField fullWidth multiline rows={3} label="Notes" value={value} onChange={onChange} />;
}

SubmissionNoteField.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export { SubmissionNoteField };
