import { useContext } from "react";
import { AuthContext } from "../context/auth-context";
import { AuthState } from "../interfaces/auth-state";

export function useAuthState() {
    const state: AuthState = useContext(AuthContext);
    const isPending = state.status === "pending";
    const isError = state.status === "error";
    const isSuccess = state.status === "success";
    const isAuthenticated = state.user && isSuccess;
    return {
        ...state,
        isPending,
        isError,
        isSuccess,
        isAuthenticated,
    };
}
