const employeeGetStartedChecklistUrl = "https://mycheckin.zendesk.com/hc/en-us/articles/360061326411-Get-Started";

type AccessLevel = "employee" | "coach" | "admin";
interface Link {
    url: string;
    text: string;
    accessLevel: AccessLevel[];
}

export const links: Link[] = [
    {
        url: employeeGetStartedChecklistUrl,
        text: "Get Started",
        accessLevel: ["employee", "coach", "admin"],
    },
];

export const dialogTitle = "Welcome!";
export const dialogParagraphs = [
    "Your most important investment → <strong>your time</strong> and <strong>your focus.</strong>",
    "MyCheck-In is committed to helping you maximize that investment!",
    "To begin, just click…",
];
export const closingParagraphs = ["Enjoy the journey!", "Visit LEARN for additional information and inside scoop."];
export const dismissText = "Don't show this again";
export const closeButtonText = "Close";

export const dialogWidth = "sm";
export const closeButtonVariant = "contained";
export const closeButtonColor = "primary";
