import { Card, Container, Grid, IconButton, Link, makeStyles } from "@material-ui/core";
import { CellParams, Columns, DataGrid } from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PageTitle from "../../components/page-title";
import MyCheckinTextField from "../../components/my-checkin-text-field";
import { useAuthState } from "../../hooks/use-auth-state";
import { urls } from "../../config/urls";
import { Jot } from "../../interfaces/jot";
import { useFindApi } from "../../hooks/api/use-find-api";
import { jotAPI } from "../../util/api";
import { queryKeys } from "../../hooks/api/config/query-keys";

// TODO Update Data Grid to most recent release. May break this and Employees list.
// GridCellParams doesn't exist on Data Grid v4.0.0-alpha.14? Not exported from the package and can't find
// it in the GitHub repo. Mocking it for now until version upgrade.
interface GridCellParams {
    getValue: (param: string) => any;
}

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: "32px 24px",
    },
}));

const dateFormatter = (params: CellParams) => {
    const paramsValue = params.value?.toString() || "";
    const date = new Date(paramsValue);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();
    return `${dateString} ${timeString}`;
};

const columns: Columns = [
    {
        field: "title",
        headerName: "Title",
        flex: 3,
        renderCell: (params: GridCellParams) => {
            return (
                <Link
                    component={RouterLink}
                    to={`${urls.employee.viewEntry.baseUrl}/${params.getValue("id")}`}
                    color="inherit"
                >
                    {params.getValue("title")}
                </Link>
            );
        },
    },
    {
        field: "createdAt",
        headerName: "Created",
        flex: 1,
        valueFormatter: dateFormatter,
    },
    { field: "updatedAt", headerName: "Modified", flex: 1, valueFormatter: dateFormatter },
    {
        field: "edit",
        headerName: " ",
        flex: 0.5,
        renderCell: (params: GridCellParams) => {
            return (
                <IconButton component={RouterLink} to={`${urls.employee.editEntry.baseUrl}/${params.getValue("id")}`}>
                    <EditIcon />
                </IconButton>
            );
        },
    },
];

const JotsLog = () => {
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [entries, setEntries] = useState<Jot[]>([]);
    const { user } = useAuthState();
    const findJots = useFindApi<Jot>(jotAPI, queryKeys.jot.find, { user: user?.id }, "jots");

    useEffect(() => {
        setEntries(findJots.data.result.filter((entry) => entry.title.toLowerCase().includes(search.toLowerCase())));
    }, [findJots.data.result, search]);

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearch(value);
    };

    return (
        <Container>
            <PageTitle>Jots Log</PageTitle>
            <Card className={classes.contentContainer}>
                <Grid container item xs={12} justify="flex-end">
                    <MyCheckinTextField
                        label="Search"
                        InputProps={{ endAdornment: <SearchIcon /> }}
                        value={search}
                        onChange={handleSearchChange}
                    />
                </Grid>
                <Grid container item xs={12} style={{ height: 512, width: "100%", marginTop: 32 }}>
                    <DataGrid
                        columns={columns}
                        rows={entries}
                        pageSize={7}
                        disableColumnResize
                        loading={findJots.status === "loading"}
                        sortModel={[{ field: "updatedAt", sort: "desc" }]}
                        disableSelectionOnClick
                    />
                </Grid>
            </Card>
        </Container>
    );
};

export default JotsLog;
