import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MyCheckinTextField from "../../my-checkin-text-field";
import MyCheckinButton from "../../my-checkin-button";
import {
    cancelButtonText,
    confirmButtonProps,
    confirmButtonText,
    dialogMaxWidth,
    // dialogTitle,
    // inputFieldProps,
} from "./config";
import { useStyles } from "./edit-division-dialog.styles";
import PropTypes from "prop-types";
import { useGetApi } from "../../../hooks/api/use-get-api";
import { Company } from "../../../interfaces/company/company";
import { companyAPI } from "../../../util/api";
import { queryKeys } from "../../../hooks/api/config/query-keys";

interface EditDivisionDialogProps {
    open: boolean;
    onClose: () => unknown;
    onSave: ({ divisionId, name }: { divisionId: string; name: string }) => unknown;
    name?: string;
    divisionId?: string;
}

const EditDivisionDialog = ({ open, onClose, onSave, name, divisionId }: EditDivisionDialogProps) => {
    const classes = useStyles();
    const { data: company } = useGetApi<Company>(companyAPI, queryKeys.company.get, "");
    const [updatedName, setUpdatedName] = useState<string | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setUpdatedName(value);
    };

    const handleCancel = () => {
        setUpdatedName(null);
        onClose();
    };

    const handleSave = () => {
        if (!divisionId || !updatedName) {
            onClose();
            return;
        }
        if (updatedName === name) {
            onClose();
            return;
        }
        onSave({ divisionId, name: updatedName });
        setUpdatedName(null);
    };

    return (
        <Dialog open={open} maxWidth={dialogMaxWidth} fullWidth onClose={onClose}>
            <DialogTitle>Edit {company?.organizationalUnitName?.singular || "Division"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <MyCheckinTextField
                    label={`${company?.organizationalUnitName?.singular} Name`}
                    className={classes.inputField}
                    value={updatedName || name}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <MyCheckinButton onClick={handleCancel}>{cancelButtonText}</MyCheckinButton>
                <MyCheckinButton {...confirmButtonProps} onClick={handleSave}>
                    {confirmButtonText}
                </MyCheckinButton>
            </DialogActions>
        </Dialog>
    );
};

EditDivisionDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    name: PropTypes.string,
    divisionId: PropTypes.string,
};

export { EditDivisionDialog };
